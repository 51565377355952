<mat-dialog-content>
    <div class="title-section mb-3" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle style="cursor: move;">
        <h5 class="title">Crea un gruppo</h5>
    </div>
    <nz-divider></nz-divider>
    <form [formGroup]="formGruppo" (ngSubmit)="onSubmit()" id="formGruppo">
        <div id="indirizzoObbl" class="form-text text-start">*campo obbligatorio</div>        
        <div class="row mt-3">
            <div class="col-md-6">
                <nz-form-item class="flex-column mb-2">
                    <nz-form-label nzRequired class="text-start">Nome</nz-form-label>
                    <nz-form-control>
                        <input nz-input formControlName="nome" nzSize="large" />
                    </nz-form-control>
                </nz-form-item>
                <div class="error-messages">
                    <ng-container *ngFor="let error of error_messages.nome">                                                                    
                        <nz-alert nzType="error" [nzMessage]="error.message" nzShowIcon nzCloseable *ngIf="formGruppo.get('nome')?.hasError(error.type) && (formGruppo.get('nome')?.dirty || formGruppo.get('nome')?.touched)"></nz-alert>
                    </ng-container>
                </div>
            </div>
            <div class="col-md-6">                                                                            
                <nz-form-item class="flex-column mb-2">
                    <nz-form-label nzRequired class="text-start">Ricerca cliente</nz-form-label>
                    <nz-form-control>
                        <nz-select nzShowSearch nzAllowClear formControlName="cliente" nzSize="large" class="w-100" (nzOnSearch)="onSeleziona($event)">
                            <nz-option *ngFor="let cliente of filteredClienti" [nzValue]="cliente" [nzLabel]="cliente.cognome + ' ' + cliente.nome + ' | ' + (cliente.data_nascita | date: 'dd MMM yyyy')"></nz-option>
                        </nz-select> 
                    </nz-form-control>
                </nz-form-item>                         
                <div class="error-messages">
                    <ng-container *ngFor="let error of error_messages.cliente">                                                                    
                        <nz-alert nzType="error" [nzMessage]="error.message" nzShowIcon nzCloseable *ngIf="formGruppo.get('cliente')?.hasError(error.type) && (formGruppo.get('cliente')?.dirty || formGruppo.get('cliente')?.touched)"></nz-alert>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <nz-form-item class="flex-column mb-2">
                    <nz-form-label class="text-start">Descrizione</nz-form-label>
                    <nz-form-control>
                        <textarea nz-input [nzAutosize]="{ minRows: 3, maxRows: 6 }" formControlName="descrizione" nzSize="large" ></textarea>
                    </nz-form-control>
                </nz-form-item>                 
            </div>
        </div>
        <div class="row mt-3">
            <div class="col">                                    
                <nz-upload [(nzFileList)]="immagineList" [nzShowButton]="immagineList.length <= 0" [nzBeforeUpload]="onImmagineUpload" nzAccept="image/*" [nzRemove]="onRemoveImmagineUpload">
                    <button nzSize="large" nz-button type="button" class="bottone mt-3" style="width: 100%">
                        <span class="align-middle" nz-icon nzType="upload"></span>
                        Carica immagine gruppo
                    </button>
                </nz-upload>

                <div class="error-messages">
                    <ng-container *ngFor="let error of error_messages.immagine">
                        <nz-alert nzType="error" [nzMessage]="error.message" nzShowIcon nzCloseable *ngIf="formGruppo.get('immagine')?.hasError(error.type)"></nz-alert>
                    </ng-container>
                </div>                                                                    
            </div>
        </div>
    </form>
    <nz-divider></nz-divider>
</mat-dialog-content>
<mat-dialog-actions>
    <button type="button" nz-button nzSize="large" class="me-2" (click)="onClose()">Annulla</button>
    
    <span nzTooltipTitle="Per aggiungere un gruppo, inserire tutti i campi con l'asterisco" nz-tooltip nzTooltipPlacement="top" *ngIf="!formGruppo.valid">
        <button nz-button nzSize="large" nzType="primary" [disabled]="true">Crea Gruppo</button>
    </span>  
    <button form="formGruppo" nz-button nzType="primary" nzSize="large" *ngIf="formGruppo.valid">Crea gruppo</button>
</mat-dialog-actions>