import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './shared/auth.guard';
import { OperatoreGuard } from './shared/operatore.guard';
import { ClientiComponent } from './clienti/clienti.component';
import { GruppiComponent } from './gruppi/gruppi.component';
import { AuthComponent } from './login/login.component';
import { DettagliClienteComponent } from './clienti/dettagli-cliente/dettagli-cliente.component';
import { DettagliGruppoComponent } from './gruppi/dettagli-gruppo/dettagli-gruppo.component';
import { StatisticheComponent } from './statistiche/statistiche.component';
import { UtentiComponent } from './utenti/utenti.component';
import { DettagliUtenteComponent } from './utenti/dettagli-utente/dettagli-utente.component';
import { PromozioniComponent } from './promozioni/promozioni.component';
import { DettagliPromozioneComponent } from './promozioni/dettagli-promozione/dettagli-promozione.component';
import { CategorieComponent } from './categorie/categorie.component';
import { DettagliCategoriaComponent } from './categorie/dettagli-categoria/dettagli-categoria.component';
import { LogComponent } from './log/log.component';
import { MenuComponent } from './menu/menu.component';
import { HomeComponent } from './home/home.component';
import { PrimaNotaComponent } from './prima-nota/prima-nota.component';
import { ProdottiComponent } from './prodotti/prodotti.component';
import { DettagliProdottoComponent } from './prodotti/dettagli-prodotto/dettagli-prodotto.component';
import { StoricoOrdiniComponent } from './storico-ordini/storico-ordini.component';
import { DettagliStoricoOrdineComponent } from './storico-ordini/dettagli-storico-ordine/dettagli-storico-ordine.component';
import { DettagliTesseramentoComponent } from './tesseramenti/dettagli-tesseramento/dettagli-tesseramento.component';
import { TesseramentiComponent } from './tesseramenti/tesseramenti.component';
import { MovimentiCassaComponent } from './prima-nota/movimenti-cassa/movimenti-cassa.component';
import { MovimentiMetodiComponent } from './prima-nota/movimenti-metodi/movimenti-metodi.component';
import { DettagliMovimentoCassaComponent } from './prima-nota/dettagli-movimento-cassa/dettagli-movimento-cassa.component';
import { DettagliMovimentoMetodiComponent } from './prima-nota/dettagli-movimento-metodi/dettagli-movimento-metodi.component';
import { DettaglioPuntoRitiroComponent } from './punti-ritiro/dettaglio-punto-ritiro/dettaglio-punto-ritiro.component';
import { PuntiRitiroComponent } from './punti-ritiro/punti-ritiro.component';
import { TesseramentiCardVialatteaComponent } from './tesseramenti-card-vialattea/tesseramenti-card-vialattea.component';
import { TesseramentiCardOpenComponent } from './tesseramenti-card-open/tesseramenti-card-open.component';
import { TesseramentiAssicurazioniComponent } from './tesseramenti-assicurazioni/tesseramenti-assicurazioni.component';
import { TesseramentiGiornalieriVialatteaComponent } from './tesseramenti-giornalieri-vialattea/tesseramenti-giornalieri-vialattea.component';
import { TesseramentiSciclubComponent } from './tesseramenti-sciclub/tesseramenti-sciclub.component';
import { ClientiNewsletterComponent } from './clienti-newsletter/clienti-newsletter.component';
import { CodiciScontoComponent } from './codici-sconto/codici-sconto.component';
import { DettaglioCodiciScontoComponent } from './codici-sconto/dettaglio-codici-sconto/dettaglio-codici-sconto.component';
import { ConfigurazioniComponent } from './configurazioni/configurazioni.component';
import { DettaglioConfigurazioneComponent } from './configurazioni/dettaglio-configurazione/dettaglio-configurazione.component';

const routes: Routes = [
  {
    path: '', component: MenuComponent, canActivateChild: [AuthGuard], children: [
      { path: '', redirectTo: 'home', pathMatch: 'full' },
      { path: 'home', component: HomeComponent, data: { title: 'Home' } },
      { path: 'clienti', component: ClientiComponent, data: { title: 'Gestione clienti' } },
      { path: 'clienti/:id', component: DettagliClienteComponent, data: { title: 'Dettaglio cliente' } },
      { path: 'categorie', component: CategorieComponent, data: { title: 'Gestione categorie' } },
      { path: 'categorie/:id', component: DettagliCategoriaComponent, data: { title: 'Dettaglio categoria' } },
      { path: 'gruppi', component: GruppiComponent, data: { title: 'Gestione gruppi' } },
      { path: 'gruppi/:id', component: DettagliGruppoComponent, data: { title: 'Dettaglio gruppo' } },
      { path: 'tesseramenti', component: TesseramentiComponent, data: { title: 'Gestione ordini / tesseramenti' } },
      { path: 'tesseramenti/:id', component: DettagliTesseramentoComponent, data: { title: 'Dettaglio ordine / tesseramento' } },
      { path: 'prodotti', component: ProdottiComponent, data: { title: 'Gestione prodotti' } },
      { path: 'prodotti/:id', component: DettagliProdottoComponent, data: { title: 'Dettaglio prodotto' } },      
      { path: 'storico', component: StoricoOrdiniComponent, data: { title: 'Gestione storico ordini' } },
      { path: 'storico/:id', component: DettagliStoricoOrdineComponent, data: { title: 'Dettaglio ordine' } },
      { path: 'statistiche', component: StatisticheComponent, data: { title: 'Statistiche' } },
      { path: 'utenti', component: UtentiComponent, canActivateChild: [OperatoreGuard], data: { title: 'Gestione operatori' } },
      { path: 'utenti/:id', component: DettagliUtenteComponent, canActivateChild: [OperatoreGuard], data: { title: 'Dettaglio operatore' } },
      { path: 'promozioni', component: PromozioniComponent, data: { title: 'Gestione promozioni' } },
      { path: 'promozioni/:id', component: DettagliPromozioneComponent, data: { title: 'Dettaglio promozione' } },
      { path: 'codici-sconto', component: CodiciScontoComponent, data: { title: 'Gestione codici sconto' } },
      { path: 'codici-sconto/:id', component: DettaglioCodiciScontoComponent, data: { title: 'Dettaglio codici sconto' } },
      { path: 'log', component: LogComponent, canActivateChild: [OperatoreGuard], data: { title: 'Log operazioni' } },
      { path: 'movimenti', component: PrimaNotaComponent, data: { title: 'Gestione movimenti' } },
      { path: 'movimenti/cassa', component: MovimentiCassaComponent, data: { title: 'Gestione movimenti cassa' } },
      { path: 'movimenti/:id/cassa', component: DettagliMovimentoCassaComponent, data: { title: 'Dettaglio movimento cassa' } },
      { path: 'movimenti/altri-metodi', component: MovimentiMetodiComponent, data: { title: 'Gestione movimenti altri metodi' } },
      { path: 'movimenti/:id/altri-metodi', component: DettagliMovimentoMetodiComponent, data: { title: 'Dettaglio movimento altri metodi' } },
      { path: 'punti-ritiro', component: PuntiRitiroComponent, data: { title: 'Gestione punti di ritiro' } },
      { path: 'punti-ritiro/:id', component: DettaglioPuntoRitiroComponent, data: { title: 'Dettaglio punto di ritiro' } },
      { path: 'card-vialattea', component: TesseramentiCardVialatteaComponent, data: { title: 'Gestione Tesseramenti Card Vialattea' } },
      { path: 'card-skiopen', component: TesseramentiCardOpenComponent, data: { title: 'Gestione Tesseramenti Card SkiOpen' } },
      { path: 'assicurazioni', component: TesseramentiAssicurazioniComponent, data: { title: 'Gestione Tesseramenti Assucurazioni' } },
      { path: 'giornalieri-vialattea', component: TesseramentiGiornalieriVialatteaComponent, data: { title: 'Gestione Tesseramenti Giornalieri Vialattea' } },
      { path: 'tesseramenti-sciclub', component: TesseramentiSciclubComponent, data: { title: 'Gestione Tesseramenti Sci Club' } },
      { path: 'clienti-newsletter', component: ClientiNewsletterComponent, data: { title: 'Gestione Clienti con Newsletter' } },
      { path: 'configurazioni', component: ConfigurazioniComponent, data: { title: 'Gestione Configurazioni' } },
      { path: 'configurazioni/:id', component: DettaglioConfigurazioneComponent, data: { title: 'Dettaglio Configurazione' } },
    ]
  },
  { path: 'login', component: AuthComponent, data: { title: 'Login' } },
  { path: '**', redirectTo: 'home' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: "top" })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
