import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/service/api.service';
import { ModificaUtentePasswordComponent } from './modifica-utente-password/modifica-utente-password.component';
import { Utente } from 'src/app/shared/interfacce';
import * as CryptoJS from "crypto-js";

@Component({
  selector: 'app-modifica-utente',
  templateUrl: './modifica-utente.component.html',
  styleUrls: ['./modifica-utente.component.css']
})
export class ModificaUtenteComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<ModificaUtenteComponent>, @Inject(MAT_DIALOG_DATA) public data: { id: number }, private apiService: ApiService, private dialog: MatDialog) { }

  formUtente!: FormGroup;

  utente?: Utente

  old_password!: string;
  hide: boolean = true;

  error_messages = {
    'username': [
      { type: 'required', message: "Inserire un username per procedere con la modifica dell'utente" },
    ],
    'password': [
      { type: 'required', message: "Inserire una password per procedere con la modifica dell'utente" },
      { type: 'minlength', message: "Inserire almeno 3 caratteri" },
      { type: 'checkPassword', message: "La password inserita è diversa da quella precedente!" },
    ],
    'tipo': [
      { type: 'required', message: "Inserire un tipo per procedere con la modifica dell'utente" },
    ]
  }

  ngOnInit(): void {

    // Creo il form con i controlli
    this.formUtente = new FormGroup({
      username: new FormControl(null, Validators.required),
      password: new FormControl(null),
      tipo: new FormControl(null, Validators.required)
    })

    // Recupero l'utente in base all'id
    this.apiService.getUtente(this.data.id).subscribe({
      next: (data) => this.utente = data,
      error: (err) => this.apiService.Error(err.error.message),
      complete: () => {

        this.old_password = this.utente!.password;

        // Associo i dati dell'utente al form
        this.formUtente.setValue({
          username: this.utente?.username,
          password: '',
          tipo: this.utente?.tipo

        })
      }
    })

  }

  onSubmit() {

    // Formo l'oggetto per l'api
    let utente = {
      username: this.formUtente.get('username')?.value,
      password: this.formUtente.get('password')?.value || "",
      tipo: this.formUtente.get('tipo')?.value
    }

    // Se è stata inserita una password proced
    if (utente.password.length > 0) {

      // Cripto la password inserita 
      let new_password = CryptoJS.SHA1(utente.password).toString();

      // Controllo se la nuova password è diversa da quella vecchia
      if (this.old_password != new_password) {

        // Apro il modal con la conferma di voler modificare la password
        let dialogPassword = this.dialog.open(ModificaUtentePasswordComponent, { autoFocus: false, width: '1000px' });
        dialogPassword?.afterClosed().subscribe((confirm: boolean) => {
          // Se ha confermato procedo
          if (confirm) {

            // Effettuo l'update dell'utente con i nuovi dati
            this.apiService.updateUtente(this.data.id, utente).subscribe({
              error: (err) => this.apiService.Error(err.error.message),
              complete: () => {

                // Effettuo l'update della password dell'utente con i nuovi dati
                this.apiService.updatePasswordUtente(this.data.id, utente.password).subscribe({
                  error: (err) => this.apiService.Error(err.error.message),
                  complete: () => this.dialogRef.close(true)
                })

              }
            })

          }
        })
      } else {

        // Effettuo l'update dell'utente con i nuovi dati
        this.apiService.updateUtente(this.data.id, utente).subscribe({
          next: (data) => this.dialogRef.close(true),
          error: (err) => this.apiService.Error(err.error.message)
        })

      }
    } else {

      // Effettuo l'update dell'utente con i nuovi dati
      this.apiService.updateUtente(this.data.id, utente).subscribe({
        next: (data) => this.dialogRef.close(true),
        error: (err) => this.apiService.Error(err.error.message)        
      })

    }

  }

  onClose() {
    this.dialogRef.close(false);
  }

}