import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { StorageService } from '../service/storage.service';

@Injectable({
  providedIn: 'root'
})
export class OperatoreGuard implements CanActivate {
  constructor(private storageService: StorageService, private router: Router) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const user = this.storageService.getUser();
    if (!user.id) {
      this.router.navigate(["/home"]);
      return false;
    }

    if (user.tipo.toLocaleLowerCase() === 'operatore') {
      this.router.navigate(['/home']);
      return false;
    }
    return true;
  }

}