<div class="p-3">
    <ng-template #codiciScontoTitle>    
        <div class="row p-md-3 p-1 align-items-center">            
            <div class="col-lg-8">
                <h3 class="mb-0" style="white-space: break-spaces;">Codice: <span class="text-muted">{{codice_sconto?.codice}}</span></h3>                
            </div>     
            <div class="col-lg-4">                  
                <nz-button-group class="w-100">
                    <button nz-button nzSize="large" class="w-100 crop" type="button" (click)="onModifica()">Modifica il codice sconto</button>
                    <button nz-button nzSize="large" class="w-25" nz-dropdown [nzDropdownMenu]="menu1" nzPlacement="bottomRight">
                        <span nz-icon nzType="ellipsis"></span>
                    </button>
                </nz-button-group>
                <nz-dropdown-menu #menu1="nzDropdownMenu">
                    <ul nz-menu>                        
                        <li nz-menu-item class="p-3" (click)="onElimina()">Elimina il codice sconto <i class="ms-2 bi bi-trash3"></i></li>                        
                    </ul>
                </nz-dropdown-menu>
            </div>          
        </div>
    </ng-template>
    <nz-card [nzTitle]="codiciScontoTitle" class="mt-5 mb-5">
        <div class="row p-md-3 p-1">
            <div class="col">
                <nz-breadcrumb>
                    <nz-breadcrumb-item>
                        <a routerLink="/home">
                            <span nz-icon nzType="home"></span>
                        </a>                  
                    </nz-breadcrumb-item>
                    <nz-breadcrumb-item>
                      <a routerLink="/codici-sconto">                    
                        <span>Codici sconto</span>
                      </a>
                    </nz-breadcrumb-item>
                    <nz-breadcrumb-item>Dettaglio codice sconto</nz-breadcrumb-item>
                </nz-breadcrumb>
            </div>
        </div>
        <div class="row p-md-3 p-1" style="padding-top: 0px !important;">
            <div class="col">
                <div class="mt-3">
    
                    <ng-template #datiHeader>
                        <div class="header">
                            <h5 class="d-inline text-white">Dati</h5>                        
                        </div>                    
                    </ng-template>
    
                    <nz-list nzBordered [nzHeader]="datiHeader" class="lista">
                        <nz-list-item>
                            <span nz-typography><span class="text-muted">N°</span></span> {{codice_sconto?.id}}
                        </nz-list-item>
                        <nz-list-item>
                            <span nz-typography><span class="text-muted">Codice:</span></span> {{codice_sconto?.codice}}
                        </nz-list-item>
                        <nz-list-item>
                            <span nz-typography><span class="text-muted">Descrizione:</span></span> {{codice_sconto?.descrizione || '-'}}
                        </nz-list-item>
                        <nz-list-item [routerLink]="'/clienti/' + codice_sconto?.id_cliente" style="cursor: pointer;">
                            <span nz-typography><span class="text-muted">Cliente:</span></span> {{codice_sconto?.cliente}}
                        </nz-list-item>
                        <nz-list-item>
                            <span nz-typography><span class="text-muted">Valore:</span></span> {{codice_sconto?.valore | currency: 'EUR'}}
                        </nz-list-item>
                        <nz-list-item>
                            <span nz-typography><span class="text-muted">Valido Dal:</span></span> {{codice_sconto?.inizio_validita | date: 'dd/MM/yyyy'}}
                        </nz-list-item>
                        <nz-list-item>
                            <span nz-typography><span class="text-muted">Valido Fino Al:</span></span> {{codice_sconto?.fine_validita | date: 'dd/MM/yyyy'}}
                        </nz-list-item>                        
                        <nz-list-item class="p-0">
                            <nz-collapse nzAccordion [nzExpandIconPosition]="'right'" class="w-100" style="background-color: transparent; border: 0px solid; padding-left: 8px;">
                                <nz-collapse-panel [nzHeader]="'Altro'" style="border-bottom: 0px;" class="cursor-pointer">
                                    <nz-list-item>
                                        <span nz-typography><span class="text-muted">Data creazione:</span></span> {{codice_sconto?.data_creazione | date: 'dd/MM/yyyy HH:mm'}}
                                    </nz-list-item>
                                    <nz-list-item>
                                        <span nz-typography><span class="text-muted">Data aggiornamento:</span></span> {{codice_sconto?.data_aggiornamento | date: 'dd/MM/yyyy HH:mm'}}
                                    </nz-list-item>
                                </nz-collapse-panel>
                              </nz-collapse>
                        </nz-list-item>                    
                    </nz-list>                
                </div>            
            </div>
            
        </div>
    </nz-card>
</div>