import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/service/api.service';
import { CodiceSconto } from 'src/app/shared/interfacce';
import { ModificaCodiciScontoComponent } from '../modifica-codici-sconto/modifica-codici-sconto.component';
import { EliminaCodiciScontoComponent } from '../elimina-codici-sconto/elimina-codici-sconto.component';

@Component({
  selector: 'app-dettaglio-codici-sconto',
  templateUrl: './dettaglio-codici-sconto.component.html',
  styleUrls: ['./dettaglio-codici-sconto.component.css']
})
export class DettaglioCodiciScontoComponent implements OnInit {

  constructor(private apiService: ApiService, private route: ActivatedRoute, private dialog: MatDialog, private router: Router) { }

  codice_sconto?: CodiceSconto;

  carica: boolean = true;

  ngOnInit(): void {

    // Recupero l'id passato nell'url
    this.route.params.subscribe(params => {
      const id = params['id'];
      this.getData(id);
    });
  }

  getData(id: number) {

    // Recupero i dati del codici sconto
    this.apiService.getCodiceSconto(id).subscribe({
      next: (data) => this.codice_sconto = data,
      error: (err) => this.router.navigate(['/codici-sconto']),
      complete: () => this.carica = false
    })

  }

  onIndietro() {
    this.router.navigate(['/codici-sconto'])
  }

  onModifica() {
    this.dialog.closeAll();
    var dialogRef = this.dialog.open(ModificaCodiciScontoComponent, { data: { id: this.codice_sconto!.id }, autoFocus: false, width: '1000px' });
    dialogRef?.afterClosed().subscribe(() => {
      this.getData(this.codice_sconto!.id);
    })
  }

  onElimina() {
    this.dialog.closeAll();
    this.dialog.open(EliminaCodiciScontoComponent, { data: { id: this.codice_sconto!.id }, autoFocus: false, width: '750px' })
  }

}