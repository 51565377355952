import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ApiService } from 'src/app/service/api.service';
import { Cliente } from 'src/app/shared/interfacce';

@Component({
  selector: 'app-aggiungi-codici-sconto',
  templateUrl: './aggiungi-codici-sconto.component.html',
  styleUrls: ['./aggiungi-codici-sconto.component.css']
})
export class AggiungiCodiciScontoComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<AggiungiCodiciScontoComponent>, private apiService: ApiService, private datePipe: DatePipe) { }

  formCodiceSconto!: FormGroup;

  clienti: Cliente[] = [];
  filteredClienti: Cliente[] = [];

  error_messages = {
    'cliente': [
      { type: 'required', message: "Inserire un cliente per procedere con la creazione di un codice sconto" },
    ],
    'codice': [
      { type: 'required', message: "Inserire un codice per procedere con la creazione di un codice sconto" },
    ],
    'valore': [
      { type: 'required', message: "Inserire un valore per procedere con la creazione di un codice sconto" },
    ],
    'validita': [
      { type: 'required', message: 'Inserire una data di inizio di validita per procedere con la creazione di un codice sconto' },
      { type: 'matDatepickerFilter', message: 'Inserire una data valida' }
    ]
  }

  ngOnInit(): void {

    // Creo il form
    this.formCodiceSconto = new FormGroup({
      cliente: new FormControl(null, Validators.required),
      codice: new FormControl(null, Validators.required),
      descrizione: new FormControl(null),
      validita: new FormControl(null, Validators.required),
      valore: new FormControl(null, Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')),
    })

    // Recupero i clienti
    this.apiService.getClienti().subscribe({
      next: (data) => this.clienti = [...data],
      error: (err) => this.apiService.Error(err.error.message),
      complete: () => this.filteredClienti = this.clienti.slice(0, 10)
    })

  }

  onSeleziona(ricerca: any) {
    this.filteredClienti = this.clienti.filter(cliente => (cliente.nome.trim() + ' ' + cliente.cognome.trim()).toLocaleLowerCase().includes(ricerca.toLocaleLowerCase()) || (cliente.cognome.trim() + ' ' + cliente.nome.trim()).toLocaleLowerCase().includes(ricerca.toLocaleLowerCase())).slice(0, 10)
  }

  onSubmit() {

    let codice_sconto = {
      id_cliente: this.formCodiceSconto.get('cliente')?.value,
      codice: this.formCodiceSconto.get('codice')?.value || '',
      descrizione: this.formCodiceSconto.get('descrizione')?.value,
      inizio_validita: this.datePipe.transform((this.formCodiceSconto.get('validita')?.value ? this.formCodiceSconto.get('validita')?.value[0] : this.formCodiceSconto.get('validita')?.value), 'yyyy-MM-dd'),
      fine_validita: this.datePipe.transform(this.formCodiceSconto.get('validita')?.value ? this.formCodiceSconto.get('validita')?.value[1] : this.formCodiceSconto.get('validita')?.value, 'yyyy-MM-dd'),
      valore: this.formCodiceSconto.get('valore')?.value || 0
    }

    // Aggiungo il codice sconto
    this.apiService.addCodiceSconto(codice_sconto).subscribe({
      next: (data) => this.apiService.setCodiciSconto(),
      error: (err) => this.apiService.Error(err.error.message),
      complete: () => this.dialogRef.close()
    })
  }

  onClose() {
    this.dialogRef.close();
  }

}
