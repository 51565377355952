<div class="p-3">    
  <ng-template #clientiTitle>
    <div class="row p-md-3 p-1">
      <div class="col-lg-1 mt-2">
        <button nz-button nzSize="large" class="w-100" type="button" (click)="onIndietro()"><i style="font-size: 20px;" class="bi bi-arrow-left"></i></button>
      </div>
      <div class="col-lg-8 mt-2">
        <div class="row">
          <div class="col-md-6">
            <input nz-input placeholder="Cerca Nome o Cognome" nzSize="large" [(ngModel)]="clienteFilter" />
          </div>
          <div class="col-md-6 mt-md-0 mt-3">
            <input nz-input placeholder="Cerca Email" nzSize="large" [(ngModel)]="emailFilter" />
          </div>
        </div>
      </div>
      <div class="col-lg-3 mt-2" style="text-align: right;">        
        <nz-button-group class="w-100">
          <button nz-button nzSize="large" class="w-100 crop" type="button" (click)="onAdd()">Crea cliente</button>
          <button nz-button nzSize="large" class="w-25" nz-dropdown [nzDropdownMenu]="menu1" nzPlacement="bottomRight">
            <span nz-icon nzType="ellipsis"></span>
          </button>
        </nz-button-group>
        <nz-dropdown-menu #menu1="nzDropdownMenu">
          <ul nz-menu>
            <li nz-menu-item class="p-3" (click)="onExport()">Esporta clienti <i class="ms-2 bi bi-file-earmark-person-fill"></i></li>
          </ul>
        </nz-dropdown-menu>
      </div>
    </div>
  </ng-template>
  <nz-card [nzTitle]="clientiTitle">
    <div class="row  p-md-3 p-1">
      <nz-table #clientiTable 
      *ngIf="!carica"
      nzShowSizeChanger      
      [nzTotal]="totale"
      [nzData]="clienti"
      nzNoResult="Nessun Cliente trovato"
      [nzPageSize]="pageSize"
      [nzFrontPagination]="false"
      [nzLoading]="loading"             
      [nzPageIndex]="pageIndex"
      [nzBordered]="true" 
      [nzPageSizeOptions]="[5, 10, 25, 50, 100, 150, 200, 250, 300, 350, 400, 500, 750, 1000]"
      (nzQueryParams)="onParamsChange($event)">
        <thead>
          <tr>
            <th [nzSortFn]="onOrdinamento" nzColumnKey="cognome" [nzShowFilter]="true" [nzCustomFilter]="true" [nzFilterFn]="clienteFilter">Cognome e Nome</th>
            <th [nzSortFn]="onOrdinamento" nzColumnKey="data_nascita" class="text-center">Data Nascita</th>
            <th [nzSortFn]="onOrdinamento" nzColumnKey="email" [nzShowFilter]="true" [nzCustomFilter]="true" [nzFilterFn]="emailFilter" class="text-start">Email</th>
            <th [nzSortFn]="onOrdinamento" nzColumnKey="data_creazione" class="text-end">Data Creazione</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let cliente of clientiTable.data" [routerLink]="['/clienti/', cliente.id]" class="cursor-pointer">
            <td>{{cliente.cognome}} {{cliente.nome}} </td>
            <td class="text-center">{{cliente.data_nascita | date: 'dd/MM/yyyy'}}</td>
            <td class="text-start">{{cliente.email}}</td>
            <td class="text-end">{{cliente.data_creazione | date: 'dd/MM/yyyy'}}</td>
          </tr>
        </tbody>
      </nz-table>
  
      <app-caricamento *ngIf="carica" class="d-flex align-items-center justify-content-center"></app-caricamento>
    </div>
  </nz-card>
</div>