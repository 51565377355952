<mat-dialog-content>
    <div class="title-section mb-3" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle style="cursor: move;">
        <h5 class="title d-inline">Crea Categoria</h5>
    </div>
    <nz-divider></nz-divider>
    <form [formGroup]="formCategoria" (ngSubmit)="onSubmit()" id="formCategoria">
        <div class="row">
            <div id="indirizzoObbl" class="form-text text-start">* campo obbligatorio</div>
        </div>
        <div class="row mt-3">
            <div class="col">                
                <nz-form-item class="flex-column mb-2">
                    <nz-form-label nzRequired nzFor="nome" class="text-start">Nome</nz-form-label>
                    <nz-form-control >
                        <input nz-input formControlName="nome" id="nome" nzSize="large" required>                 
                    </nz-form-control>
                </nz-form-item> 
                <div class="error-messages">
                    <ng-container *ngFor="let error of error_messages.nome">
                        <nz-alert nzType="error" [nzMessage]="error.message" nzShowIcon nzCloseable *ngIf="formCategoria.get('nome')?.hasError(error.type) && (formCategoria.get('nome')?.dirty || formCategoria.get('nome')?.touched)"></nz-alert>
                    </ng-container>
                </div>               
            </div>
        </div>
        <div class="row">
            <div class="col">
                <nz-form-item class="flex-column mb-2">
                    <nz-form-label nzFor="descrizione" class="text-start">Descrizione</nz-form-label>
                    <nz-form-control>
                        <textarea nz-input [nzAutosize]="{ minRows: 3, maxRows: 6 }" formControlName="descrizione" id="descrizione"  nzSize="large"></textarea>
                    </nz-form-control>
                </nz-form-item>                 
            </div>
        </div>
        <div class="row">
            <div class="col">
                <nz-form-item class="flex-column mb-2">
                    <nz-form-label class="text-start">Posizione</nz-form-label>
                    <nz-form-control>
                        <nz-select nzShowSearch nzAllowClear formControlName="posizione" nzSize="large" class="w-100">
                            <nz-option nzValue="1" nzLabel="Mostra per primo"></nz-option>
                            <nz-option *ngFor="let categoria of categorie" [nzValue]="categoria.posizione" [nzLabel]="categoria.posizione + ' - ' + categoria.nome"></nz-option>
                            <nz-option nzValue="ultimo" nzLabel="Mostra per ultimo"></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>                   
            </div>
        </div>
    </form>
    <nz-divider></nz-divider>
</mat-dialog-content>
<mat-dialog-actions>
    <button type="button" nz-button nzSize="large" class="me-2" (click)="onClose()">Annulla</button>

    <span nzTooltipTitle="Per creare una categoria, inserire tutti i campi con l'asterisco" nz-tooltip
        nzTooltipPlacement="top" *ngIf="!formCategoria.valid">
        <button nz-button nzType="primary" nzSize="large" [disabled]="true">Crea Categoria</button>
    </span>
    <button nz-button nzType="primary" nzSize="large" *ngIf="formCategoria.valid" form="formCategoria">Crea
        Categoria</button>
</mat-dialog-actions>