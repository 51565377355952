<mat-dialog-content>
    <div class="title-section mb-3" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle style="cursor: move;">
        <h5 class="title">Conferma</h5>
    </div>
    <nz-divider></nz-divider>        
    <p><strong> Stai per duplicare il prodotto {{data.prodotto.nome}}, sei sicuro di voler procedere?</strong></p>            
    <p>Inserisci un nome per poter procedere alla duplicazione.</p>

    <form [formGroup]="formProdotti" (ngSubmit)="onDuplica()" id="formProdotti">        
        <div class="row mt-3">
            <div class="col">
                <nz-form-item class="flex-column mb-2">
                    <nz-form-label nzRequired class="text-start">Nome</nz-form-label>
                    <nz-form-control>
                        <input nz-input formControlName="nome" nzSize="large" required>
                    </nz-form-control>
                </nz-form-item>
                <div class="error-messages">
                    <ng-container *ngFor="let error of error_messages.nome">                                                                    
                        <nz-alert nzType="error" [nzMessage]="error.message" nzShowIcon nzCloseable *ngIf="formProdotti.get('nome')?.hasError(error.type) && (formProdotti.get('nome')?.dirty || formProdotti.get('nome')?.touched)"></nz-alert>
                    </ng-container>
                </div>
            </div>
        </div>
 
    </form>
    <nz-divider></nz-divider>
</mat-dialog-content>
<mat-dialog-actions>    
    <button type="button" nz-button nzSize="large" class="me-2" (click)="onClose()">Annulla</button>
    <span nzTooltipTitle="Per duplicare un prodotto, inserire tutti i campi con l'asterisco" nz-tooltip nzTooltipPlacement="top" *ngIf="!formProdotti.valid">
        <button nz-button nzType="primary" nzSize="large" [disabled]="true">Duplica Prodotto</button>
    </span>  
    <button nz-button nzType="primary" nzSize="large" *ngIf="formProdotti.valid" form="formProdotti">Duplica Prodotto</button>        
</mat-dialog-actions>