import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/service/api.service';

@Component({
  selector: 'app-elimina-punto-ritiro',
  templateUrl: './elimina-punto-ritiro.component.html',
  styleUrls: ['./elimina-punto-ritiro.component.css']
})
export class EliminaPuntoRitiroComponent {

  constructor(private dialogRef: MatDialogRef<EliminaPuntoRitiroComponent>, @Inject(MAT_DIALOG_DATA) public data: { id: number }, private router: Router, private apiService: ApiService) { }

  onDelete() {

    // Elimino il punto di ritiro
    this.apiService.deletePuntoRitiro(this.data.id).subscribe({
      next: (data) => this.dialogRef.close(true),
      error: (err) => this.apiService.Error(err.error.message),
      complete: () => this.router.navigate(['/punti-ritiro'])
    })

  }

  onClose() {
    this.dialogRef.close(false);
  }

}