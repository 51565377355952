<mat-dialog-content>
    <div class="title-section mb-3" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle style="cursor: move;">
        <h5 class="title d-inline">Modifica Dettaglio Ordine</h5>
    </div>
    <nz-divider></nz-divider>
    <form [formGroup]="formTessera" (ngSubmit)="onSubmit()" id="formTessera">        
        <div class="row">
            <div id="indirizzoObbl" class="form-text text-start">*campo obbligatorio</div>
        </div>    
        <div class="row mt-3">
            <div class="col">   
                <nz-form-item class="flex-column mb-2">
                    <nz-form-label class="text-start">Codice tesseramento</nz-form-label>
                    <nz-form-control>
                        <input nz-input formControlName="codice" nzSize="large" required>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>     
        
        <div class="row mt-3">
            <div class="col text-center">   
                <nz-form-item class="flex-column mb-2">                    
                    <nz-form-control>
                        <label nz-checkbox formControlName="attivo">Ordine attivo</label> 
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>   
    </form>
    <nz-divider></nz-divider>
</mat-dialog-content>
<mat-dialog-actions>
    <button type="button" nz-button nzSize="large" class="me-2" (click)="onClose()">Annulla</button>    
    
    <span nzTooltipTitle="Per modificare un tesseramento, inserire un codice!" nz-tooltip nzTooltipPlacement="top" *ngIf="!formTessera.valid">
        <button nz-button nzType="primary" nzSize="large" [disabled]="true">Modifica tesseramento</button>
    </span>  
    <button nz-button nzType="primary" nzSize="large" form="formTessera" *ngIf="formTessera.valid">Modifica tesseramento</button>
</mat-dialog-actions>