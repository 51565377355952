<div class="p-3">
    <ng-template #storicoTitle>
        <div class="row p-md-3 p-1">
            <div class="col-lg-8 my-auto">
                <h3 style="white-space: break-spaces;" class="col text-muted">Ordine #{{ordine?.id}}</h3>
            </div>
            <div class="col-lg-4 my-auto">                  
                <button nz-button nz-dropdown [nzDropdownMenu]="menuOrdine" type="button" class="w-100 crop" nzSize="large"> Gestisci l'ordine <span nz-icon nzType="down"></span> </button>
                <nz-dropdown-menu #menuOrdine="nzDropdownMenu">
                    <ul nz-menu>
                        <!-- <li nz-menu-item class="p-3" *ngIf="ordine?.metodo?.toLowerCase() == 'contanti'" (click)="onModifica()">Modifica l'ordine <i class="ms-2 bi bi-pencil-square"></i></li>                  -->
                        <li nz-menu-item class="p-3" (click)="onMovimento()">Crea movimento di prima nota <i class="ms-2 bi bi-receipt"></i></li>                 
                        <li nz-menu-item class="p-3" (click)="onMail()">Invia ordine via mail <i class="ms-2 bi bi-envelope-check-fill"></i></li>                 
                        <li nz-menu-item class="p-3" (click)="onElimina()">Elimina l'ordine <i class="ms-2 bi bi-trash3"></i></li>                                        
                    </ul>
                </nz-dropdown-menu>
            </div>          
        </div>
    </ng-template>
    <nz-card [nzTitle]="storicoTitle" class="mt-5 mb-5">
        <div class="row p-md-3 p-1">
            <div class="col">
                <nz-breadcrumb>
                    <nz-breadcrumb-item>
                        <a routerLink="/home">
                            <span nz-icon nzType="home"></span>
                        </a>                  
                    </nz-breadcrumb-item>
                    <nz-breadcrumb-item>
                      <a routerLink="/storico">                    
                        <span>Storico Ordini</span>
                      </a>
                    </nz-breadcrumb-item>
                    <nz-breadcrumb-item>Dettaglio storico ordine</nz-breadcrumb-item>
                </nz-breadcrumb>
            </div>
        </div>
        <div class="row p-md-3 p-1">
            <div class="col">            
    
                    <ng-template #datiHeader>
                        <div class="header">
                            <h5 class="d-inline text-white">Dettaglio ordine</h5>                        
                            <a (click)="onStampa()" class="float-end text-white" style="text-decoration: underline;">Stampa ricevuta</a>
                        </div>                    
                    </ng-template>
    
                    <nz-list nzBordered [nzHeader]="datiHeader" class="lista">
                        <nz-list-item>
                            <span nz-typography><span class="text-muted">N°</span></span> {{ordine?.id}}
                        </nz-list-item>
                        <nz-list-item>
                            <span nz-typography><span class="text-muted">Totale:</span></span> {{ordine?.totale | currency: 'EUR'}}
                        </nz-list-item>                        
                        <nz-list-item>
                            <span nz-typography><span class="text-muted">Residuo:</span></span> {{ordine?.residuo | currency: 'EUR'}}
                        </nz-list-item>
                        <nz-list-item>
                            <span nz-typography><span class="text-muted">Metodo:</span></span> {{ordine?.metodo || '-'}}
                        </nz-list-item>                                                             
                        <nz-list-item class="p-0" *ngFor="let cliente of ordine?.clienti">
                            <nz-collapse nzAccordion [nzExpandIconPosition]="'right'" class="w-100" style="background-color: transparent; border: 0px solid; padding-left: 8px;">
                                <ng-template #headerPanel>
                                    <p style="margin-bottom: 0px;">Cliente: <strong>{{cliente.cliente}}</strong></p>
                                </ng-template>
                                <nz-collapse-panel [nzHeader]="headerPanel" style="border-bottom: 0px;" nzActive="true">
                                    <div class="prodotto" *ngFor="let prodotto of cliente.prodotti">
                                        <nz-list-item >
                                            <span nz-typography><span class="text-muted">Prodotto: </span> <strong>{{prodotto.prodotto}}</strong> - {{prodotto.prezzo | currency: 'EUR'}}</span>
                                            <strong [ngStyle]="{'color': prodotto.stato == 'non pagato' ? 'red' : 'green' }">{{ prodotto.stato | titlecase }}</strong>
                                            <!-- <button nz-button type="button" nzSize="large" *ngIf="!prodotto.obbligatorio && ordine?.metodo?.toLowerCase() == 'contanti'" (click)="onDelete(cliente.id_cliente, cliente.cliente, prodotto.id_prodotto, prodotto.prodotto)"> Rimuovi </button> -->
                                        </nz-list-item> 
                                        <nz-list-item *ngIf="prodotto.id_categoria == 13">
                                            <span nz-typography><span class="text-muted">Utilizzato il: </span> <strong>{{prodotto.inizio_validita | date: 'dd/MM/yyyy'}}</strong></span>
                                        </nz-list-item>                                                                                
                                    </div>                                                                                                          
                                </nz-collapse-panel>                                
                              </nz-collapse>
                        </nz-list-item>   
                        <nz-collapse nzAccordion [nzExpandIconPosition]="'right'" class="w-100" style="background-color: transparent; border: 0px solid; padding-left: 8px; border-bottom: 1px solid #303030;" *ngIf="codici_sconto.length > 0">
                            <ng-template #headerCodiciPanel>
                                <p style="margin-bottom: 0px;">Codici Sconto: </p>
                            </ng-template>
                            <nz-collapse-panel [nzHeader]="headerCodiciPanel" style="border-bottom: 0px;" nzActive="true">
                                <ng-container *ngFor="let codice_sconto of codici_sconto">
                                    <nz-list-item>
                                        <span nz-typography><span class="text-muted">Codice: </span> <strong>{{codice_sconto.codice | uppercase}}</strong> - Valore: <strong>{{codice_sconto.valore | currency: 'EUR'}}</strong></span>
                                    </nz-list-item>
                                </ng-container>  
                            </nz-collapse-panel>                            
                        </nz-collapse>                        
                        <nz-list-item>
                            <span nz-typography><span class="text-muted">Eseguito da:</span></span> {{ordine?.eseguitoDa | titlecase }}
                        </nz-list-item>  
                        <nz-list-item>
                            <span nz-typography><span class="text-muted">Note:</span></span> {{ordine?.note}}
                        </nz-list-item>             
                        <nz-list-item>
                            <span nz-typography><span class="text-muted">Data:</span></span> {{ordine?.data_creazione | date: 'dd/MM/yyyy HH:mm'}}
                        </nz-list-item>           
                    </nz-list>                                    
            </div>
        </div>
    </nz-card>
</div>