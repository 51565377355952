<mat-dialog-content>
    <div class="title-section mb-3" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle style="cursor: move;">
        <h5 class="title">Crea una configurazione</h5>
    </div>
    <nz-divider></nz-divider>
    <form [formGroup]="formConfigurazione" (ngSubmit)="onSubmit()" id="formConfigurazione">
        <div class="row">
            <div class="col">
                <div id="indirizzoObbl" class="form-text text-start">*campo obbligatorio</div>        
            </div>
        </div>        
        <div class="row mt-3">
            <div class="col">   
                <nz-form-item class="flex-column mb-2">
                    <nz-form-label class="text-start">Descrizione</nz-form-label>
                    <nz-form-control>
                        <textarea nz-input formControlName="descrizione" nzSize="large"></textarea>
                    </nz-form-control>
                </nz-form-item>                
            </div>
        </div>

        <div class="row">
            <div class="col">                   
                <nz-form-item class="flex-column mb-3">
                    <nz-form-label nzRequired class="text-start">Valore</nz-form-label>
                    <nz-form-control>
                        <nz-input-group nzSize="large" >
                            <input nz-input formControlName="valore" required>
                        </nz-input-group>                                        
                    </nz-form-control>
                </nz-form-item>

                <div class="error-messages">
                    <ng-container *ngFor="let error of error_messages.valore">                                                                    
                        <nz-alert nzType="error" [nzMessage]="error.message" nzShowIcon nzCloseable *ngIf="formConfigurazione.get('valore')?.hasError(error.type) && (formConfigurazione.get('valore')?.dirty || formConfigurazione.get('valore')?.touched)"></nz-alert>
                    </ng-container>
                </div>
            </div>                    
        </div>
    </form>
    <nz-divider></nz-divider>
</mat-dialog-content>
<mat-dialog-actions>
    <button type="button" nz-button nzSize="large" class="me-2" (click)="onClose()">Annulla</button>    

    <span nzTooltipTitle="Per creare una configurazione, inserire tutti i campi con l'asterisco" nz-tooltip nzTooltipPlacement="top" *ngIf="!formConfigurazione.valid">
        <button nz-button nzType="primary" nzSize="large" [disabled]="true">Crea Configurazione</button>
    </span>  
    <button nz-button nzType="primary" nzSize="large" *ngIf="formConfigurazione.valid" form="formConfigurazione">Crea Configurazione</button>        
</mat-dialog-actions>