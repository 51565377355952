<mat-dialog-content>
    <div class="title-section mb-3" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle style="cursor: move;">
        <h5 class="title">Conferma</h5>
    </div>
    <nz-divider></nz-divider>    
    <p> <strong>La password inserita è diversa da quella salvata, sei sicuro di voler procedere con la modifica?</strong></p>
    <p>Questa azione è permanente.</p>    
    <nz-divider></nz-divider>
</mat-dialog-content>
<mat-dialog-actions>
    <button type="button" nz-button nzSize="large" class="me-2" (click)="onClose()">Annulla</button>
    <button type="button" nz-button nzType="primary" nzSize="large" nzDanger (click)="onAccept()">Prosegui</button>
</mat-dialog-actions>