import { Component, OnInit } from '@angular/core';
import { Cliente, Gruppo } from '../../shared/interfacce';
import { ApiService } from '../../service/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { saveAs } from 'file-saver';
import { EliminaImmagineGruppoComponent } from '../elimina-immagine-gruppo/elimina-immagine-gruppo.component';
import { EliminaGruppoComponent } from '../elimina-gruppo/elimina-gruppo.component';
import { ModificaGruppoComponent } from '../modifica-gruppo/modifica-gruppo.component';

@Component({
  selector: 'app-dettagli-gruppo',
  templateUrl: './dettagli-gruppo.component.html',
  styleUrls: ['./dettagli-gruppo.component.css']
})
export class DettagliGruppoComponent implements OnInit {

  constructor(private apiService: ApiService, private route: ActivatedRoute, private dialog: MatDialog, private router: Router) { }

  gruppo?: Gruppo;
  membri: Cliente[] = [];

  immagine: string = '';

  ngOnInit(): void {

    // Recupero l'id passato nell'url
    this.route.params.subscribe(params => {
      const id = params['id'];
      this.getData(id);
    });
  }

  getData(id: number) {

    // Recupero i dati del gruppo in base all'id
    this.apiService.getGruppo(id).subscribe({
      next: (data) => this.gruppo = data,
      error: (err) => this.router.navigate(['/gruppi']),
      complete: () => {

        // Recupero l'immagine del gruppo
        this.apiService.getImmagine(this.gruppo!.immagine).subscribe({
          next: (data) => this.immagine = data.file,
          error: (err) => this.apiService.Error(err.error.message),
          complete: () => {

            // Recupero i membri del gruppo
            this.apiService.setMembri(id);
            this.apiService.membriChanged.subscribe({
              next: (data) => this.membri = [...data],
              error: (err) => this.apiService.Error(err.error.message)
            })
          }
        })
      }
    })

  }

  onIndietro() {
    this.router.navigate(['/gruppi'])
  }

  onElimina() {
    this.dialog.closeAll();
    this.dialog.open(EliminaGruppoComponent, { data: { id: this.gruppo!.id }, autoFocus: false, width: '750px' })
  }

  onEliminaImmagine() {
    this.dialog.closeAll();
    var dialogRef = this.dialog.open(EliminaImmagineGruppoComponent, { data: { id: this.gruppo!.id, immagine: this.gruppo?.immagine }, autoFocus: false, width: '750px' });
    dialogRef?.afterClosed().subscribe(() => {
      this.getData(this.gruppo!.id);
    })
  }

  onModifica() {
    this.dialog.closeAll();
    var dialogRef = this.dialog.open(ModificaGruppoComponent, { data: { id: this.gruppo!.id, id_cliente: this.gruppo!.id_cliente }, autoFocus: false, width: '1000px' })
    dialogRef?.afterClosed().subscribe(() => {
      this.getData(this.gruppo!.id);
    })
  }

  onDownload() {
    // Se ho una immagine procedo
    if (this.immagine.length > 0) {

      // Recupero l'immagine del gruppo
      this.apiService.getImmagineGruppo(this.gruppo!.id).subscribe({
        next: (data) => {
          // Mando l'immagine al browser che la scarica in automatico
          let downloadURL = window.URL.createObjectURL(data);
          saveAs(downloadURL, 'img_gruppo_' + this.gruppo!.id + '.jpg');
        },
        error: (err) => this.apiService.Error(err.error.message)
      })

    }
  }

}