<div class="p-3">
    <ng-template #gruppiTitle>
        <div class="row p-md-3 p-1">
            <div class="col-lg-2 my-auto">            
                <div class="border rounded foto" [style.background-image]="'url(' + immagine + '), url(../../../assets/img/default-group.jpg)'" (click)="onDownload()" nzTooltipTitle="Se presente, clicca per scaricare l'immagine!" nz-tooltip nzTooltipPlacement="top"></div>                 
            </div>     
            <div class="col-lg-6 my-auto ps-4">            
                <h3 style="white-space: break-spaces;">{{gruppo?.nome}}</h3>
                <h5 class="col text-muted">Gruppo #{{gruppo?.id}}</h5>
            </div>   
            <div class="col-lg-4 my-auto">                  
                <nz-button-group class="w-100">
                    <button nz-button nzSize="large" class="w-100 crop" type="button" (click)="onModifica()">Modifica il gruppo</button>
                    <button nz-button nzSize="large" class="w-25" nz-dropdown [nzDropdownMenu]="menu1" nzPlacement="bottomRight">
                        <span nz-icon nzType="ellipsis"></span>
                    </button>
                </nz-button-group>
                <nz-dropdown-menu #menu1="nzDropdownMenu">
                    <ul nz-menu>                        
                        <li nz-menu-item class="p-3" (click)="onElimina()">Elimina il gruppo <i class="ms-2 bi bi-trash3"></i></li>
                        <li nz-menu-item class="p-3" (click)="onEliminaImmagine()" *ngIf="immagine.length > 0">Elimina l'immagine <i class="ms-2 bi bi-card-image"></i></li>
                    </ul>
                </nz-dropdown-menu>
            </div>          
        </div>
    </ng-template>
    <nz-card [nzTitle]="gruppiTitle" class="mt-5 mb-5">
        <div class="row p-md-3 p-1">
            <div class="col">
                <nz-breadcrumb>
                    <nz-breadcrumb-item>
                        <a routerLink="/home">
                            <span nz-icon nzType="home"></span>
                        </a>                  
                    </nz-breadcrumb-item>
                    <nz-breadcrumb-item>
                      <a routerLink="/gruppi">                    
                        <span>Gruppi</span>
                      </a>
                    </nz-breadcrumb-item>
                    <nz-breadcrumb-item>Dettaglio gruppo</nz-breadcrumb-item>
                </nz-breadcrumb>
            </div>
        </div>
        <div class="row p-md-3 p-1" style="padding-top: 0px !important;">
            <div class="col-lg-6">
                <div class="mt-3">
    
                    <ng-template #datiHeader>
                        <div class="header">
                            <h5 class="d-inline text-white">Dati</h5>                        
                        </div>                    
                    </ng-template>
    
                    <nz-list nzBordered [nzHeader]="datiHeader" class="lista">
                        <nz-list-item>
                            <span nz-typography><span class="text-muted">N°</span></span> {{gruppo?.id}}
                        </nz-list-item>
                        <nz-list-item>
                            <span nz-typography><span class="text-muted">Nome:</span></span> {{gruppo?.nome}}
                        </nz-list-item>
                        <nz-list-item>
                            <span nz-typography><span class="text-muted">Descrizione:</span></span> {{gruppo?.descrizione}}
                        </nz-list-item>
                        <nz-list-item [routerLink]="['/clienti/', gruppo?.id_cliente]" class="cursor-pointer">
                            <span nz-typography><span class="text-muted">Capogruppo:</span></span> {{gruppo?.cliente}}
                        </nz-list-item>                    
                        <nz-list-item class="p-0">
                            <nz-collapse nzAccordion [nzExpandIconPosition]="'right'" class="w-100" style="background-color: transparent; border: 0px solid; padding-left: 8px;">
                                <nz-collapse-panel [nzHeader]="'Altro'" style="border-bottom: 0px;" class="cursor-pointer">
                                    <nz-list-item>
                                        <span nz-typography><span class="text-muted">Data creazione:</span></span> {{gruppo?.data_creazione | date: 'dd/MM/yyyy HH:mm'}}
                                    </nz-list-item>
                                    <nz-list-item>
                                        <span nz-typography><span class="text-muted">Data aggiornamento:</span></span> {{gruppo?.data_aggiornamento | date: 'dd/MM/yyyy HH:mm'}}
                                    </nz-list-item>
                                </nz-collapse-panel>
                              </nz-collapse>
                        </nz-list-item>                    
                    </nz-list>                
                </div>            
            </div>
            <div class="col-lg-6">
                
                <div class="mt-3">
                    <ng-template #membriHeader>      
                        <div class="header">
                            <h5 class="d-inline text-white">Membri gruppo</h5>
                            <span class="badge bg-white text-dark rounded-pill float-end mt-1">{{membri.length}}</span>
                        </div>
                    </ng-template>
        
                    <nz-list nzBordered [nzHeader]="membriHeader" class="lista">
                        <nz-list-item class="cursor-pointer" *ngFor="let membro of membri" [routerLink]="['/clienti/', membro?.id]">
                            <span nz-typography>{{membro.nome}} {{membro.cognome}}</span>{{membro.email}}
                        </nz-list-item>     
                        <nz-list-item *ngIf="membri.length <= 0">
                            <span nz-typography>Nessun membro presente</span>
                        </nz-list-item>                  
                    </nz-list>    
                </div>                                                        
            </div>
        </div>
    </nz-card>
</div>