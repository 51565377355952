import { Component, OnInit } from '@angular/core';
import { ApiService } from '../service/api.service';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe, Location } from '@angular/common';
import { Tesseramento } from '../shared/interfacce';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { LoadingComponent } from '../loading/loading.component';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-tesseramenti-sciclub',
  templateUrl: './tesseramenti-sciclub.component.html',
  styleUrls: ['./tesseramenti-sciclub.component.css']
})
export class TesseramentiSciclubComponent implements OnInit {

  constructor(private apiService: ApiService, private location: Location, private dialog: MatDialog, private datePipe: DatePipe) { }

  totale: number = 0;
  pageIndex: number = 1;
  pageSize: number = 10;

  tesseramenti: Tesseramento[] = [];

  clienteFilter: any = '';
  dataFilter: any = '';
  pagatiFilter: any = false;
  attiviFilter: any = true;

  loading: boolean = true;
  carica: boolean = true;
  checked: boolean = false;
  indeterminate: boolean = false;

  listOfCurrentPageData: readonly Tesseramento[] = [];
  setOfCheckedId = new Set<number>();

  filtri: { pageIndex: number, pageSize: number, cliente: string, date: any, pagati: boolean, attivi: boolean } = { pageIndex: 1, pageSize: 10, cliente: '', date: '', pagati: false, attivi: true };

  ngOnInit(): void {

    // Recupero i tesseramenti della categoria 'Tesseramenti Sci Club'
    this.apiService.setTesseramentiCategoria(1, this.pageIndex, this.pageSize, 'tesseramenti.data_creazione', 'descend', this.clienteFilter, this.dataFilter, this.pagatiFilter, this.attiviFilter);
    this.apiService.tesseramentiCategoriaChanged.subscribe({
      next: (data) => {
        this.carica = false;
        this.loading = false;
        this.tesseramenti = [...data];
        this.totale = this.apiService.TotaleTesseramentiCategoria;
      },
      error: (err) => this.apiService.Error(err.error.message)
    })
  }

  onLoadData(pageIndex: number, pageSize: number, sort_key: string, sort_type: string): void {

    this.loading = true
    this.apiService.setTesseramentiCategoria(1, pageIndex, pageSize, sort_key, sort_type, this.clienteFilter, this.dataFilter, this.pagatiFilter, this.attiviFilter);

    this.pageIndex = pageIndex;
    this.pageSize = pageSize;

    this.filtri = { pageIndex: pageIndex, pageSize: pageSize, cliente: this.clienteFilter, date: this.dataFilter, pagati: this.pagatiFilter, attivi: this.attiviFilter };

  }

  onParamsChange(params: NzTableQueryParams): void {
    const { pageSize, pageIndex, sort } = params;

    const sorting = sort.filter(value => value.value)
    if (sorting.length <= 0)
      sorting.push({ key: 'tesseramenti.data_creazione', value: 'descend' })

    if (pageIndex == this.filtri.pageIndex && pageSize == this.filtri.pageSize) {
      if (this.clienteFilter != this.filtri.cliente || this.dataFilter != this.filtri.date || this.pagatiFilter != this.filtri.pagati || this.attiviFilter != this.filtri.attivi)
        this.onLoadData(1, pageSize, sorting[0].key, sorting[0].value || '');
      else
        this.onLoadData(pageIndex, pageSize, sorting[0].key, sorting[0].value || '');
    } else {
      if (this.clienteFilter != this.filtri.cliente || this.dataFilter != this.filtri.date || this.pagatiFilter != this.filtri.pagati || this.attiviFilter != this.filtri.attivi)
        this.onLoadData(1, pageSize, sorting[0].key, sorting[0].value || '');
      else
        this.onLoadData(pageIndex, pageSize, sorting[0].key, sorting[0].value || '');
    }
  }

  onOrdinamento() {
    return 1 - 1;
  }

  updateCheckedSet(id: number, checked: boolean): void {
    if (checked) {
      this.setOfCheckedId.add(id);
    } else {
      this.setOfCheckedId.delete(id);
    }
  }

  onItemChecked(id: number, checked: boolean): void {
    this.updateCheckedSet(id, checked);
    this.refreshCheckedStatus();
  }

  onAllChecked(value: boolean): void {
    this.listOfCurrentPageData.forEach(item => this.updateCheckedSet(item.id, value));
    this.refreshCheckedStatus();
  }

  refreshCheckedStatus(): void {
    this.checked = this.listOfCurrentPageData.every(item => this.setOfCheckedId.has(item.id));
    this.indeterminate = this.listOfCurrentPageData.some(item => this.setOfCheckedId.has(item.id)) && !this.checked;
  }

  onCurrentPageDataChange($event: readonly Tesseramento[]): void {
    this.listOfCurrentPageData = $event;
    this.refreshCheckedStatus();
  }

  onExport() {

    // Chiudo tutti i modal aperti
    this.dialog.closeAll();

    // Apro il modal con il loader
    let dialogLoader = this.dialog.open(LoadingComponent, { autoFocus: false, width: '200px' });

    // Recupero i tesseramenti della categoria filtrati
    this.apiService.getTesseramentiCategoria(1, this.clienteFilter, this.dataFilter, this.pagatiFilter, this.attiviFilter).subscribe({
      next: (data) => {

        let result: any = [];

        // Ciclo tra i tesseramenti recuperati e li aggiungo ad un array formattato
        data.map(tesseramento => {
          result.push({ 'N° Ordine': tesseramento.id_ordine, 'Prodotto': tesseramento.prodotto, 'Cognome': tesseramento.cognome, 'Nome': tesseramento.nome, 'Data di nascita': this.datePipe.transform(new Date(tesseramento.data_nascita), 'dd/MM/yyyy') });
        })

        // Formo excel con i tesseramenti
        const workBook = XLSX.utils.book_new();
        const workSheet = XLSX.utils.json_to_sheet(result);

        workSheet["!cols"] = [{ wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 10 }, { wch: 10 }, { wch: 20 }, { wch: 30 }];

        XLSX.utils.book_append_sheet(workBook, workSheet, 'Tesseramenti Sci Club');
        XLSX.writeFile(workBook, 'Tesseramenti Sci Club.xlsx');
        
      },
      error: (err) => this.apiService.Error(err.error.message),
      complete: () => { 
        // Chiudo il loader
        dialogLoader.close();
      }
    })

  }

  onAttiva() {

    // Recupero i tesseramenti che sono stati selezionati
    let tesseramenti = Array.from(this.setOfCheckedId.values());

    // Chiudo tutti i modal aperti
    this.dialog.closeAll();

    // Apro il modal con il loader
    let dialogLoader = this.dialog.open(LoadingComponent, { autoFocus: false, width: '200px' });

    // Aggiorno lo stato dei tesseramenti selezionati
    this.apiService.updateStatoTesseramenti(tesseramenti).subscribe({
      next: (data) => {

        let result: any = [];

        // Ciclo tra i tesseramenti recuperati e li aggiungo ad un array formattato
        data.tesseramenti.map(tesseramento => {
          result.push({ 'N° Ordine': tesseramento.id_ordine, 'Prodotto': tesseramento.prodotto, 'Cognome': tesseramento.cognome, 'Nome': tesseramento.nome, 'Data di nascita': this.datePipe.transform(new Date(tesseramento.data_nascita), 'dd/MM/yyyy') });
        })

        // Formo excel con i tesseramenti
        const workBook = XLSX.utils.book_new();
        const workSheet = XLSX.utils.json_to_sheet(result);

        workSheet["!cols"] = [{ wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 10 }, { wch: 10 }, { wch: 20 }, { wch: 30 }];

        XLSX.utils.book_append_sheet(workBook, workSheet, 'Tesseramenti Sci Club');
        XLSX.writeFile(workBook, 'Tesseramenti Sci Club.xlsx');        

      },
      error: (err) => this.apiService.Error(err.error.message),
      complete: () => { 

        // Chiudo il loader
        dialogLoader.close();

        // Aggiorno i tesseramenti
        this.apiService.setTesseramentiCategoria(1, this.pageIndex, this.pageSize, 'tesseramenti.data_creazione', 'descend', this.clienteFilter, this.dataFilter, this.pagatiFilter, this.attiviFilter);
        this.setOfCheckedId.clear();
      }
    })

  }

  onIndietro() {
    this.location.back();
  }

}