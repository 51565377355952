import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { Cliente } from "./interfacce";
import * as CryptoJS from "crypto-js";

export function emailPresente(clienti: Cliente[]): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    let duplicato = false;
    clienti.filter(cliente => cliente.email == control.value).map(cliente => duplicato = true );
    return duplicato ? { emailPresente: true } : null;
  };
}

export function emailPresenteModifica(clienti: Cliente[], id: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    let duplicato = false;
    if (control.value) {
      clienti.filter(cliente => cliente.email == control.value).map(cliente => {
        if (cliente.id != id) {
          duplicato = true;
        }
      });
    }
    return duplicato ? { emailPresente: true } : null;
  };
}

export function checkPassword(old_password: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    let new_password = CryptoJS.SHA1(control.value).toString();

    if (new_password == old_password) {
      return null;
    } else {
      return { checkPassword: true };
    }
  };
}

export function confirmPassword(password: string, confirm_password: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (password === confirm_password) {
      return null;
    } else {
      return { passwordNotMatch: true };
    }
  }
};

export function checkImage(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const file = control.value;
    if (file) {
      const name = file.name ? file.name : file;
      if (name.toLowerCase().endsWith('png')) {
        return null;
      } else if (name.toLowerCase().endsWith('jpg')) {
        return null;
      } else if (name.toLowerCase().endsWith('jpeg')) {
        return null;
      } else {
        return { requiredImageType: true };
      }
    }
    return null;
  }
}

export function checkPdf(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const file = control.value;
    if (file) {
      const name = file.name ? file.name : file;
      if (name.toLowerCase().endsWith('pdf')) {
        return null;
      } else {
        return { requiredPDFType: true };
      }
    }
    return null;
  }
}