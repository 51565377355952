import { Component, Inject, OnInit } from '@angular/core';
import { SuccessComponent } from 'src/app/success/success.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiService } from 'src/app/service/api.service';
import { EmailDettaglioOrdineComponent } from '../email-dettaglio-ordine/email-dettaglio-ordine.component';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Cliente } from 'src/app/shared/interfacce';
import { LoadingComponent } from 'src/app/loading/loading.component';

@Component({
  selector: 'app-email-multicliente-dettaglio-ordine',
  templateUrl: './email-multicliente-dettaglio-ordine.component.html',
  styleUrls: ['./email-multicliente-dettaglio-ordine.component.css']
})
export class EmailMulticlienteDettaglioOrdineComponent implements OnInit {

  constructor(private apiService: ApiService, private dialogRef: MatDialogRef<EmailDettaglioOrdineComponent>, private dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: { clienti: [{ cliente: string, id_cliente: number }], id: number }) { }

  clienti: Cliente[] = [];
  step: number = 0;

  formRicevuta!: FormGroup;
  formRicevutaEmail!: FormGroup;

  error_messages = {
    'email': [
      { type: 'required', message: "Inserire un indirizzo email per inviare la ricevuta dell'ordine" },
      { type: 'email', message: "Inserire un indirizzo email valido per inviare la ricevuta dell'ordine" },
    ],
  }

  ngOnInit(): void {

    // Creo il form con i controlli
    this.formRicevuta = new FormGroup({
      email: new FormControl(null, Validators.required)
    })

    // Creo il form con i controlli
    this.formRicevutaEmail = new FormGroup({
      email: new FormControl(null, [Validators.required, Validators.email])
    })


    // Ciclo tra i clienti passati al modal
    for (let cliente of this.data.clienti) {

      // Recupero i dati del cliente
      this.apiService.getCliente(cliente.id_cliente).subscribe({
        next: (data) => {
          // Aggiungo l'email all'array
          this.clienti?.push(data);
        },
        error: (err) => this.apiService.Error(err.error.message)
      })

    }

  }

  onClose() {
    this.dialogRef.close(false);
  }

  onStep(step: number) {
    this.step = step;
  }

  onRicevuta() {

    // Faccio partire il loader
    let dialogLoader = this.dialog.open(LoadingComponent, { autoFocus: false, width: '200px' })

    // Invio l'email con la ricevuta ai clienti selezionati
    this.apiService.inviaOrdine(this.data.id, this.clienti).subscribe({
      next: (data) => dialogLoader.close(true),
      error: (err) => this.apiService.Error(err.error.message),
      complete: () => {
        this.dialog.open(SuccessComponent, { autoFocus: false });
        this.dialogRef.close(true);
      }
    })
  }

  onRicevutaMultipla() {

    // Recupero l'email inserita nel form
    let email = this.formRicevuta.get('email')?.value;

    // Faccio partire il loader
    let dialogLoader = this.dialog.open(LoadingComponent, { autoFocus: false, width: '200px' })

    // Invio l'email con la ricevuta all'email inserita nel form
    this.apiService.inviaOrdineCompleto(this.data.id, email).subscribe({
      next: (data) => dialogLoader.close(true),
      error: (err) => this.apiService.Error(err.error.message),
      complete: () => {
        this.dialog.open(SuccessComponent, { autoFocus: false });
        this.dialogRef.close(true);
      }
    })

  }

  onRicevutaEmail() {

    // Recupero l'email inserita nel form
    let email = this.formRicevutaEmail.get('email')?.value;

    // Faccio partire il loader
    let dialogLoader = this.dialog.open(LoadingComponent, { autoFocus: false, width: '200px' })

    // Invio l'email con la ricevuta all'email scelta tra quella dei clienti nel form
    this.apiService.inviaOrdineCompleto(this.data.id, email).subscribe({
      next: (data) => dialogLoader.close(true),
      error: (err) => this.apiService.Error(err.error.message),
      complete: () => {
        this.dialog.open(SuccessComponent, { autoFocus: false });
        this.dialogRef.close(true);
      }
    })

  }

}