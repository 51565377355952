import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/service/api.service';
import { Promozione } from 'src/app/shared/interfacce';
import { EliminaPromozioneComponent } from '../elimina-promozione/elimina-promozione.component';
import { ModificaPromozioneComponent } from '../modifica-promozione/modifica-promozione.component';

@Component({
  selector: 'app-dettagli-promozione',
  templateUrl: './dettagli-promozione.component.html',
  styleUrls: ['./dettagli-promozione.component.css']
})
export class DettagliPromozioneComponent implements OnInit {

  constructor(private apiService: ApiService, private route: ActivatedRoute, private dialog: MatDialog, private router: Router) { }

  promozione?: Promozione;

  ngOnInit(): void {

    // Recupero l'id dall'url
    this.route.params.subscribe(params => {
      const id = params['id'];
      this.getData(id);
    });
  }

  getData(id: number) {

    // Recupero la promozione in base all'id
    this.apiService.getPromozione(id).subscribe({
      next: (data) => this.promozione = data,
      error: (err) => this.router.navigate(['/promozioni']),
      complete: () => {
        this.promozione!.giorni = this.promozione!.giornaliero ? JSON.parse(this.promozione!.giorni) : '';
      }
    })

  }

  onIndietro() {
    this.router.navigate(['/promozioni'])
  }

  onElimina() {
    this.dialog.closeAll();
    this.dialog.open(EliminaPromozioneComponent, { data: { id: this.promozione!.id }, autoFocus: false, width: '750px' })
  }

  onModifica() {
    this.dialog.closeAll();
    var dialogRef = this.dialog.open(ModificaPromozioneComponent, { data: { id: this.promozione!.id }, autoFocus: false, width: '1000px' })
    dialogRef?.afterClosed().subscribe(() => {
      this.getData(this.promozione!.id);
    })
  }

}
