import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { Cliente, Gruppo, Prodotto, Tesseramento, Contatto, Utente, Ordine, Statistiche, Promozione, Categoria, Log, Movimenti, Carrello, DettaglioOrdine, AuthUser, FasceProdotti, PuntoRitiro, CodiceSconto, Configurazione, Stagione, DatiCliente } from '../shared/interfacce';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { ErrorComponent } from '../error/error.component';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})

export class ApiService {
  constructor(private http: HttpClient, private dialog: MatDialog) { }

  Clienti: Cliente[] = [];
  Gruppi: Gruppo[] = [];
  Membri: Cliente[] = [];
  Prodotti: Prodotto[] = [];
  Tesseramenti: Tesseramento[] = [];
  TesseramentiCategoria: Tesseramento[] = [];
  Contatti: Contatto[] = [];
  Utenti: Utente[] = [];
  Ordini: Ordine[] = [];
  Promozioni: Promozione[] = [];
  Categorie: Categoria[] = [];
  Movimenti: Movimenti[] = [];
  FasceProdotti: FasceProdotti[] = [];
  PuntiRitiro: PuntoRitiro[] = [];
  CodiciSconto: CodiceSconto[] = [];
  Configurazioni: Configurazione[] = [];

  TotaleClienti: number = 0;
  TotaleTesseramenti: number = 0;
  TotaleTesseramentiCategoria: number = 0;
  TotaleOrdini: number = 0;

  clienteChanged = new Subject<Cliente[]>();
  gruppoChanged = new Subject<Gruppo[]>();
  membriChanged = new Subject<Cliente[]>();
  prodottiChanged = new Subject<Prodotto[]>();
  tesseramentiChanged = new Subject<Tesseramento[]>();
  tesseramentiCategoriaChanged = new Subject<Tesseramento[]>();
  contattiChanged = new Subject<Contatto[]>();
  utentiChanged = new Subject<Utente[]>();
  ordineChanged = new Subject<Ordine[]>();
  promozioneChanged = new Subject<Promozione[]>();
  categorieChanged = new Subject<Categoria[]>();
  movimentiChanged = new Subject<Movimenti[]>();
  fasceProdottiChanged = new Subject<FasceProdotti[]>();
  puntiRitiroChanged = new Subject<PuntoRitiro[]>();
  codiciScontoChanged = new Subject<CodiceSconto[]>();
  configurazioniChanged = new Subject<Configurazione[]>();


  /* Set Dati */
  setGruppi() {
    this.http.get<Gruppo[]>(environment.apiURL + 'gruppi').subscribe(gruppi => {
      this.Gruppi = gruppi;
      this.gruppoChanged.next(this.Gruppi.slice());
    })
  }
  setMembri(id: number) {
    this.http.get<Cliente[]>(environment.apiURL + 'gruppi/' + id + '/membri').subscribe(membri => {
      this.Membri = membri;
      this.membriChanged.next(this.Membri.slice());
    })
  }
  setProdotti() {
    this.http.get<Prodotto[]>(environment.apiURL + 'prodotti').subscribe(prodotti => {
      this.Prodotti = prodotti;
      this.prodottiChanged.next(this.Prodotti.slice());
    })
  }
  setClienti(pageIndex: number, pageSize: number, sortKey: string, sortType: string, clienteFilter: string, emailFilter: string, newsletterFilter: boolean) {
    this.http.get<{ clienti: Cliente[], totale: number }>(environment.apiURL + 'clienti_tabella', { params: { pageIndex: pageIndex, pageSize: pageSize, sortKey: sortKey, sortType: sortType, cliente: clienteFilter, email: emailFilter, newsletter: newsletterFilter } }).subscribe(clienti => {
      this.Clienti = clienti.clienti;
      this.TotaleClienti = clienti.totale;
      this.clienteChanged.next(this.Clienti.slice());
    })
  }
  setTesseramenti(pageIndex: number, pageSize: number, sortKey: string, sortType: string, clienteFilter: string, prodottoFilter: string, statoFilter: string) {
    this.http.get<{ tesseramenti: Tesseramento[], totale: number }>(environment.apiURL + 'tesseramenti', { params: { pageIndex: pageIndex, pageSize: pageSize, sortKey: sortKey, sortType: sortType, cliente: clienteFilter, prodotto: prodottoFilter, stato: statoFilter } }).subscribe(tesseramenti => {
      this.Tesseramenti = tesseramenti.tesseramenti;
      this.TotaleTesseramenti = tesseramenti.totale;
      this.tesseramentiChanged.next(this.Tesseramenti.slice());
    })
  }
  setTesseramentiCategoria(id_prodotto: number, pageIndex: number, pageSize: number, sortKey: string, sortType: string, clienteFilter: string, dataFilter: string, pagatiFilter: boolean, attiviFilter: boolean) {
    this.http.get<{ tesseramenti: Tesseramento[], totale: number }>(environment.apiURL + 'tesseramenti_categoria/' + id_prodotto, { params: { pageIndex: pageIndex, pageSize: pageSize, sortKey: sortKey, sortType: sortType, cliente: clienteFilter, data: dataFilter, pagati: pagatiFilter, attivi: attiviFilter } }).subscribe(tesseramenti => {
      this.TesseramentiCategoria = tesseramenti.tesseramenti;
      this.TotaleTesseramentiCategoria = tesseramenti.totale;
      this.tesseramentiCategoriaChanged.next(this.TesseramentiCategoria.slice());
    })
  }
  setContatti(id: number) {
    this.http.get<Contatto[]>(environment.apiURL + 'contatti_cliente/' + id).subscribe(genitori => {
      this.Contatti = genitori;
      this.contattiChanged.next(this.Contatti.slice());
    })
  }
  setUtenti() {
    this.http.get<Utente[]>(environment.apiURL + 'utenti').subscribe(utenti => {
      this.Utenti = utenti;
      this.utentiChanged.next(this.Utenti.slice());
    })
  }
  setOrdini(pageIndex: number, pageSize: number, sortKey: string, sortType: string, clienteFilter: string, prodottoFilter: string, statoFilter: string) {
    this.http.get<{ ordini: Ordine[], totale: number }>(environment.apiURL + 'ordini', { params: { pageIndex: pageIndex, pageSize: pageSize, sortKey: sortKey, sortType: sortType, cliente: clienteFilter, prodotto: prodottoFilter, stato: statoFilter } }).subscribe(ordini => {
      this.Ordini = ordini.ordini;
      this.TotaleOrdini = ordini.totale;
      this.ordineChanged.next(this.Ordini.slice());
    })
  }
  setPromozioni() {
    this.http.get<Promozione[]>(environment.apiURL + 'promozioni').subscribe(promozioni => {
      this.Promozioni = promozioni;
      this.promozioneChanged.next(this.Promozioni.slice());
    })
  }
  setCategorie() {
    this.http.get<Categoria[]>(environment.apiURL + 'categorie').subscribe(categorie => {
      this.Categorie = categorie;
      this.categorieChanged.next(this.Categorie.slice());
    })
  }
  setMovimenti() {
    this.http.get<Movimenti[]>(environment.apiURL + 'movimenti').subscribe(movimenti => {
      this.Movimenti = movimenti;
      this.movimentiChanged.next(this.Movimenti.slice());
    })
  }
  setFasceProdotti(id: number | null) {
    this.http.get<FasceProdotti[]>(environment.apiURL + 'fasce_prodotto/' + id).subscribe(fasce => {
      this.FasceProdotti = fasce;
      this.fasceProdottiChanged.next(this.FasceProdotti.slice());
    })
  }
  setPuntiRitiro() {
    this.http.get<PuntoRitiro[]>(environment.apiURL + 'punti_ritiro').subscribe(punti_ritiro => {
      this.PuntiRitiro = punti_ritiro;
      this.puntiRitiroChanged.next(this.PuntiRitiro.slice());
    })
  }
  setCodiciSconto() {
    this.http.get<CodiceSconto[]>(environment.apiURL + 'codici_sconto').subscribe(codici_sconto => {
      this.CodiciSconto = codici_sconto;
      this.codiciScontoChanged.next(this.CodiciSconto.slice());
    })
  }
  setConfigurazioni() {
    this.http.get<Configurazione[]>(environment.apiURL + 'configurazioni').subscribe(configurazioni => {
      this.Configurazioni = configurazioni;
      this.configurazioniChanged.next(this.Configurazioni.slice());
    })
  }


  /* isAuthorized e login */
  isAuthorized(): Observable<Boolean> {
    return this.http.get<Boolean>(environment.authURL);
  }
  login(user: string, pass: string): Observable<any> {
    const body = { user: user, pass: pass };
    return this.http.post<any>(environment.loginURL, body);
  }

  /* richieste GET */
  getClienti() {
    return this.http.get<Cliente[]>(environment.apiURL + 'clienti');
  }
  getCliente(id: number) {
    return this.http.get<Cliente>(environment.apiURL + 'clienti/' + id);
  }
  getEsportazioneClienti() {
    return this.http.get<Cliente[]>(environment.apiURL + 'esportazione_clienti/');
  }
  getContatti(id: number) {
    return this.http.get<Contatto[]>(environment.apiURL + 'contatti_cliente/' + id);
  }
  getContatto(idContatto: number) {
    return this.http.get<Contatto>(environment.apiURL + 'contatti/' + idContatto);
  }
  getGruppo(id: number) {
    return this.http.get<Gruppo>(environment.apiURL + 'gruppi/' + id);
  }
  getGruppiCliente(id: number) {
    return this.http.get<Gruppo[]>(environment.apiURL + 'clienti/' + id + '/gruppi');
  }
  getMembriGruppoById(id: number) {
    return this.http.get<Cliente>(environment.apiURL + 'gruppi/' + id + '/membri');
  }
  getGruppi(): Observable<any> {
    return this.http.get<Gruppo>(environment.apiURL + 'gruppi');
  }
  getTesseramentiCliente(id: number) {
    return this.http.get<Tesseramento[]>(environment.apiURL + 'clienti/' + id + '/tesseramenti');
  }
  getProdotti() {
    return this.http.get<Prodotto[]>(environment.apiURL + 'prodotti');
  }
  getProdotto(id: number) {
    return this.http.get<Prodotto>(environment.apiURL + 'prodotti/' + id);
  }
  getProdottiCategoria(id_categoria: number) {
    return this.http.get<Prodotto[]>(environment.apiURL + 'prodotti_categoria/' + id_categoria);
  }
  getImmagineCliente(id: number) {
    return this.http.get(environment.apiURL + 'immagine_cliente/' + id, { responseType: 'blob' });
  }
  getImmagineProdotto(id: number) {
    return this.http.get(environment.apiURL + 'immagine_prodotto/' + id, { responseType: 'blob' });
  }
  getPDFAssicurazione(id: number) {
    return this.http.get(environment.apiURL + 'pdf_assicurazione/' + id, { responseType: 'blob' });
  }
  getImmagineGruppo(id: number) {
    return this.http.get(environment.apiURL + 'immagine_gruppo/' + id, { responseType: 'blob' });
  }
  getUtente(id: number) {
    return this.http.get<Utente>(environment.apiURL + 'utenti/' + id);
  }
  getTesseramento(id: number) {
    return this.http.get<Tesseramento>(environment.apiURL + 'tesseramenti/' + id);
  }
  getOrdini() {
    return this.http.get<Ordine[]>(environment.apiURL + 'ordini');
  }
  getOrdiniGiornalieri() {
    return this.http.get<Ordine[]>(environment.apiURL + 'ordini_giornalieri');
  }
  getOrdiniCliente(id_cliente: number) {
    return this.http.get<Ordine[]>(environment.apiURL + 'ordini/' + id_cliente);
  }
  getOrdiniFiltrati(data_inizio: string | null, data_fine: string | null, prodotto: string, cliente: string, metodo: string, stato: string) {
    return this.http.get<Ordine[]>(environment.apiURL + 'ordini_filtrati', { params: { data_inizio: data_inizio || '', data_fine: data_fine || '', prodotto: prodotto, cliente: cliente, metodo: metodo, stato: stato } });
  }
  getTesseramentiGiornalieri() {
    return this.http.get<Ordine[]>(environment.apiURL + 'tesseramenti_giornalieri');
  }
  getTesseramentiFiltrati(data_inizio: string | null, data_fine: string | null, prodotto: string, cliente: string, stato: string) {
    return this.http.get<Ordine[]>(environment.apiURL + 'tesseramenti_filtrati', { params: { data_inizio: data_inizio || '', data_fine: data_fine || '', prodotto: prodotto, cliente: cliente, stato: stato } });
  }
  getTesseramentiCategoria(id_categoria: number, clienteFilter: string, dataFilter: string, pagatiFilter: string, attiviFilter: string) {
    return this.http.get<Tesseramento[]>(environment.apiURL + 'tesseramenti_categoria_export/' + id_categoria, { params: { cliente: clienteFilter, data: dataFilter, pagati: pagatiFilter, attivi: attiviFilter } });
  }
  getProdottiOrdini() {
    return this.http.get<[{ id_prodotto: number, prodotto: string }]>(environment.apiURL + 'prodotti_ordini');
  }
  getMovimentiCliente(id_cliente: number) {
    return this.http.get<Movimenti[]>(environment.apiURL + 'movimenti/' + id_cliente);
  }
  getMovimentiGiornalieri(tipo: string) {
    return this.http.get<Movimenti[]>(environment.apiURL + 'movimenti_giornalieri', { params: { tipo: tipo } });
  }
  getMovimentiPeriodo(sezione: string, data_inizio: string | null, data_fine: string | null, tipo: string, metodo: string, cliente: string) {
    return this.http.get<Movimenti[]>(environment.apiURL + 'movimenti_filtrati', { params: { sezione: sezione, data_inizio: data_inizio || '', data_fine: data_fine || '', tipo: tipo, metodo: metodo, cliente: cliente } });
  }
  getReportMovimenti(sezione: string, data_inizio: string | null, data_fine: string | null, tipo: string, metodo: string, cliente: string) {
    return this.http.get(environment.apiURL + 'report', { params: { sezione: sezione, data_inizio: data_inizio || '', data_fine: data_fine || '', tipo: tipo, metodo: metodo, cliente: cliente }, responseType: 'blob' });
  }
  getOrdine(id: number) {
    return this.http.get<Ordine>(environment.apiURL + 'ordine/' + id);
  }
  getDettaglioOrdine(id: number) {
    return this.http.get<DettaglioOrdine>(environment.apiURL + 'dettaglio_ordini/' + id);
  }
  getRicevuta(id: number) {
    return this.http.get(environment.apiURL + 'ordini/' + id + '/ricevuta', { responseType: 'blob' });
  }
  getPromozioni() {
    return this.http.get<Promozione[]>(environment.apiURL + 'promozioni');
  }
  getPromozione(id: number) {
    return this.http.get<Promozione>(environment.apiURL + 'promozioni/' + id);
  }
  getStats(inizio: string | null, fine: string | null, categoria: number, prodotto: number) {
    return this.http.get<Statistiche>(environment.apiURL + 'statistiche', { params: { inizio: inizio || '', fine: fine || '', categoria: categoria, prodotto: prodotto } });
  }
  getDatiCliente(id: number) {
    return this.http.get<DatiCliente>(environment.apiURL + 'dati_cliente/' + id);
  }
  getCategorie() {
    return this.http.get<Categoria[]>(environment.apiURL + 'categorie');
  }
  getCategoria(id: number) {
    return this.http.get<Categoria>(environment.apiURL + 'categorie/' + id);
  }
  getLogs() {
    return this.http.get<Log[]>(environment.apiURL + 'log');
  }
  getLog(id: number) {
    return this.http.get<Log>(environment.apiURL + 'log/' + id);
  }
  getMovimenti() {
    return this.http.get<Movimenti[]>(environment.apiURL + 'movimenti/');
  }
  getMovimento(id: number) {
    return this.http.get<Movimenti>(environment.apiURL + 'movimento/' + id);
  }
  getLastOrdine() {
    return this.http.get<any>(environment.apiURL + 'ultimo_ordine/');
  }
  getImmagine(immagine: string) {
    return this.http.get<{ file: string }>(environment.apiURL + 'immagine/' + immagine);
  }
  getFasceProdotti() {
    return this.http.get<FasceProdotti[]>(environment.apiURL + 'fasce_prodotti/');
  }
  getFasceProdotto(id_prodotto: number, eta: number) {
    return this.http.get<FasceProdotti>(environment.apiURL + 'fasce_prodotto/' + id_prodotto, { params: { eta: eta } });
  }
  getFasciaProdotti(id: number) {
    return this.http.get<FasceProdotti>(environment.apiURL + 'fasce_prodotti/' + id);
  }
  getProdottiEta(id?: number, eta?: number) {
    return this.http.get<Prodotto[]>(environment.apiURL + 'prodotti_eta/', { params: { id: id || 0, eta: eta || 0 } });
  }
  getTokenImpersona(id_cliente: number) {
    return this.http.get<{ token: string }>(environment.apiURL + 'impersona/' + id_cliente);
  }
  getPuntiRitiro() {
    return this.http.get<PuntoRitiro[]>(environment.apiURL + 'punti_ritiro/');
  }
  getPuntoRitiro(id: number) {
    return this.http.get<PuntoRitiro>(environment.apiURL + 'punto_ritiro/' + id);
  }
  getCodiceSconto(id: number) {
    return this.http.get<CodiceSconto>(environment.apiURL + 'codice_sconto/' + id);
  }
  getCodiciScontoOrdine(id: number) {
    return this.http.get<CodiceSconto[]>(environment.apiURL + 'codici_sconto_ordine/' + id);
  }
  getConfigurazione(id: number) {
    return this.http.get<Configurazione>(environment.apiURL + 'configurazione/' + id);
  }
  getStagione() {
    return this.http.get<Stagione>(environment.apiURL + 'stagione/');
  }

  /* richieste ADD */

  addCliente(cliente: { nome: string, cognome: string, password: string, codice_fiscale: string, data_nascita: string | null, comune_nascita: string, provincia_nascita: string, nazione_nascita: string, email: string, nazione: string, provincia: string, comune: string, indirizzo: string, telefono: string, immagine: string, file_immagine: string, newsletter: boolean }) {
    return this.http.post(environment.apiURL + 'clienti/', cliente);
  }
  addGruppo(gruppo: { id_cliente: number, nome: string, descrizione: string, immagine: string, file_immagine: string }) {
    return this.http.post(environment.apiURL + 'gruppi/', gruppo);
  }
  addTesseramento(tesseramento: { id_prodotto: number, nome: string, prezzo: number, id_cliente: number, cliente: string, inizio_validita: any, fine_validita: any, inizio_orario: string, fine_orario: string }) {
    return this.http.post(environment.apiURL + 'tesseramenti/', tesseramento);
  }
  addContatto(contatto: { id_cliente: number, nome: string, cognome: string, parentela: string, email: string, telefono: string }) {
    return this.http.post(environment.apiURL + 'contatti/', contatto);
  }
  addMembroGruppo(gruppo: number, cliente: number) {
    return this.http.post(environment.apiURL + 'membro_gruppo/', { id_cliente: cliente, id_gruppo: gruppo });
  }
  addProdotto(prodotto: { nome: string, id_categoria: number, descrizione: string, prezzo: number, giornaliero: boolean, inizio_validita: string | null, fine_validita: string | null, giorni: string | null, date_specifiche: boolean, date_specifiche_dal: string | null, date_specifiche_al: string | null, inizio_orario: string | null, fine_orario: string | null, posizione: string, immagine: string, file_immagine: string, online: boolean, obbligatorio: boolean, codice: boolean, informativo: boolean, assicurativo: boolean, pdf_assicurazione: string, file_pdf_assicurazione: string, fototessera: boolean, fasce_prezzi: FasceProdotti[], bonifico: boolean, carta: boolean, satispay: boolean }) {
    return this.http.post<any>(environment.apiURL + 'prodotti/', prodotto);
  }
  addUtente(utente: { username: string, password: string, tipo: string }) {
    return this.http.post(environment.apiURL + 'utenti/', utente);
  }
  addPromozione(promozione: { nome: string, descrizione: string, id_prodotto: number, eta_min: number, eta_max: number, sconto: string, percentuale: boolean, giornaliero: boolean, inizio_validita: string | null, fine_validita: string | null, giorni: string | null, inizio_orario: string | null, fine_orario: string | null }) {
    return this.http.post(environment.apiURL + 'promozioni/', promozione);
  }
  addCategoria(categoria: { nome: string, descrizione: string, posizione: number }) {
    return this.http.post<{ id: number }>(environment.apiURL + 'categorie/', categoria);
  }
  addMovimento(movimento: { tipo: string, metodo: string, descrizione: string, id_cliente: number | null, id_ordine: number | null, pagato: number, residuo: number, totale: number, data: string | null }) {
    return this.http.post(environment.apiURL + 'movimenti/', movimento);
  }
  addTesseramentoOrdine(ordine: { metodo: string | null, eseguitoDa: string, data: string | null, totale: number, note: string }, carrello: Carrello[]) {
    return this.http.post<{ id_ordine: number }>(environment.apiURL + 'tesseramenti/', { ordine, carrello: carrello });
  }
  addFasciaProdotti(fascia: { eta_min: number, eta_max: number, prezzo: number, descrizione: string, id_prodotto: number }) {
    return this.http.post(environment.apiURL + 'fasce_prodotti/', fascia);
  }
  addPuntoRitiro(punto_ritiro: { valore: string, descrizione: string }) {
    return this.http.post(environment.apiURL + 'punto_ritiro/', punto_ritiro);
  }
  addCodiceSconto(codice_sconto: { id_cliente: number, codice: string, descrizione: string, inizio_validita: string | null, fine_validita: string | null, valore: number }) {
    return this.http.post(environment.apiURL + 'codice_sconto/', codice_sconto);
  }
  addConfigurazione(configurazione: { descrizione: string, valore: string }) {
    return this.http.post(environment.apiURL + 'configurazione/', configurazione);
  }

  /* richieste DELETE */
  deleteMembroGruppo(gruppo: number, cliente: number) {
    return this.http.delete(environment.apiURL + 'membro_gruppo/', { body: { id_cliente: cliente, id_gruppo: gruppo } });
  }
  deleteCliente(id: number) {
    return this.http.delete(environment.apiURL + 'clienti/' + id);
  }
  deleteMovimento(id: number) {
    return this.http.delete(environment.apiURL + 'movimenti/' + id);
  }
  deleteTesseramento(id: number) {
    return this.http.delete(environment.apiURL + 'tesseramenti/' + id);
  }
  deleteGruppo(id: number) {
    return this.http.delete(environment.apiURL + 'gruppi/' + id);
  }
  deleteContatto(contatto: number) {
    return this.http.delete(environment.apiURL + 'contatti/' + contatto);
  }
  deleteProdotto(id: number) {
    return this.http.delete(environment.apiURL + 'prodotti/' + id);
  }
  deleteUtente(id: number) {
    return this.http.delete(environment.apiURL + 'utenti/' + id);
  }
  deletePromozione(id: number) {
    return this.http.delete(environment.apiURL + 'promozioni/' + id);
  }
  deleteCategoria(id: number) {
    return this.http.delete(environment.apiURL + 'categorie/' + id);
  }
  deleteImmagineCliente(id: number, immagine: string) {
    return this.http.delete(environment.apiURL + 'immagine_cliente', { body: { id: id, immagine: immagine } });
  }
  deleteImmagineProdotto(id: number, immagine: string) {
    return this.http.delete(environment.apiURL + 'immagine_prodotto', { body: { id: id, immagine: immagine } });
  }
  deleteImmagineGruppo(id: number, immagine: string) {
    return this.http.delete(environment.apiURL + 'immagine_gruppo', { body: { id: id, immagine: immagine } });
  }
  deleteOrdine(id_ordine: number) {
    return this.http.delete(environment.apiURL + 'ordine/' + id_ordine);
  }
  deleteProdottoOrdine(id_ordine: number, id_prodotto: number, id_cliente: number) {
    return this.http.delete(environment.apiURL + 'ordine_prodotto/' + id_ordine, { body: { id_prodotto: id_prodotto, id_cliente: id_cliente } });
  }
  deleteFasciaProdotti(id: number) {
    return this.http.delete(environment.apiURL + 'fasce_prodotti/' + id);
  }
  deletePuntoRitiro(id: number) {
    return this.http.delete(environment.apiURL + 'punto_ritiro/' + id);
  }
  deleteCodiceSconto(id: number) {
    return this.http.delete(environment.apiURL + 'codice_sconto/' + id);
  }
  deleteConfigurazione(id: number) {
    return this.http.delete(environment.apiURL + 'configurazione/' + id);
  }

  /* richieste UPDATE */
  updateOrdine(id: number, ordine: { data: string | null, metodo: string, eseguitoDa: string, stato: boolean, note: string, carrello: Carrello[] }) {
    return this.http.put(environment.apiURL + 'ordini/' + id, ordine);
  }
  updateGruppo(id: number, gruppo: { nome: string, descrizione: string, immagine: string, file_immagine: string }) {
    return this.http.put(environment.apiURL + 'gruppi/' + id, gruppo);
  }
  updateCliente(id: number, cliente: { nome: string, cognome: string, codice_fiscale: string, data_nascita: string | null, comune_nascita: string, provincia_nascita: string, nazione_nascita: string, email: string, nazione: string, provincia: string, comune: string, indirizzo: string, telefono: string, immagine: string, file_immagine: string, newsletter: boolean }) {
    return this.http.put(environment.apiURL + 'clienti/' + id, cliente);
  }
  updateContatto(idContatto: number, contatto: { nome: string, cognome: string, parentela: string, email: string, telefono: string }) {
    return this.http.put(environment.apiURL + 'contatti/' + idContatto, contatto);
  }
  updateProdotto(id: number, prodotto: { nome: string, id_categoria: number, descrizione: string, prezzo: number, giornaliero: boolean, inizio_validita: string | null, fine_validita: string | null, giorni: string | null, inizio_orario: string | null, fine_orario: string | null, date_specifiche: boolean, date_specifiche_dal: string | null, date_specifiche_al: string | null, posizione: number, immagine: string, file_immagine: string, online: boolean, obbligatorio: boolean, codice: boolean, informativo: boolean, assicurativo: boolean, pdf_assicurazione: string, file_pdf_assicurazione: string, fototessera: boolean, bonifico: boolean, carta: boolean, satispay: boolean }) {
    return this.http.put(environment.apiURL + 'prodotti/' + id, prodotto);
  }
  updateProdotti(prodotti: { prodotti: number[], online: boolean, obbligatorio: boolean, informativo: boolean, fototessera: boolean, bonifico: boolean, carta: boolean, satispay: boolean }) {
    return this.http.put(environment.apiURL + 'prodotti/', prodotti);
  }
  updateUtente(id: number, utente: { username: string, password: string, tipo: string }) {
    return this.http.put(environment.apiURL + 'utenti/' + id, utente);
  }
  updateTesseramento(id: number, tesseramento: { codice: string, attivo: boolean }) {
    return this.http.put(environment.apiURL + 'tesseramenti/' + id, tesseramento);
  }
  updateStatoTesseramenti(tesseramenti: number[]) {
    return this.http.put<{ code: number, tesseramenti: Tesseramento[] }>(environment.apiURL + 'attiva_tesseramenti', { tesseramenti: tesseramenti });
  }
  updatePromozione(id: number, promozione: { nome: string, descrizione: string, sconto: string, percentuale: boolean, giornaliero: boolean, id_prodotto: number, eta_min: number, eta_max: number, inizio_validita: string | null, fine_validita: string | null, giorni: string | null, inizio_orario: string | null, fine_orario: string | null }) {
    return this.http.put(environment.apiURL + 'promozioni/' + id, promozione);
  }
  updatePasswordUtente(id: number, password: string) {
    return this.http.put(environment.apiURL + 'password_utente/' + id, { password: password });
  }
  updatePasswordCliente(id: number, password: string) {
    return this.http.put(environment.apiURL + 'password_cliente/' + id, { password: password });
  }
  updateCategoria(id: number, categoria: { nome: string, descrizione: string, posizione: number }) {
    return this.http.put(environment.apiURL + 'categorie/' + id, categoria);
  }
  updateCodiciCliente(id: number, codici: { skipass_open: string, skicard_vialattea: string }) {
    return this.http.put(environment.apiURL + 'codici_cliente/' + id, codici);
  }
  updateMovimento(id: number, movimento: { tipo: string, metodo: string, descrizione: string, id_cliente: number | null, id_ordine: number | null, pagato: number, residuo: number, totale: number, data: string | null }) {
    return this.http.put(environment.apiURL + 'movimenti/' + id, movimento);
  }
  updateFasciaProdotti(id: number, fascia: { eta_min: number, eta_max: number, prezzo: number, descrizione: string }) {
    return this.http.put(environment.apiURL + 'fasce_prodotti/' + id, fascia);
  }
  updatePuntoRitiro(id: number, punto_ritiro: { valore: string, descrizione: string }) {
    return this.http.put(environment.apiURL + 'punto_ritiro/' + id, punto_ritiro);
  }
  updateCodiceSconto(id: number, codice_sconto: { id_cliente: number, codice: string, descrizione: string, inizio_validita: string | null, fine_validita: string | null, valore: number, attivo: boolean }) {
    return this.http.put(environment.apiURL + 'codice_sconto/' + id, codice_sconto);
  }
  updateConfigurazione(id: number, configurazione: { descrizione: string, valore: string }) {
    return this.http.put(environment.apiURL + 'configurazione/' + id, configurazione);
  }


  inviaOrdine(id: number, clienti: Cliente[]) {
    return this.http.post(environment.apiURL + 'invia_ordine/' + id, { clienti: clienti })
  }
  inviaOrdineCompleto(id: number, email: string) {
    return this.http.post(environment.apiURL + 'invia_ordine_completo/' + id, { email: email })
  }

  Error(message?: string) {
    this.dialog.open(ErrorComponent, { autoFocus: false, width: '750px', data: { message: message } });
  }

}


@Injectable({
  providedIn: 'root'
})
export class ApiInterceptor implements HttpInterceptor {

  constructor(private storageService: StorageService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const userData: AuthUser = this.storageService.getUser();
    const token = this.storageService.getToken()

    const modifiedReq = req.clone({
      headers: req.headers.set('Authorization', `Bearer ${token} ${userData.id}`),
    });

    return next.handle(modifiedReq);
  }
}
