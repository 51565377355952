<mat-dialog-content>
    <div class="title-section mb-3" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle style="cursor: move;">
        <h5 class="title d-inline">Modifica prezzo prodotto</h5>
    </div>
    <nz-divider></nz-divider>    
    <form [formGroup]="formProdotto">
        <div class="row">
            <div class="col">
                <nz-form-item class="flex-column mb-2">
                    <nz-form-label nzRequired class="text-start">Prezzo</nz-form-label>
                    <nz-form-control>
                        <nz-input-group [nzSuffix]="suffixTemplateInfo"  nzSize="large">
                            <input nz-input formControlName="prezzo" required>
                        </nz-input-group>                
                        <ng-template #suffixTemplateInfo>                    
                            <span nz-icon nzType="euro-circle" nzTheme="outline"></span>                    
                        </ng-template>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>        
    </form>    
    <nz-divider></nz-divider>
</mat-dialog-content>
<mat-dialog-actions>
    <button type="button" nz-button nzSize="large" class="me-2" (click)="onClose()">Annulla</button>    

    <span nzTooltipTitle="Per aggiornare il prezzo, inserire un numero!" nz-tooltip nzTooltipPlacement="top" *ngIf="!formProdotto.valid">
        <button nz-button nzType="primary" nzSize="large" [disabled]="true">Aggiorna prezzo</button>
    </span>  
    <button nz-button nzType="primary" nzSize="large" (click)="onSubmit()" *ngIf="formProdotto.valid">Aggiorna prezzo</button>
</mat-dialog-actions>