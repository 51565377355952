import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from 'src/app/service/api.service';
import { FasceProdotti, Prodotto } from 'src/app/shared/interfacce';

@Component({
  selector: 'app-duplica-prodotto',
  templateUrl: './duplica-prodotto.component.html',
  styleUrls: ['./duplica-prodotto.component.css']
})
export class DuplicaProdottoComponent implements OnInit {

  constructor(private apiService: ApiService, private datePipe: DatePipe, private dialogRef: MatDialogRef<DuplicaProdottoComponent>, @Inject(MAT_DIALOG_DATA) public data: { prodotto: Prodotto, fasce: FasceProdotti[] }) { }

  formProdotti!: FormGroup;

  error_messages = {
    'nome': [
      { type: 'required', message: 'Inserire un nome per procedere con la creazione del prodotto' },
      { type: 'minlength', message: 'Inserire almeno 3 caratteri' }
    ]
  }

  ngOnInit(): void {
    // Creo il form con i controlli 
    this.formProdotti = new FormGroup({
      nome: new FormControl(null, [Validators.required, Validators.minLength(3)])
    })
  }

  onClose() {
    this.dialogRef.close({ esito: false })
  }

  onDuplica() {

    // Formo l'oggetto con i dati del prodotto da duplicare
    let prodotto = {
      nome: this.formProdotti.get('nome')?.value,
      id_categoria: this.data.prodotto.id_categoria,
      descrizione: this.data.prodotto.descrizione || "",
      prezzo: this.data.prodotto.prezzo,
      giornaliero: this.data.prodotto.giornaliero,
      inizio_validita: this.datePipe.transform(this.data.prodotto.inizio_validita, 'yyyy-MM-dd'),
      fine_validita: this.datePipe.transform(this.data.prodotto.fine_validita, 'yyyy-MM-dd'),
      giorni: this.data.prodotto.giornaliero ? JSON.stringify(this.data.prodotto.giorni) : null,
      date_specifiche: this.data.prodotto.date_specifiche,
      date_specifiche_dal: this.data.prodotto.date_specifiche ? this.datePipe.transform(this.data.prodotto.date_specifiche_dal, 'yyyy-MM-dd') : null,
      date_specifiche_al: this.data.prodotto.date_specifiche ? this.datePipe.transform(this.data.prodotto.date_specifiche_al, 'yyyy-MM-dd') : null,
      inizio_orario: null,
      fine_orario: null,
      posizione: "ultimo",
      immagine: this.data.prodotto.immagine || "",
      file_immagine: this.data.prodotto.file_immagine,
      online: this.data.prodotto.online || false,
      obbligatorio: this.data.prodotto.obbligatorio || false,
      codice: this.data.prodotto.codice || false,
      informativo: this.data.prodotto.informativo || false,
      assicurativo: this.data.prodotto.assicurativo || false,
      pdf_assicurazione: this.data.prodotto.pdf_assicurazione || "",
      file_pdf_assicurazione: "",
      fototessera: this.data.prodotto.fototessera || false,
      fasce_prezzi: this.data.fasce,
      bonifico: this.data.prodotto.bonifico || false,
      carta: this.data.prodotto.carta || false,
      satispay: this.data.prodotto.satispay || false
    }

    // Aggiungo il prodotto al database
    this.apiService.addProdotto(prodotto).subscribe({
      next: (data) => this.apiService.setProdotti(),
      error: (err) => this.apiService.Error(err.error.message),
      complete: () => this.dialogRef.close()
    })
  }
}