import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { ApiService } from 'src/app/service/api.service';
import { AggiungiCategoriaComponent } from 'src/app/categorie/aggiungi-categoria/aggiungi-categoria.component';
import { checkImage, checkPdf } from 'src/app/shared/controlli';
import { Categoria, FasceProdotti, Prodotto } from 'src/app/shared/interfacce';
import { FascePrezziProdottoComponent } from '../fasce-prezzi-prodotto/fasce-prezzi-prodotto.component';
import { DateService } from 'src/app/service/date.service';
import { StorageService } from 'src/app/service/storage.service';

@Component({
  selector: 'app-aggiungi-prodotto',
  templateUrl: './aggiungi-prodotto.component.html',
  styleUrls: ['./aggiungi-prodotto.component.css']
})
export class AggiungiProdottoComponent implements OnInit {

  constructor(private apiService: ApiService, public dateService: DateService, private storageService: StorageService, private dialog: MatDialog, private dialogRef: MatDialogRef<AggiungiProdottoComponent>, private datePipe: DatePipe) { }

  formProdotti!: FormGroup;

  categorie: Categoria[] = [];
  prodotti: Prodotto[] = [];
  fasce: FasceProdotti[] = [];

  giorni: string[] = ['Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato', 'Domenica'];

  immagineFile!: string;
  immagineList: NzUploadFile[] = [];

  pdfFile!: string;
  pdfList: NzUploadFile[] = [];

  error_messages = {
    'nome': [
      { type: 'required', message: 'Inserire un nome per procedere con la creazione del prodotto' },
      { type: 'minlength', message: 'Inserire almeno 3 caratteri' }
    ],
    'categoria': [
      { type: 'required', message: 'Inserire una categoria per procedere con la creazione del prodotto' },
    ],
    'descrizione': [
      { type: 'required', message: 'Inserire un descrizione per procedere con la creazione del prodotto' }
    ],
    'prezzo': [
      { type: 'required', message: 'Inserire un prezzo per procedere con la creazione del prodotto' },
      { type: 'pattern', message: 'Inserire un prezzo valido' }
    ],
    'giornaliero': [
      { type: 'required', message: 'Inserire un giornaliero per procedere con la creazione del prodotto' }
    ],
    'date_specifiche': [
      { type: 'required', message: 'Inserire delle date per procedere con la creazione del prodotto' }
    ],
    'inizio_validita': [
      { type: 'required', message: 'Inserire una data di inizio di validita per procedere con la creazione del prodotto' },
      { type: 'matDatepickerFilter', message: 'Inserire una data valida' }
    ],
    'fine_validita': [
      { type: 'required', message: 'Inserire una data di fine di validita per procedere con la creazione del prodotto' },
      { type: 'matDatepickerFilter', message: 'Inserire una data valida' }
    ],
    'giorni': [
      { type: 'required', message: 'Inserire dei giorni per procedere con la creazione del prodotto' }
    ],
    'immagine': [
      { type: 'requiredImageType', message: "Inserire un'immagine di tipo JPG, PNG o JPEG" }
    ],
    'pdf_assicurazione': [
      { type: 'requiredPDFType', message: "Inserire un PDF" }
    ]
  }

  ngOnInit(): void {

    // Creo il form con i controlli
    this.formProdotti = new FormGroup({
      nome: new FormControl(null, [Validators.required, Validators.minLength(3)]),
      categoria: new FormControl(null, Validators.required),
      descrizione: new FormControl(null),
      prezzo: new FormControl(null, [Validators.required, Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')]),
      giornaliero: new FormControl(null),
      inizio_validita: new FormControl(null, Validators.required),
      fine_validita: new FormControl(null, Validators.required),
      giorni: new FormControl(null),
      date: new FormControl(null),
      date_specifiche: new FormControl(null),
      posizione: new FormControl(null),
      immagine: new FormControl(null, checkImage()),
      online: new FormControl(null),
      obbligatorio: new FormControl(null),
      codice: new FormControl(null),
      informativo: new FormControl(null),
      assicurativo: new FormControl(null),
      pdf_assicurazione: new FormControl(null, checkPdf()),
      fototessera: new FormControl(null),
      bonifico: new FormControl(true),
      carta: new FormControl(true),
      satispay: new FormControl(true)
    })

    // Recupero i prodotti
    this.apiService.getProdotti().subscribe({
      next: (data) => this.prodotti = [...data],
      error: (err) => this.apiService.Error(err.error.message),
      complete: () => {

        // Recupero le categorie
        this.apiService.setCategorie();
        this.apiService.categorieChanged.subscribe({
          next: (data) => {
            this.categorie = [...data];
            this.getFasce()
          },
          error: (err) => this.apiService.Error(err.error.message)
        })
      }
    })
  }

  getFasce() {
    // Controllo se ci sono delle fasce nella session, se si le mostro    
    this.fasce = this.storageService.getFasceProdotto();

    // Ordino le fasce per il campo eta max
    this.fasce.sort(function (a, b) { return a.eta_max - b.eta_max; });
  }

  onImmagineUpload = (file: any): boolean => {

    // Controllo se il file inserito è una immagine
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';

    // Se è una immagine procedo
    if (isJpgOrPng) {

      // Aggiungo il file alla lista dei file al form
      this.immagineList = this.immagineList.concat(file);
      this.formProdotti.patchValue({ immagine: file.name });

      // Recupero il base64 del file caricato
      const reader = new FileReader();
      reader.onload = () => {
        this.immagineFile = reader.result as string;
      }
      reader.readAsDataURL(file);

      return false;
    } else {
      return false
    }
  };

  onRemoveImmagineUpload = (file: any): boolean => {
    this.formProdotti.patchValue({ immagine: '' });
    this.immagineList = [];
    return false;
  }

  onPDFUpload = (file: any): boolean => {

    // Controllo se il file inserito è una immagine
    const isPdf = file.type === 'application/pdf' || file.type === 'application/pdf';

    // Se è una immagine procedo
    if (isPdf) {

      // Aggiungo il file alla lista dei file al form
      this.pdfList = this.pdfList.concat(file);
      this.formProdotti.patchValue({ pdf_assicurazione: file.name });

      // Recupero il base64 del file caricato
      const reader = new FileReader();
      reader.onload = () => {
        this.pdfFile = reader.result as string;
      }
      reader.readAsDataURL(file);

      return false;
    } else {
      return false
    }
  };

  onRemovePDF = (file: any): boolean => {
    this.formProdotti.patchValue({ pdf_assicurazione: '' });
    this.pdfList = [];
    return false;
  }

  onNewCategory(categoria: string) {

    // Se la categoria selezionata è 'Altro' procedo
    if (categoria == 'Altro') {

      // Apro il modal con il form per aggiungere una nuova categoria
      let dialogRef = this.dialog.open(AggiungiCategoriaComponent, { autoFocus: false, width: '1000px' })
      dialogRef?.afterClosed().subscribe((esito: { result: boolean; data?: number }) => {

        // Se il modal ritorna esito positivo procedo
        if (esito.result) {

          // Setto nel form la categoria appena creata
          this.apiService.setCategorie();
          this.formProdotti.patchValue({ categoria: esito.data })

        }
      })
    }
  }

  onPrezzi() {
    let dialogRef = this.dialog.open(FascePrezziProdottoComponent, { autoFocus: false, width: '1000px' })
    dialogRef?.afterClosed().subscribe(() => {
      this.getFasce();
    })
  }

  onEliminaFascia(i: number) {

    this.fasce.splice(i, 1);    
    this.storageService.setFasceProdotto(this.fasce);
    this.getFasce();

  }

  onGiornalieri(giornalieri: boolean) {

    this.formProdotti.get('giorni')?.clearValidators();

    if (giornalieri) {
      this.formProdotti.get('giorni')?.setValidators(Validators.required);
      this.formProdotti.get('date')?.disable();
      this.formProdotti.get('date_specifiche')?.disable();
      this.formProdotti.patchValue({ date: null, date_specifiche: null })
    } else {
      this.formProdotti.get('date')?.enable();
      this.formProdotti.get('date_specifiche')?.enable();
    }

    this.formProdotti.get('giorni')?.updateValueAndValidity();

  }

  onDateSpecifiche(date: boolean) {

    this.formProdotti.get('date_specifiche')?.clearValidators();

    if (date) {
      this.formProdotti.get('date_specifiche')?.setValidators(Validators.required);
      this.formProdotti.get('giorni')?.disable();
      this.formProdotti.get('giornaliero')?.disable();
      this.formProdotti.patchValue({ giorni: null, giornaliero: null })
    } else {
      this.formProdotti.get('giorni')?.enable();
      this.formProdotti.get('giornaliero')?.enable();
    }

    this.formProdotti.get('date_specifiche')?.updateValueAndValidity();

  }

  onAssicurativo(assicurativo: boolean) {

    this.formProdotti.get('pdf_assicurazione')?.removeValidators(Validators.required);

    if (assicurativo)
      this.formProdotti.get('pdf_assicurazione')?.addValidators(Validators.required);

    this.formProdotti.get('pdf_assicurazione')?.updateValueAndValidity();

  }

  onClose() {
    this.dialogRef.close(false);
  }

  onSubmit() {

    let prodotto = {
      nome: this.formProdotti.get('nome')?.value,
      id_categoria: this.formProdotti.get('categoria')?.value,
      descrizione: this.formProdotti.get('descrizione')?.value,
      prezzo: this.formProdotti.get('prezzo')?.value,
      giornaliero: this.formProdotti.get('giornaliero')?.value || false,
      inizio_validita: this.datePipe.transform(this.formProdotti.get('inizio_validita')?.value, 'yyyy-MM-dd'),
      fine_validita: this.datePipe.transform(this.formProdotti.get('fine_validita')?.value, 'yyyy-MM-dd'),
      giorni: this.formProdotti.get('giornaliero')?.value ? JSON.stringify(this.formProdotti.get('giorni')?.value) : null,
      date_specifiche: this.formProdotti.get('date')?.value || false,
      date_specifiche_dal: this.formProdotti.get('date')?.value ? this.datePipe.transform((this.formProdotti.get('date')?.value ? this.formProdotti.get('date_specifiche')?.value[0] : this.formProdotti.get('date_specifiche')?.value), 'yyyy-MM-dd') : null,
      date_specifiche_al: this.formProdotti.get('date')?.value ? this.datePipe.transform(this.formProdotti.get('date')?.value ? this.formProdotti.get('date_specifiche')?.value[1] : this.formProdotti.get('date_specifiche')?.value, 'yyyy-MM-dd') : null,
      inizio_orario: null,
      fine_orario: null,
      posizione: this.formProdotti.get('posizione')?.value || "ultimo",
      immagine: this.formProdotti.get('immagine')?.value || "",
      file_immagine: this.immagineFile || "",
      online: this.formProdotti.get('online')?.value || false,
      obbligatorio: this.formProdotti.get('obbligatorio')?.value || false,
      codice: this.formProdotti.get('codice')?.value || false,
      informativo: this.formProdotti.get('informativo')?.value || false,
      assicurativo: this.formProdotti.get('assicurativo')?.value || false,
      pdf_assicurazione: this.formProdotti.get('pdf_assicurazione')?.value || "",
      file_pdf_assicurazione: this.pdfFile || "",
      fototessera: this.formProdotti.get('fototessera')?.value || false,
      fasce_prezzi: this.fasce,
      bonifico: this.formProdotti.get('bonifico')?.value || false,
      carta: this.formProdotti.get('carta')?.value || false,
      satispay: this.formProdotti.get('satispay')?.value || false
    }

    // Aggiungo il prodotto
    this.apiService.addProdotto(prodotto).subscribe({
      next: (data) => this.apiService.setProdotti(),
      error: (err) => this.apiService.Error(err.error.message),
      complete: () => this.dialogRef.close(true)
    })
  }
}