import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ApiService } from '../service/api.service';
import { Promozione } from '../shared/interfacce';
import { AggiungiPromozioneComponent } from './aggiungi-promozione/aggiungi-promozione.component';
import { DateService } from '../service/date.service';

@Component({
  selector: 'app-promozioni',
  templateUrl: './promozioni.component.html',
  styleUrls: ['./promozioni.component.css']
})
export class PromozioniComponent implements OnInit {

  constructor(private apiService: ApiService, public dateService: DateService, private dialog: MatDialog, private location: Location) { }

  promozioni: Promozione[] = [];

  nomeFilter: any = '';
  prodottoFilter: any = '';

  carica: boolean = true;

  ngOnInit(): void {

    // Recupero le promozioni
    this.apiService.setPromozioni();
    this.apiService.promozioneChanged.subscribe({
      next: (data) => { this.promozioni = [...data]; this.carica = false },
      error: (err) => this.apiService.Error(err.error.message)
    })

  }

  onOrdinamentoNome(a: Promozione, b: Promozione) {
    return a.nome.localeCompare(b.nome)
  }

  onOrdinamentoProdotto(a: Promozione, b: Promozione) {
    return a.nome_prodotto.localeCompare(b.nome_prodotto)
  }

  onOrdinamentoPrezzo(a: Promozione, b: Promozione) {
    return a.sconto - b.sconto;
  }

  onIndietro() {
    this.location.back();
  }

  onAdd() {
    this.dialog.closeAll()
    this.dialog.open(AggiungiPromozioneComponent, { autoFocus: false, width: '1000px' });
  }

}