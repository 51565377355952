import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from 'src/app/service/api.service';
import { Cliente, Movimenti } from 'src/app/shared/interfacce';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-esportazione-movimenti',
  templateUrl: './esportazione-movimenti.component.html',
  styleUrls: ['./esportazione-movimenti.component.css']
})
export class EsportazioneMovimentiComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<EsportazioneMovimentiComponent>, @Inject(MAT_DIALOG_DATA) public data: { tipo: string }, private apiService: ApiService, private datePipe: DatePipe) { }

  formEsportazione!: FormGroup;

  clienti: Cliente[] = [];
  filteredClienti: Cliente[] = [];
  movimenti: Movimenti[] = [];

  ngOnInit(): void {

    // Creo il form con i controlli
    this.formEsportazione = new FormGroup({
      data: new FormControl(null, Validators.required),
      tipo: new FormControl(null),
      metodo: new FormControl(null),
      cliente: new FormControl(null)
    })

    // Recupero i clienti
    this.apiService.getClienti().subscribe({
      next: (data) => this.clienti = [...data],
      error: (err) => this.apiService.Error(err.error.message),
      complete: () => {
        this.filteredClienti = [...this.clienti.slice(0, 10)]

        if (this.data.tipo == 'cassa') {
          this.formEsportazione.get('metodo')?.disable()
          this.formEsportazione.patchValue({ metodo: 'Contanti' });
        }
      }
    })

  }

  onSeleziona(ricerca: any) {
    // Filtro i clienti per nome o cognome in base al testo inserito
    this.filteredClienti = this.clienti.filter(cliente => (cliente.nome.trim() + ' ' + cliente.cognome.trim()).toLocaleLowerCase().includes(ricerca.toLocaleLowerCase()) || (cliente.cognome.trim() + ' ' + cliente.nome.trim()).toLocaleLowerCase().includes(ricerca.toLocaleLowerCase())).slice(0, 10)
  }

  onSubmit() {

    let movimento = {
      data_inizio: this.datePipe.transform((this.formEsportazione.get('data')?.value ? this.formEsportazione.get('data')?.value[0] : this.formEsportazione.get('data')?.value), 'yyyy-MM-dd'),
      data_fine: this.datePipe.transform(this.formEsportazione.get('data')?.value ? this.formEsportazione.get('data')?.value[1] : this.formEsportazione.get('data')?.value, 'yyyy-MM-dd'),
      tipo: this.formEsportazione.get('tipo')?.value || '',
      metodo: this.formEsportazione.get('metodo')?.value || '',
      cliente: this.formEsportazione.get('cliente')?.value ? this.formEsportazione.get('cliente')?.value.id : ''
    }    

    // Recupero i movimenti in base ai filtri passati
    this.apiService.getMovimentiPeriodo(this.data.tipo, movimento.data_inizio, movimento.data_fine, movimento.tipo, movimento.metodo, movimento.cliente).subscribe({
      next: (data) => this.movimenti = data,
      error: (err) => this.apiService.Error(err.error.message),
      complete: () => {
        const workBook = XLSX.utils.book_new();
        const workSheet = XLSX.utils.json_to_sheet(this.movimenti);

        workSheet["!cols"] = [{ wch: 10 }, { wch: 30 }, { wch: 40 }, { wch: 30 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }];

        XLSX.utils.book_append_sheet(workBook, workSheet, 'Movimenti');
        XLSX.writeFile(workBook, 'movimenti_filtrati.xlsx');

        this.dialogRef.close();
      }
    })

  }

  onClose() {
    this.dialogRef.close();
  }

}