import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { DatePipe, registerLocaleData } from '@angular/common';

// MATERIAL
import { MatDialogModule } from '@angular/material/dialog';
import { DragDropModule } from '@angular/cdk/drag-drop';
// MATERIAL

// NGZORRO
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzI18nModule, NZ_I18N, it_IT, NZ_DATE_LOCALE } from 'ng-zorro-antd/i18n';
import { it as IT } from 'date-fns/locale';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { NzStatisticModule } from 'ng-zorro-antd/statistic';
import { NzFormModule } from 'ng-zorro-antd/form';
// NGZORRO

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// CHART
import { NgChartsModule } from 'ng2-charts';
// CHART

import { LayoutModule } from '@angular/cdk/layout';
import localeIT from '@angular/common/locales/it';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ApiInterceptor } from './service/api.service';

// PIPE
import { SearchCategorieNomePipe, SearchClientiEmailPipe, SearchClientiNomeCognomePipe, SearchCodiciScontoClientePipe, SearchCodiciScontoCodicePipe, SearchConfigurazioneDescrizionePipe, SearchGruppiCapogruppoPipe, SearchGruppiNomePipe, SearchLogDescrizionePipe, SearchLogUtentiPipe, SearchMovimentiClientePipe, SearchMovimentiDescrizionePipe, SearchOrdiniClientePipe, SearchOrdiniProdottoPipe, SearchOrdiniResiduoPipe, SearchProdottiDescrizionePipe, SearchProdottiNomePipe, SearchPromozioniNomePipe, SearchPromozioniProdottoPipe, SearchPuntiRitiroDescrizionePipe, SearchTesseramentiCategoriaAttiviPipe, SearchTesseramentiCategoriaDataPipe, SearchTesseramentiCategoriaPagatiPipe, SearchTesseramentiProdottoPipe, SearchTesseramentiSocioPipe, SearchTesseramentiStatoPipe, SearchUtentiTipoPipe, SearchUtentiUsernamePipe } from './shared/search.pipe';
// PIPE

// COMMON
import { CaricamentoComponent } from './caricamento/caricamento.component';
import { ErrorComponent } from './error/error.component';
import { SuccessComponent } from './success/success.component';
import { LoadingComponent } from './loading/loading.component';
import { MenuComponent } from './menu/menu.component';
import { HomeComponent } from './home/home.component';
import { AuthComponent } from './login/login.component';
// COMMON

// CLIENTI
import { ClientiComponent } from './clienti/clienti.component';
import { DettagliClienteComponent } from './clienti/dettagli-cliente/dettagli-cliente.component';
import { AggiungiClienteComponent } from './clienti/aggiungi-cliente/aggiungi-cliente.component';
import { ModificaContattoClienteComponent } from './clienti/modifica-contatto-cliente/modifica-contatto-cliente.component';
import { ModificaClienteComponent } from './clienti/modifica-cliente/modifica-cliente.component';
import { EliminaClienteComponent } from './clienti/elimina-cliente/elimina-cliente.component';
import { ContattiClienteComponent } from './clienti/contatti-cliente/contatti-cliente.component';
import { EliminaImmagineClienteComponent } from './clienti/elimina-immagine-cliente/elimina-immagine-cliente.component';
import { EliminaContattoComponent } from './clienti/elimina-contatto/elimina-contatto.component';
// CLIENTI

// GRUPPI
import { GruppiComponent } from './gruppi/gruppi.component';
import { DettagliGruppoComponent } from './gruppi/dettagli-gruppo/dettagli-gruppo.component';
import { AggiungiGruppoComponent } from './gruppi/aggiungi-gruppo/aggiungi-gruppo.component';
import { EliminaImmagineGruppoComponent } from './gruppi/elimina-immagine-gruppo/elimina-immagine-gruppo.component';
import { ModificaGruppoComponent } from './gruppi/modifica-gruppo/modifica-gruppo.component';
import { EliminaGruppoComponent } from './gruppi/elimina-gruppo/elimina-gruppo.component';
// GRUPPI

// UTENTI
import { UtentiComponent } from './utenti/utenti.component';
import { DettagliUtenteComponent } from './utenti/dettagli-utente/dettagli-utente.component';
import { AggiungiUtenteComponent } from './utenti/aggiungi-utente/aggiungi-utente.component';
import { ModificaUtenteComponent } from './utenti/modifica-utente/modifica-utente.component';
import { EliminaUtenteComponent } from './utenti/elimina-utente/elimina-utente.component';
import { ModificaUtentePasswordComponent } from './utenti/modifica-utente/modifica-utente-password/modifica-utente-password.component';
// UTENTI

// PROMOZIONI
import { PromozioniComponent } from './promozioni/promozioni.component';
import { AggiungiPromozioneComponent } from './promozioni/aggiungi-promozione/aggiungi-promozione.component';
import { DettagliPromozioneComponent } from './promozioni/dettagli-promozione/dettagli-promozione.component';
import { ModificaPromozioneComponent } from './promozioni/modifica-promozione/modifica-promozione.component';
import { EliminaPromozioneComponent } from './promozioni/elimina-promozione/elimina-promozione.component';
// PROMOZIONI

// CATEGORIE
import { CategorieComponent } from './categorie/categorie.component';
import { AggiungiCategoriaComponent } from './categorie/aggiungi-categoria/aggiungi-categoria.component';
import { DettagliCategoriaComponent } from './categorie/dettagli-categoria/dettagli-categoria.component';
import { EliminaCategoriaComponent } from './categorie/elimina-categoria/elimina-categoria.component';
import { ModificaCategoriaComponent } from './categorie/modifica-categoria/modifica-categoria.component';
// CATEGORIE

// STORICO ORDINI
import { StoricoOrdiniComponent } from './storico-ordini/storico-ordini.component';
import { DettagliStoricoOrdineComponent } from './storico-ordini/dettagli-storico-ordine/dettagli-storico-ordine.component';
import { ModificaStoricoOrdineComponent } from './storico-ordini/modifica-storico-ordine/modifica-storico-ordine.component';
import { EsportazioneStoricoOrdineComponent } from './storico-ordini/esportazione-storico-ordine/esportazione-storico-ordine.component';
import { EliminaProdottiStoricoOrdineComponent } from './storico-ordini/elimina-prodotti-storico-ordine/elimina-prodotti-storico-ordine.component';
import { EliminaOrdineComponent } from './storico-ordini/elimina-ordine/elimina-ordine.component';
import { EmailMulticlienteDettaglioOrdineComponent } from './storico-ordini/email-multicliente-dettaglio-ordine/email-multicliente-dettaglio-ordine.component';
import { EmailDettaglioOrdineComponent } from './storico-ordini/email-dettaglio-ordine/email-dettaglio-ordine.component';
// STORICO ORDINI

// PRODOTTI
import { ProdottiComponent } from './prodotti/prodotti.component';
import { AggiungiProdottoComponent } from './prodotti/aggiungi-prodotto/aggiungi-prodotto.component';
import { DettagliProdottoComponent } from './prodotti/dettagli-prodotto/dettagli-prodotto.component';
import { EliminaProdottoComponent } from './prodotti/elimina-prodotto/elimina-prodotto.component';
import { ModificaProdottoComponent } from './prodotti/modifica-prodotto/modifica-prodotto.component';
import { EliminaImmagineProdottoComponent } from './prodotti/elimina-immagine-prodotto/elimina-immagine-prodotto.component';
import { ModificaProdottiComponent } from './prodotti/modifica-prodotti/modifica-prodotti.component';
import { DuplicaProdottoComponent } from './prodotti/duplica-prodotto/duplica-prodotto.component';
import { FascePrezziProdottoComponent } from './prodotti/fasce-prezzi-prodotto/fasce-prezzi-prodotto.component';
import { ModificaFascePrezziProdottoComponent } from './prodotti/modifica-fasce-prezzi-prodotto/modifica-fasce-prezzi-prodotto.component';
import { EliminaFascePrezziProdottoComponent } from './prodotti/elimina-fasce-prezzi-prodotto/elimina-fasce-prezzi-prodotto.component';
// PRODOTTI

// PRIMA NOTA
import { PrimaNotaComponent } from './prima-nota/prima-nota.component';
import { AggiungiMovimentoComponent } from './prima-nota/aggiungi-movimento/aggiungi-movimento.component';
import { ModificaMovimentoComponent } from './prima-nota/modifica-movimento/modifica-movimento.component';
import { EliminaMovimentoComponent } from './prima-nota/elimina-movimento/elimina-movimento.component';
import { EsportazioneMovimentiComponent } from './prima-nota/esportazione-movimenti/esportazione-movimenti.component';
import { MovimentiCassaComponent } from './prima-nota/movimenti-cassa/movimenti-cassa.component';
import { MovimentiMetodiComponent } from './prima-nota/movimenti-metodi/movimenti-metodi.component';
import { DettagliMovimentoCassaComponent } from './prima-nota/dettagli-movimento-cassa/dettagli-movimento-cassa.component';
import { DettagliMovimentoMetodiComponent } from './prima-nota/dettagli-movimento-metodi/dettagli-movimento-metodi.component';
import { ReportMovimentiComponent } from './prima-nota/report-movimenti/report-movimenti.component';
// PRIMA NOTA

// DETTAGLIO ORDINI
import { TesseramentiComponent } from './tesseramenti/tesseramenti.component';
import { AggiungiTesseramentoComponent } from './tesseramenti/aggiungi-tesseramento/aggiungi-tesseramento.component';
import { ModificaTesseramentoComponent } from './tesseramenti/modifica-tesseramento/modifica-tesseramento.component';
import { EliminaTesseramentoComponent } from './tesseramenti/elimina-tesseramento/elimina-tesseramento.component';
import { AggiungiProdottiTesseramentoComponent } from './tesseramenti/aggiungi-prodotti-tesseramento/aggiungi-prodotti-tesseramento.component';
import { DettagliTesseramentoComponent } from './tesseramenti/dettagli-tesseramento/dettagli-tesseramento.component';
import { EsportazioneTesseramentoComponent } from './tesseramenti/esportazione-tesseramento/esportazione-tesseramento.component';
import { ModificaProdottiTesseramentiComponent } from './tesseramenti/modifica-prodotti-tesseramenti/modifica-prodotti-tesseramenti.component';
import { ModificaProdottoTesseramentoComponent } from './tesseramenti/modifica-prodotto-tesseramento/modifica-prodotto-tesseramento.component';
// DETTAGLIO ORDINI

// SEZIONI 
import { StatisticheComponent } from './statistiche/statistiche.component';
import { ImpostazioniComponent } from './impostazioni/impostazioni.component';
import { LogComponent } from './log/log.component';
// SEZIONI 

// PUNTI DI RITIRO
import { PuntiRitiroComponent } from './punti-ritiro/punti-ritiro.component';
import { AggiungiPuntoRitiroComponent } from './punti-ritiro/aggiungi-punto-ritiro/aggiungi-punto-ritiro.component';
import { DettaglioPuntoRitiroComponent } from './punti-ritiro/dettaglio-punto-ritiro/dettaglio-punto-ritiro.component';
import { ModificaPuntoRitiroComponent } from './punti-ritiro/modifica-punto-ritiro/modifica-punto-ritiro.component';
import { EliminaPuntoRitiroComponent } from './punti-ritiro/elimina-punto-ritiro/elimina-punto-ritiro.component';
// PUNTI DI RITIRO

// CODICI SCONTO
import { CodiciScontoComponent } from './codici-sconto/codici-sconto.component';
import { DettaglioCodiciScontoComponent } from './codici-sconto/dettaglio-codici-sconto/dettaglio-codici-sconto.component';
import { AggiungiCodiciScontoComponent } from './codici-sconto/aggiungi-codici-sconto/aggiungi-codici-sconto.component';
import { EliminaCodiciScontoComponent } from './codici-sconto/elimina-codici-sconto/elimina-codici-sconto.component';
import { ModificaCodiciScontoComponent } from './codici-sconto/modifica-codici-sconto/modifica-codici-sconto.component';
// CODICI SCONTO

// CONFIGURAZIONI
import { ConfigurazioniComponent } from './configurazioni/configurazioni.component';
import { AggiungiConfigurazioneComponent } from './configurazioni/aggiungi-configurazione/aggiungi-configurazione.component';
import { DettaglioConfigurazioneComponent } from './configurazioni/dettaglio-configurazione/dettaglio-configurazione.component';
import { ModificaConfigurazioneComponent } from './configurazioni/modifica-configurazione/modifica-configurazione.component';
import { EliminaConfigurazioneComponent } from './configurazioni/elimina-configurazione/elimina-configurazione.component';
// CONFIGURAZIONI

// SEZIONI ATTIVAMENTO
import { ClientiNewsletterComponent } from './clienti-newsletter/clienti-newsletter.component';
import { TesseramentiCardOpenComponent } from './tesseramenti-card-open/tesseramenti-card-open.component';
import { TesseramentiCardVialatteaComponent } from './tesseramenti-card-vialattea/tesseramenti-card-vialattea.component';
import { TesseramentiGiornalieriVialatteaComponent } from './tesseramenti-giornalieri-vialattea/tesseramenti-giornalieri-vialattea.component';
import { TesseramentiAssicurazioniComponent } from './tesseramenti-assicurazioni/tesseramenti-assicurazioni.component';
import { TesseramentiSciclubComponent } from './tesseramenti-sciclub/tesseramenti-sciclub.component';
// SEZIONI ATTIVAMENTO

registerLocaleData(localeIT, 'it');

@NgModule({
  declarations: [
    AppComponent,
    ClientiComponent,
    DettagliClienteComponent,
    GruppiComponent,
    AuthComponent,
    CaricamentoComponent,
    AggiungiClienteComponent,
    DettagliGruppoComponent,
    ModificaGruppoComponent,
    ModificaClienteComponent,
    AggiungiGruppoComponent,
    EliminaClienteComponent,
    ContattiClienteComponent,
    StatisticheComponent,
    ImpostazioniComponent,
    EliminaGruppoComponent,
    ModificaContattoClienteComponent,
    UtentiComponent,
    DettagliUtenteComponent,
    ModificaUtenteComponent,
    EliminaUtenteComponent,
    AggiungiUtenteComponent,
    PromozioniComponent,
    AggiungiPromozioneComponent,
    DettagliPromozioneComponent,
    ModificaPromozioneComponent,
    EliminaPromozioneComponent,
    ModificaUtentePasswordComponent,
    CategorieComponent,
    AggiungiCategoriaComponent,
    DettagliCategoriaComponent,
    ModificaCategoriaComponent,
    EliminaCategoriaComponent,
    LogComponent,
    MenuComponent,
    HomeComponent,
    ErrorComponent,
    PrimaNotaComponent,
    StoricoOrdiniComponent,
    DettagliStoricoOrdineComponent,
    ModificaStoricoOrdineComponent,
    ProdottiComponent,
    AggiungiProdottoComponent,
    DettagliProdottoComponent,
    EliminaProdottoComponent,
    ModificaProdottoComponent,
    ModificaContattoClienteComponent,
    AggiungiMovimentoComponent,
    ModificaMovimentoComponent,
    EliminaMovimentoComponent,
    EsportazioneStoricoOrdineComponent,
    EsportazioneMovimentiComponent,
    TesseramentiComponent,
    AggiungiTesseramentoComponent,
    ModificaTesseramentoComponent,
    EliminaTesseramentoComponent,
    AggiungiProdottiTesseramentoComponent,
    DettagliTesseramentoComponent,
    EliminaImmagineProdottoComponent,
    EliminaImmagineClienteComponent,
    EmailDettaglioOrdineComponent,
    EliminaImmagineGruppoComponent,
    SuccessComponent,
    EsportazioneTesseramentoComponent,
    EliminaProdottiStoricoOrdineComponent,
    EliminaOrdineComponent,
    ModificaProdottiTesseramentiComponent,
    EliminaContattoComponent,
    EmailMulticlienteDettaglioOrdineComponent,
    FascePrezziProdottoComponent,
    ModificaFascePrezziProdottoComponent,
    EliminaFascePrezziProdottoComponent,
    SearchClientiNomeCognomePipe,
    SearchClientiEmailPipe,
    SearchGruppiNomePipe,
    SearchGruppiCapogruppoPipe,
    SearchTesseramentiProdottoPipe,
    SearchTesseramentiStatoPipe,
    SearchTesseramentiCategoriaDataPipe,
    SearchTesseramentiCategoriaPagatiPipe,
    SearchTesseramentiCategoriaAttiviPipe,
    SearchTesseramentiSocioPipe,
    SearchOrdiniClientePipe,
    SearchOrdiniProdottoPipe,
    SearchOrdiniResiduoPipe,
    SearchProdottiNomePipe,
    SearchProdottiDescrizionePipe,
    SearchCategorieNomePipe,
    SearchPromozioniNomePipe,
    SearchPromozioniProdottoPipe,
    SearchMovimentiDescrizionePipe,
    SearchMovimentiClientePipe,
    SearchUtentiTipoPipe,
    SearchUtentiUsernamePipe,
    SearchLogUtentiPipe,
    SearchLogDescrizionePipe,
    SearchPuntiRitiroDescrizionePipe,
    SearchCodiciScontoClientePipe,
    SearchCodiciScontoCodicePipe,
    SearchConfigurazioneDescrizionePipe,
    MovimentiCassaComponent,
    MovimentiMetodiComponent,
    DettagliMovimentoCassaComponent,
    DettagliMovimentoMetodiComponent,
    ModificaProdottoTesseramentoComponent,
    ReportMovimentiComponent,
    PuntiRitiroComponent,
    AggiungiPuntoRitiroComponent,
    DettaglioPuntoRitiroComponent,
    ModificaPuntoRitiroComponent,
    EliminaPuntoRitiroComponent,
    ModificaProdottiComponent,
    TesseramentiCardOpenComponent,
    TesseramentiCardVialatteaComponent,
    TesseramentiGiornalieriVialatteaComponent,
    TesseramentiAssicurazioniComponent,
    TesseramentiSciclubComponent,
    ClientiNewsletterComponent,
    CodiciScontoComponent,
    DettaglioCodiciScontoComponent,
    AggiungiCodiciScontoComponent,
    EliminaCodiciScontoComponent,
    ModificaCodiciScontoComponent,
    DuplicaProdottoComponent,
    ConfigurazioniComponent,
    AggiungiConfigurazioneComponent,
    DettaglioConfigurazioneComponent,
    ModificaConfigurazioneComponent,
    EliminaConfigurazioneComponent,
    LoadingComponent
  ],
  imports: [
    MatDialogModule,
    DragDropModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    NgChartsModule,
    LayoutModule,
    NzButtonModule,
    NzCardModule,
    NzCheckboxModule,
    NzCollapseModule,
    NzDatePickerModule,
    NzDividerModule,
    NzDropDownModule,
    NzI18nModule,
    NzIconModule,
    NzInputModule,
    NzInputNumberModule,
    NzListModule,
    NzMenuModule,
    NzModalModule,
    NzNotificationModule,
    NzPaginationModule,
    NzPopconfirmModule,
    NzRadioModule,
    NzSelectModule,
    NzSpinModule,
    NzTableModule,
    NzTimePickerModule,
    NzToolTipModule,
    NzUploadModule,
    NzAutocompleteModule,
    NzAlertModule,
    NzBreadCrumbModule,
    NzStatisticModule,
    NzFormModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
    { provide: NZ_I18N, useValue: it_IT },
    { provide: NZ_DATE_LOCALE, useValue: IT },
    { provide: LOCALE_ID, useValue: "it" },
    { provide: DatePipe }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }