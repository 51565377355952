<div class="p-3">
  <ng-template #storicoTitle>
    <div class="row p-md-3 p-1">
      <div class="col-lg-1 mt-2">
        <button nz-button nzSize="large" class="w-100" type="button" (click)="onIndietro()"><i style="font-size: 20px;"
            class="bi bi-arrow-left"></i></button>
      </div>
      <div class="col-lg-8 mt-2">
        <div class="row">
          <div class="col-md-4">
            <input nz-input placeholder="Cerca Cliente" nzSize="large" [(ngModel)]="clienteFilter" />
          </div>
          <div class="col-md-4 mt-md-0 mt-3">
            <input nz-input placeholder="Cerca Prodotto" nzSize="large" [(ngModel)]="prodottoFilter" />
          </div>
          <div class="col-md-4 mt-md-0 mt-3">
            <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Cerca Stato" [(ngModel)]="statoFilter" nzSize="large" class="w-100">              
              <nz-option nzValue="pagato" nzLabel="Stato: Pagato"></nz-option>
              <nz-option nzValue="non pagato" nzLabel="Stato: Non Pagato"></nz-option>
              <nz-option nzValue="" nzLabel="Stato: Tutti"></nz-option>
            </nz-select>
          </div>
        </div>
      </div>
      <div class="col-lg-3 mt-2" style="text-align: right;">
        <nz-button-group class="w-100">
          <button nz-button nzSize="large" class="w-100 crop" type="button" (click)="onAdd()">Crea ordine</button>
          <button nz-button nzSize="large" class="w-25" nz-dropdown [nzDropdownMenu]="menu1" nzPlacement="bottomRight">
            <span nz-icon nzType="ellipsis"></span>
          </button>
        </nz-button-group>
        <nz-dropdown-menu #menu1="nzDropdownMenu">
          <ul nz-menu>
            <li nz-menu-item class="p-3" (click)="onExportGiornalieri()">Esportazione giornaliera <i class="ms-2 bi bi-file-earmark-spreadsheet"></i></li>
            <li nz-menu-item class="p-3" (click)="onExport()">Esportazione filtrata <i class="ms-2 bi bi-file-earmark-spreadsheet"></i></li>
          </ul>
        </nz-dropdown-menu>
      </div>
    </div>    
  </ng-template>
  <nz-card [nzTitle]="storicoTitle">
    <div class="row p-md-3 p-1">
      <nz-table #ordiniTable 
      *ngIf="!carica" 
      nzShowSizeChanger
      [nzTotal]="totale"
      [nzData]="ordini"
      nzNoResult="Nessun Ordine trovato"
      [nzPageSize]="pageSize"
      [nzFrontPagination]="false"
      [nzLoading]="loading"             
      [nzPageIndex]="pageIndex"
      [nzBordered]="true" 
      [nzPageSizeOptions]="[5, 10, 25, 50, 100, 150, 200, 250, 300, 350, 400, 500, 750, 1000]"
      (nzQueryParams)="onParamsChange($event)">
        <thead>
          <tr>
            <th [nzSortFn]="onOrdinamento" nzColumnKey="id_ordine">N°</th>
            <th [nzSortFn]="onOrdinamento" nzColumnKey="clienti" [nzShowFilter]="true" [nzCustomFilter]="true" [nzFilterFn]="clienteFilter" class="text-start">Cliente/i</th>
            <th [nzSortFn]="onOrdinamento" nzColumnKey="prodotti" [nzShowFilter]="true" [nzCustomFilter]="true" [nzFilterFn]="prodottoFilter" class="text-start">Prodotto/i</th>
            <th [nzSortFn]="onOrdinamento" nzColumnKey="residuo" [nzShowFilter]="true" [nzCustomFilter]="true" [nzFilterFn]="statoFilter" class="text-start">Residuo</th>
            <th [nzSortFn]="onOrdinamento" nzColumnKey="eseguitoDa" class="text-center">Eseguito da</th>
            <th [nzSortFn]="onOrdinamento" nzColumnKey="data_creazione" class="text-end">Data</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let ordine of ordiniTable.data" [routerLink]="['/storico/', ordine.id]" class="cursor-pointer">
            <td>{{ordine.id}} </td>
            <td class="text-start">{{ordine.clienti}}</td>            
            <td class="text-start">{{ordine.prodotti}}</td>
            <td class="text-center">{{ordine.residuo | currency: 'EUR' }}</td>
            <td class="text-center">{{ordine.eseguitoDa}}</td>            
            <td class="text-end">{{ordine.data_creazione | date: 'dd/MM/yyyy HH:mm:ss'}}</td>
          </tr>
        </tbody>
      </nz-table>

      <app-caricamento *ngIf="carica" class="d-flex align-items-center justify-content-center"></app-caricamento>
    </div>
  </nz-card>
</div>