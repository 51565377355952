<div class="container-fluid h-100">
    <div class="row d-flex justify-content-center my-auto">
        <div class="col-md-4 text-center rounded-3 border p-md-5 p-2 position-absolute top-50 start-50 translate-middle">
            <h1 *ngIf="!carica">Effettua il login</h1>
            <app-caricamento *ngIf="carica"></app-caricamento>
            <div class="row" *ngIf="!carica">
                <form [formGroup]="formLogin" (ngSubmit)="onLogin()">                    
                    <div class="error-messages" *ngIf="credenzialiSbagliate">                        
                        <nz-alert nzType="error" [nzMessage]="'Credenziali errate!'" nzShowIcon nzCloseable></nz-alert>
                    </div>                    
                    <div class="row mt-3">
                        <div class="col">
                            <nz-form-item class="flex-column mb-2">
                                <nz-form-label nzRequired class="text-start">Username</nz-form-label>
                                <nz-form-control>
                                    <input nz-input formControlName="username" nzSize="large" required>
                                </nz-form-control>
                            </nz-form-item>
                            <div class="error-messages">
                                <ng-container *ngFor="let error of error_messages.username">                                                                            
                                    <nz-alert nzType="error" [nzMessage]="error.message" nzShowIcon nzCloseable *ngIf="formLogin.get('username')?.hasError(error.type) && (formLogin.get('username')?.dirty || formLogin.get('username')?.touched)"></nz-alert>
                                </ng-container>
                            </div>
                        </div>                        
                    </div>
                    <div class="row">
                        <div class="col">
                            <nz-form-item class="flex-column">
                                <nz-form-label nzRequired class="text-start">Password</nz-form-label>
                                <nz-form-control>
                                    <nz-input-group [nzSuffix]="passwordTemplate" nzSize="large">
                                        <input [type]="hide ? 'text' : 'password'" nz-input formControlName="password" />
                                    </nz-input-group>
                                    <ng-template #passwordTemplate>
                                        <span nz-icon [nzType]="hide ? 'eye-invisible' : 'eye'" (click)="hide = !hide"></span>
                                    </ng-template>
                                </nz-form-control>
                            </nz-form-item>                            
                            <div class="error-messages">
                                <ng-container *ngFor="let error of error_messages.password">                                                                            
                                    <nz-alert nzType="error" [nzMessage]="error.message" nzShowIcon nzCloseable *ngIf="formLogin.get('password')?.hasError(error.type) && (formLogin.get('password')?.dirty || formLogin.get('password')?.touched)"></nz-alert>
                                </ng-container>
                            </div>
                        </div>                        
                    </div>                    
                    <div class="row">
                        <div class="col">
                            <button nz-button nzType="primary" class="w-100" nzSize="large">Entra</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>