<mat-dialog-content>
    <div class="title-section mb-3" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle style="cursor: move;">
        <h5 class="title">Modifica fascia</h5>
    </div>
    <nz-divider></nz-divider>

    <form [formGroup]="formFasce" (ngSubmit)="onSubmit()" id="formFasce">        

        <div class="row">
            <div id="indirizzoObbl" class="form-text text-start">*campo obbligatorio</div>
        </div>       

        <div class="row mt-3">
            <div class="col-md-6">    
                <nz-form-item class="flex-column mb-2">
                    <nz-form-label nzRequired class="text-start">Età min</nz-form-label>
                    <nz-form-control>
                        <nz-input-number nz-input formControlName="eta_min" nzSize="large" class="w-100" [nzMin]="0" [nzMax]="119"></nz-input-number>
                    </nz-form-control>
                </nz-form-item>
                <div class="error-messages">
                    <ng-container *ngFor="let error of error_messages.eta_min">                                                                    
                        <nz-alert nzType="error" [nzMessage]="error.message" nzShowIcon nzCloseable *ngIf="formFasce.get('eta_min')?.hasError(error.type) && (formFasce.get('eta_min')?.dirty || formFasce.get('eta_min')?.touched)"></nz-alert>
                    </ng-container>
                </div>
            </div>
            <div class="col-md-6">   
                <nz-form-item class="flex-column mb-2">
                    <nz-form-label nzRequired class="text-start">Età max</nz-form-label>
                    <nz-form-control>
                        <nz-input-number nz-input formControlName="eta_max" nzSize="large" class="w-100" [nzMin]="1" [nzMax]="120"></nz-input-number>
                    </nz-form-control>
                </nz-form-item>
                <div class="error-messages">
                    <ng-container *ngFor="let error of error_messages.eta_max">
                        <nz-alert nzType="error" [nzMessage]="error.message" nzShowIcon nzCloseable *ngIf="formFasce.get('eta_max')?.hasError(error.type) && (formFasce.get('eta_max')?.dirty || formFasce.get('eta_max')?.touched)"></nz-alert>
                    </ng-container>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <nz-form-item class="flex-column mb-2">
                    <nz-form-label nzRequired class="text-start">Prezzo</nz-form-label>
                    <nz-form-control>
                        <nz-input-group [nzSuffix]="suffixTemplateInfo"  nzSize="large">
                            <input nz-input formControlName="prezzo" required>
                        </nz-input-group>                
                        <ng-template #suffixTemplateInfo>                    
                            <span nz-icon nzType="euro-circle" nzTheme="outline"></span>                    
                        </ng-template>
                    </nz-form-control>
                </nz-form-item>
                <div class="error-messages">
                    <ng-container *ngFor="let error of error_messages.prezzo">
                        <nz-alert nzType="error" [nzMessage]="error.message" nzShowIcon nzCloseable *ngIf="formFasce.get('prezzo')?.hasError(error.type) && (formFasce.get('prezzo')?.dirty || formFasce.get('prezzo')?.touched)"></nz-alert>
                    </ng-container>
                </div>
            </div>
        </div> 
        
        <div class="row">
            <div class="col">            
                <nz-form-item class="flex-column mb-2">
                    <nz-form-label class="text-start">Descrizione</nz-form-label>
                    <nz-form-control>
                        <textarea nz-input [nzAutosize]="{ minRows: 3, maxRows: 6 }" formControlName="descrizione" nzSize="large"></textarea>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>
    </form>
    <nz-divider></nz-divider>
</mat-dialog-content>
<mat-dialog-actions>    
    <button nz-button nzSize="large" class="me-2" type="button" (click)="onClose()">Chiudi</button>            
    <button type="button" nz-button nzType="primary" nzSize="large" class="me-2" nzDanger (click)="onElimina()">Elimina Fascia</button>    
    
    <span nzTooltipTitle="Per modificare una fascia, inserire tutti i campi con l'asterisco" nz-tooltip nzTooltipPlacement="top" *ngIf="!formFasce.valid">
        <button nz-button nzType="primary" nzSize="large" [disabled]="true">Modifica Fascia</button>
    </span>  
    <button nz-button nzType="primary" nzSize="large" *ngIf="formFasce.valid" form="formFasce">Modfica Fascia</button>        
</mat-dialog-actions>