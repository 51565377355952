<div class="p-3">
    <ng-template #prodottiTitle>
        <div class="row p-md-3 p-1">
            <div class="col-lg-4 mt-2">
                <button nz-button nzSize="large" class="w-100" type="button" (click)="onIndietro()"><i style="font-size: 20px;" class="bi bi-arrow-left"></i></button>
            </div>
            <div class="col-lg-4">                
            </div>
            <div class="col-lg-4 mt-2" style="text-align: right;">
                <nz-button-group class="w-100">
                    <button nz-button nzSize="large" class="w-100 crop" type="button" [disabled]="setOfCheckedId.size <= 0" (click)="onEsporta()">Esporta i clienti selezionati</button>
                    <button nz-button nzSize="large" class="" nz-dropdown [nzDropdownMenu]="menu1" nzPlacement="bottomRight">
                        <span nz-icon nzType="ellipsis"></span>
                    </button>
                </nz-button-group>
                <nz-dropdown-menu #menu1="nzDropdownMenu">
                    <ul nz-menu>                        
                        <li nz-menu-item class="p-3" (click)="onExport()">Esporta i clieni<i class="ms-2 bi bi-file-earmark-person-fill"></i></li>
                    </ul>
                </nz-dropdown-menu>
            </div>
        </div>
        <div class="row p-md-3 p-1">            
            <div class="col-md-6">
                <input nz-input placeholder="Cerca Cliente" nzSize="large" [(ngModel)]="clienteFilter" />
            </div>
            <div class="col-md-6 mt-md-0 mt-3">
                <input nz-input placeholder="Cerca Email" nzSize="large" [(ngModel)]="emailFilter" />
            </div>            
        </div>        
    </ng-template>
    <nz-card [nzTitle]="prodottiTitle">
        <div class="row p-md-3 p-1">
            <div class="col text-center">
                <h2 class="fw-bold">Elenco Clienti con Newsletter</h2>
            </div>
        </div>
        <div class="row p-md-3 p-1">
            <nz-table #clientiTable
                *ngIf="!carica"
                nzShowSizeChanger      
                [nzTotal]="totale"
                [nzData]="clienti"
                nzNoResult="Nessun Cliente trovato"
                [nzPageSize]="pageSize"
                [nzFrontPagination]="false"
                [nzLoading]="loading"             
                [nzPageIndex]="pageIndex"
                [nzBordered]="true" 
                [nzPageSizeOptions]="[5, 10, 25, 50, 100, 150, 200, 250, 300, 350, 400, 500, 750, 1000]"
                (nzQueryParams)="onParamsChange($event)"
                (nzCurrentPageDataChange)="onCurrentPageDataChange($event)">
                <thead>
                    <tr>
                        <th [(nzChecked)]="checked" [nzIndeterminate]="indeterminate" (nzCheckedChange)="onAllChecked($event)"></th>                        
                        <th [nzSortFn]="onOrdinamento" nzColumnKey="cognome" [nzShowFilter]="true" [nzCustomFilter]="true" [nzFilterFn]="clienteFilter">Cliente</th>
                        <th [nzSortFn]="onOrdinamento" nzColumnKey="data_nascita">Data di nascita</th>
                        <th [nzSortFn]="onOrdinamento" nzColumnKey="email" [nzShowFilter]="true" [nzCustomFilter]="true" [nzFilterFn]="emailFilter">Email</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let cliente of clientiTable.data" class="cursor-pointer">
                        <td [nzChecked]="setOfCheckedId.has(cliente.id)" (nzCheckedChange)="onItemChecked(cliente.id, $event)"></td>                        
                        <td>{{cliente.cognome + ' ' + cliente.nome}} </td>
                        <td>{{cliente.data_nascita | date: 'dd/MM/yyyy'}}</td>
                        <td>{{cliente.email || '-'}}</td>                        
                    </tr>
                </tbody>
            </nz-table>

            <app-caricamento *ngIf="carica" class="d-flex align-items-center justify-content-center"></app-caricamento>
        </div>
    </nz-card>
</div>