<mat-dialog-content>
    <div class="title-section mb-3" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle style="cursor: move;">
        <h5 class="title">Cambia la tua password</h5>
    </div>
    <nz-divider></nz-divider>
    <form [formGroup]="formSetting" (ngSubmit)="onSubmit()" id="formSetting">
        <div class="row">
            <div class="col">
                <div id="indirizzoObbl" class="form-text text-start">*campo obbligatorio</div>        
            </div>
        </div>    
        <div class="row mt-3">
            <div class="col-md-6">                                
                <nz-form-item class="flex-column mb-2">
                    <nz-form-label nzRequired class="text-start">Nuova Password</nz-form-label>
                    <nz-form-control>
                        <nz-input-group [nzSuffix]="passwordTemplate" nzSize="large">
                            <input [type]="hide ? 'text' : 'password'" nz-input formControlName="password" />
                        </nz-input-group>
                        <ng-template #passwordTemplate>
                            <span nz-icon [nzType]="hide ? 'eye-invisible' : 'eye'" (click)="hide = !hide"></span>
                        </ng-template>
                    </nz-form-control>
                </nz-form-item> 
                <div class="error-messages">
                    <ng-container *ngFor="let error of error_messages.password">                                            
                        <nz-alert nzType="error" [nzMessage]="error.message" nzShowIcon nzCloseable *ngIf="formSetting.get('password')?.hasError(error.type) && (formSetting.get('password')?.dirty || formSetting.get('password')?.touched)"></nz-alert>
                    </ng-container>
                    <nz-alert nzType="error" [nzMessage]="'Le due password non combaciano!'" nzShowIcon nzCloseable *ngIf="formSetting.hasError('mismatch') && (formSetting.get('password')?.dirty || formSetting.get('password')?.touched) && (formSetting.get('confirm_password')?.dirty || formSetting.get('confirm_password')?.touched)"></nz-alert>
                </div>
            </div>
            <div class="col-md-6">                            
                <nz-form-item class="flex-column mb-2">
                    <nz-form-label nzRequired class="text-start">Conferma Password</nz-form-label>
                    <nz-form-control>
                        <nz-input-group [nzSuffix]="confirmPasswordTemplate" nzSize="large">
                            <input [type]="hide_confirm ? 'text' : 'password'" nz-input formControlName="confirm_password" />
                        </nz-input-group>
                        <ng-template #confirmPasswordTemplate>
                            <span nz-icon [nzType]="hide_confirm ? 'eye-invisible' : 'eye'" (click)="hide_confirm = !hide_confirm"></span>
                        </ng-template>
                    </nz-form-control>
                </nz-form-item> 
                <div class="error-messages">
                    <ng-container *ngFor="let error of error_messages.confirm_password">                                                                    
                        <nz-alert nzType="error" [nzMessage]="error.message" nzShowIcon nzCloseable *ngIf="formSetting.get('confirm_password')?.hasError(error.type) && (formSetting.get('confirm_password')?.dirty || formSetting.get('confirm_password')?.touched)"></nz-alert>
                    </ng-container>
                    <nz-alert nzType="error" [nzMessage]="'Le due password non combaciano!'" nzShowIcon nzCloseable *ngIf="formSetting.hasError('mismatch') && (formSetting.get('password')?.dirty || formSetting.get('password')?.touched) && (formSetting.get('confirm_password')?.dirty || formSetting.get('confirm_password')?.touched)"></nz-alert>
                </div>
            </div>
        </div>        
    </form>
    <nz-divider></nz-divider>
</mat-dialog-content>
<mat-dialog-actions>    
    <button nz-button type="button" nzSize="large" class="me-2" (click)="onClose()">Annulla</button>    

    <span nzTooltipTitle="Per modificare la password, inserire tutti i campi con l'asterisco" nz-tooltip nzTooltipPlacement="top" *ngIf="!formSetting.valid">
        <button nz-button nzType="primary" nzSize="large" [disabled]="true">Aggiorna Password</button>
    </span>  
    <button nz-button nzType="primary" nzSize="large" *ngIf="formSetting.valid" form="formSetting">Aggiorna Password</button>        
</mat-dialog-actions>