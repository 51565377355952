<mat-dialog-content>
    <div class="title-section mb-3" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle style="cursor: move;">
        <h5 class="title">Modifica un punto di ritiro</h5>
    </div>
    <nz-divider></nz-divider>
    <form [formGroup]="formPuntoRitiro" (ngSubmit)="onSubmit()" id="formPuntoRitiro">
        <div class="row">
            <div class="col">
                <div id="indirizzoObbl" class="form-text text-start">*campo obbligatorio</div>        
            </div>
        </div>        
        <div class="row mt-3">
            <div class="col">   
                <nz-form-item class="flex-column mb-2">
                    <nz-form-label nzRequired class="text-start">Valore</nz-form-label>
                    <nz-form-control>
                        <input nz-input formControlName="valore" nzSize="large" />
                    </nz-form-control>
                </nz-form-item>
                <div class="error-messages">
                    <ng-container *ngFor="let error of error_messages.valore">                                                                    
                        <nz-alert nzType="error" [nzMessage]="error.message" nzShowIcon nzCloseable *ngIf="formPuntoRitiro.get('valore')?.hasError(error.type) && (formPuntoRitiro.get('valore')?.dirty || formPuntoRitiro.get('valore')?.touched)"></nz-alert>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">   
                <nz-form-item class="flex-column mb-2">
                    <nz-form-label class="text-start">Descrizione</nz-form-label>
                    <nz-form-control>
                        <textarea nz-input [nzAutosize]="{ minRows: 3, maxRows: 6 }"  formControlName="descrizione" nzSize="large"></textarea>
                    </nz-form-control>
                </nz-form-item>
                <div class="error-messages">
                    <ng-container *ngFor="let error of error_messages.descrizione">                                                                    
                        <nz-alert nzType="error" [nzMessage]="error.message" nzShowIcon nzCloseable *ngIf="formPuntoRitiro.get('descrizione')?.hasError(error.type) && (formPuntoRitiro.get('descrizione')?.dirty || formPuntoRitiro.get('descrizione')?.touched)"></nz-alert>
                    </ng-container>
                </div>
            </div>
        </div>
    </form>
    <nz-divider></nz-divider>
</mat-dialog-content>
<mat-dialog-actions>
    <button type="button" nz-button nzSize="large" class="me-2" (click)="onClose()">Annulla</button>    

    <span nzTooltipTitle="Per modificare un punto di ritiro, inserire tutti i campi con l'asterisco" nz-tooltip nzTooltipPlacement="top" *ngIf="!formPuntoRitiro.valid">
        <button nz-button nzType="primary" nzSize="large" [disabled]="true">Modifica Punto di Ritiro</button>
    </span>  
    <button nz-button nzType="primary" nzSize="large" *ngIf="formPuntoRitiro.valid" form="formPuntoRitiro">Modifica Punto di Ritiro</button>        
</mat-dialog-actions>