<div class="p-3">
    <ng-template #puntiRitiroTitle>
        <div class="row p-md-3 p-1">
            <div class="col-lg-1 mt-2">
                <button nz-button nzSize="large" class="w-100" type="button" (click)="onIndietro()"><i style="font-size: 20px;" class="bi bi-arrow-left"></i></button>
            </div>
            <div class="col-lg-8 mt-2">
                <div class="row">
                    <div class="col">
                        <input nz-input placeholder="Cerca Descrizione" nzSize="large" [(ngModel)]="descrizioneFilter" />
                    </div>                    
                </div>
            </div>
            <div class="col-lg-3 mt-2" style="text-align: right;">
                <button nz-button nzSize="large" class="w-100 crop" type="button" (click)="onAdd()">Crea un punto di ritiro</button>
            </div>
        </div>
    </ng-template>
    <nz-card [nzTitle]="puntiRitiroTitle">
        <div class="row p-md-3 p-1">
            <nz-table #puntiRitiroTable nzShowPagination *ngIf="!carica"
                [nzTotal]="(punti_ritiro | searchPuntiRitiroDescrizione: descrizioneFilter).length"
                nzShowSizeChanger [nzPageSize]="10"
                [nzPageSizeOptions]="[5, 10, 25, 50, 100, 150, 200, 250, 300, 350, 400, 500, 750, 1000]"
                [nzData]="punti_ritiro | searchPuntiRitiroDescrizione: descrizioneFilter" [nzBordered]="true" nzNoResult="Nessun punto di ritiro trovato">
                <thead>
                    <tr>
                        <th [nzSortFn]="onOrdinamentoValore">Valore</th>
                        <th [nzSortFn]="onOrdinamentoDescrizione" class="text-start">Descrizione</th>                        
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let punto_ritiro of puntiRitiroTable.data | searchPuntiRitiroDescrizione: descrizioneFilter" [routerLink]="['/punti-ritiro/', punto_ritiro.id]" class="cursor-pointer">
                        <td>{{punto_ritiro.valore}} </td>
                        <td class="text-start">{{punto_ritiro.descrizione}}</td>                        
                    </tr>
                </tbody>
            </nz-table>

            <app-caricamento *ngIf="carica" class="d-flex align-items-center justify-content-center"></app-caricamento>
        </div>
    </nz-card>
</div>