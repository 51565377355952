<mat-dialog-content>
    <div class="title-section mb-3" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle style="cursor: move;">
        <h5 class="title d-inline">Modifica Movimento</h5>
    </div>
    <nz-divider></nz-divider>
    <form [formGroup]="formMovimento" (ngSubmit)="onSubmit()" id="formMovimento">
        <div class="row">
            <div class="col">
                <div id="indirizzoObbl" class="form-text text-start">*campo obbligatorio</div>        
            </div>
        </div>    
        <div class="row mt-3">            
            <div class="col-md-6">    
                <nz-form-item class="flex-column mb-2">
                    <nz-form-label nzRequired class="text-start">Tipo</nz-form-label>
                    <nz-form-control>
                        <nz-select nzAllowClear formControlName="tipo" nzSize="large" class="w-100" (ngModelChange)="onTipo($event)">
                            <nz-option nzValue="Incasso" nzLabel="Incasso"></nz-option>
                            <nz-option nzValue="Pagamento" nzLabel="Pagamento"></nz-option>                    
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
                <div class="error-messages">
                    <ng-container *ngFor="let error of error_messages.tipo">
                        <nz-alert nzType="error" [nzMessage]="error.message" nzShowIcon nzCloseable *ngIf="formMovimento.get('tipo')?.hasError(error.type) && (formMovimento.get('tipo')?.dirty || formMovimento.get('tipo')?.touched)"></nz-alert>                        
                    </ng-container>
                </div>
            </div>
            <div class="col-md-6">     
                <nz-form-item class="flex-column mb-2">
                    <nz-form-label nzRequired class="text-start">Metodo</nz-form-label>
                    <nz-form-control>
                        <nz-select nzAllowClear formControlName="metodo" nzSize="large" class="w-100">
                            <nz-option nzValue="Contanti" nzLabel="Contanti" nzDisabled></nz-option>
                            <nz-option nzValue="Satispay" nzLabel="SatisPay"></nz-option>
                            <nz-option nzValue="Bancomat" nzLabel="Bancomat"></nz-option>
                            <nz-option nzValue="Carta di credito" nzLabel="Carta di credito"></nz-option>
                            <nz-option nzValue="Bonifico" nzLabel="Bonifico"></nz-option>
                            <nz-option nzValue="MyBank" nzLabel="Bonifico istantaneo (MyBank)"></nz-option>
                            <nz-option nzValue="Coupon" nzLabel="Coupon"></nz-option>
                            <nz-option nzValue="Altro" nzLabel="Altro"></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
                <div class="error-messages">
                    <ng-container *ngFor="let error of error_messages.tipo">
                        <nz-alert nzType="error" [nzMessage]="error.message" nzShowIcon nzCloseable *ngIf="formMovimento.get('tipo')?.hasError(error.type) && (formMovimento.get('tipo')?.dirty || formMovimento.get('tipo')?.touched)"></nz-alert>                        
                    </ng-container>
                </div>
            </div>
        </div>        
        <div class="row">
            <div class="col">     
                <nz-form-item class="flex-column mb-2">
                    <nz-form-label nzRequired class="text-start">Descrizione</nz-form-label>
                    <nz-form-control>
                        <textarea nz-input [nzAutosize]="{ minRows: 3, maxRows: 6 }" formControlName="descrizione" nzSize="large" required ></textarea>
                    </nz-form-control>
                </nz-form-item>
                <div class="error-messages">
                    <ng-container *ngFor="let error of error_messages.descrizione">                            
                        <nz-alert nzType="error" [nzMessage]="error.message" nzShowIcon nzCloseable *ngIf="formMovimento.get('descrizione')?.hasError(error.type) && (formMovimento.get('descrizione')?.dirty || formMovimento.get('descrizione')?.touched)"></nz-alert>
                    </ng-container>
                </div>                
            </div>
        </div>    

        <div class="row"> 
            <div class="col">
                <nz-form-item class="flex-column mb-2">
                    <nz-form-label class="text-start">Cliente</nz-form-label>
                    <nz-form-control>
                        <nz-select nzShowSearch nzAllowClear formControlName="cliente" nzSize="large" class="w-100" (nzOnSearch)="onSeleziona($event)" (ngModelChange)="onCliente($event)">
                            <nz-option *ngFor="let cliente of filteredClienti" [nzValue]="cliente.id" [nzLabel]="cliente.cognome + ' ' + cliente.nome + ' | ' + ( cliente.data_nascita | date: 'dd/MM/yyyy' )"></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>

        <div class="row"> 
            <div class="col">
                <nz-form-item class="flex-column mb-2">
                    <nz-form-label class="text-start">Ordine non pagato</nz-form-label>
                    <nz-form-control>
                        <nz-select nzShowSearch nzAllowClear formControlName="ordine" nzSize="large" class="w-100" (ngModelChange)="onOrdine($event)">
                            <nz-option *ngFor="let ordine of ordini" [nzValue]="ordine" [nzLabel]="'N° ' + ordine.id + ' | ' + ordine.prodotti + ' | ' + (ordine.prezzo | currency: 'EUR')"></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>

        <div class="row">
            <div class="col-md-4">
                <nz-form-item class="flex-column mb-2">
                    <nz-form-label nzRequired class="text-start">Totale</nz-form-label>                                          
                    <nz-form-control>
                        <input nz-input formControlName="totale" nzSize="large" [readOnly]="formMovimento.get('ordine')?.value" (ngModelChange)="onTotale($event)">
                    </nz-form-control>
                </nz-form-item>
                <div class="error-messages">
                    <ng-container *ngFor="let error of error_messages.totale">                                                                    
                        <nz-alert nzType="error" [nzMessage]="error.message" nzShowIcon nzCloseable *ngIf="formMovimento.get('totale')?.hasError(error.type) && (formMovimento.get('totale')?.dirty || formMovimento.get('totale')?.touched)"></nz-alert>
                    </ng-container>
                </div>
            </div>  
            
            <div class="col-md-4">
                <nz-form-item class="flex-column mb-2">
                    <nz-form-label nzRequired class="text-start">Pagato</nz-form-label>
                    <nz-form-control>
                        <input nz-input formControlName="pagato" nzSize="large" (ngModelChange)="onPagato($event)">                            
                    </nz-form-control>
                </nz-form-item>
                <div class="error-messages">
                    <ng-container *ngFor="let error of error_messages.pagato">                                                                    
                        <nz-alert nzType="error" [nzMessage]="error.message" nzShowIcon nzCloseable *ngIf="formMovimento.get('pagato')?.hasError(error.type) && (formMovimento.get('pagato')?.dirty || formMovimento.get('pagato')?.touched)"></nz-alert>
                    </ng-container>
                </div>
            </div>                         
            
            <div class="col-md-4">
                <nz-form-item class="flex-column mb-2">
                    <nz-form-label class="text-start">Residuo</nz-form-label>
                    <nz-form-control>
                        <input nz-input formControlName="residuo" nzSize="large" readonly>                        
                    </nz-form-control>
                </nz-form-item>
                <div class="error-messages">
                    <ng-container *ngFor="let error of error_messages.residuo">                                                                    
                        <nz-alert nzType="error" [nzMessage]="error.message" nzShowIcon nzCloseable *ngIf="formMovimento.get('residuo')?.hasError(error.type) && (formMovimento.get('residuo')?.dirty || formMovimento.get('residuo')?.touched)"></nz-alert>
                    </ng-container>
                </div>
            </div>   
        </div>
        <div class="row">
            <div class="col">   
                <nz-form-item class="flex-column mb-2">
                    <nz-form-label class="text-start">Data</nz-form-label>
                    <nz-form-control>
                        <nz-date-picker formControlName="data" [nzMode]="'date'" nzSize="large" [nzFormat]="'dd/MM/yyyy'" class="w-100"></nz-date-picker>
                    </nz-form-control>
                </nz-form-item>                                              
                <div class="error-messages">
                    <ng-container *ngFor="let error of error_messages.data">
                        <nz-alert nzType="error" [nzMessage]="error.message" nzShowIcon nzCloseable *ngIf="formMovimento.get('data')?.hasError(error.type) && (formMovimento.get('data')?.dirty || formMovimento.get('data')?.touched)"></nz-alert>                            
                    </ng-container>
                </div>                
            </div>
        </div>        
    </form>
    <nz-divider></nz-divider>
</mat-dialog-content>
<mat-dialog-actions>
    <button type="button" nz-button nzSize="large" class="me-2" (click)="onClose()">Annulla</button>    

    <span nzTooltipTitle="Per modificare un movimento, inserire tutti i campi con l'asterisco" nz-tooltip nzTooltipPlacement="top" *ngIf="!formMovimento.valid">
        <button nz-button nzType="primary" nzSize="large" [disabled]="true">Modifica Movimento</button>    
    </span>  
    <button nz-button nzType="primary" nzSize="large" form="formMovimento" *ngIf="formMovimento.valid">Modifica Movimento</button>        
</mat-dialog-actions>