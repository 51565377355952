import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ApiService } from '../service/api.service';
import { confirmPassword } from '../shared/controlli';
import { AuthUser } from '../shared/interfacce';
import { StorageService } from '../service/storage.service';

@Component({
  selector: 'app-impostazioni',
  templateUrl: './impostazioni.component.html',
  styleUrls: ['./impostazioni.component.css']
})
export class ImpostazioniComponent implements OnInit {

  hide: boolean = false;
  hide_confirm: boolean = false;

  formSetting!: FormGroup;

  user?: AuthUser;

  error_messages = {
    'password': [
      { type: 'required', message: 'Inserire la nuova password per procedere con la modifica della password' },
      { type: 'minlength', message: 'Inserire almeno 3 caratteri' },      
    ],
    'confirm_password': [
      { type: 'required', message: 'Confermare la nuova password per procedere con la modifica della password' },
      { type: 'minlength', message: 'Inserire almeno 3 caratteri' },      
    ],
  }

  constructor(private dialogRef: MatDialogRef<ImpostazioniComponent>, private apiService: ApiService, private storageService: StorageService) { }

  ngOnInit(): void {

    // Recupero l'utente
    this.user = this.storageService.getUser();

    // Creo il form con i controlli
    this.formSetting = new FormGroup({
      password: new FormControl(null, [Validators.required, Validators.minLength(3)]),
      confirm_password: new FormControl(null, [Validators.required, Validators.minLength(3)]),
    },
      {
        validators: this.passwordMatchValidator
      }
    )
  }

  passwordMatchValidator(control: AbstractControl) {
    // Controllo se le due password combaciano
    return control.get('password')?.value === control.get('confirm_password')?.value ? null : { mismatch: true }
  }

  onClose() {
    this.dialogRef.close(true)
  }

  onSubmit() {

    let password = this.formSetting.get('password')?.value;

    // Aggiorno la password dell'utente
    this.apiService.updatePasswordUtente(this.user!.id, password).subscribe({
      next: (data) => this.dialogRef.close(true),
      error: (err) => this.apiService.Error(err.error.message)
    })

  }

}