import { DatePipe } from '@angular/common';
import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { ApiService } from 'src/app/service/api.service';
import { checkImage, emailPresenteModifica } from 'src/app/shared/controlli';
import { Cliente } from 'src/app/shared/interfacce';

@Component({
  selector: 'app-modifica-cliente',
  templateUrl: './modifica-cliente.component.html',
  styleUrls: ['./modifica-cliente.component.css']
})
export class ModificaClienteComponent implements OnInit {

  constructor(private apiService: ApiService, private datePipe: DatePipe, private dialogRef: MatDialogRef<ModificaClienteComponent>, @Inject(MAT_DIALOG_DATA) public data: { cliente: Cliente, skipass_open: string, skicard_vialattea: string, info: { skipass_open: boolean, skicard_vialattea: boolean } }) { }

  formCliente!: FormGroup;

  clienti: Cliente[] = [];

  hide: boolean = true;

  immagineFile!: string;
  immagineList: NzUploadFile[] = [];

  error_messages = {
    'nome': [
      { type: 'required', message: 'Inserire un nome per procedere con la modifica del cliente' }
    ],
    'cognome': [
      { type: 'required', message: 'Inserire un cognome per procedere con la modifica del cliente' }
    ],
    'password': [
      { type: 'pattern', message: 'Inserire almeno 8 caratteri, una lettera maiuscola, un numero e un carattere speciale' }
    ],
    'codice_fiscale': [
      { type: 'required', message: 'Inserire un codice fiscale per procedere con la modifica del cliente' },
      { type: 'pattern', message: 'Inserire un codice fiscale valido' }
    ],
    'data_nascita': [
      { type: 'required', message: 'Inserire una data di nascita per procedere con la modifica del cliente' }
    ],
    'comune_nascita': [
      { type: 'required', message: 'Inserire una data di nascita per procedere con la modifica del cliente' }
    ],
    'provincia_nascita': [
      { type: 'required', message: 'Inserire una data di nascita per procedere con la modifica del cliente' }
    ],
    'nazione_nascita': [
      { type: 'required', message: 'Inserire una data di nascita per procedere con la modifica del cliente' }
    ],
    'email': [
      { type: 'email', message: "Inserire un'email valida per procedere con la modifica del cliente" },
      { type: 'emailPresente', message: "Email già utilizzata da un altro cliente, inserirne un'altra oppure rimuoverla!" }
    ],
    'nazione': [
      { type: 'required', message: 'Inserire una nazione per procedere con la modifica del cliente' }
    ],
    'provincia': [
      { type: 'required', message: 'Inserire una provincia per procedere con la modifica del cliente' }
    ],
    'comune': [
      { type: 'required', message: 'Inserire una comune per procedere con la modifica del cliente' }
    ],
    'indirizzo': [
      { type: 'required', message: 'Inserire un indirizzo per procedere con la modifica del cliente' }
    ],
    'telefono': [
      { type: 'pattern', message: 'Inserire un numero di telefono valido, di 9 o 10 cifre' }
    ],
    'immagine': [
      { type: 'requiredImageType', message: "Inserire un'immagine di tipo JPG, PNG o JPEG" }
    ]
  }

  ngOnInit(): void {

    // Creo il form con i controlli
    this.formCliente = new FormGroup({
      nome: new FormControl(null, Validators.required),
      cognome: new FormControl(null, Validators.required),
      password: new FormControl(null, Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$')),
      codice_fiscale: new FormControl(null, [Validators.pattern('^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]$')]),
      data_nascita: new FormControl(null, Validators.required),
      comune_nascita: new FormControl(null),
      provincia_nascita: new FormControl(null),
      nazione_nascita: new FormControl(null),
      email: new FormControl(null, [Validators.email]),
      nazione: new FormControl(null),
      provincia: new FormControl(null),
      comune: new FormControl(null),
      indirizzo: new FormControl(null),
      telefono: new FormControl(null, Validators.pattern('^(?=[0-9]*$)(?:.{9}|.{10})$')),
      immagine: new FormControl(null, checkImage()),
      skipass_open: new FormControl(null),
      skicard_vialattea: new FormControl(null),
      newsletter: new FormControl(null)
    })

    // Recupero i clienti
    this.apiService.getClienti().subscribe({
      next: (data) => this.clienti = data,
      error: (err) => this.apiService.Error(err.error.message),
      complete: () => {

        // Aggiungo i dati del cliente passato al modal al form
        this.formCliente.setValue({
          nome: this.data.cliente.nome,
          cognome: this.data.cliente.cognome,
          password: '',
          codice_fiscale: this.data.cliente.codice_fiscale,
          data_nascita: this.datePipe.transform(this.data.cliente.data_nascita, 'yyyy-MM-dd'),
          comune_nascita: this.data.cliente.comune_nascita,
          provincia_nascita: this.data.cliente.provincia_nascita,
          nazione_nascita: this.data.cliente.nazione_nascita,
          email: this.data.cliente.email,
          nazione: this.data.cliente.nazione,
          provincia: this.data.cliente.provincia,
          comune: this.data.cliente.comune,
          indirizzo: this.data.cliente.indirizzo,
          telefono: this.data.cliente.telefono,
          skipass_open: this.data.skipass_open ? this.data.skipass_open : '',
          skicard_vialattea: this.data.skicard_vialattea ? this.data.skicard_vialattea : '',
          immagine: '',
          newsletter: this.data.cliente.newsletter ? true : false
        })
      }
    })

  }

  onImmagineUpload = (file: any): boolean => {

    // Controllo se il file inserito è una immagine
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';

    // Se è una immagine procedo
    if (isJpgOrPng) {

      // Aggiungo il file alla lista dei file al form
      this.immagineList = this.immagineList.concat(file);
      this.formCliente.patchValue({ immagine: file.name });

      // Recupero il base64 del file caricato
      const reader = new FileReader();
      reader.onload = () => {
        this.immagineFile = reader.result as string;
      }
      reader.readAsDataURL(file);

      return false;
    } else {
      return false
    }
  };

  onRemoveImmagineUpload = (file: any): boolean => {
    this.formCliente.patchValue({ immagine: '' });
    this.immagineList = [];
    return false;
  }

  onEmail() {
    // All'inserimento di una email controllo se è già stata inserita
    this.formCliente.get('email')?.clearValidators();
    this.formCliente.get('email')?.setValidators([Validators.email, emailPresenteModifica(this.clienti, this.data.cliente.id)]);
    this.formCliente.get('email')?.updateValueAndValidity();
  }

  onClose() {
    this.dialogRef.close(true);
  }

  onSubmit() {

    let cliente = {
      nome: this.formCliente.get('nome')?.value,
      cognome: this.formCliente.get('cognome')?.value,
      codice_fiscale: this.formCliente.get('codice_fiscale')?.value,
      data_nascita: this.datePipe.transform(this.formCliente.get('data_nascita')?.value, 'yyyy-MM-dd'),
      comune_nascita: this.formCliente.get('comune_nascita')?.value,
      provincia_nascita: this.formCliente.get('provincia_nascita')?.value,
      nazione_nascita: this.formCliente.get('nazione_nascita')?.value,
      email: this.formCliente.get('email')?.value,
      nazione: this.formCliente.get('nazione')?.value,
      provincia: this.formCliente.get('provincia')?.value,
      comune: this.formCliente.get('comune')?.value,
      indirizzo: this.formCliente.get('indirizzo')?.value,
      telefono: this.formCliente.get('telefono')?.value,
      immagine: this.formCliente.get('immagine')?.value || "",
      file_immagine: this.immagineFile || "",
      newsletter: this.formCliente.get('newsletter')?.value || false
    }

    let tesseramento = {
      skipass_open: this.formCliente.get('skipass_open')?.value,
      skicard_vialattea: this.formCliente.get('skicard_vialattea')?.value,
    }

    let password = this.formCliente.get('password')?.value || '';

    // Aggiorno i dati del cliente
    this.apiService.updateCliente(this.data.cliente.id, cliente).subscribe({
      error: (err) => this.apiService.Error(err.error.message),
      complete: () => {
        // Se ci sono i codici dei tesseramenti procdeo
        if (tesseramento.skipass_open || tesseramento.skicard_vialattea) {

          // Aggiorno i codici
          this.apiService.updateCodiciCliente(this.data.cliente.id, tesseramento).subscribe({
            error: (err) => this.apiService.Error(err.error.message),
            complete: () => {

              // Se è stata inserita la password procedo
              if (password.length > 0) {

                // Aggiorno la password
                this.apiService.updatePasswordCliente(this.data.cliente.id, password).subscribe({
                  error: (err) => this.apiService.Error(err.error.message),
                  complete: () => this.dialogRef.close()
                })
              }
            }
          })
        } else {
          // Se è stata inserita la password procedo
          if (password.length > 0) {

            // Aggiorno la password
            this.apiService.updatePasswordCliente(this.data.cliente.id, password).subscribe({
              error: (err) => this.apiService.Error(err.error.message),
              complete: () => this.dialogRef.close()
            })
          } else
            this.dialogRef.close()
        }
      }
    })
  }
}
