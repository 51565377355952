import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/service/api.service';
import { Movimenti } from 'src/app/shared/interfacce';
import { ModificaMovimentoComponent } from '../modifica-movimento/modifica-movimento.component';
import { EliminaMovimentoComponent } from '../elimina-movimento/elimina-movimento.component';

@Component({
  selector: 'app-dettagli-movimento-metodi',
  templateUrl: './dettagli-movimento-metodi.component.html',
  styleUrls: ['./dettagli-movimento-metodi.component.css']
})
export class DettagliMovimentoMetodiComponent implements OnInit {

  constructor(private apiService: ApiService, private route: ActivatedRoute, private dialog: MatDialog, private router: Router) { }

  movimento?: Movimenti;

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      const id = params['id'];
      this.getData(id);
    });
  }

  getData(id: number) {

    this.apiService.getMovimento(id).subscribe({
      next: (data) => this.movimento = data,
      error: (err) => this.router.navigate(['/movimenti/altri-metodi'])
    })

  }

  onIndietro() {
    this.router.navigate(['/movimenti/altri-metodi'])
  }

  onModifica() {
    this.dialog.closeAll();
    var dialogRef = this.dialog.open(ModificaMovimentoComponent, { data: { id: this.movimento!.id }, autoFocus: false, width: '1000px' });
    dialogRef?.afterClosed().subscribe(() => {
      this.getData(this.movimento!.id);
    })
  }

  onElimina() {
    this.dialog.closeAll();
    this.dialog.open(EliminaMovimentoComponent, { data: { id: this.movimento!.id }, autoFocus: false, width: '750px' })
  }

}