import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/service/api.service';
import { CodiceSconto, DettaglioOrdine } from 'src/app/shared/interfacce';
import { ModificaStoricoOrdineComponent } from '../modifica-storico-ordine/modifica-storico-ordine.component';
import { EmailDettaglioOrdineComponent } from '../email-dettaglio-ordine/email-dettaglio-ordine.component';
import { EliminaProdottiStoricoOrdineComponent } from '../elimina-prodotti-storico-ordine/elimina-prodotti-storico-ordine.component';
import { EliminaOrdineComponent } from '../elimina-ordine/elimina-ordine.component';
import { EmailMulticlienteDettaglioOrdineComponent } from '../email-multicliente-dettaglio-ordine/email-multicliente-dettaglio-ordine.component';
import { AggiungiMovimentoComponent } from 'src/app/prima-nota/aggiungi-movimento/aggiungi-movimento.component';
import { LoadingComponent } from 'src/app/loading/loading.component';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-dettagli-storico-ordine',
  templateUrl: './dettagli-storico-ordine.component.html',
  styleUrls: ['./dettagli-storico-ordine.component.css']
})
export class DettagliStoricoOrdineComponent implements OnInit {

  constructor(private dialog: MatDialog, private apiService: ApiService, private route: ActivatedRoute, private router: Router) { }

  add: boolean = false;
  carica: boolean = true;

  ordine?: DettaglioOrdine;
  codici_sconto: CodiceSconto[] = [];

  ngOnInit(): void {

    // Recupero l'id dall'url
    this.route.params.subscribe(params => {
      const id = params['id'];

      // Recupero il parametro 'add' se presente nell'url
      this.add = this.route.snapshot.paramMap.get('add') ? true : false;
      this.getData(id)

    });
  }

  getData(id: number) {

    // Recupero il dettaglio dell'ordine
    this.apiService.getDettaglioOrdine(id).subscribe({
      next: (data) => this.ordine = data,
      error: (err) => this.router.navigate(['/storico']),
      complete: () => {

        // Recupero i codici sconto associati all'ordine
        this.apiService.getCodiciScontoOrdine(id).subscribe({
          next: (data) => this.codici_sconto = [...data],
          error: (err) => this.apiService.Error(err.error.message)
        })
      }
    })

  }

  onIndietro() {
    // Se presente 'add' nell'url vuol dire che è stato appena creato e all'indietro devo tornare alla creazione degli ordini
    if (this.add) {
      this.router.navigate(['/tesseramenti'])
    } else {
      this.router.navigate(['/storico'])
    }
  }

  onModifica() {
    this.dialog.closeAll();
    let dialogRef = this.dialog.open(ModificaStoricoOrdineComponent, { data: { id: this.ordine!.id }, autoFocus: false, width: '1000px' });
    dialogRef?.afterClosed().subscribe(() => {
      this.getData(this.ordine!.id);
    });
  }

  onMovimento() {
    this.dialog.closeAll();
    let dialogRef = this.dialog.open(AggiungiMovimentoComponent, { data: { tipo: 'ordine' }, autoFocus: false, width: '1000px' });
    dialogRef?.afterClosed().subscribe(() => {
      this.getData(this.ordine!.id);
    });
  }

  onMail() {
    this.dialog.closeAll();
    if (this.ordine!.clienti.length > 1) {
      this.dialog.open(EmailMulticlienteDettaglioOrdineComponent, { data: { clienti: this.ordine?.clienti, id: this.ordine!.id }, autoFocus: false, width: '1000px' });
    } else {
      this.dialog.open(EmailDettaglioOrdineComponent, { data: { clienti: this.ordine?.clienti, id: this.ordine!.id }, autoFocus: false, width: '1000px' });
    }
  }

  onElimina() {
    this.dialog.closeAll();
    this.dialog.open(EliminaOrdineComponent, { data: { id_ordine: this.ordine!.id }, autoFocus: false, width: '750px' });
  }

  onDelete(id_cliente: number, cliente: string, id_prodotto: number, prodotto: string) {
    this.dialog.closeAll();
    let dialogRef = this.dialog.open(EliminaProdottiStoricoOrdineComponent, { data: { id_ordine: this.ordine!.id, id_cliente: id_cliente, cliente: cliente, id_prodotto: id_prodotto, prodotto: prodotto }, autoFocus: false, width: '750px' });
    dialogRef?.afterClosed().subscribe(() => {
      this.getData(this.ordine!.id);
    });
  }

  onStampa() {
    this.dialog.closeAll();
    this.dialog.open(LoadingComponent, { autoFocus: false, width: '200px' });

    // Recupero la ricevuta 
    this.apiService.getRicevuta(this.ordine!.id).subscribe({
      next: (data) => {

        // Trasformo il blob in pdf
        let downloadURL = window.URL.createObjectURL(data);
        saveAs(downloadURL, 'ricevuta_' + this.ordine!.id + '.pdf');
      },
      error: (err) => this.apiService.Error(err.error.message),
      complete: () => this.dialog.closeAll()
    })

  }
}
