<mat-dialog-content>
    <div class="title-section mb-3" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle style="cursor: move;">
        <h5>Crea ordine</h5>
    </div>
    <nz-divider></nz-divider>
    <form [formGroup]="formTessera" (ngSubmit)="onSubmit()" id="formTessera">        
        <div class="row">
            <div id="indirizzoObbl" class="form-text text-start">*campo obbligatorio</div>
        </div>       
        <div class="row mt-3">                                    
            <div class="col-md-8">    
                <nz-form-item class="flex-column mb-0">
                    <nz-form-label nzRequired class="text-start" [nzTooltipTitle]="'Inserire il cliente o i clienti per i quali si vogliono effettuare tesseramenti o acquistare prodotti dello SciClub'">Clienti</nz-form-label>
                    <nz-form-control>
                        <nz-select nzShowSearch nzAllowClear formControlName="cliente" nzSize="large" nzMode="multiple" class="w-100" (ngModelChange)="onSelezionaCliente($event)" (nzOnSearch)="onSeleziona($event)">
                            <nz-option *ngFor="let cliente of filteredClienti" [nzValue]="cliente" [nzLabel]="cliente.cognome + ' ' + cliente.nome + ' | ' + (cliente.data_nascita | date: 'dd MMM yyyy')"></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
            </div>                                                        
            <div class="col-md-4 d-flex align-items-end mt-md-0 mt-3">
                <button class="w-100" nz-button nzSize="large" nzType="primary" type="button" (click)="onCliente()">Nuovo cliente</button>
            </div>
        </div>    

        <nz-divider></nz-divider>

        <div class="row mt-3" *ngIf="carrello.length <= 0">
            <div class="col">
                <span [nzTooltipTitle]="clientiSelezionati.length <= 0 ? 'Prima di aggiungere i prodotti devi prima indicare almeno UN CLIENTE!' : ''" nz-tooltip nzTooltipPlacement="top" *ngIf="clientiSelezionati.length <= 0">
                    <button type="button" class="w-100" nz-button nzSize="large" nzType="primary" [disabled]="true">Aggiungi prodotti</button>                    
                </span>                      
                <button type="button" class="w-100" nz-button nzSize="large" nzType="primary" (click)="onProdotti()" *ngIf="clientiSelezionati.length > 0">Aggiungi prodotti</button>
            </div>
        </div>
        
        <div class="row mt-3" *ngIf="carrello.length > 0 || modifica">
            <div class="col">                                     
                <button type="button" class="w-100" nz-button nzSize="large" nzType="primary" (click)="onModificaProdotti()">Modifica prodotti</button>
            </div>
        </div>

        <nz-divider *ngIf="carrello.length > 0"></nz-divider>

        <div class="row mt-3" *ngIf="carrello.length > 0">
            <div class="col">                
                <ng-template #datiHeader>
                    <div class="header">
                        <h5 class="d-inline">Riepilogo - Totale carrello: {{ totale | currency: 'EUR' }}</h5>                                                
                    </div>                    
                </ng-template>

                <nz-list nzBordered [nzHeader]="datiHeader" class="lista">                                                                               
                    <nz-list-item class="p-0" *ngFor="let cliente of carrello">
                        <nz-collapse nzAccordion [nzExpandIconPosition]="'right'" class="w-100" style="background-color: transparent; border: 0px solid; padding-left: 8px;">
                            <ng-template #headerPanel>
                                <p style="margin-bottom: 0px;">Cliente: <strong>{{cliente.cognome}} {{cliente.nome}}</strong> | Totale cliente: <strong>{{ cliente.totale | currency: 'EUR' }}</strong></p>
                            </ng-template>
                            <nz-collapse-panel [nzHeader]="headerPanel" style="border-bottom: 0px;" nzActive="true">
                                <nz-list-item *ngFor="let prodotto of cliente.prodotti">
                                    <span nz-typography nz-tooltip [nzTooltipTitle]="prodotto.supplemento ? 'Il prodotto ha avuto un supplemento perché è stato acquistato per il giorno seguente in queste fascie di orario: Dalle 21:01 alle 23:00 - 0,50€. Dalle 23:01 alle 8:00 - 2,00€': ''">Prodotto: {{prodotto.nome}} - {{prodotto.prezzo | currency: 'EUR'}}{{prodotto.supplemento ? " + " + prodotto.supplemento : ""}}</span>
                                    <span nz-icon nzType="delete" nzTheme="outline" class="cursor-pointer" nz-popconfirm nzPopconfirmTitle="Sei sicuro di voler rimuovere il prodotto dall'ordine?." (nzOnConfirm)="onRimozioneProdotto(prodotto, cliente.id_cliente)" [nzOkText]="'Rimuovi'"></span>
                                </nz-list-item>                                                                
                            </nz-collapse-panel>
                          </nz-collapse>
                    </nz-list-item>            
                </nz-list> 
            </div>
        </div>

        <nz-divider></nz-divider>
     
        <div class="row">
            <div class="col">
                <nz-form-item class="flex-column mb-2">
                    <nz-form-label class="text-start" [nzTooltipTitle]="carrello.length <= 0 ? 'Selezionare dei prodotti per i clienti selezionati per modificare la stagione!' : ''">Stagione</nz-form-label>
                    <nz-form-control>
                        <nz-select nzShowSearch nzAllowClear formControlName="stagione" nzSize="large" class="w-100" [nzDisabled]="carrello.length <= 0" (ngModelChange)="onStagione($event)">
                            <nz-option nzValue="2015-2016" nzLabel="2015/2016"></nz-option>
                            <nz-option nzValue="2016-2017" nzLabel="2016/2017"></nz-option>
                            <nz-option nzValue="2017-2018" nzLabel="2017/2018"></nz-option>
                            <nz-option nzValue="2018-2019" nzLabel="2018/2019"></nz-option>
                            <nz-option nzValue="2019-2020" nzLabel="2019/2020"></nz-option>
                            <nz-option nzValue="2020-2021" nzLabel="2020/2021"></nz-option>
                            <nz-option nzValue="2021-2022" nzLabel="2021/2022"></nz-option>
                            <nz-option nzValue="2022-2023" nzLabel="2022/2023"></nz-option>
                            <nz-option nzValue="2023-2024" nzLabel="2023/2024"></nz-option>
                            <nz-option nzValue="2024-2025" nzLabel="2024/2025"></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <nz-form-item class="flex-column mb-2">
                    <nz-form-label class="text-start">Data ordine</nz-form-label>
                    <nz-form-control>
                        <nz-date-picker formControlName="data" [nzMode]="'date'" nzSize="large" [nzFormat]="'dd/MM/yyyy'" class="w-100"></nz-date-picker>                    
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>

        <div class="row">
            <div class="col">      
                <nz-form-item class="flex-column mb-2">
                    <nz-form-label class="text-start">Note</nz-form-label>
                    <nz-form-control>
                        <textarea nz-input [nzAutosize]="{ minRows: 3, maxRows: 6 }" formControlName="note" nzSize="large"></textarea>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>                    
    </form>
    <nz-divider></nz-divider>
</mat-dialog-content>
<mat-dialog-actions>
    <button nz-button nzSize="large" type="button" class="me-2" (click)="onClose()">Annulla</button>    

    <span nzTooltipTitle="Per creare un ordine, inserire tutti i campi con l'asterisco" nz-tooltip nzTooltipPlacement="top" *ngIf="!formTessera.valid">
        <button nz-button nzSize="large" nzType="primary" [disabled]="true">Crea Ordine</button>
    </span>  
    <button nz-button nzSize="large" nzType="primary" form="formTessera" *ngIf="formTessera.valid">Crea ordine</button>
</mat-dialog-actions>