import { Injectable } from '@angular/core';
import { differenceInCalendarDays } from 'date-fns';
import { Prodotto } from '../shared/interfacce';

@Injectable({
  providedIn: 'root'
})
export class DateService {

  constructor() { }

  giorni: string[] = ['Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato', 'Domenica'];

  checkValidita(fine_validita: string) {

    let todayDate = new Date();
    let fineDate = new Date(fine_validita);
    fineDate.setDate(fineDate.getDate());
    let differenceInTime = todayDate.getTime() - fineDate.getTime();

    // To calculate the no. of days between two dates
    let differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24));

    return differenceInDays;
  }

  checkDate(inizio_validita: Date, fine_validita: Date) {
    let todayDate = new Date();
    let fineDate = new Date(fine_validita);
    let inizioDate = new Date(inizio_validita);

    inizioDate.setDate(inizioDate.getDate());
    let diffInizio = todayDate.getTime() - inizioDate.getTime();
    let diffGiorniInizio = Math.floor(diffInizio / (1000 * 3600 * 24));

    fineDate.setDate(fineDate.getDate());
    let diffFine = todayDate.getTime() - fineDate.getTime();
    let diffGiorniFine = Math.floor(diffFine / (1000 * 3600 * 24));

    return diffGiorniInizio < 0 ? 2 : diffGiorniInizio >= 0 && diffGiorniFine <= 0 ? 1 : 0;
  }

  checkSupplemento(giorno: string, giornaliero: Prodotto) {

    let date_21_1 = new Date();
    date_21_1.setHours(21, 1, 0);
    let date_23 = new Date();
    date_23.setHours(23, 0, 0);
    let date_23_1 = new Date();
    date_23_1.setHours(23, 1, 0);
    let date_08 = new Date();
    date_08.setHours(8, 0, 0);

    let oggi = new Date()

    // Controllo se la data di acquisto e la data di utilizzo sono le stesse
    if (differenceInCalendarDays(new Date(giorno), oggi) == 1 && giornaliero.id_categoria == 13) {

      date_08.setDate(date_08.getDate() + 1);

      // Dalle 21 alle 23 supplemento di 0.50, dalle 23 fino alle 8 supplemento di 2.00
      if (oggi >= date_21_1 && oggi <= date_23)
        giornaliero.supplemento = 0.50;
      else if (oggi >= date_23_1 && oggi <= date_08)
        giornaliero.supplemento = 2.00

    } else if (differenceInCalendarDays(new Date(giorno), oggi) == 0 && giornaliero.id_categoria == 13) {
      date_23_1.setDate(date_23_1.getDate() - 1);

      if (oggi >= date_23_1 && oggi <= date_08)
        giornaliero.supplemento = 2.00;

    } else {
      giornaliero.supplemento = 0.00
    }

    return giornaliero;

  }  

  disableDateGiornalieri(giorni: any, inizio_validita: any, fine_validita: any) {
    return (current: Date): boolean => {

      // Formo le date di inizio e fine
      let date_inizio = new Date(inizio_validita)
      date_inizio.setHours(0, 0, 0)

      let date_fine = new Date(fine_validita)
      date_fine.setHours(23, 59, 59)

      // Mostro le date disponibili in base alle date di inizio e fine validità
      if (date_inizio <= current && date_fine >= current) {

        // Recupero al data con ora fino alle 8 per il controllo
        let date_08 = new Date();
        date_08.setHours(8, 0, 0);
        let oggi = new Date()

        // Controllo se manca meno di un giorno
        if (differenceInCalendarDays(current, oggi) <= 0) {

          // Procedo se sono prima delle 8
          if (date_08.getTime() >= oggi.getTime()) {

            // Recupero i giorni del prodotto e mostro solo quelli
            const day = current.getDay();
            const numeriConvertiti = this.convertDays(giorni);
            return !numeriConvertiti.includes(day);

          } else {
            return true;
          }

        } else {
          // Recupero i giorni del prodotto e mostro solo quelli
          const day = current.getDay();
          const numeriConvertiti = this.convertDays(giorni);
          return !numeriConvertiti.includes(day);
        }
      } else
        return true;
    };
  }

  disableDateGiornalieriSpecifici(date_specifiche_dal: any, date_specifiche_al: any) {
    return (current: Date): boolean => {

      // Recupero la data di inizio e fine
      let date_inizio = new Date(date_specifiche_dal)
      date_inizio.setHours(0, 0, 0)

      let date_fine = new Date(date_specifiche_al)
      date_fine.setHours(23, 59, 59)

      // Mostro le date disponibili in base alle date di inizio e fine
      if (date_inizio <= current && date_fine >= current) {

        // Setto la data per il controllo fino alle 8 di mattina
        let date_08 = new Date();
        date_08.setHours(8, 0, 0);
        let oggi = new Date()

        // Controllo se manca menu di un giorno alla disponibilità del giornaliero
        if (differenceInCalendarDays(current, oggi) <= 0) {
          // Controllo che siano prima dell 8
          if (date_08.getTime() >= oggi.getTime()) {
            return false
          } else {
            return true;
          }
        } else
          return false;
      } else
        return true;
    };
  }

  disableDate(inizio_validita: any, fine_validita: any) {
    return (current: Date): boolean => {

      let date_inizio = new Date(inizio_validita)
      date_inizio.setHours(0, 0, 0)

      let date_fine = new Date(fine_validita)
      date_fine.setHours(23, 59, 59)

      // Mostro le date disponibili in base alle date di inizio e fine validità
      if (inizio_validita && fine_validita) {
        if (date_inizio <= current && date_fine >= current) {
          return false;
        } else
          return true;
      } else
        return false
    };
  }

  convertDays(nomiGiorni: string[]): number[] {
    const numeriGiorni: number[] = [];

    for (const nomeGiorno of nomiGiorni) {
      let indice = this.giorni.indexOf(nomeGiorno) + 1;
      if (indice !== -1) {
        if (indice == 7)
          indice = 0;
        numeriGiorni.push(indice);
      }
    }

    return numeriGiorni;
  }
}

