<div class="p-3">
    <ng-template #logTitle>
        <div class="row p-md-3 p-1">
            <div class="col-lg-1 mt-2">
                <button nz-button nzSize="large" class="w-100" type="button" (click)="onIndietro()"><i
                        style="font-size: 20px;" class="bi bi-arrow-left"></i></button>
            </div>
            <div class="col-lg-11 mt-2">
                <div class="row">
                    <div class="col-md-6">
                        <input nz-input placeholder="Cerca Utente" nzSize="large" [(ngModel)]="userFilter" />
                    </div>
                    <div class="col-md-6 mt-md-0 mt-3">
                        <input nz-input placeholder="Cerca Descrizione" nzSize="large"
                            [(ngModel)]="descrizioneFilter" />
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
    <nz-card [nzTitle]="logTitle">
        <div class="row p-md-3 p-1">
            <nz-table #logTable nzShowPagination *ngIf="!carica"
                [nzTotal]="(logs | searchLogUtenti: userFilter | searchLogDescrizione: descrizioneFilter).length"
                nzShowSizeChanger [nzPageSize]="10"
                [nzPageSizeOptions]="[5, 10, 25, 50, 100, 150, 200, 250, 300, 350, 400, 500, 750, 1000]"
                [nzData]="logs | searchLogUtenti: userFilter | searchLogDescrizione: descrizioneFilter" [nzBordered]="true" nzNoResult="Nessun log trovato">
                <thead>
                    <tr>
                        <th [nzSortFn]="onOrdinamentoUtente">Utente</th>
                        <th [nzSortFn]="onOrdinamentoDescrizione" class="text-start">Descrizione</th>
                        <th [nzSortFn]="onOrdinamentoData" class="text-end">Data</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let log of logTable.data | searchLogUtenti: userFilter | searchLogDescrizione: descrizioneFilter"
                        (click)="onLog(log)">
                        <td>{{log.username}} </td>
                        <td class="text-start">{{log.descrizione}}</td>
                        <td class="text-end">{{log.data | date: 'dd/MM/yyyy HH:mm:ss'}}</td>
                    </tr>
                </tbody>
            </nz-table>

            <app-caricamento *ngIf="carica" class="d-flex align-items-center justify-content-center"></app-caricamento>
        </div>
    </nz-card>
</div>