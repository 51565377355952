<div class="p-3">
    <ng-template #utentiTitle>
        <div class="row p-md-3 p-1">        
            <div class="col-lg-8 my-auto">
                <h3 style="white-space: break-spaces;">{{utente?.username}}</h3>
                <h5 class="col text-muted">Utente #{{utente?.id}}</h5>
            </div>     
            <div class="col-lg-4 my-auto">                          
                <nz-button-group class="w-100">
                    <button nz-button nzSize="large" class="w-100 crop" type="button" (click)="onModifica()">Modifica l'utente</button>
                    <button nz-button nzSize="large" class="w-25" nz-dropdown [nzDropdownMenu]="menu1" nzPlacement="bottomRight">
                        <span nz-icon nzType="ellipsis"></span>
                    </button>
                </nz-button-group>
                <nz-dropdown-menu #menu1="nzDropdownMenu">
                    <ul nz-menu>                        
                        <li nz-menu-item class="p-3" [nzDisabled]="utente?.id == 1" (click)="onElimina()">Elimina l'utente <i class="ms-2 bi bi-trash3"></i></li>
                    </ul>
                </nz-dropdown-menu>
            </div>          
        </div>
    </ng-template>
    <nz-card [nzTitle]="utentiTitle" class="mt-5 mb-5">
        <div class="row p-md-3 p-1">
            <div class="col">
                <nz-breadcrumb>
                    <nz-breadcrumb-item>
                        <a routerLink="/home">
                            <span nz-icon nzType="home"></span>
                        </a>                  
                    </nz-breadcrumb-item>
                    <nz-breadcrumb-item>
                      <a routerLink="/utenti">                    
                        <span>Utenti</span>
                      </a>
                    </nz-breadcrumb-item>
                    <nz-breadcrumb-item>Dettaglio utente</nz-breadcrumb-item>
                </nz-breadcrumb>
            </div>
        </div>
        <div class="row p-md-3 p-1">
            <div class="col">            
    
                    <ng-template #datiHeader>
                        <div class="header">
                            <h5 class="d-inline text-white">Dati</h5>                        
                        </div>                    
                    </ng-template>
    
                    <nz-list nzBordered [nzHeader]="datiHeader" class="lista">
                        <nz-list-item>
                            <span nz-typography><span class="text-muted">N°</span></span> {{utente?.id}}
                        </nz-list-item>
                        <nz-list-item>
                            <span nz-typography><span class="text-muted">Username:</span></span> {{utente?.username}}
                        </nz-list-item>
                        <nz-list-item>
                            <span nz-typography><span class="text-muted">Tipo:</span></span> {{utente?.tipo}}
                        </nz-list-item>                       
                        <nz-list-item class="p-0">
                            <nz-collapse nzAccordion [nzExpandIconPosition]="'right'" class="w-100" style="background-color: transparent; border: 0px solid; padding-left: 8px;">
                                <nz-collapse-panel [nzHeader]="'Altro'" style="border-bottom: 0px;" class="cursor-pointer">
                                    <nz-list-item>
                                        <span nz-typography><span class="text-muted">Data creazione:</span></span> {{utente?.data_creazione | date: 'dd/MM/yyyy HH:mm'}}
                                    </nz-list-item>
                                    <nz-list-item>
                                        <span nz-typography><span class="text-muted">Data aggiornamento:</span></span> {{utente?.data_aggiornamento | date: 'dd/MM/yyyy HH:mm'}}
                                    </nz-list-item>
                                </nz-collapse-panel>
                              </nz-collapse>
                        </nz-list-item>                    
                    </nz-list>                
                </div>            
            </div>            
    </nz-card>
</div>