import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ApiService } from '../service/api.service';
import { Prodotto } from '../shared/interfacce';
import { AggiungiProdottoComponent } from './aggiungi-prodotto/aggiungi-prodotto.component';
import { ModificaProdottiComponent } from './modifica-prodotti/modifica-prodotti.component';
import { DateService } from '../service/date.service';
import { StorageService } from '../service/storage.service';

@Component({
  selector: 'app-prodotti',
  templateUrl: './prodotti.component.html',
  styleUrls: ['./prodotti.component.css']
})
export class ProdottiComponent implements OnInit {

  constructor(private apiService: ApiService, public dateService: DateService, private storageService: StorageService, private dialog: MatDialog, private location: Location) { }

  prodotti: Prodotto[] = [];

  nomeFilter: any = '';
  descrizioneFilter: any = '';

  carica: boolean = true;
  seleziona: boolean = false;
  checked: boolean = false;
  indeterminate: boolean = false;

  listOfCurrentPageData: readonly Prodotto[] = [];
  setOfCheckedId = new Set<number>();

  ngOnInit(): void {

    // Recupero i prodotti
    this.apiService.setProdotti();
    this.apiService.prodottiChanged.subscribe({
      next: (data) => { this.prodotti = [...data]; this.carica = false },
      error: (err) => this.apiService.Error(err.error.message)
    })

  }

  onOrdinamentoNome(a: Prodotto, b: Prodotto) {
    return a.nome.localeCompare(b.nome)
  }

  onOrdinamentoCategoria(a: Prodotto, b: Prodotto) {
    return a.categoria.localeCompare(b.categoria)
  }

  onOrdinamentoDescrizione(a: Prodotto, b: Prodotto) {
    return (a.descrizione || '').localeCompare((b.descrizione || ''))
  }

  onOrdinamentoPrezzo(a: Prodotto, b: Prodotto) {
    return a.prezzo - b.prezzo;
  }

  updateCheckedSet(id: number, checked: boolean): void {
    if (checked) {
      this.setOfCheckedId.add(id);
    } else {
      this.setOfCheckedId.delete(id);
    }
  }

  onItemChecked(id: number, checked: boolean): void {
    this.updateCheckedSet(id, checked);
    this.refreshCheckedStatus();
  }

  onAllChecked(value: boolean): void {
    this.listOfCurrentPageData.forEach(item => this.updateCheckedSet(item.id, value));
    this.refreshCheckedStatus();
  }

  refreshCheckedStatus(): void {
    this.checked = this.listOfCurrentPageData.every(item => this.setOfCheckedId.has(item.id));
    this.indeterminate = this.listOfCurrentPageData.some(item => this.setOfCheckedId.has(item.id)) && !this.checked;
  }

  onCurrentPageDataChange($event: readonly Prodotto[]): void {
    this.listOfCurrentPageData = $event;
    this.refreshCheckedStatus();
  }

  onSeleziona() {
    this.seleziona = true;
  }

  onDeseleziona() {
    this.seleziona = false;
    this.setOfCheckedId.clear();
  }

  onModifica() {
    this.dialog.closeAll()
    let dialogRef = this.dialog.open(ModificaProdottiComponent, { data: { prodotti: this.setOfCheckedId }, autoFocus: false, width: '1000px' });
    dialogRef?.afterClosed().subscribe((esito: boolean) => {
      if (esito) {
        this.setOfCheckedId.clear();
        this.seleziona = false;
      }
    })
  }

  onAdd() {
    this.dialog.closeAll()
    let dialogRef = this.dialog.open(AggiungiProdottoComponent, { autoFocus: false, width: '1000px' });
    dialogRef?.afterClosed().subscribe((esito: boolean) => {
      if (esito) this.storageService.deleteFasceProdotto();
    })
  }

  onIndietro() {
    this.location.back();
  }

}