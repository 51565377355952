import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Prodotto } from 'src/app/shared/interfacce';

@Component({
  selector: 'app-modifica-prodotto-tesseramento',
  templateUrl: './modifica-prodotto-tesseramento.component.html',
  styleUrls: ['./modifica-prodotto-tesseramento.component.css']
})
export class ModificaProdottoTesseramentoComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<ModificaProdottoTesseramentoComponent>, @Inject(MAT_DIALOG_DATA) public data: { prodotto: Prodotto }) { }

  formProdotto!: FormGroup;

  ngOnInit(): void {

    // Creo il form con i controlli
    this.formProdotto = new FormGroup({
      prezzo: new FormControl(null, Validators.required)
    })

    // Setto il prezzo del prodotto passato nel form
    this.formProdotto.setValue({ prezzo: this.data.prodotto.prezzo });

  }

  onSubmit() {

    // Aggiorno il prezo del prodotto passato con quello inserito nel form
    let prezzo = this.formProdotto.get('prezzo')?.value;
    this.data.prodotto.prezzo = prezzo;

    this.dialogRef.close(this.data.prodotto);

  }

  onClose() {
    this.dialogRef.close(false);
  }

}