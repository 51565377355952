import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ImpostazioniComponent } from '../impostazioni/impostazioni.component';
import { AuthUser } from '../shared/interfacce';
import { DOCUMENT } from '@angular/common';
import { environment } from 'src/environments/environment';
import { StorageService } from '../service/storage.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  user!: AuthUser

  collapse: boolean = false;
  theme: boolean = false;

  shop_url = environment.shopURL;

  constructor(private observer: BreakpointObserver, private dialog: MatDialog, private storageService: StorageService, private router: Router, @Inject(DOCUMENT) private document: Document) { }

  ngOnInit(): void {
    this.user = this.storageService.getUser();
    this.theme = this.user.tema;

    if (this.theme) {
      this.theme = true;
      const head = this.document.getElementsByTagName('head')[0];
      const style = this.document.createElement('link');
      style.rel = 'stylesheet';
      style.href = `assets/black-theme.css`; //<--add assets

      head.appendChild(style);

      this.storageService.setUser({ user: this.user.user, tipo: this.user.tipo, id: this.user.id, tema: true })

    } else {
      this.theme = false;
      const head = this.document.getElementsByTagName('head')[0];

      const style = this.document.createElement('link');
      style.rel = 'stylesheet';
      style.href = `assets/white-theme.css`; //<--add assets

      head.appendChild(style);

      this.storageService.setUser({ user: this.user.user, tipo: this.user.tipo, id: this.user.id, tema: false })

    }
  }

  onLogout() {
    this.storageService.clearStorage();
    this.router.navigate(['/login']);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.observer.observe(['(max-width: 1024px)']).subscribe((res) => {
        if (res.matches) {
          this.collapse = true;
        } else {
          this.collapse = false
        }
      });
    }, 0);
  }

  onTheme() {

    this.theme = !this.theme;

    if (this.theme) {
      this.theme = true;
      const head = this.document.getElementsByTagName('head')[0];
      const style = this.document.createElement('link');
      style.rel = 'stylesheet';
      style.href = `assets/black-theme.css`; //<--add assets

      head.appendChild(style);

      this.storageService.setUser({ user: this.user.user, tipo: this.user.tipo, id: this.user.id, tema: true })

    } else {
      this.theme = false;
      const head = this.document.getElementsByTagName('head')[0];

      const style = this.document.createElement('link');
      style.rel = 'stylesheet';
      style.href = `assets/white-theme.css`; //<--add assets

      head.appendChild(style);

      this.storageService.setUser({ user: this.user.user, tipo: this.user.tipo, id: this.user.id, tema: false })

    }
  }


  onImpostazioni() {
    this.dialog.closeAll();
    this.dialog.open(ImpostazioniComponent, { autoFocus: false, width: '1000px' });
  }

}