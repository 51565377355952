<mat-dialog-content>
    <div class="title-section mb-3" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle style="cursor: move;">
        <h5 class="title">Esportazione ordini</h5>
    </div>
    <nz-divider></nz-divider>
    <form [formGroup]="formEsportazione" (ngSubmit)="onSubmit()" id="formEsportazione">        
        <div class="row mt-3">
            <div class="col">
                <nz-form-item class="flex-column mb-2">
                    <nz-form-label nzRequired class="text-start">Range di tempo</nz-form-label>
                    <nz-form-control>
                        <nz-range-picker formControlName="data" class="w-100" nzSize="large" [nzFormat]="'dd/MM/yyyy'"></nz-range-picker>
                    </nz-form-control>
                </nz-form-item>                
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">         
                <nz-form-item class="flex-column mb-2">
                    <nz-form-label class="text-start" [nzTooltipTitle]="'Se non inserito verranno mostrati gli ordini di tutti i prodotti!'">Prodotto</nz-form-label>
                    <nz-form-control>
                        <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Prodotto" formControlName="prodotto" nzSize="large" class="w-100">
                            <nz-option *ngFor="let prodotto of prodotti" [nzValue]="prodotto.id" [nzLabel]="prodotto.nome"></nz-option>                   
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>                        
            </div>
            <div class="col-md-6">
                <nz-form-item class="flex-column mb-2">
                    <nz-form-label class="text-start" [nzTooltipTitle]="'Se non inserito verranno mostrati gli ordini di tutti i clienti!'">Cliente</nz-form-label>
                    <nz-form-control>
                        <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Cliente" formControlName="cliente" nzSize="large" class="w-100">
                            <nz-option *ngFor="let cliente of clienti" [nzValue]="cliente.id" [nzLabel]="cliente.cognome + ' ' + cliente.nome + ' | ' + (cliente.data_nascita | date: 'dd/MM/yyyy')"></nz-option>                   
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>   
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <nz-form-item class="flex-column mb-2">
                    <nz-form-label class="text-start" [nzTooltipTitle]="'Se non inserito verranno mostrati gli ordini con qualsiasi metodo di pagamento!'">Metodo di Pagamento</nz-form-label>
                    <nz-form-control>
                        <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Metodo di pagamento" formControlName="metodo" nzSize="large" class="w-100">
                            <nz-option nzValue="Contanti" nzLabel="Contanti"></nz-option>
                            <nz-option nzValue="Satispay" nzLabel="SatisPay"></nz-option>
                            <nz-option nzValue="Bancomat" nzLabel="Bancomat"></nz-option>
                            <nz-option nzValue="Carta di credito" nzLabel="Carta di credito"></nz-option>
                            <nz-option nzValue="Bonifico" nzLabel="Bonifico"></nz-option>
                            <nz-option nzValue="Coupon" nzLabel="Coupon"></nz-option>
                            <nz-option nzValue="MyBank" nzLabel="Bonifico istantaneo (MyBank)"></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>                 
            </div>      
            <div class="col-md-6">
                <nz-form-item class="flex-column mb-2">
                    <nz-form-label class="text-start" [nzTooltipTitle]="'Se non inserito verranno mostrati gli ordini con qualsiasi stato!'">Stato</nz-form-label>
                    <nz-form-control>
                        <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Stato" formControlName="stato" nzSize="large" class="w-100">
                            <nz-option nzValue="pagato" nzLabel="Pagato"></nz-option>
                            <nz-option nzValue="non pagato" nzLabel="Non Pagato"></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item> 
            </div>
        </div>
    </form>
    <nz-divider></nz-divider>
</mat-dialog-content>
<mat-dialog-actions>
    <button type="button" nz-button nzSize="large" class="me-2" (click)="onClose()">Annulla</button>
    <button form="formEsportazione" nz-button nzType="primary" nzSize="large" [disabled]="!formEsportazione.valid">Esporta</button>
</mat-dialog-actions>