import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/service/api.service';
import { Utente } from 'src/app/shared/interfacce';
import { EliminaUtenteComponent } from '../elimina-utente/elimina-utente.component';
import { ModificaUtenteComponent } from '../modifica-utente/modifica-utente.component';

@Component({
  selector: 'app-dettagli-utente',
  templateUrl: './dettagli-utente.component.html',
  styleUrls: ['./dettagli-utente.component.css']
})
export class DettagliUtenteComponent implements OnInit {

  constructor(private apiService: ApiService, private route: ActivatedRoute, private dialog: MatDialog, private router: Router) { }
  
  utente?: Utente;

  ngOnInit(): void {

    // Recupero l'id dall'url
    this.route.params.subscribe(params => {
      const id = params['id'];
      this.getData(id);
    });
  }

  getData(id: number) {

    // Recupero l'utente in base all'id
    this.apiService.getUtente(id).subscribe({
      next: (data) => this.utente = data,
      error: (err) => this.router.navigate(['/utenti'])
    })
  }

  onIndietro() {
    this.router.navigate(['/utenti'])
  }

  onModifica() {
    this.dialog.closeAll();
    var dialogRef = this.dialog.open(ModificaUtenteComponent, { data: { id: this.utente!.id }, autoFocus: false, width: '1000px' });
    dialogRef?.afterClosed().subscribe(() => {
      this.getData(this.utente!.id);
    })
  }

  onElimina() {
    this.dialog.closeAll();
    this.dialog.open(EliminaUtenteComponent, { data: { id: this.utente!.id }, autoFocus: false, width: '750px' })
  }

}