import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Cliente, Gruppo } from 'src/app/shared/interfacce';
import { ApiService } from 'src/app/service/api.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { checkImage } from 'src/app/shared/controlli';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { AggiungiClienteComponent } from 'src/app/clienti/aggiungi-cliente/aggiungi-cliente.component';

@Component({
  selector: 'app-modifica-gruppo',
  templateUrl: './modifica-gruppo.component.html',
  styleUrls: ['./modifica-gruppo.component.css']
})
export class ModificaGruppoComponent implements OnInit {

  constructor(private apiService: ApiService, private dialogRef: MatDialogRef<ModificaGruppoComponent>, private dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: { id: number, id_cliente: number }) { }

  membri: Cliente[] = [];
  clienti: Cliente[] = [];

  cliente?: Cliente;
  gruppo?: Gruppo;

  filteredClienti: Cliente[] = [];

  formGruppo!: FormGroup;
  formRicerca!: FormGroup;

  immagineList: NzUploadFile[] = [];
  immagineFile!: string;  

  error_messages = {
    'nome': [
      { type: 'required', message: 'Inserire un nome per procedere con la modifica del gruppo' },
      { type: 'minlength', message: 'Inserire almeno 5 caratteri' }
    ],
    'immagine': [
      { type: 'requiredImageType', message: "Inserire un'immagine di tipo JPG, PNG o JPEG" }
    ],
    'membro': [
      { type: 'duplicato', message: "Membro già presente, selezionarne o crearne un altro!" }
    ]
  }

  ngOnInit(): void {

    // Creo il form con i controlli
    this.formGruppo = new FormGroup({
      nome: new FormControl(null, [Validators.required, Validators.minLength(5)]),
      descrizione: new FormControl(null),
      immagine: new FormControl(null, checkImage()),
    })

    // Creo il form con i controlli
    this.formRicerca = new FormGroup({
      cliente: new FormControl(null)
    })

    // Recupero i dati del gruppo
    this.apiService.getGruppo(this.data.id).subscribe({
      next: (data) => this.gruppo = data,
      error: (err) => this.apiService.Error(err.error.message),
      complete: () => {

        // Aggiungo i dati del gruppo nel form
        this.formGruppo.setValue({
          nome: this.gruppo?.nome,
          descrizione: this.gruppo?.descrizione,
          immagine: ''
        })

        // Recupero i membri
        this.apiService.setMembri(this.gruppo!.id);
        this.apiService.membriChanged.subscribe({
          next: (data) => { 
            this.membri = [...data]; 
            this.getClienti()
          },
          error: (err) => this.apiService.Error(err.error.message)          
        })

      }
    })

  }

  getClienti() {

    // Recupero i clienti
    this.apiService.getClienti().subscribe({
      next: (data) => this.clienti = [...data],
      error: (err) => this.apiService.Error(err.error.message),
      complete: () => this.filteredClienti = [...this.clienti.slice(0, 10)]
    })

  }

  mostraValore(cliente: Cliente): string {
    return cliente && cliente.nome ? cliente.cognome.trim() + ' ' + cliente.nome.trim() : '';    
  }

  onSeleziona(ricerca: any) {
    // Filtro i clienti per nome o cognome in base al testo inserito
    this.filteredClienti = this.clienti.filter(cliente => (cliente.nome.trim() + ' ' + cliente.cognome.trim()).toLocaleLowerCase().includes(ricerca.toLocaleLowerCase()) || (cliente.cognome.trim() + ' ' + cliente.nome.trim()).toLocaleLowerCase().includes(ricerca.toLocaleLowerCase())).slice(0, 10)
  }

  onCliente(cliente: Cliente) {
    this.cliente = cliente;
  }

  onClienti() {
    var dialogRef = this.dialog.open(AggiungiClienteComponent, { data: { clienti: this.clienti }, autoFocus: false, width: '1000px' });
    dialogRef?.afterClosed().subscribe(esito => {
      if (esito) {
        this.getClienti();
      }
    })
  }

  onDelete(cliente: number) {

    // Elimino il membro dal gruppo
    this.apiService.deleteMembroGruppo(this.data.id, cliente).subscribe({
      next: (data) => this.apiService.setMembri(this.data.id),
      error: (err) => this.apiService.Error(err.error.message)
    })

  }

  onAdd() {

    // Controllo se il membro esiste
    const alreadyExists = this.membri.find(membro => membro.id === this.cliente!.id);
    if (!alreadyExists) {   

      // Aggiungo il membro al gruppo
      this.apiService.addMembroGruppo(this.data.id, this.cliente!.id).subscribe({
        next: (data) => this.formRicerca.patchValue({ cliente: null }),
        error: (err) => this.apiService.Error(err.error.message),
        complete: () => this.apiService.setMembri(this.data.id)
      })

    } else {
      this.apiService.Error("Membro già presente nel gruppo!")
    }
  }

  onImmagineUpload = (file: any): boolean => {

    // Controllo se il file inserito è una immagine
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';

    // Se è una immagine procedo
    if (isJpgOrPng) {

      // Aggiungo il file alla lista dei file al form
      this.immagineList = this.immagineList.concat(file);
      this.formGruppo.patchValue({ immagine: file.name });

      // Recupero il base64 del file caricato
      const reader = new FileReader();
      reader.onload = () => {
        this.immagineFile = reader.result as string;
      }
      reader.readAsDataURL(file);

      return false;
    } else {
      return false
    }
  }

  onRemoveImmagineUpload = (file: any): boolean => {
    this.formGruppo.patchValue({ immagine: '' });
    this.immagineList = [];
    return false;
  }

  onSubmit() {
    let gruppo = {
      nome: this.formGruppo.get('nome')?.value,
      descrizione: this.formGruppo.get('descrizione')?.value,
      immagine: this.formGruppo.get('immagine')?.value || "",
      file_immagine: this.immagineFile || "",
    }

    // Aggiorno i dati del gruppo
    this.apiService.updateGruppo(this.data.id, gruppo).subscribe({
      next: (data) => this.dialogRef.close(),
      error: (err) => this.apiService.Error(err.error.message)
    })

  }

  onClose() {
    this.dialogRef.close(true)
  }
}