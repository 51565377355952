<div class="p-3">
    <ng-template #tesseramentiTitle>
        <div class="row p-md-3 p-1">
            <div class="col-lg-8 my-auto">
                <h3 class="mb-2" style="white-space: break-spaces;">{{tesseramento?.prodotto}}</h3>                
                <h5 class="text-muted mb-2">Codice tessera: {{tesseramento?.codice_tesseramento}}</h5>            
                <span *ngIf="tesseramento?.stato == 'scaduto'" class="badge bg-danger fs-5">Scaduto</span>
                <span *ngIf="tesseramento?.stato == 'valido'" class="badge bg-success fs-5">Valido</span>
                <span *ngIf="tesseramento?.stato == 'attivazione'" class="badge bg-warning fs-5">In Attivazione</span>

                <span *ngIf="tesseramento?.attivo == false" class="badge bg-danger fs-5 ms-2">Non Attivo</span>
                <span *ngIf="tesseramento?.attivo == true" class="badge bg-success fs-5 ms-2">Attivo</span>                
            </div>
            <div class="col-lg-4 my-auto">                  
                <nz-button-group class="w-100">
                    <button nz-button nzSize="large" class="w-100 crop" type="button" (click)="onModifica()">Modifica il dettaglio ordine</button>
                    <button nz-button nzSize="large" class="w-25" nz-dropdown [nzDropdownMenu]="menu1" nzPlacement="bottomRight">
                        <span nz-icon nzType="ellipsis"></span>
                    </button>
                </nz-button-group>
                <nz-dropdown-menu #menu1="nzDropdownMenu">
                    <ul nz-menu>                        
                        <li nz-menu-item class="p-3" (click)="onElimina()">Elimina il dettaglio ordine<i class="ms-2 bi bi-trash3"></i></li>                                        
                    </ul>
                </nz-dropdown-menu>
            </div>          
        </div>
    </ng-template>
    <nz-card [nzTitle]="tesseramentiTitle" class="mt-5 mb-5">
        <div class="row p-md-3 p-1">
            <div class="col">
                <nz-breadcrumb>
                    <nz-breadcrumb-item>
                        <a routerLink="/home">
                            <span nz-icon nzType="home"></span>
                        </a>                  
                    </nz-breadcrumb-item>
                    <nz-breadcrumb-item>
                      <a routerLink="/tesseramenti">                    
                        <span>Storico Ordini in dettaglio</span>
                      </a>
                    </nz-breadcrumb-item>
                    <nz-breadcrumb-item>Dettaglio Storico Ordine</nz-breadcrumb-item>
                </nz-breadcrumb>
            </div>
        </div>
        <div class="row p-md-3 p-1" style="padding-top: 0px !important;">
            <div class="col-lg-6">
                <div class="mt-3">
    
                    <ng-template #datiHeader>
                        <div class="header">
                            <h5 class="d-inline text-white">Dati</h5>                        
                        </div>                    
                    </ng-template>
    
                    <nz-list nzBordered [nzHeader]="datiHeader" class="lista">
                        <nz-list-item>
                            <span nz-typography><span class="text-muted">N°</span></span> {{tesseramento?.id}}
                        </nz-list-item>
                        <nz-list-item>
                            <span nz-typography><span class="text-muted">Prodotto:</span></span> {{tesseramento?.prodotto}}
                        </nz-list-item>
                        <nz-list-item>
                            <span nz-typography><span class="text-muted">Prezzo:</span></span> {{tesseramento?.prezzo | currency: 'EUR'}}
                        </nz-list-item>                    
                        <nz-list-item [routerLink]="['/clienti/', tesseramento?.id_cliente]" class="cursor-pointer">
                            <span nz-typography><span class="text-muted">Intestato a:</span></span> {{tesseramento?.cognome + ' ' + tesseramento?.nome}}
                        </nz-list-item>     
                        <nz-list-item>
                            <span nz-typography><span class="text-muted">Stato:</span></span> {{tesseramento?.stato | titlecase}}
                        </nz-list-item>  
                        <nz-list-item>
                            <span nz-typography><span class="text-muted">Attivo:</span></span> {{tesseramento?.attivo == true ? 'Si' : 'No' }}
                        </nz-list-item>               
                        <nz-list-item class="p-0">
                            <nz-collapse nzAccordion [nzExpandIconPosition]="'right'" class="w-100" style="background-color: transparent; border: 0px solid; padding-left: 8px;">
                                <nz-collapse-panel [nzHeader]="'Altro'" style="border-bottom: 0px;" class="cursor-pointer">
                                    <nz-list-item>
                                        <span nz-typography><span class="text-muted">Data creazione:</span></span> {{tesseramento?.data_creazione | date: 'dd/MM/yyyy HH:mm'}}
                                    </nz-list-item>
                                    <nz-list-item>
                                        <span nz-typography><span class="text-muted">Data aggiornamento:</span></span> {{tesseramento?.data_aggiornamento | date: 'dd/MM/yyyy HH:mm'}}
                                    </nz-list-item>
                                </nz-collapse-panel>
                              </nz-collapse>
                        </nz-list-item>                    
                    </nz-list>                
                </div>            
            </div>
            <div class="col-lg-6">
                
                <div class="mt-3">
                    <ng-template #infoHeader>      
                        <div class="header">
                            <h5 class="d-inline text-white">Informazioni</h5>                        
                        </div>
                    </ng-template>
        
                    <nz-list nzBordered [nzHeader]="infoHeader" class="lista">
                        <nz-list-item>
                            <span nz-typography>Inizio validita</span>{{tesseramento?.inizio_validita | date: 'dd/MM/yyyy HH:mm:ss'}}
                        </nz-list-item>     
                        <nz-list-item>
                            <span nz-typography>Fine validita</span>{{tesseramento?.fine_validita | date: 'dd/MM/yyyy HH:mm:ss'}}
                        </nz-list-item>                                      
                    </nz-list>    
                </div>                                                        
            </div>
        </div>
    </nz-card>
</div>