<div class="p-md-3 p-1" *ngIf="carica">
    <div class="row">
        <div class="col text-center">
            <app-caricamento class="text-center"></app-caricamento>
        </div>
    </div>
</div>
<div class="p-md-3 p-1" *ngIf="!carica">
    
    <ng-template #filtriTitle>        
        <h5 class="fw-bold mb-0">Filtri</h5>                    
    </ng-template>

    <nz-card [nzTitle]="filtriTitle">        
        <form [formGroup]="formStatistiche">
            <div class="row">
                <div class="col">
                    <nz-form-item class="flex-column mb-2">
                        <nz-form-label nzRequired nzFor="data" class="text-start">Periodo</nz-form-label>
                        <nz-form-control>
                            <nz-range-picker id="data" formControlName="data" class="w-100" [nzFormat]="'dd/MM/yyyy'"></nz-range-picker>
                        </nz-form-control>
                    </nz-form-item>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6">
                    <nz-form-item class="flex-column mb-2">
                        <nz-form-label nzFor="categoria" class="text-start">Categoria</nz-form-label>
                        <nz-form-control>
                            <nz-select nzShowSearch nzAllowClear formControlName="categoria" nzSize="large" class="w-100" [nzPlaceHolder]="'Seleziona una categoria'">
                                <nz-option *ngFor="let categoria of categorie" [nzValue]="categoria.id" [nzLabel]="categoria.nome"></nz-option>
                            </nz-select>
                        </nz-form-control>
                    </nz-form-item> 
                </div>
                <div class="col-lg-6">
                    <nz-form-item class="flex-column mb-2">
                        <nz-form-label nzFor="prodotto" class="text-start">Prodotto</nz-form-label>
                        <nz-form-control>
                            <nz-select nzShowSearch nzAllowClear formControlName="prodotto" nzSize="large" class="w-100" [nzPlaceHolder]="'Seleziona un prodotto'">                                
                                <nz-option *ngFor="let prodotto of prodotti" [nzValue]="prodotto.id" [nzLabel]="prodotto.nome"></nz-option>
                            </nz-select>
                        </nz-form-control>
                    </nz-form-item> 
                </div>
            </div>
            <div class="row mt-3">
                <div class="col text-end">
                    <button nz-button nzType="primary" nzSize="large" (click)="onStatistiche()" [disabled]="!formStatistiche.valid">Calcola statistiche</button>
                </div>
            </div>
        </form>        
    </nz-card>    
    
    <ng-template #statisticheTitle>        
        <h5 class="fw-bold mb-0">Statistiche {{formStatistiche.get('data')?.value[0] | date: 'dd/MM/yyyy'}} - {{formStatistiche.get('data')?.value[1] | date: 'dd/MM/yyyy'}}</h5>                    
    </ng-template>
            
    <nz-card [nzTitle]="statisticheTitle">
        <div class="row">
            <div class="text-center" *ngIf="show_clienti_chart" [ngClass]="{'col-md-4': show_clienti_chart && show_registrazioni_chart, 'col-md-6': show_clienti_chart && !show_registrazioni_chart}">
                <nz-card nzTitle="Tesserati SciClub">                                                
                    <h3 class="mb-0">{{statistiche?.totaleTesserati}}</h3>                    
                </nz-card>
            </div>
            <div class="col-md-4 text-center" *ngIf="show_clienti_chart && show_registrazioni_chart">
                <nz-card nzTitle="Totale clienti registrati">                            
                    <h3 class="mb-0">{{statistiche?.totaleClienti}}</h3>
                </nz-card>
            </div>
            <div class="text-center" [ngClass]="{'col-md-4': show_clienti_chart && show_registrazioni_chart, 'col-md-6': show_clienti_chart && !show_registrazioni_chart, 'col-12': !show_clienti_chart}">
                <nz-card nzTitle="Totale prodotti ordinati">                            
                    <h3 class="mb-0">{{statistiche?.totaleOrdini}}</h3>
                </nz-card>
            </div>        
            <div class="col-md-4 text-center">
                <nz-card nzTitle="Totale vendite">
                    <h3 class="mb-0">{{statistiche?.entrate?.totale_vendite | currency: 'EUR'}}</h3>
                </nz-card>
            </div>
            <div class="col-md-4 text-center">
                <nz-card nzTitle="Totale incassi">
                    <h3 class="mb-0">{{statistiche?.entrate?.totale_incassato | currency: 'EUR'}}</h3>
                </nz-card>
            </div>
            <div class="col-md-4 text-center">
                <nz-card nzTitle="Residuo da incassare">
                    <h3 class="mb-0">{{statistiche?.entrate?.residuo | currency: 'EUR'}}</h3>
                </nz-card>
            </div>
        </div>
        
        <nz-card nzTitle="Ordini">
            <div class="row mb-3">            
                <div class="chartContainer">
                    <canvas baseChart [data]="ordiniData" [legend]="false" [options]="lineOptions" [type]="'line'"></canvas>
                </div>
            </div>
        </nz-card>
        <nz-card nzTitle="Ordini in dettaglio">
            <div class="row mb-3">                
                <div class="chartContainer">
                    <canvas baseChart [data]="nuoviTesseramentiData" [legend]="false" [options]="lineOptions" [type]="'line'"></canvas>
                </div>
            </div>
        </nz-card>

        <nz-card nzTitle="Età degli iscritti" *ngIf="show_clienti_chart && show_categorie_chart">
            <div class="row mb-3">                
                <div class="chartContainer">
                    <canvas baseChart [data]="etaClientiData" [legend]="false" [options]="generalChartOptions" [type]="'line'"></canvas>
                </div>
            </div>
        </nz-card>    

        <ng-container *ngIf="show_clienti_chart && !show_categorie_chart">
            <div class="row">
                <div class="col-md-8">
                    <nz-card nzTitle="Età degli iscritti">
                        <div class="row mb-3">                
                            <div class="chartContainer">
                                <canvas baseChart [data]="etaClientiData" [legend]="false" [options]="generalChartOptions" [type]="'line'"></canvas>
                            </div>
                        </div>
                    </nz-card>
                </div>
                <div class="col-md-4">
                    <nz-card nzTitle="Fasce d'età tesserati">
                        <canvas baseChart [data]="percClientiData" [legend]="false" [options]="generalChartOptions" [type]="'pie'"></canvas>
                    </nz-card>
                </div>
            </div>
        </ng-container>   

        <ng-container *ngIf="show_clienti_chart && show_categorie_chart">              
            <div class="row">
                <div class="text-center" [ngClass]="{'col-md-4': show_categorie_chart, 'col': !show_categorie_chart}" *ngIf="show_clienti_chart">
                    <nz-card nzTitle="Fasce d'età tesserati">
                        <canvas baseChart [data]="percClientiData" [legend]="false" [options]="generalChartOptions" [type]="'pie'"></canvas>
                    </nz-card>
                </div>
                <div class="text-center" [ngClass]="{'col-md-8': show_clienti_chart, 'col': !show_clienti_chart}">
                    <nz-card [nzTitle]="show_prodotti_chart ? 'Prodotti più venduti' : 'Categorie più vendute'">
                        <div class="row">
                            <div class="col-md-6">
                                <canvas baseChart [data]="prodottiCatData" [legend]="false" [options]="generalChartOptions" [type]="'pie'"></canvas>
                            </div>
                            <div class="col-md-6 mt-md-0 mt-3" style="align-self: center;">
                                <div *ngFor="let cat of statistiche?.prodottiCat" class="d-flex justify-content-between border-0 border-bottom" style="border-style: dotted !important;">
                                    <span class="fs-0" [ngClass]="!cat.categoria ? 'fw-bold' : ''">{{cat.categoria || 'Totale'}}</span>
                                    <span class="fs-0">{{cat.ordini}}</span>
                                </div>
                            </div>
                        </div>                                        
                    </nz-card>
                </div>
            </div>  
        </ng-container>                    
    </nz-card>
</div>