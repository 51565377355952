import { Component, OnInit } from '@angular/core';
import { Gruppo } from '../shared/interfacce';
import { ApiService } from '../service/api.service';
import { MatDialog } from '@angular/material/dialog';
import { AggiungiGruppoComponent } from './aggiungi-gruppo/aggiungi-gruppo.component';
import { Location } from '@angular/common';

@Component({
  selector: 'app-gruppi',
  templateUrl: './gruppi.component.html',
  styleUrls: ['./gruppi.component.css']
})
export class GruppiComponent implements OnInit {

  constructor(private apiService: ApiService, private dialog: MatDialog, private location: Location) { }

  gruppi: Gruppo[] = [];

  nomeFilter: any = '';
  clienteFilter: any = '';

  carica: boolean = true;

  ngOnInit(): void {

    this.apiService.setGruppi();
    this.apiService.gruppoChanged.subscribe({
      next: (data) => {
        this.gruppi = [...data];
        this.carica = false
      },
      error: (err) => this.apiService.Error(err.error.message)
    })

  }

  onOrdinamentoNome(a: Gruppo, b: Gruppo) {
    return a.nome.localeCompare(b.nome)
  }

  onOrdinamentoCapogruppo(a: Gruppo, b: Gruppo) {
    return a?.cliente.localeCompare(b?.cliente)
  }

  onOrdinamentoMembri(a: Gruppo, b: Gruppo) {
    return a.membri - b.membri;
  }

  onOrdinamentoDataCreazione(a: Gruppo, b: Gruppo) {
    return a.data_creazione.toString().localeCompare(b.data_creazione.toString())
  }

  onIndietro() {
    this.location.back();
  }

  onAdd() {
    this.dialog.closeAll()
    this.dialog.open(AggiungiGruppoComponent, { autoFocus: false, width: '1000px' });
  }

}