<mat-dialog-content>
    <div class="title-section mb-3" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle style="cursor: move;">
        <h5 class="title">Aggiungi contatto</h5>
    </div>
    <nz-divider></nz-divider>    
                       
        <form [formGroup]="formContatto" (ngSubmit)="onSubmit()" id="formContatto">
            <div class="row">
                <div id="indirizzoObbl" class="form-text text-start">* campo obbligatorio</div>
            </div>     

            <div class="row mt-3">
                <div class="col">  
                    <nz-form-item class="flex-column mb-2">
                        <nz-form-label class="text-start">Ricerca cliente</nz-form-label>
                        <nz-form-control>
                            <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Ricerca cliente" formControlName="cliente" nzSize="large" class="w-100" (ngModelChange)="onEvent($event)" (nzOnSearch)="onSeleziona($event)">
                                <nz-option *ngFor="let cliente of filteredClienti" [nzValue]="cliente" [nzLabel]="cliente.cognome + ' ' + cliente.nome + ' | ' + (cliente.data_nascita | date: 'dd MMM yyyy')"></nz-option>
                            </nz-select>   
                        </nz-form-control>
                    </nz-form-item>                                                          
                </div>
            </div>        
            <div class="row">                                
                <div class="col-md-6">
                    <nz-form-item class="flex-column mb-2">
                        <nz-form-label nzRequired class="text-start">Cognome</nz-form-label>
                        <nz-form-control>
                            <input nz-input formControlName="cognome" nzSize="large">  
                        </nz-form-control>
                    </nz-form-item>
                    <div class="error-messages">
                        <ng-container *ngFor="let error of error_messages.cognome">                                                                        
                            <nz-alert nzType="error" [nzMessage]="error.message" nzShowIcon nzCloseable *ngIf="formContatto.get('cognome')?.hasError(error.type) && (formContatto.get('cognome')?.dirty || formContatto.get('cognome')?.touched)"></nz-alert>
                        </ng-container>
                    </div>
                </div>
                <div class="col-md-6">
                    <nz-form-item class="flex-column mb-2">
                        <nz-form-label nzRequired class="text-start">Nome</nz-form-label>
                        <nz-form-control>
                            <input nz-input formControlName="nome" nzSize="large">  
                        </nz-form-control>
                    </nz-form-item>
                    <div class="error-messages">
                        <ng-container *ngFor="let error of error_messages.nome">                                                                        
                            <nz-alert nzType="error" [nzMessage]="error.message" nzShowIcon nzCloseable *ngIf="formContatto.get('nome')?.hasError(error.type) && (formContatto.get('nome')?.dirty || formContatto.get('nome')?.touched)"></nz-alert>
                        </ng-container>
                    </div>
                </div>                                
            </div>
            <div class="row">
                <div class="col-md-6">
                    <nz-form-item class="flex-column mb-2">
                        <nz-form-label class="text-start">Email</nz-form-label>
                        <nz-form-control>
                            <input nz-input formControlName="email" nzSize="large" type="email" >
                        </nz-form-control>
                    </nz-form-item>
                    <div class="error-messages">
                        <ng-container *ngFor="let error of error_messages.email">                                                                        
                            <nz-alert nzType="error" [nzMessage]="error.message" nzShowIcon nzCloseable *ngIf="formContatto.get('email')?.hasError(error.type) && (formContatto.get('email')?.dirty || formContatto.get('email')?.touched)"></nz-alert>
                        </ng-container>
                    </div>
                </div>
                <div class="col-md-6">
                    <nz-form-item class="flex-column mb-2">
                        <nz-form-label class="text-start">Telefono</nz-form-label>
                        <nz-form-control>
                            <input nz-input formControlName="telefono" nzSize="large" type="tel">
                        </nz-form-control>
                    </nz-form-item>
                    <div class="error-messages">
                        <ng-container *ngFor="let error of error_messages.telefono">                                                                        
                            <nz-alert nzType="error" [nzMessage]="error.message" nzShowIcon nzCloseable *ngIf="formContatto.get('telefono')?.hasError(error.type) && (formContatto.get('telefono')?.dirty || formContatto.get('telefono')?.touched)"></nz-alert>
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <nz-form-item class="flex-column mb-2">
                        <nz-form-label nzRequired class="text-start">Parentela</nz-form-label>
                        <nz-form-control>
                            <input nz-input formControlName="parentela" nzSize="large">
                        </nz-form-control>
                    </nz-form-item>
                </div>
            </div>
        </form>     
    <nz-divider></nz-divider>
</mat-dialog-content>
<mat-dialog-actions>
    <button type="button" nz-button (click)="onClose()" nzSize="large" class="me-2">Annulla</button>
    
    <span nzTooltipTitle="Per aggiungere un contatto, inserire tutti i campi con l'asterisco" nz-tooltip nzTooltipPlacement="top" *ngIf="!formContatto.valid">
        <button nz-button nzType="primary" nzSize="large" [disabled]="true">Aggiungi Contatto</button>
    </span>  
    <button nz-button nzType="primary" nzSize="large" *ngIf="formContatto.valid" form="formContatto">Aggiungi Contatto</button>        
</mat-dialog-actions>