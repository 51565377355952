import { Component, OnInit } from '@angular/core';
import { ApiService } from '../service/api.service';
import { DatePipe, Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { Cliente } from '../shared/interfacce';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { LoadingComponent } from '../loading/loading.component';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-clienti-newsletter',
  templateUrl: './clienti-newsletter.component.html',
  styleUrls: ['./clienti-newsletter.component.css']
})
export class ClientiNewsletterComponent implements OnInit {

  constructor(private apiService: ApiService, private location: Location, private dialog: MatDialog, private datePipe: DatePipe) { }

  clienti: Cliente[] = [];

  clienteFilter: any = '';
  emailFilter: any = '';

  loading: boolean = true;
  carica: boolean = true;
  checked: boolean = false;
  indeterminate: boolean = false;

  totale: number = 0;
  pageIndex: number = 1;
  pageSize: number = 10;

  listOfCurrentPageData: readonly Cliente[] = [];
  setOfCheckedId = new Set<number>();

  filtri: { pageIndex: number, pageSize: number, cliente: string, email: string } = { pageIndex: 1, pageSize: 10, cliente: '', email: '' };

  ngOnInit(): void {

    this.loading = true;
    this.apiService.setClienti(this.pageIndex, 10, 'id', 'ascend', this.clienteFilter, this.emailFilter, true);
    this.apiService.clienteChanged.subscribe({
      next: (data) => {
        this.clienti = data;
        this.totale = this.apiService.TotaleClienti;
        this.carica = false;
        this.loading = false;
      },
      error: (err) => this.apiService.Error(err.error.message)
    })

  }

  onLoadData(pageIndex: number, pageSize: number, sort_key: string, sort_type: string): void {

    this.loading = true
    this.apiService.setClienti(pageIndex, pageSize, sort_key, sort_type, this.clienteFilter, this.emailFilter, true);

    this.pageIndex = pageIndex;
    this.pageSize = pageSize;

    this.filtri = { pageIndex: pageIndex, pageSize: pageSize, cliente: this.clienteFilter, email: this.emailFilter };

  }

  onParamsChange(params: NzTableQueryParams): void {
    const { pageSize, pageIndex, sort } = params;

    const sorting = sort.filter(value => value.value)
    if (sorting.length <= 0)
      sorting.push({ key: 'id', value: 'ascend' })

    if (pageIndex == this.filtri.pageIndex && pageSize == this.filtri.pageSize) {
      if (this.clienteFilter != this.filtri.cliente || this.emailFilter != this.filtri.email)
        this.onLoadData(1, pageSize, sorting[0].key, sorting[0].value || '');
      else
        this.onLoadData(pageIndex, pageSize, sorting[0].key, sorting[0].value || '');
    } else {
      if (this.clienteFilter != this.filtri.cliente || this.emailFilter != this.filtri.email)
        this.onLoadData(1, pageSize, sorting[0].key, sorting[0].value || '');
      else
        this.onLoadData(pageIndex, pageSize, sorting[0].key, sorting[0].value || '');
    }
  }

  onOrdinamento() {
    return 1 - 1;
  }

  updateCheckedSet(id: number, checked: boolean): void {
    if (checked) {
      this.setOfCheckedId.add(id);
    } else {
      this.setOfCheckedId.delete(id);
    }
  }

  onItemChecked(id: number, checked: boolean): void {
    this.updateCheckedSet(id, checked);
    this.refreshCheckedStatus();
  }

  onAllChecked(value: boolean): void {
    this.listOfCurrentPageData.forEach(item => this.updateCheckedSet(item.id, value));
    this.refreshCheckedStatus();
  }

  refreshCheckedStatus(): void {
    this.checked = this.listOfCurrentPageData.every(item => this.setOfCheckedId.has(item.id));
    this.indeterminate = this.listOfCurrentPageData.some(item => this.setOfCheckedId.has(item.id)) && !this.checked;
  }

  onCurrentPageDataChange($event: readonly Cliente[]): void {
    this.listOfCurrentPageData = $event;
    this.refreshCheckedStatus();
  }

  onExport() {

    this.dialog.closeAll();
    this.dialog.open(LoadingComponent, { autoFocus: false, width: '200px' });

    let result = this.clienti.filter(cliente => (cliente?.email || '')?.toLowerCase().indexOf(this.emailFilter.toLowerCase()) >= 0) // Filtro i clienti per email      
      .filter(cliente => (cliente.cognome.trim() + ' ' + cliente.nome.trim()).toString().trim().toLocaleLowerCase().indexOf(this.clienteFilter.toLocaleLowerCase()) !== -1 || (cliente.nome.trim() + ' ' + cliente.cognome.trim()).toString().trim().toLocaleLowerCase().indexOf(this.clienteFilter.toLocaleLowerCase()) !== -1) // Filtro i clienti per nome o cognome 
      .map(cliente => ({
        'Cognome': cliente.cognome,
        'Nome': cliente.nome,
        'Data di nascita': this.datePipe.transform(cliente.data_nascita, 'dd/MM/yyyy'),
        'Email': cliente.email
      })); // Aggiungo l'oggetto con i dati del cliente

    this.dialog.closeAll();

    const workBook = XLSX.utils.book_new();
    const workSheet = XLSX.utils.json_to_sheet(result);

    workSheet["!cols"] = [{ wch: 30 }, { wch: 30 }, { wch: 30 }, { wch: 30 }];

    XLSX.utils.book_append_sheet(workBook, workSheet, 'Clienti');
    XLSX.writeFile(workBook, 'Clienti.xlsx');

  }

  onEsporta() {

    let clienti = Array.from(this.setOfCheckedId.values());

    this.dialog.closeAll();
    this.dialog.open(LoadingComponent, { autoFocus: false, width: '200px' });

    let result = this.clienti.filter(cliente => clienti.includes(cliente.id)) // Filtro i clienti il cui ID è presente 
      .map(cliente => ({
        'Cognome': cliente.cognome,
        'Nome': cliente.nome,
        'Data di nascita': this.datePipe.transform(cliente.data_nascita, 'dd/MM/yyyy'),
        'Email': cliente.email
      })); // Aggiungo l'oggetto con i dati del cliente

    this.dialog.closeAll();

    const workBook = XLSX.utils.book_new();
    const workSheet = XLSX.utils.json_to_sheet(result);

    workSheet["!cols"] = [{ wch: 30 }, { wch: 30 }, { wch: 30 }, { wch: 30 }];

    XLSX.utils.book_append_sheet(workBook, workSheet, 'Clienti');
    XLSX.writeFile(workBook, 'Clienti.xlsx');

  }

  onIndietro() {
    this.location.back();
  }

}