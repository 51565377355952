<mat-dialog-content>
    <div class="title-section mb-3" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle style="cursor: move;">
        <h5 class="title">Crea un prodotto</h5>
    </div>
    <nz-divider></nz-divider>
    <form [formGroup]="formProdotti" (ngSubmit)="onSubmit()" id="formProdotti">
        <div class="row">
            <div id="indirizzoObbl" class="form-text text-start">*campo obbligatorio</div>
        </div>        
        <div class="row mt-3">
            <div class="col">
                <nz-form-item class="flex-column mb-2">
                    <nz-form-label nzRequired class="text-start">Nome</nz-form-label>
                    <nz-form-control>
                        <input nz-input formControlName="nome" nzSize="large" required>
                    </nz-form-control>
                </nz-form-item>
                <div class="error-messages">
                    <ng-container *ngFor="let error of error_messages.nome">                                                                    
                        <nz-alert nzType="error" [nzMessage]="error.message" nzShowIcon nzCloseable *ngIf="formProdotti.get('nome')?.hasError(error.type) && (formProdotti.get('nome')?.dirty || formProdotti.get('nome')?.touched)"></nz-alert>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <nz-form-item class="flex-column mb-2">
                    <nz-form-label class="text-start">Descrizione</nz-form-label>
                    <nz-form-control>
                        <textarea nz-input [nzAutosize]="{ minRows: 3, maxRows: 6 }" formControlName="descrizione" nzSize="large"></textarea>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">     
                <nz-form-item class="flex-column">
                    <nz-form-label nzRequired class="text-start">Categoria</nz-form-label>
                    <nz-form-control>
                        <nz-select nzShowSearch nzAllowClear formControlName="categoria" nzSize="large" class="w-100" (ngModelChange)="onNewCategory($event)">
                            <nz-option *ngFor="let categoria of categorie" [nzValue]="categoria.id" [nzLabel]="categoria.nome"></nz-option>
                            <nz-option nzValue="Altro" nzLabel="Altro"></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
                <div class="error-messages">
                    <ng-container *ngFor="let error of error_messages.categoria">                                                                    
                        <nz-alert nzType="error" [nzMessage]="error.message" nzShowIcon nzCloseable *ngIf="formProdotti.get('categoria')?.hasError(error.type) && (formProdotti.get('categoria')?.dirty || formProdotti.get('categoria')?.touched)"></nz-alert>
                    </ng-container>
                </div>
            </div>
            <div class="col-md-6">
                <nz-form-item class="flex-column">
                    <nz-form-label nzRequired class="text-start">Prezzo</nz-form-label>
                    <nz-form-control>
                        <nz-input-group [nzSuffix]="suffixTemplateInfo"  nzSize="large">
                            <input nz-input formControlName="prezzo" required>
                        </nz-input-group>                
                        <ng-template #suffixTemplateInfo>                    
                            <span nz-icon nzType="euro-circle" nzTheme="outline"></span>                    
                        </ng-template>
                    </nz-form-control>
                </nz-form-item>                
                <div class="error-messages">
                    <ng-container *ngFor="let error of error_messages.prezzo">                                                                    
                        <nz-alert nzType="error" [nzMessage]="error.message" nzShowIcon nzCloseable *ngIf="formProdotti.get('prezzo')?.hasError(error.type) && (formProdotti.get('prezzo')?.dirty || formProdotti.get('prezzo')?.touched)"></nz-alert>
                    </ng-container>
                </div>
            </div>
        </div>

        <div class="row mb-3">            
            <div class="col">
                <span nzTooltipTitle="Premi sul pulsante, per creare una fascia di prezzo in base all'età" nz-tooltip nzTooltipPlacement="top" >
                    <button nz-button nzType="primary" nzSize="large" type="button" class="w-100" (click)="onPrezzi()">Crea fasce prezzo</button>
                </span>                           
            </div>
        </div>

        <ng-template #fasceHeader>      
            <div class="header">
                <h5 class="d-inline text-white">Fasce di età</h5>
                <span class="badge bg-white text-dark rounded-pill float-end mt-1">{{fasce.length}}</span>
            </div>
        </ng-template>

        <nz-list nzBordered [nzHeader]="fasceHeader" class="lista">
            <nz-list-item class="cursor-pointer" *ngFor="let fascia of fasce; let i = index">
                <span nz-typography><span class="cursor-pointer" nz-popconfirm nzPopconfirmTitle="Sei sicuro di voler eliminare la fascia? L'azione è irreversibile!." (nzOnConfirm)="onEliminaFascia(i)"><i class="bi bi-dash-circle-fill me-2"></i></span> {{fascia.eta_min}} - {{fascia.eta_max}} | {{fascia.descrizione}}</span>{{fascia.prezzo | currency: 'EUR'}}
            </nz-list-item>     
            <nz-list-item *ngIf="fasce.length <= 0">
                <span nz-typography>Nessuna fascia presente</span>
            </nz-list-item>                  
        </nz-list>         

        <div class="row mt-3">
            <div class="col-md-6">
                <nz-form-item class="flex-column mb-2">
                    <nz-form-label nzRequired class="text-start">Vendibile a partire dal</nz-form-label>
                    <nz-form-control>
                        <nz-date-picker formControlName="inizio_validita" [nzMode]="'date'" nzSize="large" [nzFormat]="'dd/MM/yyyy'" class="w-100"></nz-date-picker>
                    </nz-form-control>
                </nz-form-item>
                <div class="error-messages">
                    <ng-container *ngFor="let error of error_messages.inizio_validita">                                                                    
                        <nz-alert nzType="error" [nzMessage]="error.message" nzShowIcon nzCloseable *ngIf="formProdotti.get('inizio_validita')?.hasError(error.type) && (formProdotti.get('inizio_validita')?.dirty || formProdotti.get('inizio_validita')?.touched)"></nz-alert>
                    </ng-container>
                </div>
            </div>
            <div class="col-md-6">
                <nz-form-item class="flex-column mb-2">
                    <nz-form-label nzRequired class="text-start">Vendibile fino al</nz-form-label>
                    <nz-form-control>
                        <nz-date-picker formControlName="fine_validita" [nzMode]="'date'" nzSize="large" [nzFormat]="'dd/MM/yyyy'" class="w-100"></nz-date-picker>
                    </nz-form-control>
                </nz-form-item>                
                <div class="error-messages">
                    <ng-container *ngFor="let error of error_messages.fine_validita">                                                                    
                        <nz-alert nzType="error" [nzMessage]="error.message" nzShowIcon nzCloseable *ngIf="formProdotti.get('fine_validita')?.hasError(error.type) && (formProdotti.get('fine_validita')?.dirty || formProdotti.get('fine_validita')?.touched)"></nz-alert>
                    </ng-container>
                </div>
            </div>
        </div>

        <div class="row mt-3 text-center align-items-center">
            <div [ngClass]="formProdotti.get('giornaliero')?.value ? 'col-md-6' : 'col'">  
                <nz-form-item class="flex-column text-center mb-3">
                    <nz-form-label class="text-start" style="display: none;"></nz-form-label>
                    <nz-form-control>
                        <span nzTooltipTitle="Se selezionato, puoi scegliere per quali giorni il prodotto sarà valido" nz-tooltip nzTooltipPlacement="top">                    
                            <label nz-checkbox formControlName="giornaliero" (ngModelChange)="onGiornalieri($event)">Giornaliero</label> 
                        </span>   
                    </nz-form-control>
                </nz-form-item>                               
            </div>
            <div class="col-md-6" *ngIf="formProdotti.get('giornaliero')?.value">
                <nz-form-item class="flex-column mb-2">
                    <nz-form-label nzRequired class="text-start">Giorni di validità del prodotto</nz-form-label>
                    <nz-form-control>
                        <nz-select nzShowSearch nzAllowClear formControlName="giorni" nzSize="large" nzMode="multiple" class="w-100">
                            <nz-option *ngFor="let giorno of giorni" [nzValue]="giorno" [nzLabel]="giorno"></nz-option>
                        </nz-select>    
                    </nz-form-control>
                </nz-form-item>                
                <div class="error-messages">
                    <ng-container *ngFor="let error of error_messages.giorni">                                                                    
                        <nz-alert nzType="error" [nzMessage]="error.message" nzShowIcon nzCloseable *ngIf="formProdotti.get('giorni')?.hasError(error.type) && (formProdotti.get('giorni')?.dirty || formProdotti.get('giorni')?.touched)"></nz-alert>
                    </ng-container>
                </div>
            </div>
        </div>

        <div class="row mt-2 align-items-center">
            <div [ngClass]="formProdotti.get('date')?.value ? 'col-md-6' : 'col'">  
                <nz-form-item class="flex-column text-center mb-0">
                    <nz-form-label class="text-start" style="display: none;"></nz-form-label>
                    <nz-form-control>
                        <span nzTooltipTitle="Se selezionato, puoi scegliere per quali date il prodotto sarà valido! Seleziona prima le date 'Vendibile a partire dal' e 'Vendibile fino al' per poter selezionare le date specifiche!" nz-tooltip nzTooltipPlacement="top">                    
                            <label nz-checkbox formControlName="date" (ngModelChange)="onDateSpecifiche($event)">Prodotto valido solo in date specifiche</label> 
                        </span>   
                    </nz-form-control>
                </nz-form-item>                               
            </div>
            <div class="col-md-6" *ngIf="formProdotti.get('date')?.value">
                <nz-form-item class="flex-column mb-0">
                    <nz-form-label nzRequired class="text-start">Date specifiche di validità del prodotto</nz-form-label>
                    <nz-form-control>
                        <nz-range-picker class="w-100" nzSize="large" formControlName="date_specifiche" [nzDisabledDate]="dateService.disableDate(formProdotti.get('inizio_validita')?.value, formProdotti.get('fine_validita')?.value)" [nzFormat]="'dd/MM/yyyy'"></nz-range-picker>
                    </nz-form-control>
                </nz-form-item>                
                <div class="error-messages">
                    <ng-container *ngFor="let error of error_messages.giorni">                                                                    
                        <nz-alert nzType="error" [nzMessage]="error.message" nzShowIcon nzCloseable *ngIf="formProdotti.get('date_specifiche')?.hasError(error.type) && (formProdotti.get('date_specifiche')?.dirty || formProdotti.get('date_specifiche')?.touched)"></nz-alert>
                    </ng-container>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <nz-form-item class="flex-column mb-3">
                    <nz-form-label class="text-start">Posizione</nz-form-label>
                    <nz-form-control>
                        <nz-select nzShowSearch nzAllowClear formControlName="posizione" nzSize="large" class="w-100">
                            <nz-option nzValue="1" nzLabel="Mostra per primo"></nz-option>
                            <nz-option *ngFor="let prodotto of prodotti" [nzValue]="prodotto.posizione" [nzLabel]="'Al posto di: ' + prodotto.nome"></nz-option>
                            <nz-option nzValue="ultimo" nzLabel="Mostra per ultimo"></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>

        <div class="row">
            <div class="col">                                                    

                <nz-upload [(nzFileList)]="immagineList" [nzShowButton]="immagineList.length <= 0" [nzBeforeUpload]="onImmagineUpload" nzAccept="image/*" [nzRemove]="onRemoveImmagineUpload" class="w-100">
                    <button nzSize="large" nz-button type="button" class="bottone mt-3" style="width: 100%">
                        <span class="align-middle" nz-icon nzType="upload"></span>
                        Carica immagine prodotto
                    </button>
                </nz-upload>

                <div class="error-messages">
                    <ng-container *ngFor="let error of error_messages.immagine">                                                                    
                        <nz-alert nzType="error" [nzMessage]="error.message" nzShowIcon nzCloseable *ngIf="formProdotti.get('immagine')?.hasError(error.type)"></nz-alert>
                    </ng-container>
                </div>
            </div>
        </div>

        <div class="row mt-3">
            <div class="col-md-4">                
                <span nzTooltipTitle="Se selezionato, il prodotto sarà visibile ai clienti dello shop!" nz-tooltip nzTooltipPlacement="top">                                        
                    <label nz-checkbox formControlName="online">Vendibile nello shop</label> 
                </span>  
            </div>
            <div class="col-md-4">                
                <span nzTooltipTitle="Se selezionato, il prodotto deve essere acquistato dai clienti per aver accesso agli altri prodotti!" nz-tooltip nzTooltipPlacement="top">                    
                    <label nz-checkbox formControlName="obbligatorio">Prodotto obbligatorio</label> 
                </span>  
            </div>
            <div class="col-md-4">                
                <span nzTooltipTitle="Se selezionato, all'acquisto verrà richiesto di inserire il codice della card SkipassOpen o SKiCard vialattea!" nz-tooltip nzTooltipPlacement="top">                    
                    <label nz-checkbox formControlName="codice">Codice Card</label> 
                </span>  
            </div>
        </div>

        <div class="row mt-3">
            <div class="col-md-4">                
                <span nzTooltipTitle="Se selezionato, all'acquisto del prodotto, verrà richiesto di inserire il punto di ritiro del prodotto!" nz-tooltip nzTooltipPlacement="top">                    
                    <label nz-checkbox formControlName="informativo">Punti di ritiro</label> 
                </span>  
            </div>

            <div class="col-md-4">                
                <span nzTooltipTitle="Se selezionato, all'acquisto del prodotto nello shop, verrà richiesto di accettare il modulo di accettazione e presa visione dell'assicurazione e se non presente verrà richiesto di inserire il codice fiscale!" nz-tooltip nzTooltipPlacement="top">                    
                    <label nz-checkbox formControlName="assicurativo" (ngModelChange)="onAssicurativo($event)">Assicurativo</label> 
                </span>  
            </div>

            <div class="col-md-4">                
                <span nzTooltipTitle="Se selezionato, all'acquisto del prodotto nello shop, verrà richiesto all'utente di inserire una propria immagine formato fototessera." nz-tooltip nzTooltipPlacement="top">                    
                    <label nz-checkbox formControlName="fototessera">Fototessera</label> 
                </span>  
            </div>
        </div>

        <div class="row mt-3" *ngIf="formProdotti.get('assicurativo')?.value">
            <div class="col">                                                    

                <nz-upload [(nzFileList)]="pdfList" [nzShowButton]="pdfList.length <= 0" [nzBeforeUpload]="onPDFUpload" nzAccept="application/pdf" [nzRemove]="onRemovePDF" class="w-100">
                    <button nzSize="large" nz-button type="button" class="bottone mt-3" style="width: 100%">
                        <span class="align-middle" nz-icon nzType="upload"></span>
                        Carica PDF Assicurazione
                    </button>
                </nz-upload>

                <div class="error-messages">
                    <ng-container *ngFor="let error of error_messages.pdf_assicurazione">                                                                    
                        <nz-alert nzType="error" [nzMessage]="error.message" nzShowIcon nzCloseable *ngIf="formProdotti.get('pdf_assicurazione')?.hasError(error.type)"></nz-alert>
                    </ng-container>
                </div>
            </div>
        </div>   

        <div class="row mt-3">
            <div class="col-md-4">
                <span nzTooltipTitle="Se selezionato, all'acquisto del prodotto nello shop, verrà controllato che il metodo di pagamento inserito sia il bonifico, altrimenti non sarà possibile acquistarlo!" nz-tooltip nzTooltipPlacement="top">                    
                    <label nz-checkbox formControlName="bonifico">Acquistabile con bonifico</label> 
                </span>  
            </div>
            <div class="col-md-4">
                <span nzTooltipTitle="Se selezionato, all'acquisto del prodotto nello shop, verrà controllato che il metodo di pagamento inserito sia carta di credito, altrimenti non sarà possibile acquistarlo!" nz-tooltip nzTooltipPlacement="top">                    
                    <label nz-checkbox formControlName="carta">Acquistabile con carta</label> 
                </span>  
            </div>
            <div class="col-md-4">
                <span nzTooltipTitle="Se selezionato, all'acquisto del prodotto nello shop, verrà controllato che il metodo di pagamento inserito sia satispay, altrimenti non sarà possibile acquistarlo!" nz-tooltip nzTooltipPlacement="top">                    
                    <label nz-checkbox formControlName="satispay">Acquistabile con satispay</label> 
                </span>  
            </div>
        </div>
                
    </form>
    <nz-divider></nz-divider>
</mat-dialog-content>
<mat-dialog-actions>    
    <button nz-button nzSize="large" class="me-2" type="button" (click)="onClose()">Annulla</button>            
    
    <span nzTooltipTitle="Per creare un prodotto, inserire tutti i campi con l'asterisco" nz-tooltip nzTooltipPlacement="top" *ngIf="!formProdotti.valid">
        <button nz-button nzType="primary" nzSize="large" [disabled]="true">Crea Prodotto</button>
    </span>  
    <button nz-button nzType="primary" nzSize="large" *ngIf="formProdotti.valid" form="formProdotti">Crea Prodotto</button>        
</mat-dialog-actions>