<mat-dialog-content>
    <div class="title-section mb-3" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle style="cursor: move;">
        <h5 class="title">Invio ricevuta via mail</h5>
    </div>
    <nz-divider></nz-divider>    
    <div class="body mt-3" *ngIf="step == 0 && clienti[0]?.email">
        <div class="row">
            <div class="col">
                <p><strong>Stai per inviare l'email contenente la ricevuta dell'ordine a:</strong></p>
                <ul>
                    <li *ngFor="let cliente of clienti">{{cliente.cognome}} {{cliente.nome}} - {{cliente.email}}</li>
                </ul>
            </div>            
        </div>        
    </div>
    <div class="body mt-3" *ngIf="step == 1 || !clienti[0]?.email">
        <div class="row">
            <div class="col">
                <p><strong>{{ clienti[0]!.email ? "Inserisci un'email per inviare la ricevuta!" : "Il cliente associato all'ordine, non ha un'email associata. Inserisci un'email per inviare la ricevuta!"}}</strong></p>
                <form [formGroup]="formRicevuta">       
                    <nz-form-item class="flex-column mb-2">
                        <nz-form-label nzRequired class="text-start">Email</nz-form-label>
                        <nz-form-control>
                            <input nz-input formControlName="email" nzSize="large" required>
                        </nz-form-control>
                    </nz-form-item>
                    <div class="error-messages">
                        <ng-container *ngFor="let error of error_messages.email">
                            <nz-alert nzType="error" [nzMessage]="error.message" nzShowIcon nzCloseable *ngIf="formRicevuta.get('email')?.hasError(error.type) && (formRicevuta.get('email')?.dirty || formRicevuta.get('email')?.touched)"></nz-alert>
                        </ng-container>
                    </div>
                </form>
            </div>            
        </div>        
    </div>
    <nz-divider></nz-divider>
</mat-dialog-content>
<mat-dialog-actions>
    <button type="button" nz-button nzSize="large" (click)="onClose()" class="me-2">Annulla</button>
    <button type="button" nz-button nzSize="large" nzType="primary" (click)="onEmail(step)" class="me-2" *ngIf="clienti[0]?.email">{{step == 0 ? 'Inserisci email' : 'Torna indietro'}}</button>
    <button type="button" nz-button nzSize="large" nzType="primary" (click)="onRicevuta()" [disabled]="step == 1 && !formRicevuta.valid">Invia ricevuta</button>    
</mat-dialog-actions>