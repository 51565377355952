<mat-dialog-content>
    <div class="title-section mb-3" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle style="cursor: move;">
        <h5 class="title">Esportazione movimenti</h5>
    </div>
    <nz-divider></nz-divider>
    <form [formGroup]="formEsportazione" (ngSubmit)="onSubmit()" id="formEsportazione">          
        <div class="row mt-3">
            <div class="col">                
                <nz-form-item class="flex-column mb-2">
                    <nz-form-label class="text-start" nzRequired>Range di tempo</nz-form-label>
                    <nz-form-control>
                        <nz-range-picker formControlName="data" class="w-100" nzSize="large" [nzFormat]="'dd/MM/yyyy'"></nz-range-picker>                
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <nz-form-item class="flex-column mb-2">
                    <nz-form-label class="text-start" [nzTooltipTitle]="'Se non inserito verranno mostrati i movimenti di tutti i tipi!'">Tipo</nz-form-label>
                    <nz-form-control>
                        <nz-select nzAllowClear formControlName="tipo" nzSize="large" class="w-100" >
                            <nz-option nzValue="Incasso" nzLabel="Incasso"></nz-option>
                            <nz-option nzValue="Pagamento" nzLabel="Pagamento"></nz-option>                    
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div class="col-md-6">
                <nz-form-item class="flex-column mb-2">
                    <nz-form-label class="text-start" [nzTooltipTitle]="'Se non inserito verranno mostrati i movimenti con qualsiasi metodo di pagamento!'">Metodo</nz-form-label>
                    <nz-form-control>
                        <nz-select nzAllowClear formControlName="metodo" nzSize="large" class="w-100">
                            <nz-option nzValue="Contanti" nzLabel="Contanti" nzDisabled></nz-option>
                            <nz-option nzValue="Satispay" nzLabel="SatisPay"></nz-option>
                            <nz-option nzValue="Bancomat" nzLabel="Bancomat"></nz-option>
                            <nz-option nzValue="Carta di credito" nzLabel="Carta di credito"></nz-option>
                            <nz-option nzValue="Bonifico" nzLabel="Bonifico"></nz-option>
                            <nz-option nzValue="MyBank" nzLabel="Bonifico istantaneo (MyBank)"></nz-option>
                            <nz-option nzValue="Coupon" nzLabel="Coupon"></nz-option>
                            <nz-option nzValue="Altro" nzLabel="Altro"></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>
        <div class="row"> 
            <div class="col">
                <nz-form-item class="flex-column mb-2">
                    <nz-form-label class="text-start" [nzTooltipTitle]="'Se non inserito verranno mostrati i movimenti di qualsiasi cliente!'">Cliente</nz-form-label>
                    <nz-form-control>
                        <nz-select nzShowSearch nzAllowClear formControlName="cliente" nzSize="large" class="w-100" (nzOnSearch)="onSeleziona($event)">
                            <nz-option *ngFor="let cliente of filteredClienti" [nzValue]="cliente" [nzLabel]="cliente.cognome + ' ' + cliente.nome + ' | ' + ( cliente.data_nascita | date: 'dd/MM/yyyy' )"></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>
    </form>
    <nz-divider></nz-divider>
</mat-dialog-content>
<mat-dialog-actions>
    <button type="button" nz-button nzSize="large" class="me-2" (click)="onClose()">Annulla</button>
    <button type="submit" nz-button nzType="primary" nzSize="large" form="formEsportazione" [disabled]="!formEsportazione.valid" >Esporta</button>
</mat-dialog-actions>