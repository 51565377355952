<mat-dialog-content>
    <div class="title-section mb-3" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle style="cursor: move;">
        <h5 class="title">Modifica prodotto</h5>
    </div>
    <nz-divider></nz-divider>
    <form [formGroup]="formProdotti" (ngSubmit)="onSubmit()" id="formProdotti">
        <div class="row">
            <div id="indirizzoObbl" class="form-text text-start">*campo obbligatorio</div>
        </div>             
        <div class="row mt-3">
            <div class="col-md-6">                
                <span nzTooltipTitle="Se selezionato, il prodotto sarà visibile ai clienti dello shop!" nz-tooltip nzTooltipPlacement="top">                                        
                    <label nz-checkbox formControlName="online">Vendibile nello shop</label> 
                </span>  
            </div>
            <div class="col-md-6">                
                <span nzTooltipTitle="Se selezionato, il prodotto deve essere acquistato dai clienti per aver accesso agli altri prodotti!" nz-tooltip nzTooltipPlacement="top">                    
                    <label nz-checkbox formControlName="obbligatorio">Prodotto obbligatorio</label> 
                </span>  
            </div>            
        
        </div>

        <div class="row mt-3">
            <div class="col-md-6">                
                <span nzTooltipTitle="Se selezionato, all'acquisto del prodotto, verrà richiesto di inserire il punto di ritiro del prodotto!" nz-tooltip nzTooltipPlacement="top">                    
                    <label nz-checkbox formControlName="informativo">Punti di ritiro</label> 
                </span>  
            </div>            

            <div class="col-md-6">                
                <span nzTooltipTitle="Se selezionato, all'acquisto del prodotto nello shop, verrà richiesto all'utente di inserire una propria immagine formato fototessera." nz-tooltip nzTooltipPlacement="top">                    
                    <label nz-checkbox formControlName="fototessera">Fototessera</label> 
                </span>  
            </div>
        </div>

        <div class="row mt-3">
            <div class="col-md-4">
                <span nzTooltipTitle="Se selezionato, all'acquisto del prodotto nello shop, verrà controllato che il metodo di pagamento inserito sia il bonifico, altrimenti non sarà possibile acquistarlo!" nz-tooltip nzTooltipPlacement="top">                    
                    <label nz-checkbox formControlName="bonifico">Acquistabile con bonifico</label> 
                </span>  
            </div>
            <div class="col-md-4">
                <span nzTooltipTitle="Se selezionato, all'acquisto del prodotto nello shop, verrà controllato che il metodo di pagamento inserito sia carta di credito, altrimenti non sarà possibile acquistarlo!" nz-tooltip nzTooltipPlacement="top">                    
                    <label nz-checkbox formControlName="carta">Acquistabile con carta</label> 
                </span>  
            </div>
            <div class="col-md-4">
                <span nzTooltipTitle="Se selezionato, all'acquisto del prodotto nello shop, verrà controllato che il metodo di pagamento inserito sia satispay, altrimenti non sarà possibile acquistarlo!" nz-tooltip nzTooltipPlacement="top">                    
                    <label nz-checkbox formControlName="satispay">Acquistabile con satispay</label> 
                </span>  
            </div>
        </div>
                
    </form>
    <nz-divider></nz-divider>
</mat-dialog-content>
<mat-dialog-actions>    
    <button nz-button nzSize="large" type="button" class="me-2" (click)="onClose()">Annulla</button>            
    
    <span nzTooltipTitle="Per modificare un prodotto, inserire tutti i campi con l'asterisco" nz-tooltip nzTooltipPlacement="top" *ngIf="!formProdotti.valid">
        <button nz-button nzType="primary" nzSize="large" [disabled]="true">Modifica Prodotto</button>
    </span>  
    <button nz-button nzType="primary" nzSize="large" *ngIf="formProdotti.valid" form="formProdotti">Modifica Prodotto</button>        
</mat-dialog-actions>