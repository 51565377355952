import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from '../../service/api.service'
import { Cliente } from 'src/app/shared/interfacce';
import { checkImage } from 'src/app/shared/controlli';
import { NzUploadFile } from 'ng-zorro-antd/upload';

@Component({
  selector: 'app-aggiungi-gruppo',
  templateUrl: './aggiungi-gruppo.component.html',
  styleUrls: ['./aggiungi-gruppo.component.css']
})
export class AggiungiGruppoComponent implements OnInit {

  formGruppo!: FormGroup;

  clienti: Cliente[] = [];
  filteredClienti: Cliente[] = []

  immagineList: NzUploadFile[] = [];
  immagineFile!: string;

  error_messages = {
    'nome': [
      { type: 'required', message: 'Inserire un nome per procedere con la creazione del gruppo' },
      { type: 'minlength', message: 'Inserire almeno 5 caratteri' }
    ],
    'cliente': [
      { type: 'required', message: 'Scegliere un cliente dalla lista per procedere con la creazione del gruppo' }
    ],
    'immagine': [
      { type: 'requiredImageType', message: "Inserire un'immagine di tipo JPG, PNG o JPEG" }
    ]
  }

  constructor(private apiService: ApiService, private dialogRef: MatDialogRef<AggiungiGruppoComponent>, @Inject(MAT_DIALOG_DATA) public data?: { cliente: Cliente }) { }

  ngOnInit(): void {

    // Creo il form con i controlli
    this.formGruppo = new FormGroup({
      nome: new FormControl(null, [Validators.required, Validators.minLength(5)]),
      descrizione: new FormControl(null),
      immagine: new FormControl(null, checkImage()),
      cliente: new FormControl(null, Validators.required)
    })

    // Recupero i clienti
    this.apiService.getClienti().subscribe({
      next: (data) => this.clienti = [...data],
      error: (err) => this.apiService.Error(err.error.message),
      complete: () => {

        this.filteredClienti = [...this.clienti];

        // Se è stato passato un cliente procedo
        if (this.data?.cliente) {

          // Aggiungo il cliente tra i clienti selezionati          
          this.formGruppo.patchValue({ cliente: [...this.clienti.filter(data => data.id == this.data?.cliente.id)][0] })

        }
      }
    })

  }

  onSeleziona(ricerca: any) {
    // Filtro i clienti per nome o cognome in base al testo inserito
    this.filteredClienti = this.clienti.filter(cliente => (cliente.nome.trim() + ' ' + cliente.cognome.trim()).toLocaleLowerCase().includes(ricerca.toLocaleLowerCase()) || (cliente.cognome.trim() + ' ' + cliente.nome.trim()).toLocaleLowerCase().includes(ricerca.toLocaleLowerCase())).slice(0, 10)
  }

  onImmagineUpload = (file: any): boolean => {

    // Controllo se il file inserito è una immagine
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';

    // Se è una immagine procedo
    if (isJpgOrPng) {

      // Aggiungo il file alla lista dei file al form
      this.immagineList = this.immagineList.concat(file);
      this.formGruppo.patchValue({ immagine: file.name });

      // Recupero il base64 del file caricato
      const reader = new FileReader();
      reader.onload = () => {
        this.immagineFile = reader.result as string;
      }
      reader.readAsDataURL(file);

      return false;
    } else {
      return false
    }
  };

  onRemoveImmagineUpload = (file: any): boolean => {
    this.formGruppo.patchValue({ immagine: '' });
    this.immagineList = [];
    return false;
  }

  onSubmit() {

    let gruppo = {
      id_cliente: this.formGruppo.get('cliente')?.value?.id,
      nome: this.formGruppo.get('nome')?.value,
      descrizione: this.formGruppo.get('descrizione')?.value,
      immagine: this.formGruppo.get('immagine')?.value || "",
      file_immagine: this.immagineFile || "",
    }

    // Aggiungo il gruppo
    this.apiService.addGruppo(gruppo).subscribe({
      next: (data) => this.apiService.setGruppi(),
      error: (err) => this.apiService.Error(err.error.message),
      complete: () => this.dialogRef.close()
    })

  }

  onClose() {
    this.dialogRef.close(true)
  }

}