import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/service/api.service';
import { Categoria, Prodotto } from 'src/app/shared/interfacce';
import { EliminaCategoriaComponent } from '../elimina-categoria/elimina-categoria.component';
import { ModificaCategoriaComponent } from '../modifica-categoria/modifica-categoria.component';

@Component({
  selector: 'app-dettagli-categoria',
  templateUrl: './dettagli-categoria.component.html',
  styleUrls: ['./dettagli-categoria.component.css']
})
export class DettagliCategoriaComponent implements OnInit {

  constructor(private dialog: MatDialog, private location: Location, private apiService: ApiService, private route: ActivatedRoute, private router: Router) { }

  id!: number;

  categoria?: Categoria;
  prodotti: Prodotto[] = [];

  carica: boolean = true;
  show_all: boolean = false;

  ngOnInit(): void {

    // Recupero l'id passato nell'url
    this.route.params.subscribe(params => {
      this.id = params['id'];
      this.getData();
    });
  }

  getData() {

    // Recupero la categoria
    this.apiService.getCategoria(this.id).subscribe({
      next: (data) => this.categoria = data,
      error: (err) => this.router.navigate(['/categorie']),
      complete: () => {
        // Recupero i prodotti della categoria
        this.apiService.getProdottiCategoria(this.id).subscribe({
          next: (data) => this.prodotti = [...data],
          error: (err) => this.apiService.Error(err.error.message),
          complete: () => {
            this.carica = false
          }
        })
      }
    })

  }

  onIndietro() {
    this.router.navigate(['/categorie']);
  }

  onModifica() {
    this.dialog.closeAll();    
    let dialogRef = this.dialog.open(ModificaCategoriaComponent, { data: { id: this.id }, autoFocus: false, width: '1000px' });
    dialogRef?.afterClosed().subscribe(() => {
      this.getData();
    })
  }

  onElimina() {
    this.dialog.closeAll();
    this.dialog.open(EliminaCategoriaComponent, { data: { id: this.id }, autoFocus: false, width: '750px' });
  }
}
