import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  constructor(private router: Router, private titleService: Title) { }

  ngOnInit(): void {

    // Ciclo per ogni route che viene aperta nel browser
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd), map(() => {

      let route: ActivatedRoute = this.router.routerState.root;
      let routeTitle = '';

      while (route!.firstChild) {
        route = route.firstChild;
      }

      // Recupero il valore title nel routing
      if (route.snapshot.data['title']) {
        routeTitle = route!.snapshot.data['title'];
      }

      return routeTitle;
    })).subscribe((title: string) => {
      
      if (title)
        this.titleService.setTitle(`Gestionale SciClub Bussoleno - ${title}`);
            
    });
  }

}
