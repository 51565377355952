<mat-dialog-content>
    <div class="title-section mb-3" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle style="cursor: move;">
        <h5 class="title">Modifica una promozione</h5>
    </div>
    <nz-divider></nz-divider>
    <form [formGroup]="formPromozione" (ngSubmit)="onSubmit()" id="formPromozione">
        <div class="row">
            <div id="indirizzoObbl" class="form-text text-start">*campo obbligatorio</div>
        </div>        
        <div class="row mt-3">
            <div class="col">
                <nz-form-item class="flex-column mb-2">
                    <nz-form-label nzRequired class="text-start">Nome</nz-form-label>
                    <nz-form-control>
                        <input nz-input formControlName="nome" nzSize="large" required>  
                    </nz-form-control>
                </nz-form-item>
                <div class="error-messages">
                    <ng-container *ngFor="let error of error_messages.nome">                                                                    
                        <nz-alert nzType="error" [nzMessage]="error.message" nzShowIcon nzCloseable *ngIf="formPromozione.get('nome')?.hasError(error.type) && (formPromozione.get('nome')?.dirty || formPromozione.get('nome')?.touched)"></nz-alert>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <nz-form-item class="flex-column mb-2">
                    <nz-form-label class="text-start">Descrizione</nz-form-label>
                    <nz-form-control>
                        <textarea nz-input [nzAutosize]="{ minRows: 3, maxRows: 6 }" formControlName="descrizione" nzSize="large"></textarea>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>
        <div class="row">
            <div class="col">   
                <nz-form-item class="flex-column mb-3">
                    <nz-form-label nzRequired class="text-start">Prodotto</nz-form-label>
                    <nz-form-control>
                        <nz-select nzShowSearch nzAllowClear formControlName="prodotto" nzSize="large" class="w-100">
                            <nz-option *ngFor="let prodotto of prodotti" [nzValue]="prodotto.id" [nzLabel]="prodotto.nome"></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
                <div class="error-messages">
                    <ng-container *ngFor="let error of error_messages.prodotto">                                                                    
                        <nz-alert nzType="error" [nzMessage]="error.message" nzShowIcon nzCloseable *ngIf="formPromozione.get('prodotto')?.hasError(error.type) && (formPromozione.get('prodotto')?.dirty || formPromozione.get('prodotto')?.touched)"></nz-alert>
                    </ng-container>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6">    
                <nz-form-item class="flex-column mb-2">
                    <nz-form-label class="text-start">Età min</nz-form-label>
                    <nz-form-control>
                        <nz-input-number nz-input formControlName="eta_min" nzSize="large" class="w-100" [nzMin]="0" [nzMax]="119"></nz-input-number>
                    </nz-form-control>
                </nz-form-item>
                <div class="error-messages">
                    <ng-container *ngFor="let error of error_messages.eta_min">                                                                    
                        <nz-alert nzType="error" [nzMessage]="error.message" nzShowIcon nzCloseable *ngIf="formPromozione.get('eta_min')?.hasError(error.type) && (formPromozione.get('eta_min')?.dirty || formPromozione.get('eta_min')?.touched)"></nz-alert>
                    </ng-container>
                </div>
            </div>
            <div class="col-md-6">   
                <nz-form-item class="flex-column mb-2">
                    <nz-form-label class="text-start">Età max</nz-form-label>
                    <nz-form-control>
                        <nz-input-number nz-input formControlName="eta_max" nzSize="large" class="w-100" [nzMin]="1" [nzMax]="120"></nz-input-number>
                    </nz-form-control>
                </nz-form-item>
                <div class="error-messages">
                    <ng-container *ngFor="let error of error_messages.eta_max">
                        <nz-alert nzType="error" [nzMessage]="error.message" nzShowIcon nzCloseable *ngIf="formPromozione.get('eta_max')?.hasError(error.type) && (formPromozione.get('eta_max')?.dirty || formPromozione.get('eta_max')?.touched)"></nz-alert>
                    </ng-container>
                </div>
            </div>
        </div>
        
        <div class="row">
            <div class="col text-center">                
                <span nzTooltipTitle="Se selezionato, per il prodotto scelto lo sconto di prezzo sarà in percentuale!" nz-tooltip nzTooltipPlacement="top">                    
                    <label nz-checkbox formControlName="percentuale">Percentuale</label> 
                </span>  
            </div>
        </div>                
        <div class="row">
            <div class="col">  
                <nz-form-item class="flex-column mb-3">
                    <nz-form-label nzRequired class="text-start">Sconto</nz-form-label>
                    <nz-form-control>
                        <nz-input-group [nzSuffix]="suffixTemplateInfo" nzSize="large" >
                            <input nz-input formControlName="sconto" required>
                        </nz-input-group>                
                        <ng-template #suffixTemplateInfo>                    
                            <span nz-icon [nzType]="!formPromozione.get('percentuale')?.value ? 'euro-circle' : 'percentage'" nzTheme="outline"></span>                    
                        </ng-template>
                    </nz-form-control>
                </nz-form-item>
                <div class="error-messages">
                    <ng-container *ngFor="let error of error_messages.sconto">                                                                    
                        <nz-alert nzType="error" [nzMessage]="error.message" nzShowIcon nzCloseable *ngIf="formPromozione.get('sconto')?.hasError(error.type) && (formPromozione.get('sconto')?.dirty || formPromozione.get('sconto')?.touched)"></nz-alert>
                    </ng-container>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col text-center">                
                <span nzTooltipTitle="Se selezionato, puoi scegliere per quali giorni il prodotto sarà valido. Altrimenti seleziona una data di inizio e fine validità!" nz-tooltip nzTooltipPlacement="top">                    
                    <label nz-checkbox formControlName="giornaliero" (ngModelChange)="onGiornalieri($event)">Giornaliero</label> 
                </span>  
            </div>
        </div>   

        <div class="row" *ngIf="!formPromozione.get('giornaliero')?.value">
            <div class="col-md-6">       
                <nz-form-item class="flex-column mb-2">
                    <nz-form-label nzRequired class="text-start">Inizio validità</nz-form-label>
                    <nz-form-control>
                        <nz-date-picker formControlName="inizio_validita" [nzMode]="'date'" nzSize="large" [nzFormat]="'dd/MM/yyyy'" class="w-100"></nz-date-picker>
                    </nz-form-control>
                </nz-form-item>
                <div class="error-messages">
                    <ng-container *ngFor="let error of error_messages.inizio_validita">                                                                    
                        <nz-alert nzType="error" [nzMessage]="error.message" nzShowIcon nzCloseable *ngIf="formPromozione.get('inizio_validita')?.hasError(error.type) && (formPromozione.get('inizio_validita')?.dirty || formPromozione.get('inizio_validita')?.touched)"></nz-alert>
                    </ng-container>
                </div>
            </div>
            <div class="col-md-6">                                
                <nz-form-item class="flex-column mb-2">
                    <nz-form-label nzRequired class="text-start">Fine validità</nz-form-label>
                    <nz-form-control>
                        <nz-date-picker formControlName="fine_validita" [nzMode]="'date'" nzSize="large" [nzFormat]="'dd/MM/yyyy'" class="w-100"></nz-date-picker>
                    </nz-form-control>
                </nz-form-item>
                <div class="error-messages">
                    <ng-container *ngFor="let error of error_messages.fine_validita">                                                                    
                        <nz-alert nzType="error" [nzMessage]="error.message" nzShowIcon nzCloseable *ngIf="formPromozione.get('fine_validita')?.hasError(error.type) && (formPromozione.get('fine_validita')?.dirty || formPromozione.get('fine_validita')?.touched)"></nz-alert>
                    </ng-container>
                </div>
            </div>
        </div>

        <div class="row" *ngIf="formPromozione.get('giornaliero')?.value">
            <div class="col">     
                <nz-form-item class="flex-column mb-2">
                    <nz-form-label nzRequired class="text-start">Giorni</nz-form-label>
                    <nz-form-control>
                        <nz-select nzShowSearch nzAllowClear formControlName="giorni" nzSize="large" nzMode="multiple" class="w-100">
                            <nz-option *ngFor="let giorno of giorni" [nzValue]="giorno" [nzLabel]="giorno"></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
                <div class="error-messages">
                    <ng-container *ngFor="let error of error_messages.giorni">                                                                    
                        <nz-alert nzType="error" [nzMessage]="error.message" nzShowIcon nzCloseable *ngIf="formPromozione.get('giorni')?.hasError(error.type) && (formPromozione.get('giorni')?.dirty || formPromozione.get('giorni')?.touched)"></nz-alert>
                    </ng-container>
                </div>
            </div>
        </div>

        <div class="row mb-3">
            <div class="col-md-6">       
                <nz-form-item class="flex-column mb-2">
                    <nz-form-label class="text-start">Inizio orario</nz-form-label>
                    <nz-form-control>
                        <nz-time-picker formControlName="inizio_orario" nzFormat="HH:mm" nzSize="large" class="w-100" nzNowText="Ora"></nz-time-picker>                                
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div class="col-md-6">   
                <nz-form-item class="flex-column mb-2">
                    <nz-form-label class="text-start">Fine orario</nz-form-label>
                    <nz-form-control>
                        <nz-time-picker formControlName="fine_orario" nzFormat="HH:mm" nzSize="large" class="w-100" nzNowText="Ora"></nz-time-picker>                
                    </nz-form-control>
                </nz-form-item>                             
            </div>            
        </div>                
    </form>
    <nz-divider></nz-divider>
</mat-dialog-content>
<mat-dialog-actions>    
    <button nz-button nzSize="large" class="me-2" type="button" (click)="onClose()">Annulla</button>

    <span nzTooltipTitle="Per modificare una promozione, inserire tutti i campi con l'asterisco" nz-tooltip nzTooltipPlacement="top" *ngIf="!formPromozione.valid">
        <button nz-button nzType="primary" nzSize="large" [disabled]="true" form="formPromozione">Modifica Promozione</button>    
    </span>  
    <button nz-button nzType="primary" nzSize="large" form="formPromozione" *ngIf="formPromozione.valid">Modifica Promozione</button>        
</mat-dialog-actions>