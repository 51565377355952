<div class="p-3">
    <ng-template #promozioniTitle>
        <div class="row p-md-3 p-1">
            <div class="col-lg-1 mt-2">
                <button nz-button nzSize="large" class="w-100" type="button" (click)="onIndietro()"><i
                        style="font-size: 20px;" class="bi bi-arrow-left"></i></button>
            </div>
            <div class="col-lg-8 mt-2">
                <div class="row">
                    <div class="col-md-6">
                        <input nz-input placeholder="Cerca Nome" nzSize="large" [(ngModel)]="nomeFilter" />
                    </div>
                    <div class="col-md-6 mt-md-0 mt-3">
                        <input nz-input placeholder="Cerca Prodotto" nzSize="large" [(ngModel)]="prodottoFilter" />
                    </div>
                </div>
            </div>
            <div class="col-lg-3 mt-2" style="text-align: right;">
                <button nz-button nzSize="large" class="w-100 crop" type="button" (click)="onAdd()">Crea una
                    promozione</button>
            </div>
        </div>
    </ng-template>
    <nz-card [nzTitle]="promozioniTitle">
        <div class="row p-md-3 p-1">
            <p class="text-muted d-flex justify-content-between small">
                <span>
                    <span class="text-muted text-decoration-none">
                        <i class="bi bi-info-circle"></i> Legenda:
                    </span>
                    <span class="ms-5">
                        <span class="me-3"><i class="bi bi-circle mx-2"></i>Promo attiva</span>
                        <span class="text-danger me-3"><i class="bi bi-circle-fill mx-2"></i>Promo scaduta</span>
                    </span>
                </span>
            </p>
            <nz-table #promozioniTable nzShowPagination *ngIf="!carica"
                [nzTotal]="(promozioni | searchPromozioniNome: nomeFilter | searchPromozioniProdotto: prodottoFilter).length"
                nzShowSizeChanger [nzPageSize]="10"
                [nzPageSizeOptions]="[5, 10, 25, 50, 100, 150, 200, 250, 300, 350, 400, 500, 750, 1000]"
                [nzData]="promozioni | searchPromozioniNome: nomeFilter | searchPromozioniProdotto: prodottoFilter" [nzBordered]="true" nzNoResult="Nessuna promozione trovata">
                <thead>
                    <tr>
                        <th [nzSortFn]="onOrdinamentoNome">Nome</th>
                        <th [nzSortFn]="onOrdinamentoProdotto" class="text-start">Prodotto</th>
                        <th [nzSortFn]="onOrdinamentoPrezzo" class="text-end">Valore</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let promozione of promozioniTable.data | searchPromozioniNome: nomeFilter | searchPromozioniProdotto: prodottoFilter"
                        [routerLink]="['/promozioni/', promozione.id]" class="cursor-pointer"
                        [ngClass]="{'bg-danger':  dateService.checkValidita(promozione.fine_validita) > 0 && promozione.giornaliero == false}">
                        <td>{{promozione.nome}} </td>
                        <td class="text-start">{{promozione.nome_prodotto}}</td>
                        <td class="text-end">- {{promozione.percentuale ? (promozione.sconto + ' %' ) : (promozione.sconto | currency: 'EUR' )}}</td>
                    </tr>
                </tbody>
            </nz-table>

            <app-caricamento *ngIf="carica" class="d-flex align-items-center justify-content-center"></app-caricamento>
        </div>
    </nz-card>
</div>