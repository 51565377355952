import { Injectable } from '@angular/core';
import { AuthUser, FasceProdotti } from '../shared/interfacce';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }

  TOKEN_KEY = 'sciclub-gestionale-auth-token';
  USER_KEY = 'sciclub-gestionale-auth-data';  

  FASCE_KEY = 'sciclub-gestionale-fasce-prodotto';  

  user!: AuthUser | undefined;
  subjectUser = new BehaviorSubject(this.user);  

  public getToken(): string {    
    return localStorage.getItem(this.TOKEN_KEY) || '';
  }

  public getUser(): AuthUser {
    let user = localStorage.getItem(this.USER_KEY);           
    return user ? JSON.parse(user) : '';
  }  

  public getFasceProdotto(): FasceProdotti[] {
    let fasce = sessionStorage.getItem(this.FASCE_KEY);           
    return fasce ? JSON.parse(fasce) : [];
  }  

  setToken(token: string): void {
    localStorage.removeItem(this.TOKEN_KEY);
    localStorage.setItem(this.TOKEN_KEY, token);
  }  
  
  setUser(utente: {user?: string, tipo?: string, id?: number, tema?: boolean}): void {
    localStorage.removeItem(this.USER_KEY);
    localStorage.setItem(this.USER_KEY, JSON.stringify(utente));    

    this.user = this.getUser();    
    this.subjectUser.next({ ...this.user });    
  }  

  setFasceProdotto(fasce: FasceProdotti[]) {
    sessionStorage.removeItem(this.FASCE_KEY);
    sessionStorage.setItem(this.FASCE_KEY, JSON.stringify(fasce));
  }

  deleteToken() {
    localStorage.removeItem(this.TOKEN_KEY);
  }

  deleteUser() {
    localStorage.removeItem(this.USER_KEY);
  }

  deleteFasceProdotto() {
    sessionStorage.removeItem(this.FASCE_KEY);
  }

  clearStorage(): void {
    
    localStorage.removeItem(this.TOKEN_KEY);
    localStorage.removeItem(this.USER_KEY);
    sessionStorage.removeItem(this.FASCE_KEY);

    this.user = this.getUser();    
    this.subjectUser.next(undefined);
  }
}