<div class="p-3">
    <ng-template #movimentiTitle>
        <div class="row p-md-3 p-1">
            <div class="col-1">
                <button nz-button nzSize="large" class="w-100" type="button" (click)="onIndietro()"><i
                        style="font-size: 20px;" class="bi bi-arrow-left"></i></button>
            </div>
            <div class="col my-auto">
                <h3 style="white-space: break-spaces;" class="text-muted mb-0">Movimento #{{movimento?.id}}</h3>
            </div>
            <div class="col-lg-4 my-auto">
                <button nz-button nz-dropdown [nzDropdownMenu]="menuMovimento" type="button" class="w-100 crop"
                    nzSize="large"> Gestisci il movimento <span nz-icon nzType="down"></span> </button>
                <nz-dropdown-menu #menuMovimento="nzDropdownMenu">
                    <ul nz-menu>
                        <li nz-menu-item class="p-3" (click)="onModifica()">Modifica il movimento <i
                                class="ms-2 bi bi-pencil-square"></i></li>
                        <li nz-menu-item class="p-3" (click)="onElimina()">Elimina il movimento <i
                                class="ms-2 bi bi-trash3"></i></li>
                    </ul>
                </nz-dropdown-menu>
            </div>
        </div>
    </ng-template>
    <nz-card [nzTitle]="movimentiTitle" class="mt-5 mb-5">
        <div class="row p-md-3 p-1">
            <div class="col">
                <nz-breadcrumb>
                    <nz-breadcrumb-item>
                        <a routerLink="/home">
                            <span nz-icon nzType="home"></span>
                        </a>
                    </nz-breadcrumb-item>
                    <nz-breadcrumb-item>
                        <a routerLink="/movimenti/altri-metodi">
                            <span>Movimenti altri metodi di pagamento</span>
                        </a>
                    </nz-breadcrumb-item>
                    <nz-breadcrumb-item>Dettaglio movimento</nz-breadcrumb-item>
                </nz-breadcrumb>
            </div>
        </div>
        <div class="row p-md-3 p-1">
            <div class="col">
                <ng-template #datiHeader>
                    <div class="header">
                        <h5 class="d-inline text-white">Dati</h5>
                    </div>
                </ng-template>

                <nz-list nzBordered [nzHeader]="datiHeader" class="lista">
                    <nz-list-item>
                        <span nz-typography><span class="text-muted">N°</span></span> {{movimento?.id}}
                    </nz-list-item>
                    <nz-list-item>
                        <span nz-typography><span class="text-muted">Tipo:</span></span> {{movimento?.tipo}}
                    </nz-list-item>
                    <nz-list-item>
                        <span nz-typography><span class="text-muted">Metodo:</span></span> {{movimento?.metodo}}
                    </nz-list-item>
                    <nz-list-item>
                        <span nz-typography><span class="text-muted">Descrizione:</span></span>
                        {{movimento?.descrizione}}
                    </nz-list-item>
                    <nz-list-item *ngIf="movimento?.id_cliente" [routerLink]="'/clienti/' + movimento?.id_cliente" style="cursor: pointer;">
                        <span nz-typography><span class="text-muted">Cliente:</span></span>
                        {{movimento?.cliente}}
                    </nz-list-item>
                    <nz-list-item *ngIf="movimento?.id_ordine" [routerLink]="'/storico/' + movimento?.id_ordine" style="cursor: pointer;">
                        <span nz-typography><span class="text-muted">Ordine:</span></span>
                        {{movimento?.id_ordine}}
                    </nz-list-item>
                    <nz-list-item>
                        <span nz-typography><span class="text-muted">Pagato:</span></span> {{movimento?.pagato |
                        currency: 'EUR'}}
                    </nz-list-item>
                    <nz-list-item>
                        <span nz-typography><span class="text-muted">Residuo:</span></span> {{movimento?.residuo |
                        currency: 'EUR'}}
                    </nz-list-item>
                    <nz-list-item>
                        <span nz-typography><span class="text-muted">Totale:</span></span> {{movimento?.totale |
                        currency: 'EUR'}}
                    </nz-list-item>
                    <nz-list-item>
                        <span nz-typography><span class="text-muted">Data:</span></span> {{movimento?.data_creazione |
                        date: 'dd/MM/yyyy'}}
                    </nz-list-item>
                </nz-list>
            </div>
        </div>
    </nz-card>
</div>