import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/service/api.service';
import { Configurazione } from 'src/app/shared/interfacce';
import { ModificaConfigurazioneComponent } from '../modifica-configurazione/modifica-configurazione.component';
import { EliminaConfigurazioneComponent } from '../elimina-configurazione/elimina-configurazione.component';

@Component({
  selector: 'app-dettaglio-configurazione',
  templateUrl: './dettaglio-configurazione.component.html',
  styleUrls: ['./dettaglio-configurazione.component.css']
})
export class DettaglioConfigurazioneComponent implements OnInit {

  constructor(private apiService: ApiService, private route: ActivatedRoute, private dialog: MatDialog, private router: Router) { }

  configurazione?: Configurazione;

  carica: boolean = true;

  ngOnInit(): void {
    // Recupero l'id della configurazione passato nell'url
    this.route.params.subscribe(params => {
      const id = params['id'];
      this.getData(id);
    });
  }

  getData(id: number) {

    // Recupero i dati della configurazione
    this.apiService.getConfigurazione(id).subscribe({
      next: (data) => this.configurazione = data,
      error: (err) => this.router.navigate(['/configurazioni']),
      complete: () => this.carica = false
    })

  }

  onIndietro() {
    this.router.navigate(['/configurazioni'])
  }

  onModifica() {
    this.dialog.closeAll();
    var dialogRef = this.dialog.open(ModificaConfigurazioneComponent, { data: { id: this.configurazione!.id }, autoFocus: false, width: '1000px' });
    dialogRef?.afterClosed().subscribe(() => {
      this.getData(this.configurazione!.id);
    })
  }

  onElimina() {
    this.dialog.closeAll();
    this.dialog.open(EliminaConfigurazioneComponent, { data: { id: this.configurazione!.id }, autoFocus: false, width: '750px' })
  }
}
