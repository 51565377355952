import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from 'src/app/service/api.service';
import { Cliente } from 'src/app/shared/interfacce';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-report-movimenti',
  templateUrl: './report-movimenti.component.html',
  styleUrls: ['./report-movimenti.component.css']
})
export class ReportMovimentiComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<ReportMovimentiComponent>, @Inject(MAT_DIALOG_DATA) public data: { tipo: string }, private apiService: ApiService, private datePipe: DatePipe) { }

  loading: boolean = false;

  formReport!: FormGroup;

  clienti: Cliente[] = [];
  filteredClienti: Cliente[] = []

  ngOnInit(): void {

    // Creo il form con i controlli
    this.formReport = new FormGroup({
      data: new FormControl(null, Validators.required),
      tipo: new FormControl(null),
      metodo: new FormControl(null),
      cliente: new FormControl(null)
    })

    // Recupero i clienti
    this.apiService.getClienti().subscribe({
      next: (data) => this.clienti = [...data],
      error: (err) => this.apiService.Error(err.error.message),
      complete: () => {
        this.filteredClienti = [...this.clienti.slice(0, 10)]

        if (this.data.tipo == 'cassa') {
          this.formReport.get('metodo')?.disable()
          this.formReport.patchValue({ metodo: 'Contanti' });
        }
      }
    })

  }

  onSeleziona(ricerca: any) {
    this.filteredClienti = this.clienti.filter(cliente => (cliente.nome.trim() + ' ' + cliente.cognome.trim()).toLocaleLowerCase().includes(ricerca.toLocaleLowerCase()) || (cliente.cognome.trim() + ' ' + cliente.nome.trim()).toLocaleLowerCase().includes(ricerca.toLocaleLowerCase())).slice(0, 10)
  }

  onSubmit() {
    let movimento = {
      data_inizio: this.datePipe.transform((this.formReport.get('data')?.value ? this.formReport.get('data')?.value[0] : this.formReport.get('data')?.value), 'yyyy-MM-dd'),
      data_fine: this.datePipe.transform(this.formReport.get('data')?.value ? this.formReport.get('data')?.value[1] : this.formReport.get('data')?.value, 'yyyy-MM-dd'),
      tipo: this.formReport.get('tipo')?.value || '',
      metodo: this.formReport.get('metodo')?.value || '',
      cliente: this.formReport.get('cliente')?.value ? this.formReport.get('cliente')?.value.id : ''
    }

    this.loading = true;

    // Recupero il report dei movimenti secondo i filtri
    this.apiService.getReportMovimenti(this.data.tipo, movimento.data_inizio, movimento.data_fine, movimento.tipo, movimento.metodo, movimento.cliente).subscribe({
      next: (data) => {
        this.loading = false;

        // Mando il pdf al browser che la scarica in automatico        
        let downloadURL = window.URL.createObjectURL(data);
        saveAs(downloadURL, 'ricevuta_movimenti.pdf');
      },
      error: (err) => {
        if (err.status == 300) { this.apiService.Error("Nessun movimento trovato!"); } else { this.apiService.Error(err.error.message); this.loading = false }
      },
      complete: () => this.dialogRef.close()
    })

  }

  onClose() {
    this.dialogRef.close();
  }

}