<div class="p-3">    
    <ng-template #codiciScontoTitle>
      <div class="row p-md-3 p-1">
        <div class="col-lg-1 mt-2">
          <button nz-button nzSize="large" class="w-100" type="button" (click)="onIndietro()"><i style="font-size: 20px;" class="bi bi-arrow-left"></i></button>
        </div>
        <div class="col-lg-8 mt-2">
          <div class="row">
            <div class="col-md-6">
              <input nz-input placeholder="Cerca Cliente" nzSize="large" [(ngModel)]="clienteFilter" />
            </div>
            <div class="col-md-6 mt-md-0 mt-3">
              <input nz-input placeholder="Cerca Codice" nzSize="large" [(ngModel)]="codiceFilter" />
            </div>
          </div>
        </div>
        <div class="col-lg-3 mt-2" style="text-align: right;">        
            <button nz-button nzSize="large" class="w-100 crop" type="button" (click)="onAdd()">Crea codice sconto</button>
        </div>
      </div>
    </ng-template>
    <nz-card [nzTitle]="codiciScontoTitle">
      <div class="row  p-md-3 p-1">
        <p class="text-muted d-flex justify-content-between small">
          <span>
              <span class="text-muted text-decoration-none">
                  <i class="bi bi-info-circle"></i> Legenda:
              </span>
              <span class="ms-5">
                  <span class="text-muted me-3"><i class="bi bi-circle mx-2"></i>Codice Sconto attivo</span>
                  <span class="text-danger me-3"><i class="bi bi-circle-fill mx-2"></i>Codice Sconto non attivo</span>
              </span>
            </span>
        </p>
        <nz-table *ngIf="!carica" #codiciScontoTable nzShowPagination
          [nzTotal]="(codici_sconto | searchCodiciScontoCliente: clienteFilter | searchCodiciScontoCodice: codiceFilter).length"
          nzShowSizeChanger [nzPageSize]="10"
          [nzPageSizeOptions]="[5, 10, 25, 50, 100, 150, 200, 250, 300, 350, 400, 500, 750, 1000]"
          [nzData]="codici_sconto | searchCodiciScontoCliente: clienteFilter | searchCodiciScontoCodice: codiceFilter" [nzBordered]="true" nzNoResult="Nessun codice sconto trovato">
          <thead>
            <tr>
              <th [nzSortFn]="onOrdinamentoCliente">Cliente</th>
              <th [nzSortFn]="onOrdinamentoCodice">Codice</th>
              <th [nzSortFn]="onOrdinamentoDescrizione">Descrizione</th>
              <th [nzSortFn]="onOrdinamentoValore" class="text-center">Valore</th>
              <th [nzSortFn]="onOrdinamentoData" class="text-end">Fine Validità</th>              
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let codice_sconto of codiciScontoTable.data | searchCodiciScontoCliente: clienteFilter | searchCodiciScontoCodice: codiceFilter" [routerLink]="['/codici-sconto/', codice_sconto.id]" class="cursor-pointer" [ngClass]="{'bg-danger': codice_sconto.attivo == false}">
              <td>{{codice_sconto.cliente}}</td>
              <td>{{codice_sconto.codice}}</td>
              <td>{{codice_sconto.descrizione || '-'}}</td>
              <td class="text-center">{{codice_sconto.valore | currency: 'EUR'}}</td>              
              <td class="text-end">{{codice_sconto.fine_validita | date: 'dd/MM/yyyy'}}</td>
            </tr>
          </tbody>
        </nz-table>
    
        <app-caricamento *ngIf="carica" class="d-flex align-items-center justify-content-center"></app-caricamento>
      </div>
    </nz-card>
  </div>