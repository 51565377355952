import { Component } from '@angular/core';

@Component({
  selector: 'app-prima-nota',
  templateUrl: './prima-nota.component.html',
  styleUrls: ['./prima-nota.component.css']
})
export class PrimaNotaComponent {

}
