import { Component, OnInit } from '@angular/core';
import { ApiService } from '../service/api.service';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { StorageService } from '../service/storage.service';

@Component({
  selector: 'app-auth',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class AuthComponent implements OnInit {

  constructor(private apiService: ApiService, private storageService: StorageService, private router: Router) { }

  credenzialiSbagliate: boolean = false;
  carica: boolean = false;
  hide: boolean = false;

  formLogin!: FormGroup;

  error_messages = {
    'username': [
      { type: 'required', message: 'Inserire un username' },
    ],
    'password': [
      { type: 'required', message: 'Inserire una password' }
    ]
  }

  ngOnInit(): void {

    // Creo il form con i controlli
    this.formLogin = new FormGroup({
      username: new FormControl(null, Validators.required),
      password: new FormControl(null, Validators.required)
    })

    // Recupero l'utente se già presente
    const user = this.storageService.getUser();
    if (user.id) {

      // Controllo se l'utente recuperato è autorizzato
      this.apiService.isAuthorized().subscribe({
        next: (data) => this.router.navigate(['/home']),
        error: (err) => console.error(err)
      });

    }

  }

  onLogin() {

    // Mando le credenziali all'api
    this.apiService.login(this.formLogin.get('username')?.value, this.formLogin.get('password')?.value).subscribe({
      next: (res) => {
        // Salco i dati dell'utente nello storage
        this.storageService.setUser({ user: res.user, tipo: res.tipo, id: res.id })
        this.storageService.setToken(res.accessToken);
      },
      error: async (err) => {
        // Ritardo l'errore per mostrare un caricamento
        await this.delay(200);
        if (err.status == 403) {          
          this.credenzialiSbagliate = true;
          this.carica = false;
        } else {
        }
      },
      complete: async () => {
        // Ritardo l'accesso per mostrare un caricamento
        await this.delay(200);
        this.router.navigate(['']);
        this.carica = false;
      }
    });
  }


  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
}