<mat-dialog-content>
    <div class="title-section mb-3" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle style="cursor: move;">
        <h5 class="title">Modifica il cliente</h5>
    </div>
    <nz-divider></nz-divider>
    <form [formGroup]="formCliente" (ngSubmit)="onSubmit()" id="formCliente">
        <div class="row">
            <div id="indirizzoObbl" class="form-text text-start">* campo obbligatorio</div>
        </div>        
        <div class="row mt-3">
            <div class="col-md-6">                
                <nz-form-item class="flex-column mb-2">
                    <nz-form-label nzRequired nzFor="nome" class="text-start">Nome</nz-form-label>
                    <nz-form-control>
                        <input nz-input formControlName="nome" id="nome" nzSize="large" >
                    </nz-form-control>
                </nz-form-item> 
                <div class="error-messages">
                    <ng-container *ngFor="let error of error_messages.nome">                                                                    
                        <nz-alert nzType="error" [nzMessage]="error.message" nzShowIcon nzCloseable *ngIf="formCliente.get('nome')?.hasError(error.type) && (formCliente.get('nome')?.dirty || formCliente.get('nome')?.touched)"></nz-alert>
                    </ng-container>
                </div>
            </div>
            <div class="col-md-6">
                <nz-form-item class="flex-column mb-2">
                    <nz-form-label nzRequired nzFor="cognome" class="text-start">Cognome</nz-form-label>
                    <nz-form-control>
                        <input nz-input formControlName="cognome" id="cognome"  nzSize="large">
                    </nz-form-control>
                </nz-form-item>
                <div class="error-messages">
                    <ng-container *ngFor="let error of error_messages.cognome">                                                                    
                        <nz-alert nzType="error" [nzMessage]="error.message" nzShowIcon nzCloseable *ngIf="formCliente.get('cognome')?.hasError(error.type) && (formCliente.get('cognome')?.dirty || formCliente.get('cognome')?.touched)"></nz-alert>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">  
                <nz-form-item class="flex-column mb-2">
                    <nz-form-label nzRequired class="text-start">Data di nascita</nz-form-label>
                    <nz-form-control>
                        <nz-date-picker formControlName="data_nascita" [nzMode]="'date'" nzSize="large"[nzFormat]="'dd/MM/yyyy'" class="w-100"></nz-date-picker>
                    </nz-form-control>
                </nz-form-item>
                <div class="error-messages">
                    <ng-container *ngFor="let error of error_messages.data_nascita">                                                                    
                        <nz-alert nzType="error" [nzMessage]="error.message" nzShowIcon nzCloseable *ngIf="formCliente.get('data_nascita')?.hasError(error.type) && (formCliente.get('data_nascita')?.dirty || formCliente.get('data_nascita')?.touched)"></nz-alert>
                    </ng-container>
                </div>
            </div>
            <div class="col-md-6">
                <nz-form-item class="flex-column mb-2">
                    <nz-form-label class="text-start">Comune di nascita</nz-form-label>
                    <nz-form-control>
                        <input nz-input formControlName="comune_nascita" nzSize="large">                
                    </nz-form-control>
                </nz-form-item>                       
            </div>   
        </div>  
        <div class="row">
            <div class="col-md-6">
                <nz-form-item class="flex-column mb-2">
                    <nz-form-label class="text-start">Provincia di nascita</nz-form-label>
                    <nz-form-control>
                        <input nz-input formControlName="provincia_nascita" nzSize="large">
                    </nz-form-control>
                </nz-form-item>
            </div>   
            <div class="col-md-6">
                <nz-form-item class="flex-column mb-2">
                    <nz-form-label class="text-start">Nazione di nascita</nz-form-label>
                    <nz-form-control>
                        <input nz-input formControlName="nazione_nascita" nzSize="large">
                    </nz-form-control>
                </nz-form-item>
            </div>   
        </div>               
        <div class="row">   
            <div class="col-md-6">
                <nz-form-item class="flex-column mb-2">
                    <nz-form-label class="text-start">Indirizzo di residenza</nz-form-label>
                    <nz-form-control>
                        <input nz-input formControlName="indirizzo" nzSize="large" >
                    </nz-form-control>
                </nz-form-item>                
            </div>           
            <div class="col-md-6">
                <nz-form-item class="flex-column mb-2">
                    <nz-form-label class="text-start">Comune di residenza</nz-form-label>
                    <nz-form-control>
                        <input nz-input formControlName="comune" nzSize="large">
                    </nz-form-control>
                </nz-form-item>                
            </div>            
        </div>  
        <div class="row">
            <div class="col-md-6">
                <nz-form-item class="flex-column mb-2">
                    <nz-form-label class="text-start">Provincia di residenza</nz-form-label>
                    <nz-form-control>
                        <input nz-input formControlName="provincia" nzSize="large">
                    </nz-form-control>
                </nz-form-item>               
            </div>  
            <div class="col-md-6">
                <nz-form-item class="flex-column mb-2">
                    <nz-form-label class="text-start">Nazione di residenza</nz-form-label>
                    <nz-form-control>
                        <input nz-input formControlName="nazione" nzSize="large">
                    </nz-form-control>
                </nz-form-item>
            </div>                      
        </div>          
        <div class="row">
            <div class="col-md-6">
                <nz-form-item class="flex-column mb-2">
                    <nz-form-label class="text-start">Codice fiscale</nz-form-label>
                    <nz-form-control>
                        <input nz-input formControlName="codice_fiscale" nzSize="large" maxlength="16" max="16">
                    </nz-form-control>
                </nz-form-item>
                <div class="error-messages">
                    <ng-container *ngFor="let error of error_messages.codice_fiscale">                                                                    
                        <nz-alert nzType="error" [nzMessage]="error.message" nzShowIcon nzCloseable *ngIf="formCliente.get('codice_fiscale')?.hasError(error.type) && (formCliente.get('codice_fiscale')?.dirty || formCliente.get('codice_fiscale')?.touched)"></nz-alert>
                    </ng-container>
                </div>
            </div>
            <div class="col-md-6">
                <nz-form-item class="flex-column mb-2">
                    <nz-form-label class="text-start">Telefono</nz-form-label>
                    <nz-form-control>
                        <input nz-input formControlName="telefono" nzSize="large" type="tel" maxlength="10">
                    </nz-form-control>
                </nz-form-item>
                <div class="error-messages">
                    <ng-container *ngFor="let error of error_messages.telefono">                                                                    
                        <nz-alert nzType="error" [nzMessage]="error.message" nzShowIcon nzCloseable *ngIf="formCliente.get('telefono')?.hasError(error.type) && (formCliente.get('telefono')?.dirty || formCliente.get('telefono')?.touched)"></nz-alert>
                    </ng-container>
                </div>
            </div>            
        </div>         
        <div class="row">
            <div class="col-md-6">
                <nz-form-item class="flex-column mb-3">
                    <nz-form-label class="text-start">Password</nz-form-label>
                    <nz-form-control>
                        <nz-input-group [nzSuffix]="passwordTemplate" nzSize="large">
                            <input [type]="hide ? 'text' : 'password'" nz-input formControlName="password" />
                        </nz-input-group>
                        <ng-template #passwordTemplate>
                            <span nz-icon [nzType]="hide ? 'eye-invisible' : 'eye'" (click)="hide = !hide"></span>
                        </ng-template>
                    </nz-form-control>
                </nz-form-item>
                <div class="error-messages">
                    <ng-container *ngFor="let error of error_messages.password">                                                                    
                        <nz-alert nzType="error" [nzMessage]="error.message" nzShowIcon nzCloseable *ngIf="formCliente.get('password')?.hasError(error.type) && (formCliente.get('password')?.dirty || formCliente.get('password')?.touched)"></nz-alert>
                    </ng-container>
                </div>
            </div>
            <div class="col-md-6">
                <nz-form-item class="flex-column mb-3">
                    <nz-form-label class="text-start">Email</nz-form-label>
                    <nz-form-control>
                        <input nz-input formControlName="email" nzSize="large" type="email" (ngModelChange)="onEmail()">
                    </nz-form-control>
                </nz-form-item>
                <div class="error-messages">
                    <ng-container *ngFor="let error of error_messages.email">                                                                    
                        <nz-alert nzType="error" [nzMessage]="error.message" nzShowIcon nzCloseable *ngIf="formCliente.get('email')?.hasError(error.type) && (formCliente.get('email')?.dirty || formCliente.get('email')?.touched)"></nz-alert>
                    </ng-container>
                </div>
            </div>  
        </div>
        <div class="row">
            <div class="col-md-6">
                <nz-form-item class="flex-column mb-3">
                    <nz-form-label class="text-start">Card SkiPassOpen</nz-form-label>
                    <nz-form-control>                        
                        <input nz-input nzSize="large" formControlName="skipass_open" />                                                
                    </nz-form-control>
                </nz-form-item>                
            </div>
            <div class="col-md-6">
                <nz-form-item class="flex-column mb-3">
                    <nz-form-label class="text-start">Card Vialattea</nz-form-label>
                    <nz-form-control>
                        <input nz-input formControlName="skicard_vialattea" nzSize="large">
                    </nz-form-control>
                </nz-form-item>                
            </div>  
        </div>     
        <div class="row">
            <div class="col">

                <nz-upload [(nzFileList)]="immagineList" [nzShowButton]="immagineList.length <= 0" [nzBeforeUpload]="onImmagineUpload" nzAccept="image/*" [nzRemove]="onRemoveImmagineUpload">
                    <button nzSize="large" nz-button type="button" class="bottone mt-3" style="width: 100%">
                        <span class="align-middle" nz-icon nzType="upload"></span>
                        Carica fototessera
                    </button>
                </nz-upload>

                <div class="error-messages">
                    <ng-container *ngFor="let error of error_messages.immagine">                                                                    
                        <nz-alert nzType="error" [nzMessage]="error.message" nzShowIcon nzCloseable *ngIf="formCliente.get('immagine')?.hasError(error.type)"></nz-alert>
                    </ng-container>
                </div>     
            </div>
        </div>        
        <div class="row mt-3 mb-3">
            <div class="col text-center">
                <span nzTooltipTitle="Se selezionato il cliente, avrà la possibilità di ricevere comunicazioni dalla Newsletter" nz-tooltip nzTooltipPlacement="top">
                    <label nz-checkbox formControlName="newsletter">Newsletter</label> 
                </span>                  
            </div>
        </div>        
    </form>
    <nz-divider></nz-divider>
</mat-dialog-content>
<mat-dialog-actions>    
    <button nz-button nzSize="large" type="button" (click)="onClose()" class="me-2">Annulla</button>
    
    <span nzTooltipTitle="Per modificare un cliente, inserire tutti i campi con l'asterisco" nz-tooltip nzTooltipPlacement="top" *ngIf="!formCliente.valid">
        <button nz-button nzType="primary" nzSize="large" [disabled]="true">Modifica Cliente</button>
    </span>  
    <button nz-button nzType="primary" nzSize="large" *ngIf="formCliente.valid" form="formCliente">Modifica Cliente</button>        
</mat-dialog-actions>