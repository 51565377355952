<div class="p-3">
    <ng-template #categorieTitle>
        <div class="row p-md-3 p-1">
            <div class="col-lg-1 mt-2">
                <button nz-button nzSize="large" class="w-100" type="button" (click)="onIndietro()"><i
                        style="font-size: 20px;" class="bi bi-arrow-left"></i></button>
            </div>
            <div class="col-lg-8 mt-2">
                <div class="row">
                    <div class="col-md-6">
                        <input nz-input placeholder="Cerca Username" nzSize="large" [(ngModel)]="usernameFilter" />
                    </div>
                    <div class="col-md-6 mt-md-0 mt-3">
                        <input nz-input placeholder="Cerca Tipo" nzSize="large" [(ngModel)]="tipoFilter" />
                    </div>
                </div>
            </div>
            <div class="col-lg-3 mt-2" style="text-align: right;">
                <button nz-button nzSize="large" class="w-100 crop" type="button" (click)="onAdd()">Crea un utente</button>
            </div>
        </div>
    </ng-template>
    <nz-card [nzTitle]="categorieTitle">
        <div class="row p-md-3 p-1">
            <nz-table #utentiTable nzShowPagination *ngIf="!carica"
                [nzTotal]="(utenti | searchUtentiUsername: usernameFilter | searchUtentiTipo: tipoFilter).length"
                nzShowSizeChanger [nzPageSize]="10"
                [nzPageSizeOptions]="[5, 10, 25, 50, 100, 150, 200, 250, 300, 350, 400, 500, 750, 1000]"
                [nzData]="utenti | searchUtentiUsername: usernameFilter | searchUtentiTipo: tipoFilter" [nzBordered]="true" nzNoResult="Nessun utente trovato">
                <thead>
                    <tr>
                        <th [nzSortFn]="onOrdinamentoUsername" nzColumnKey="nome">Nome</th>
                        <th [nzSortFn]="onOrdinamentoTipo" nzColumnKey="tipo" class="text-center">Tipo</th>                                        
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let utente of utentiTable.data | searchUtentiUsername: usernameFilter | searchUtentiTipo: tipoFilter" [routerLink]="['/utenti/', utente.id]" class="cursor-pointer">
                        <td>{{utente.username}} </td>
                        <td class="text-center">{{utente.tipo}}</td>                                        
                    </tr>
                </tbody>
            </nz-table>
    
            <app-caricamento *ngIf="carica" class="d-flex align-items-center justify-content-center"></app-caricamento>
        </div>
    </nz-card>
</div>