<mat-dialog-content>
    <div class="title-section mb-3" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle style="cursor: move;">
        <h5 class="title">Invio ricevuta via mail</h5>
    </div>
    <nz-divider></nz-divider>    
    <div class="body mt-3" *ngIf="step == 0">
        <div class="row">
            <div class="col">
                <p><strong>Stai per inviare l'email contenente la ricevuta dell'ordine a:</strong></p>
                <ul>
                    <li *ngFor="let cliente of clienti">{{cliente.cognome}} {{cliente.nome}} - {{cliente.email}}</li>
                </ul>
            </div>            
        </div>        
    </div>
    <div class="body mt-3" *ngIf="step == 1">
        <p><strong>Seleziona a quale indirizzo email inviare la ricevuta:</strong></p>
        <form [formGroup]="formRicevuta">
            <div class="row">
                <div class="col">     
                    <nz-form-item class="flex-column mb-2">
                        <nz-form-label nzRequired class="text-start">Indirizzi email clienti</nz-form-label>
                        <nz-form-control>
                            <nz-select nzShowSearch nzAllowClear formControlName="email" nzSize="large" class="w-100">                        
                                <nz-option *ngFor="let cliente of clienti" [nzValue]="cliente.email" [nzLabel]="cliente.email"></nz-option>                    
                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>
                    <div class="error-messages">
                        <ng-container *ngFor="let error of error_messages.email">                            
                            <nz-alert nzType="error" [nzMessage]="error.message" nzShowIcon nzCloseable *ngIf="formRicevuta.get('email')?.hasError(error.type) && (formRicevuta.get('email')?.dirty || formRicevuta.get('email')?.touched)"></nz-alert>
                        </ng-container>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="body mt-3" *ngIf="step == 2">
        <div class="row">
            <div class="col">
                <p><strong>Inserisci un'email per inviare la ricevuta!</strong></p>
                <form [formGroup]="formRicevutaEmail">                    
                    <nz-form-item class="flex-column mb-2">
                        <nz-form-label nzRequired class="text-start">Email</nz-form-label>
                        <nz-form-control>
                            <input nz-input formControlName="email" nzSize="large" required>
                        </nz-form-control>
                    </nz-form-item>                    
                    <div class="error-messages">
                        <ng-container *ngFor="let error of error_messages.email">
                            <nz-alert nzType="error" [nzMessage]="error.message" nzShowIcon nzCloseable *ngIf="formRicevutaEmail.get('email')?.hasError(error.type) && (formRicevutaEmail.get('email')?.dirty || formRicevutaEmail.get('email')?.touched)"></nz-alert>
                        </ng-container>
                    </div>
                </form>
            </div>            
        </div>        
    </div>
    <nz-divider></nz-divider>
</mat-dialog-content>
<mat-dialog-actions>
    <button type="button" nz-button nzSize="large" class="me-2" (click)="onClose()">Annulla</button>
    <button type="button" nz-button nzSize="large" nzType="primary" (click)="onStep(2)" class="me-2" *ngIf="step == 0">Inserisci email</button>        
    <span nzTooltipTitle="Se premuto, verrà inviata la ricevuta, suddivisa per cliente." nz-tooltip nzTooltipPlacement="top" *ngIf="step == 0">
        <button type="button" nz-button nzType="primary" nzSize="large" class="me-2" (click)="onRicevuta()">Invia Ricevuta singola</button>
    </span>
    <span nzTooltipTitle="Se premuto, potrai scegliere a quale email inviare la ricevuta completa." nz-tooltip nzTooltipPlacement="top" *ngIf="step == 0">
        <button type="button" nz-button nzType="primary" nzSize="large" (click)="onStep(1)">Invia Ricevuta completa</button>
    </span>
    <button type="button" nz-button nzType="primary" nzSize="large" class="me-2" *ngIf="step != 0" (click)="onStep(0)">Torna indietro</button>
    <button type="button" nz-button nzSize="large" nzType="primary" (click)="onRicevutaEmail()" [disabled]="!formRicevutaEmail.valid" class="me-2" *ngIf="step == 2">Invia ricevuta</button>
    <span nzTooltipTitle="Selezionare un'email se si vuole inviare la ricevuta, ad un singolo indirizzo!" nz-tooltip nzTooltipPlacement="top" *ngIf="step == 1">
        <button type="button" nz-button nzType="primary" nzSize="large" (click)="onRicevutaMultipla()" [disabled]="!formRicevuta.valid">Invia Ricevuta completa</button>
    </span>
</mat-dialog-actions>