import { DatePipe, Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ApiService } from '../service/api.service';
import { Cliente } from '../shared/interfacce';
import { AggiungiClienteComponent } from './aggiungi-cliente/aggiungi-cliente.component';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-clienti',
  templateUrl: './clienti.component.html',
  styleUrls: ['./clienti.component.css']
})

export class ClientiComponent implements OnInit {

  clienti: Cliente[] = [];

  totale: number = 0;
  pageIndex: number = 1;
  pageSize: number = 10;

  carica: boolean = true;
  loading: boolean = true;

  clienteFilter: any = '';
  emailFilter: any = ''

  filtri: { pageIndex: number, pageSize: number, cliente: string, email: string } = { pageIndex: 1, pageSize: 10, cliente: '', email: '' };

  constructor(private apiService: ApiService, private datePipe: DatePipe, private dialog: MatDialog, private location: Location) { }

  ngOnInit(): void {

    this.loading = true;
    this.apiService.setClienti(this.pageIndex, 10, 'id', 'ascend', this.clienteFilter, this.emailFilter, false);
    this.apiService.clienteChanged.subscribe({
      next: (data) => {
        this.clienti = data;
        this.totale = this.apiService.TotaleClienti;
        this.carica = false;
        this.loading = false;
      },
      error: (err) => this.apiService.Error(err.error.message)
    })

  }

  onLoadData(pageIndex: number, pageSize: number, sort_key: string, sort_type: string): void {

    this.loading = true
    this.apiService.setClienti(pageIndex, pageSize, sort_key, sort_type, this.clienteFilter, this.emailFilter, false);

    this.pageIndex = pageIndex;
    this.pageSize = pageSize;

    this.filtri = { pageIndex: pageIndex, pageSize: pageSize, cliente: this.clienteFilter, email: this.emailFilter };

  }

  onParamsChange(params: NzTableQueryParams): void {
    const { pageSize, pageIndex, sort } = params;

    const sorting = sort.filter(value => value.value)
    if (sorting.length <= 0)
      sorting.push({ key: 'id', value: 'ascend' })

    if (pageIndex == this.filtri.pageIndex && pageSize == this.filtri.pageSize) {
      if (this.clienteFilter != this.filtri.cliente || this.emailFilter != this.filtri.email)
        this.onLoadData(1, pageSize, sorting[0].key, sorting[0].value || '');
      else
        this.onLoadData(pageIndex, pageSize, sorting[0].key, sorting[0].value || '');
    } else {
      if (this.clienteFilter != this.filtri.cliente || this.emailFilter != this.filtri.email)
        this.onLoadData(1, pageSize, sorting[0].key, sorting[0].value || '');
      else
        this.onLoadData(pageIndex, pageSize, sorting[0].key, sorting[0].value || '');
    }
  }

  onIndietro() {
    this.location.back();
  }

  onOrdinamento() {
    return 1 - 1;
  }

  onExport() {

    let result = this.clienti.filter(cliente => (cliente?.email || '')?.toLowerCase().indexOf(this.emailFilter.toLowerCase()) >= 0) // Filtro i clienti per email      
      .filter(cliente => (cliente.cognome.trim() + ' ' + cliente.nome.trim()).toString().trim().toLocaleLowerCase().indexOf(this.clienteFilter.toLocaleLowerCase()) !== -1 || (cliente.nome.trim() + ' ' + cliente.cognome.trim()).toString().trim().toLocaleLowerCase().indexOf(this.clienteFilter.toLocaleLowerCase()) !== -1) // Filtro i clienti per nome o cognome 
      .map(cliente => ({
        'Id': cliente.id,        
        'Cognome': cliente.cognome,
        'Nome': cliente.nome,
        'Data di nascita': this.datePipe.transform(cliente.data_nascita, 'dd/MM/yyyy'),
        'Email': cliente.email,
        'Codice fiscale': cliente.codice_fiscale,
        'Comune di nascita': cliente.comune_nascita,
        'Provincia di nascita': cliente.provincia_nascita,
        'Stato di nascita': cliente.nazione_nascita,
        'Indirizzo di residenza': cliente.indirizzo,
        'Comune di residenza': cliente.comune,
        'Provincia di residenza': cliente.provincia,
        'Stato di residenza': cliente.nazione,
        'Telefono': cliente.telefono,
        'Newsletter': cliente.newsletter ? 'Si' : 'No',
        'Data creazione': this.datePipe.transform(cliente.data_creazione, 'dd/MM/yyyy'),
        'Data aggiornamento': this.datePipe.transform(cliente.data_aggiornamento, 'dd/MM/yyyy')         
      })); // Aggiungo l'oggetto con i dati del cliente

    const workBook = XLSX.utils.book_new();
    const workSheet = XLSX.utils.json_to_sheet(result);

    workSheet["!cols"] = [{ wch: 10 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 30 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 10 }, { wch: 20 }, { wch: 20 }];

    XLSX.utils.book_append_sheet(workBook, workSheet, 'Cliente');
    XLSX.writeFile(workBook, 'clienti.xlsx');

  }

  onAdd() {
    this.dialog.closeAll();
    this.dialog.open(AggiungiClienteComponent, { data: { clienti: this.clienti }, autoFocus: false, width: '1000px' })
  }

}
