import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/service/api.service';
import { Tesseramento } from 'src/app/shared/interfacce';
import { EliminaTesseramentoComponent } from '../elimina-tesseramento/elimina-tesseramento.component';
import { ModificaTesseramentoComponent } from '../modifica-tesseramento/modifica-tesseramento.component';

@Component({
  selector: 'app-dettagli-tesseramento',
  templateUrl: './dettagli-tesseramento.component.html',
  styleUrls: ['./dettagli-tesseramento.component.css']
})
export class DettagliTesseramentoComponent implements OnInit {

  constructor(private apiService: ApiService, private route: ActivatedRoute, private dialog: MatDialog, private router: Router) { }
  
  tesseramento?: Tesseramento;

  ngOnInit(): void {

    // Recupero l'id del tesseramento dall'url
    this.route.params.subscribe(params => {
      const id = params['id'];
      this.getData(id);
    });

  }

  getData(id: number) {

    // Recupero il tesseramento in base all'id
    this.apiService.getTesseramento(id).subscribe({
      next: (data) => this.tesseramento = data,
      error: (err) => this.router.navigate(['/tesseramenti'])
    })

  }

  onElimina() {
    this.dialog.closeAll();
    this.dialog.open(EliminaTesseramentoComponent, { data: { id: this.tesseramento!.id }, autoFocus: false, width: '750px' })
  }

  onIndietro() {
    this.router.navigate(['/tesseramenti'])
  }

  onModifica() {
    this.dialog.closeAll();
    var dialogRef = this.dialog.open(ModificaTesseramentoComponent, { data: { id: this.tesseramento!.id }, autoFocus: false, width: '1000px' })
    dialogRef?.afterClosed().subscribe(() => {
      this.getData(this.tesseramento!.id);
    })
  }

}