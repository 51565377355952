<mat-dialog-content>
    <div class="title-section mb-3" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle style="cursor: move;">
        <h5 class="title">Modifica il gruppo</h5>
    </div>
    <nz-divider></nz-divider>    
        <form [formGroup]="formGruppo" (ngSubmit)="onSubmit()" id="formGruppo">
            <div class="row">
                <div id="indirizzoObbl" class="form-text text-start">* campo obbligatorio</div>
            </div>
            <div class="row mt-3">
                <div class="col">
                    <nz-form-item class="flex-column mb-2">
                        <nz-form-label nzRequired class="text-start">Nome</nz-form-label>
                        <nz-form-control>
                            <input nz-input formControlName="nome" nzSize="large" />
                        </nz-form-control>
                    </nz-form-item>
                    <div class="error-messages">
                        <ng-container *ngFor="let error of error_messages.nome">                            
                            <nz-alert nzType="error" [nzMessage]="error.message" nzShowIcon nzCloseable *ngIf="formGruppo.get('nome')?.hasError(error.type) && (formGruppo.get('nome')?.dirty || formGruppo.get('nome')?.touched)"></nz-alert>
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <nz-form-item class="flex-column mb-2">
                        <nz-form-label class="text-start">Descrizione</nz-form-label>
                        <nz-form-control>
                            <textarea [nzAutosize]="{ minRows: 3, maxRows: 6 }" nz-input formControlName="descrizione" nzSize="large" ></textarea>
                        </nz-form-control>
                    </nz-form-item>    
                </div>
            </div>

            <div class="row mt-3 mb-3">
                <div class="col">
                    <nz-upload [(nzFileList)]="immagineList" [nzShowButton]="immagineList.length <= 0" [nzBeforeUpload]="onImmagineUpload" nzAccept="image/*" [nzRemove]="onRemoveImmagineUpload">
                        <button nzSize="large" nz-button type="button" class="bottone mt-3" style="width: 100%">
                            <span class="align-middle" nz-icon nzType="upload"></span>
                            Carica immagine gruppo
                        </button>
                    </nz-upload>

                    <div class="error-messages">
                        <ng-container *ngFor="let error of error_messages.immagine">                            
                            <nz-alert nzType="error" [nzMessage]="error.message" nzShowIcon nzCloseable *ngIf="formGruppo.get('immagine')?.hasError(error.type)"></nz-alert>
                        </ng-container>
                    </div>
                </div>
            </div>
        </form>

        <ng-template #membroHeader>
            <div class="header" nzTooltipTitle="Cerca e seleziona un cliente già presente oppure creane uno nuovo!" nz-tooltip nzTooltipPlacement="top">
                <h5 class="d-inline text-white">Aggiungi un membro</h5>
            </div>
        </ng-template>

        <nz-list nzBordered [nzHeader]="membroHeader" class="lista mt-3">
            <nz-list-item class="w-100">
                <form [formGroup]="formRicerca" class="w-100">
                    <div class="row">
                        <div class="col">                            
                            <nz-form-item class="flex-column mb-2">
                                <nz-form-label nzRequired class="text-start">Ricerca cliente</nz-form-label>
                                <nz-form-control>
                                    <nz-select nzShowSearch nzAllowClear formControlName="cliente" nzSize="large" class="w-100" (nzOnSearch)="onSeleziona($event)" (ngModelChange)="onCliente($event)">
                                        <nz-option *ngFor="let cliente of filteredClienti" [nzValue]="cliente" [nzLabel]="cliente.cognome + ' ' + cliente.nome + ' | ' + (cliente.data_nascita | date: 'dd MMM yyyy')"></nz-option>
                                    </nz-select> 
                                </nz-form-control>
                            </nz-form-item>   
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-md-6 mt-3">
                            <span nzTooltipTitle="Per aggiungere un membro, selezionare un cliente nel campo di ricerca, se già presente creane uno nuovo!" nz-tooltip nzTooltipPlacement="top" *ngIf="!formRicerca.get('cliente')?.value">
                                <button nz-button nzType="primary" nzSize="large" class="w-100" [disabled]="true">Aggiungi Membro</button>
                            </span>
                            <button type="button" nz-button nzType="primary" nzSize="large" (click)="onAdd()" class="w-100" *ngIf="formRicerca.get('cliente')?.value">Aggiungi Membro</button>
                        </div>
                        <div class="col-md-6 mt-3">
                            <button nz-button nzType="primary" type="button" nzSize="large" class="w-100" (click)="onClienti()">Nuovo cliente</button>
                        </div>
                    </div>
                </form>
            </nz-list-item>
        </nz-list>
        <div class="row mt-3 mb-3">
            <div class="col">

                <ng-template #membriHeader>
                    <div class="header" nzTooltipTitle="Gestisci i tuoi membri del gruppo, eliminandoli tramite il bottone in nero!" nz-tooltip nzTooltipPlacement="top">
                        <h5 class="d-inline text-white">Membri gruppo</h5>
                        <span class="badge bg-white text-dark rounded-pill float-end mt-1">{{membri.length}}</span>
                    </div>
                </ng-template>

                <nz-list nzBordered [nzHeader]="membriHeader" class="lista">
                    <nz-list-item class="cursor-pointer" *ngFor="let membro of membri">
                        <span nz-typography><span *ngIf="membro.id != data.id_cliente" class="rimuovi" nz-popconfirm nzPopconfirmTitle="Sei sicuro di voler eliminare il membro dal gruppo? L'azione è irreversibile!." (nzOnConfirm)="onDelete(membro.id)"><i class="bi bi-dash-circle-fill me-2"></i></span> {{membro.nome}} {{membro.cognome}}</span>{{membro.email}}
                    </nz-list-item>
                    <nz-list-item *ngIf="membri.length <= 0">
                        <span nz-typography>Nessun membro presente</span>
                    </nz-list-item>
                </nz-list>
            </div>
        </div>    
    <nz-divider></nz-divider>
</mat-dialog-content>
<mat-dialog-actions>
    <button type="button" nz-button nzSize="large" class="me-2" (click)="onClose()">Annulla</button>

    <span nzTooltipTitle="Per modificare un gruppo, inserire tutti i campi con l'asterisco" nz-tooltip
        nzTooltipPlacement="top" *ngIf="!formGruppo.valid" >
        <button nz-button nzType="primary" nzSize="large" [disabled]="true">Modifica Gruppo</button>
    </span>
    <button form="formGruppo" nz-button nzType="primary" nzSize="large" *ngIf="formGruppo.valid">Modifica
        Gruppo</button>
</mat-dialog-actions>