import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/service/api.service';

@Component({
  selector: 'app-elimina-prodotto',
  templateUrl: './elimina-prodotto.component.html',
  styleUrls: ['./elimina-prodotto.component.css']
})
export class EliminaProdottoComponent {

  constructor(private dialogRef: MatDialogRef<EliminaProdottoComponent>, @Inject(MAT_DIALOG_DATA) public data: { id: number }, private apiService: ApiService, private router: Router) { }

  onClose() {
    this.dialogRef.close(true)
  }

  onElimina() {

    // Elimino il prodotto
    this.apiService.deleteProdotto(this.data.id).subscribe({
      next: (data) => this.dialogRef.close(true),
      error: (err) => this.apiService.Error(err.error.message),
      complete: () => this.router.navigate(['/prodotti'])
    })

  }

}