import { Component } from '@angular/core';

@Component({
  selector: 'app-caricamento',
  templateUrl: './caricamento.component.html',
  styleUrls: ['./caricamento.component.css']
})
export class CaricamentoComponent {

}
