<div class="p-3">    
    <ng-template #section1Title>
        <h5 class="m-0">Clienti e Gruppi</h5>
    </ng-template>                            
    <nz-collapse nzAccordion [nzExpandIconPosition]="'right'" class="w-100" [nzBordered]="true">
        <nz-collapse-panel [nzHeader]="section1Title" class="cursor-pointer" [nzActive]="true">
            <div class="row p-4">    
                <div class="col-lg-6">
                    <ng-template #clientiTitle>
                        <h5 class="m-0">Clienti <i class="bi bi-person"></i></h5>
                    </ng-template>
                    <nz-card class="card" [nzTitle]="clientiTitle">
                        <h6 class="card-subtitle mb-2 text-muted">Gestisci i clienti</h6>
                        <p class="card-text">Visualizza e modifica le informazioni relative ai clienti.</p>
                        <a nz-button routerLink="/clienti" nzType="primary" nzSize="large" class="mt-auto col-12">Vai ai Clienti</a>
                    </nz-card>                
                </div>    
    
                <div class="col-lg-6 mt-lg-0 mt-3">
                    <ng-template #gruppiTitle>
                        <h5 class="m-0">Gruppi <i class="bi bi-people"></i></h5>
                    </ng-template>
                    <nz-card class="card" [nzTitle]="gruppiTitle">
                        <h6 class="card-subtitle mb-2 text-muted">Gestisci i gruppi</h6>
                        <p class="card-text">Visualizza e gestisci i gruppi. Puoi crearne di nuovi o cancellare quelli esistenti.</p>
                        <a nz-button routerLink="/gruppi" nzType="primary" nzSize="large" class="mt-auto bottone col-12">Vai ai Gruppi</a>
                    </nz-card>
                </div>      
            </div>
        </nz-collapse-panel>
    </nz-collapse>    

    <nz-divider></nz-divider>

    <ng-template #section2Title>
        <h5 class="m-0">Ordini e Prodotti</h5>
    </ng-template>

    <nz-collapse nzAccordion [nzExpandIconPosition]="'right'" class="w-100" [nzBordered]="true">
        <nz-collapse-panel [nzHeader]="section2Title" class="cursor-pointer" [nzActive]="true">
            <div class="row p-4">
                <div class="col-lg-4">
                    <ng-template #categorieTitle>
                        <h5 class="m-0">Tabella Categorie <i class="bi bi-bookmarks-fill"></i></h5>
                    </ng-template>
                    <nz-card class="card" [nzTitle]="categorieTitle">
                        <h6 class="card-subtitle mb-2 text-muted">Gestisci le categorie</h6>
                        <p class="card-text">Visualizza e gestisci le categorie. Puoi crearne di nuove o cancellare quelle esistenti.</p>
                        <a nz-button routerLink="/categorie" nzType="primary" nzSize="large" class="mt-auto bottone col-12">Vai alle Categorie</a>
                    </nz-card>
                </div> 
    
                <div class="col-lg-4 mt-lg-0 mt-3">
                    <ng-template #prodottiTitle>
                        <h5 class="m-0">Tabella Prodotti <i class="bi bi-basket"></i></h5>
                    </ng-template>
                    <nz-card class="card" [nzTitle]="prodottiTitle">
                        <h6 class="card-subtitle mb-2 text-muted">Gestisci i prodotti</h6>
                        <p class="card-text">Visualizza e gestisci i prodotti. Puoi crearne di nuovi o cancellare quelli esistenti.</p>
                        <a nz-button routerLink="/prodotti" nzType="primary" nzSize="large" class="mt-auto bottone col-12">Vai ai Prodotti</a>
                    </nz-card>
                </div>              
    
                <div class="col-lg-4 mt-lg-0 mt-3">
                    <ng-template #promozioniTitle>
                        <h5 class="m-0">Tabella Promozioni <i class="bi bi-bag-check"></i></h5>
                    </ng-template>
                    <nz-card class="card" [nzTitle]="promozioniTitle">
                        <h6 class="card-subtitle mb-2 text-muted">Gestisci le promozioni</h6>
                        <p class="card-text">Visualizza e gestisci le promozioni. Puoi crearne di nuove o cancellare quelle esistenti.</p>
                        <a nz-button routerLink="/promozioni" nzType="primary" nzSize="large" class="mt-auto bottone col-12">Vai alle Promozioni</a>
                    </nz-card>
                </div> 
            </div>
            <div class="row p-4 pt-0">    
                
                <div class="col-lg-4">
                    <ng-template #codiciTitle>
                        <h5 class="m-0">Codici Sconto <i class="bi bi-tag-fill"></i></h5>
                    </ng-template>
                    <nz-card class="card" [nzTitle]="codiciTitle">
                        <h6 class="card-subtitle mb-2 text-muted">Gestisci i codici sconto</h6>
                        <p class="card-text">Visualizza e gestisci i codici sconto o creane uno nuovo. </p>
                        <a nz-button routerLink="/codici-sconto" nzType="primary" nzSize="large" class="mt-auto bottone col-12">Vai ai Codici Sconto</a>
                    </nz-card>
                </div> 
    
                <div class="col-lg-4 mt-lg-0 mt-3">
                    <ng-template #ordiniTitle>
                        <h5 class="m-0">Storico Ordini Avvenuti <i class="bi bi-receipt"></i></h5>
                    </ng-template>
                    <nz-card class="card" [nzTitle]="ordiniTitle">
                        <h6 class="card-subtitle mb-2 text-muted">Gestisci lo storico ordini</h6>
                        <p class="card-text">Visualizza e gestisci lo storico di tutti gli ordini effettuati o crea un nuovo ordine. </p>
                        <a nz-button routerLink="/storico" nzType="primary" nzSize="large" class="mt-auto bottone col-12">Vai allo Storico Ordini Avvenuti</a>
                    </nz-card>
                </div> 
        
                <div class="col-lg-4 mt-lg-0 mt-3">
                    <ng-template #tesseramentiTitle>
                        <h5 class="m-0">Storico Ordini in Dettaglio <i class="bi bi-wallet2"></i></h5>
                    </ng-template>
                    <nz-card class="card" [nzTitle]="tesseramentiTitle">
                        <h6 class="card-subtitle mb-2 text-muted">Gestisci i dettagli degli ordini</h6>
                        <p class="card-text">Visualizza e gestisci i Dettagli degli Ordini avvenuti o creane di nuovi.</p>
                        <a nz-button routerLink="/tesseramenti" nzType="primary" nzSize="large" class="mt-auto bottone col-12">Vai al Storico Oridni in Dettaglio</a>
                    </nz-card>
                </div>      
                
            </div>
        </nz-collapse-panel>
    </nz-collapse>

    <nz-divider></nz-divider>

    <ng-template #section3Title>
        <h5 class="m-0">Gestione</h5>
    </ng-template>
    <nz-collapse nzAccordion [nzExpandIconPosition]="'right'" class="w-100" [nzBordered]="true">
        <nz-collapse-panel [nzHeader]="section3Title" class="cursor-pointer" [nzActive]="true">
            <div class="row p-4">        
                <div class="col-lg-4">
                    <ng-template #tesseramentiSciClubTitle>
                        <h5 class="m-0">Tesseramenti Sci Club <i class="bi bi-card-checklist"></i></h5>
                    </ng-template>
                    <nz-card class="card" [nzTitle]="tesseramentiSciClubTitle">
                        <h6 class="card-subtitle mb-2 text-muted">Gestisci i tesseramenti effettuati allo Sci Club</h6>
                        <p class="card-text">Visualizza e gestisci lo storico di tutti i tesseramenti effettuati allo Sci Club e attivali. </p>
                        <a nz-button routerLink="/tesseramenti-sciclub" nzType="primary" nzSize="large" class="mt-auto bottone col-12">Vai ai Tesseramenti dello Sci Club</a>
                    </nz-card>
                </div> 
    
                <div class="col-lg-4 mt-lg-0 mt-3">
                    <ng-template #cardVialatteaTitle>
                        <h5 class="m-0">Card ViaLattea <i class="bi bi-card-checklist"></i></h5>
                    </ng-template>
                    <nz-card class="card" [nzTitle]="cardVialatteaTitle">
                        <h6 class="card-subtitle mb-2 text-muted">Gestisci le card ViaLattea acquistate</h6>
                        <p class="card-text">Visualizza e gestisci lo storico di tutte le skicard ViaLattea acquistate e attivale. </p>
                        <a nz-button routerLink="/card-vialattea" nzType="primary" nzSize="large" class="mt-auto bottone col-12">Vai alle Card Vialattea</a>
                    </nz-card>
                </div>      
    
                <div class="col-lg-4 mt-lg-0 mt-3">
                    <ng-template #cardSkipassOpenTitle>
                        <h5 class="m-0">Card SkiPassOpen <i class="bi bi-card-checklist"></i></h5>
                    </ng-template>
                    <nz-card class="card" [nzTitle]="cardSkipassOpenTitle">
                        <h6 class="card-subtitle mb-2 text-muted">Gestisci le card SkiPassOpen acquistate</h6>
                        <p class="card-text">Visualizza e gestisci lo storico di tutte le card SkiPassOpen acquistate e attivale. </p>
                        <a nz-button routerLink="/card-skiopen" nzType="primary" nzSize="large" class="mt-auto bottone col-12">Vai alle Card SkiPassOpen</a>
                    </nz-card>
                    
                </div> 
            </div>
            <div class="row p-4 pt-0">        
                <div class="col-lg-4">
                    <ng-template #giornalieriViaLatteaTitle>
                        <h5 class="m-0">Giornalieri ViaLattea <i class="bi bi-card-checklist"></i></h5>
                    </ng-template>
                    <nz-card class="card" [nzTitle]="giornalieriViaLatteaTitle">
                        <h6 class="card-subtitle mb-2 text-muted">Gestisci i giornalieri della ViaLattea</h6>
                        <p class="card-text">Visualizza e gestisci lo storico di tutti i giornalieri della ViaLattea acquistati e attivali. </p>
                        <a nz-button routerLink="/giornalieri-vialattea" nzType="primary" nzSize="large" class="mt-auto bottone col-12">Vai ai Giornalieri della Vialattea</a>
                    </nz-card>
                </div> 
    
                <div class="col-lg-4 mt-lg-0 mt-3">
                    <ng-template #assicurazioniTitle>
                        <h5 class="m-0">Assicurazioni <i class="bi bi-card-checklist"></i></h5>
                    </ng-template>
                    <nz-card class="card" [nzTitle]="assicurazioniTitle">
                        <h6 class="card-subtitle mb-2 text-muted">Gestisci le Assicurazioni acquistate</h6>
                        <p class="card-text">Visualizza e gestisci lo storico di tutte le Assicurazioni acquistate e attivale. </p>
                        <a nz-button routerLink="/assicurazioni" nzType="primary" nzSize="large" class="mt-auto bottone col-12">Vai alle Assicurazioni</a>
                    </nz-card>
                </div>      
    
                <div class="col-lg-4 mt-lg-0 mt-3">
                    <ng-template #newsletterTitle>
                        <h5 class="m-0">Newsletter <i class="bi bi-card-checklist"></i></h5>
                    </ng-template>
                    <nz-card class="card" [nzTitle]="newsletterTitle">
                        <h6 class="card-subtitle mb-2 text-muted">Gestisci i clienti con Newsletter</h6>
                        <p class="card-text">Visualizza e gestisci i clienti clienti che hanno accettato di ricevere la newsletter. </p>
                        <a nz-button routerLink="/clienti-newsletter" nzType="primary" nzSize="large" class="mt-auto bottone col-12">Vai alla Newsletter</a>
                    </nz-card>
                    
                </div> 
            </div>
            <div class="row p-4 pt-0">        
                <div class="col-lg-4">
                    <ng-template #movimentiTitle>
                        <h5 class="m-0">Prima Nota <i class="bi bi-credit-card-fill"></i></h5>
                    </ng-template>
                    <nz-card class="card" [nzTitle]="movimentiTitle">
                        <h6 class="card-subtitle mb-2 text-muted">Gestisci la prima nota</h6>
                        <p class="card-text">Visualizza lo storico di tutti i movimenti avvenuti o crea un nuovo movimento.</p>
                        <a nz-button routerLink="/movimenti" nzType="primary" nzSize="large" class="mt-auto bottone col-12">Vai ai Movimenti</a>
                    </nz-card>
                </div> 
    
                <div class="col-lg-4 mt-lg-0 mt-3">
                    <ng-template #puntiRitiroTitle>
                        <h5 class="m-0">Punti di Ritiro <i class="bi bi-shop-window"></i></h5>
                    </ng-template>
                    <nz-card class="card" [nzTitle]="puntiRitiroTitle">
                        <h6 class="card-subtitle mb-2 text-muted">Gestisci i punti di ritiro</h6>
                        <p class="card-text">Visualizza e gestisci i punti di ritiro o crea un nuovo punto. </p>
                        <a nz-button routerLink="/punti-ritiro" nzType="primary" nzSize="large" class="mt-auto bottone col-12">Vai ai punti di ritiro</a>
                    </nz-card>
                </div>      
    
                <div class="col-lg-4 mt-lg-0 mt-3">
                    <ng-template #statisticheTitle>
                        <h5 class="m-0">Statistiche <i class="bi bi-graph-up"></i></h5>
                    </ng-template>
                    <nz-card class="card" [nzTitle]="statisticheTitle">
                        <h6 class="card-subtitle mb-2 text-muted">Gestisci le statistiche</h6>
                        <p class="card-text">Visualizza le statistiche dei clienti che si sono registrati e i prodotti che sono stati acquistati</p>
                        <a nz-button routerLink="/statistiche" nzType="primary" nzSize="large" class="mt-auto bottone col-12">Vai alle Statistiche</a>
                    </nz-card>
                    
                </div> 
            </div>
        </nz-collapse-panel>
    </nz-collapse>

</div>