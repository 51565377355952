<div class="p-3">
    <ng-template #categorieTitle>
        <div class="row p-md-3 p-1">
            <div class="col-lg-8 my-auto">
                <h3 style="white-space: break-spaces;">{{categoria?.nome}}</h3>
                <h5 class="col text-muted">Categoria #{{categoria?.id}}</h5>
            </div>
            <div class="col-lg-4 my-auto">
                <nz-button-group class="w-100">
                    <button nz-button nzSize="large" class="w-100 crop" type="button" (click)="onModifica()">Modifica la categoria</button>
                    <button nz-button nzSize="large" class="w-25" nz-dropdown [nzDropdownMenu]="menu1" nzPlacement="bottomRight">
                        <span nz-icon nzType="ellipsis"></span>
                    </button>
                </nz-button-group>
                <nz-dropdown-menu #menu1="nzDropdownMenu">
                    <ul nz-menu>                        
                        <li nz-menu-item class="p-3" (click)="onElimina()">Elimina la categoria <i class="ms-2 bi bi-trash3"></i></li>                        
                    </ul>
                </nz-dropdown-menu>
            </div>
        </div>
    </ng-template>
    <nz-card [nzTitle]="categorieTitle" class="mt-5 mb-5">
        <div class="row p-md-3 p-1">
            <div class="col">
                <nz-breadcrumb>
                    <nz-breadcrumb-item>
                        <a routerLink="/home">
                            <span nz-icon nzType="home"></span>
                        </a>                  
                    </nz-breadcrumb-item>
                    <nz-breadcrumb-item>
                      <a routerLink="/categorie">                    
                        <span>Categorie</span>
                      </a>
                    </nz-breadcrumb-item>
                    <nz-breadcrumb-item>Dettaglio categoria</nz-breadcrumb-item>
                </nz-breadcrumb>
            </div>
        </div>
        <div class="row p-md-3 p-1">
            <div class="col-lg-6 mt-3">            
                <ng-template #datiHeader>
                    <div class="header">
                        <h5 class="d-inline text-white">Dati</h5>
                    </div>
                </ng-template>

                <nz-list nzBordered [nzHeader]="datiHeader" class="lista">
                    <nz-list-item>
                        <span nz-typography><span class="text-muted">N°</span></span> {{categoria?.id}}
                    </nz-list-item>
                    <nz-list-item>
                        <span nz-typography><span class="text-muted">Nome:</span></span> {{categoria?.nome}}
                    </nz-list-item>
                    <nz-list-item>
                        <span nz-typography><span class="text-muted">Descrizione:</span></span>
                        {{categoria?.descrizione}}
                    </nz-list-item>
                    <nz-list-item>
                        <span nz-typography><span class="text-muted">Posizione:</span></span>
                        {{categoria?.posizione}}
                    </nz-list-item>
                    <nz-list-item class="p-0">
                        <nz-collapse nzAccordion [nzExpandIconPosition]="'right'" class="w-100"
                            style="background-color: transparent; border: 0px solid; padding-left: 8px;">
                            <nz-collapse-panel [nzHeader]="'Altro'" style="border-bottom: 0px;" class="cursor-pointer">
                                <nz-list-item>
                                    <span nz-typography><span class="text-muted">Data creazione:</span></span>
                                    {{categoria?.data_creazione | date: 'dd/MM/yyyy HH:mm'}}
                                </nz-list-item>
                                <nz-list-item>
                                    <span nz-typography><span class="text-muted">Data aggiornamento:</span></span>
                                    {{categoria?.data_aggiornamento | date: 'dd/MM/yyyy HH:mm'}}
                                </nz-list-item>
                            </nz-collapse-panel>
                        </nz-collapse>
                    </nz-list-item>
                </nz-list>
            </div>
            <div class="col-lg-6 mt-3">                                
                <ng-template #prodottiHeader>      
                    <div class="header">
                        <h5 class="d-inline text-white">Prodotti</h5>                        
                        <span class="badge bg-white text-dark rounded-pill float-end mt-1">{{prodotti.length}}</span>
                    </div>
                </ng-template>
    
                <nz-list nzBordered [nzHeader]="prodottiHeader" class="lista">                    
                    <nz-list-item *ngFor="let prodotto of (show_all ? prodotti : prodotti.slice(0, 10))" [routerLink]="'/prodotti/' + prodotto.id" class="cursor-pointer">
                        <span nz-typography><span>{{prodotto.nome}}</span></span>
                    </nz-list-item>   
                    <nz-list-item *ngIf="prodotti.length > 10 && !show_all" (click)="show_all = !show_all" class="cursor-pointer">
                        <span nz-typography>Mostra tutti ...</span>
                    </nz-list-item>
                    <nz-list-item *ngIf="show_all" (click)="show_all = !show_all" class="cursor-pointer">
                        <span nz-typography>Mostra di meno ...</span>
                    </nz-list-item>
                    <nz-list-item *ngIf="prodotti.length <= 0">
                        <span nz-typography>Nessun prodotto associato alla categoria</span>
                    </nz-list-item>                
                </nz-list>    
            </div>                                     
        </div>    
    </nz-card>
</div>