import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from 'src/app/service/api.service';
import { FasceProdotti } from 'src/app/shared/interfacce';
import { EliminaFascePrezziProdottoComponent } from '../elimina-fasce-prezzi-prodotto/elimina-fasce-prezzi-prodotto.component';

@Component({
  selector: 'app-modifica-fasce-prezzi-prodotto',
  templateUrl: './modifica-fasce-prezzi-prodotto.component.html',
  styleUrls: ['./modifica-fasce-prezzi-prodotto.component.css']
})
export class ModificaFascePrezziProdottoComponent implements OnInit {

  constructor(private apiService: ApiService, private dialogRef: MatDialogRef<ModificaFascePrezziProdottoComponent>, private dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: { id: number }) { }

  formFasce!: FormGroup;

  fasce: FasceProdotti[] = [];
  fascia?: FasceProdotti

  esito: boolean = false;

  error_messages = {
    'prezzo': [
      { type: 'required', message: 'Inserire un prezzo per procedere con la creazione del prodotto' },
      { type: 'pattern', message: 'Inserire un prezzo valido' }
    ],
    'eta_min': [
      { type: 'min', message: 'Inserire come minimo 0 anni di età' },
      { type: 'max', message: 'Inserire come massimo 119 anni di età' },
      { type: 'required', message: "Inserire un'età minima per procedere con la creazione della fascia di prezzo" },
    ],
    'eta_max': [
      { type: 'min', message: 'Inserire come minimo 1 anni di età' },
      { type: 'max', message: 'Inserire come massimo 120 anni di età' },
      { type: 'required', message: "Inserire un'età massima per procedere con la creazione della fascia di prezzo" },
    ],
  }

  ngOnInit(): void {

    // Creo il form con i controlli
    this.formFasce = new FormGroup({
      prezzo: new FormControl(null, Validators.required),
      eta_max: new FormControl(null, Validators.required),
      eta_min: new FormControl(null, Validators.required),
      descrizione: new FormControl(null)
    })

    // Recupero i dati della fascia in base all'id
    this.apiService.getFasciaProdotti(this.data.id).subscribe({
      next: (data) => this.fascia = data,
      error: (err) => this.apiService.Error(err.error.message),
      complete: () => {

        // Setto i dati nel form
        this.formFasce.setValue({
          prezzo: this.fascia?.prezzo,
          eta_max: this.fascia?.eta_max,
          eta_min: this.fascia?.eta_min,
          descrizione: this.fascia?.descrizione
        })
      }
    })

  }

  onClose() {
    this.dialogRef.close(false)
  }

  onElimina() {
    let dialogRef = this.dialog.open(EliminaFascePrezziProdottoComponent, { data: { id: this.data.id }, autoFocus: false, width: '750px' });
    dialogRef?.afterClosed().subscribe((esito: boolean) => {
      if (esito) { this.dialogRef.close(); }
    })
  }

  onSubmit() {

    let fascia = {
      eta_min: this.formFasce.get('eta_min')?.value,
      eta_max: this.formFasce.get('eta_max')?.value,
      prezzo: this.formFasce.get('prezzo')?.value,
      descrizione: this.formFasce.get('descrizione')?.value,
    }

    // Aggiungo la fascia al db
    this.apiService.updateFasciaProdotti(this.data.id, fascia).subscribe({
      error: (err) => this.apiService.Error(err.error.message),
      complete: () => this.dialogRef.close()
    })

  }

}