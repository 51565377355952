import { Injectable } from '@angular/core';
import { Carrello, Cliente, Prodotto } from '../shared/interfacce';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  constructor() { }

  carrello: Carrello[] = [];

  addClienteCarrello(cliente: Cliente) {

    // creo il record nel carrello per il membro selezionato se non era presente
    const alreadyExists = this.carrello.find((carrello) => carrello.id_cliente === cliente.id);
    if (!alreadyExists) { this.carrello.push({ totale: 0, prodotti: [], id_cliente: cliente.id, nome: cliente.nome, cognome: cliente.cognome }); }
  }

  addProdottoCarrello(cliente: Cliente, prodotto: Prodotto) {

    // recupero il carrello del cliente
    this.carrello.filter(carrello => carrello.id_cliente == cliente.id).map(carrello => {

      // controllo se esiste già il prodotto nel carrello
      const alreadyExists = carrello.prodotti.find((prod) => prod.id === prodotto.id);
      if (!alreadyExists) carrello.prodotti.push(prodotto);
    });

  }

  deleteProdottoCarrello(id_cliente: number, prodotto: Prodotto) {
    // recupero il carrello del cliente
    this.carrello.filter(carrello => carrello.id_cliente == id_cliente).map(carrello => {

      // recupero l'indice del prodotto che sto togliendo
      const index = carrello.prodotti.indexOf(prodotto, 0);
      if (index >= -1) {
        carrello.prodotti.splice(index, 1);
      }

      if (prodotto.obbligatorio)
        carrello.prodotti = [];

      if (prodotto.id_categoria == 1)
        carrello.prodotti = carrello.prodotti.filter(prod => prod.id_categoria !== 13);
    })

  }  

  updateDateProdotti(stagione: string) {

    // ciclo nel carrello
    this.carrello.map(carrello => {

      // ciclo nei prodotti
      carrello.prodotti.map(prodotto => {

        // Se il prodotto non è giornaliero o non ha date specifiche
        if (!prodotto.giornaliero || !prodotto.date_specifiche) {

          // Recupero gli anni della stagione
          let split_stagione = stagione.split('-');
          let anno_inizio = split_stagione[0];
          let anno_fine = split_stagione[1];

          // Setto le date in base agli anni della stagione
          let inizio_validita = new Date(prodotto.inizio_validita);
          inizio_validita.setFullYear(+anno_inizio)
          let fine_validita = new Date(prodotto.fine_validita);
          fine_validita.setFullYear(+anno_fine);

          prodotto.inizio_validita = inizio_validita;
          prodotto.fine_validita = fine_validita;
        }

      })
    })

  }

  getCarrello() {
    return this.carrello;
  }

  getCarrelloCliente(cliente: Cliente) {
    return this.carrello.filter(carrello => carrello.id_cliente === cliente.id)
  }

  getTotaleCarrello() {
    return this.carrello.reduce((somma_cliente, cart) => {
      const sommaParziale = cart.prodotti.reduce((somma, prod) => somma + (prod.prezzo + (prod.supplemento || 0)), 0);
      cart.totale = sommaParziale;
      return somma_cliente + sommaParziale;
    }, 0);
  }

  getTotaleCarrelloCliente(cliente: Cliente) {
    return this.carrello.reduce((somma_cliente, cart) => {
      let sommaParziale = 0;
      if (cart.id_cliente == cliente.id) {
        sommaParziale = cart.prodotti.reduce((somma, prod) => somma + (prod.prezzo + (prod.supplemento || 0)), 0);
      }
      return somma_cliente + sommaParziale;
    }, 0);
  }

  getProdottiCarrelloCliente(cliente: Cliente) {
    return this.carrello.reduce((prodotti, cart) => {
      const sommaProdotti = cart.prodotti.reduce((somma, prod) => somma + 1, 0);
      return prodotti + sommaProdotti;
    }, 0);    
  }

  clearCarrello() {
    this.carrello = [];
  }
}
