<mat-dialog-content>
    <div class="title-section mb-3" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle style="cursor: move;">
        <h5 class="title">Crea un codice sconto</h5>
    </div>
    <nz-divider></nz-divider>
    <form [formGroup]="formCodiceSconto" (ngSubmit)="onSubmit()" id="formCodiceSconto">
        <div class="row">
            <div class="col">
                <div id="indirizzoObbl" class="form-text text-start">*campo obbligatorio</div>        
            </div>
        </div>        
        <div class="row mt-3">
            <div class="col-md-6">   
                <nz-form-item class="flex-column mb-2">
                    <nz-form-label nzRequired class="text-start">Cliente</nz-form-label>
                    <nz-form-control>
                        <nz-select nzShowSearch nzAllowClear formControlName="cliente" nzSize="large" class="w-100" (nzOnSearch)="onSeleziona($event)">
                            <nz-option *ngFor="let cliente of filteredClienti" [nzValue]="cliente.id" [nzLabel]="cliente.cognome + ' ' + cliente.nome + ' | ' + (cliente.data_nascita | date: 'dd MMM yyyy')"></nz-option>
                        </nz-select> 
                    </nz-form-control>
                </nz-form-item>
                <div class="error-messages">
                    <ng-container *ngFor="let error of error_messages.cliente">                                                                    
                        <nz-alert nzType="error" [nzMessage]="error.message" nzShowIcon nzCloseable *ngIf="formCodiceSconto.get('cliente')?.hasError(error.type) && (formCodiceSconto.get('cliente')?.dirty || formCodiceSconto.get('cliente')?.touched)"></nz-alert>
                    </ng-container>
                </div>
            </div>

            <div class="col-md-6">   
                <nz-form-item class="flex-column mb-2">
                    <nz-form-label nzRequired class="text-start">Codice</nz-form-label>
                    <nz-form-control>
                        <input nz-input formControlName="codice" nzSize="large" />
                    </nz-form-control>
                </nz-form-item>
                <div class="error-messages">
                    <ng-container *ngFor="let error of error_messages.codice">                                                                    
                        <nz-alert nzType="error" [nzMessage]="error.message" nzShowIcon nzCloseable *ngIf="formCodiceSconto.get('codice')?.hasError(error.type) && (formCodiceSconto.get('codice')?.dirty || formCodiceSconto.get('codice')?.touched)"></nz-alert>
                    </ng-container>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col">   
                <nz-form-item class="flex-column mb-2">
                    <nz-form-label class="text-start">Descrizione</nz-form-label>
                    <nz-form-control>
                        <textarea nz-input formControlName="descrizione" nzSize="large"></textarea>
                    </nz-form-control>
                </nz-form-item>                
            </div>
        </div>

        <div class="row">
            <div class="col">
                <nz-form-item class="flex-column mb-2">
                    <nz-form-label nzRequired class="text-start">Valido a partire dal - al</nz-form-label>
                    <nz-form-control>                        
                        <nz-range-picker formControlName="validita" class="w-100" nzSize="large" [nzFormat]="'dd/MM/yyyy'"></nz-range-picker>                                               
                    </nz-form-control>
                </nz-form-item>    
                <div class="error-messages">
                    <ng-container *ngFor="let error of error_messages.validita">                                                                    
                        <nz-alert nzType="error" [nzMessage]="error.message" nzShowIcon nzCloseable *ngIf="formCodiceSconto.get('inizio_validita')?.hasError(error.type) && (formCodiceSconto.get('inizio_validita')?.dirty || formCodiceSconto.get('inizio_validita')?.touched)"></nz-alert>
                    </ng-container>
                </div>            
            </div>
        </div>

        <div class="row">
            <div class="col">                   
                <nz-form-item class="flex-column mb-3">
                    <nz-form-label nzRequired class="text-start">Valore</nz-form-label>
                    <nz-form-control>
                        <nz-input-group [nzSuffix]="suffixTemplateInfo" nzSize="large" >
                            <input nz-input formControlName="valore" required>
                        </nz-input-group>                
                        <ng-template #suffixTemplateInfo>                    
                            <span nz-icon nzType="euro-circle" nzTheme="outline"></span>
                        </ng-template>
                    </nz-form-control>
                </nz-form-item>

                <div class="error-messages">
                    <ng-container *ngFor="let error of error_messages.valore">                                                                    
                        <nz-alert nzType="error" [nzMessage]="error.message" nzShowIcon nzCloseable *ngIf="formCodiceSconto.get('valore')?.hasError(error.type) && (formCodiceSconto.get('valore')?.dirty || formCodiceSconto.get('valore')?.touched)"></nz-alert>
                    </ng-container>
                </div>
            </div>                    
        </div>
    </form>
    <nz-divider></nz-divider>
</mat-dialog-content>
<mat-dialog-actions>
    <button type="button" nz-button nzSize="large" class="me-2" (click)="onClose()">Annulla</button>    

    <span nzTooltipTitle="Per creare un codice sconto, inserire tutti i campi con l'asterisco" nz-tooltip nzTooltipPlacement="top" *ngIf="!formCodiceSconto.valid">
        <button nz-button nzType="primary" nzSize="large" [disabled]="true">Crea Codice Sconto</button>
    </span>  
    <button nz-button nzType="primary" nzSize="large" *ngIf="formCodiceSconto.valid" form="formCodiceSconto">Crea Codice Sconto</button>        
</mat-dialog-actions>