<div class="p-3">
    <div class="row">
        <div class="col-md-6">
            <ng-template #movimentiCassaTitle>
                <h5 class="m-0">Movimenti cassa <i class="bi bi-receipt"></i></h5>
            </ng-template>
            <nz-card [nzTitle]="movimentiCassaTitle">
                <h6 class="card-subtitle mb-2 text-muted">Gestisci i movimenti della cassa</h6>
                <p class="card-text">Visualizza e gestisci i movimenti della cassa o creane uno nuovo. </p>
                <a nz-button routerLink="/movimenti/cassa" nzType="primary" nzSize="large" class="mt-auto bottone col-12">Vai ai Movimenti della cassa</a>
            </nz-card>
        </div>
        <div class="col-md-6 mt-md-0 mt-3">
            <ng-template #movimentiTitle>
                <h5 class="m-0">Movimenti altri metodi di pagamento <i class="bi bi-receipt"></i></h5>
            </ng-template>
            <nz-card [nzTitle]="movimentiTitle">
                <h6 class="card-subtitle mb-2 text-muted">Gestisci i movimenti degli altri metodi di pagamento</h6>
                <p class="card-text">Visualizza e gestisci i movimenti degli altri metodi di pagamento o creane uno nuovo. </p>
                <a nz-button routerLink="/movimenti/altri-metodi" nzType="primary" nzSize="large" class="mt-auto bottone col-12 crop">Vai ai Movimenti degli altri metodi di pagamento</a>
            </nz-card>
        </div>
    </div>
</div>