<div class="p-3">
    <ng-template #movimentiTitle>
        <div class="row p-md-3 p-1">
            <div class="col-lg-1 mt-2">
                <button nz-button nzSize="large" class="w-100" type="button" (click)="onIndietro()"><i
                        style="font-size: 20px;" class="bi bi-arrow-left"></i></button>
            </div>
            <div class="col-lg-8 mt-2">
                <div class="row">
                    <div class="col">
                        <input nz-input placeholder="Cerca Descrizione" nzSize="large" [(ngModel)]="descrizioneFilter" />
                    </div>
                </div>
            </div>
            <div class="col-lg-3 mt-2" style="text-align: right;">
                <nz-button-group class="w-100">
                    <button nz-button nzSize="large" class="w-100 crop" type="button" (click)="onAdd()">Crea movimento</button>
                    <button nz-button nzSize="large" class="w-25" nz-dropdown [nzDropdownMenu]="menu1" nzPlacement="bottomRight">
                      <span nz-icon nzType="ellipsis"></span>
                    </button>
                </nz-button-group>                
                <nz-dropdown-menu #menu1="nzDropdownMenu">
                    <ul nz-menu>
                        <li nz-menu-item class="p-3" (click)="onExportGiornalieri()">Esportazione giornaliera <i class="ms-2 bi bi-file-earmark-spreadsheet"></i></li>
                        <li nz-menu-item class="p-3" (click)="onExport()">Esportazione filtrata <i class="ms-2 bi bi-file-earmark-spreadsheet"></i></li>
                        <li nz-menu-item class="p-3" (click)="onReport()">Genera report <i class="ms-2 bi bi-filetype-pdf"></i></li>
                    </ul>
                </nz-dropdown-menu>
            </div>
        </div>
    </ng-template>
    <nz-card [nzTitle]="movimentiTitle">
        <div class="row p-md-3 p-1">

            <nz-table #movimentiTable nzShowPagination *ngIf="!carica" 
                [nzTotal]="(movimenti | searchMovimentiDescrizione: descrizioneFilter).length" nzShowSizeChanger
                [nzPageSize]="10"
                [nzPageSizeOptions]="[5, 10, 25, 50, 100, 150, 200, 250, 300, 350, 400, 500, 750, 1000]"
                [nzData]="movimenti | searchMovimentiDescrizione: descrizioneFilter" [nzBordered]="true" nzNoResult="Nessun movimento trovato">
                <thead>
                    <tr>
                        <th [nzSortFn]="onOrdinamentoDescrizione">Descrizione</th>
                        <th [nzSortFn]="onOrdinamentoMetodo">Metodo</th>
                        <th [nzSortFn]="onOrdinamentoPagato" class="text-center">Pagato</th>
                        <th [nzSortFn]="onOrdinamentoResiduo" class="text-center">Residuo</th>
                        <th [nzSortFn]="onOrdinamentoTotale" class="text-center">Totale</th>
                        <th [nzSortFn]="onOrdinamentoData" class="text-end">Data</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let movimento of movimentiTable.data | searchMovimentiDescrizione: descrizioneFilter" [routerLink]="'/movimenti/' +  movimento.id + '/altri-metodi'" class="cursor-pointer">
                        <td>{{movimento.descrizione}} </td>
                        <td>{{movimento.metodo}} </td>
                        <td class="text-center">{{movimento.pagato | currency: 'EUR'}}</td>
                        <td class="text-center">{{movimento.residuo | currency: 'EUR'}}</td>
                        <td class="text-center">{{movimento.totale | currency: 'EUR'}}</td>
                        <td class="text-end">{{movimento.data_creazione | date: 'dd/MM/yyyy'}}</td>
                    </tr>
                </tbody>
            </nz-table>

            <app-caricamento *ngIf="carica" class="d-flex align-items-center justify-content-center"></app-caricamento>
        </div>
    </nz-card>
</div>