import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ApiService } from 'src/app/service/api.service';
import { EliminaContattoComponent } from '../elimina-contatto/elimina-contatto.component';
import { Contatto } from 'src/app/shared/interfacce';

@Component({
  selector: 'app-modifica-contatto-cliente',
  templateUrl: './modifica-contatto-cliente.component.html',
  styleUrls: ['./modifica-contatto-cliente.component.css']
})
export class ModificaContattoClienteComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<ModificaContattoClienteComponent>, private dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: { id_cliente: number, id_contatto: number }, private apiService: ApiService) { }

  contatto?: Contatto

  formContatto!: FormGroup;

  error_messages = {
    'nome': [
      { type: 'required', message: 'Inserire un nome per procedere con la modifica del contatto' }
    ],
    'cognome': [
      { type: 'required', message: 'Inserire un cognome per procedere con la modifica del contatto' }
    ],
    'email': [
      { type: 'required', message: "Inserire un'email per procedere con la modifica del contatto" },
      { type: 'email', message: "Inserire un'email valida per procedere con la modifica del contatto" }
    ],
    'telefono': [
      { type: 'required', message: 'Inserire un numero di telefono per procedere con la modifica del contatto' },
      { type: 'pattern', message: 'Inserire un numero di telefono valido, di 9 o 10 cifre' }
    ],
    'parentela': [
      { type: 'required', message: 'Inserire un grado di parentela per procedere con la modifica del contatto' }
    ],
  }

  ngOnInit(): void {

    // Creo il form con i controlli
    this.formContatto = new FormGroup({
      nome: new FormControl(null, Validators.required),
      cognome: new FormControl(null, Validators.required),
      email: new FormControl(null, [Validators.required, Validators.email]),
      telefono: new FormControl(null, [Validators.required, Validators.pattern('^(?=[0-9]*$)(?:.{9}|.{10})$')]),
      parentela: new FormControl(null, Validators.required),
    })

    // Recupero i dati del contatto
    this.apiService.getContatto(this.data.id_contatto).subscribe({
      next: (data) => this.contatto = data,
      error: (err) => this.apiService.Error(err.error.message),
      complete: () => {

        // Aggiungo i dati al form
        this.formContatto.setValue({
          nome: this.contatto?.nome,
          cognome: this.contatto?.cognome,
          email: this.contatto?.email,
          telefono: this.contatto?.telefono,
          parentela: this.contatto?.parentela
        })
      }
    })

  }

  onClose() {
    this.dialogRef.close(true)
  }

  onElimina() {
    let dialogRef = this.dialog.open(EliminaContattoComponent, { data: { id: this.data.id_contatto, id_cliente: this.data.id_cliente }, autoFocus: false, width: '750px' });
    dialogRef?.afterClosed().subscribe((esito: boolean) => {
      if (esito) {
        this.dialogRef.close();
      }
    })
  }

  onSubmit() {
    let contatto = {
      nome: this.formContatto.get('nome')?.value,
      cognome: this.formContatto.get('cognome')?.value,
      parentela: this.formContatto.get('parentela')?.value,
      email: this.formContatto.get('email')?.value,
      telefono: this.formContatto.get('telefono')?.value
    }

    // Aggiorno i dati del contatto
    this.apiService.updateContatto(this.data.id_contatto, contatto).subscribe({
      next: (data) => this.apiService.setContatti(this.data.id_cliente),
      error: (err) => this.apiService.Error(err.error.message),
      complete: () => this.dialogRef.close()
    })

  }

}
