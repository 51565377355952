<div class="p-3">
    <ng-template #promozioniTitle>
        <div class="row p-md-3 p-1">        
            <div class="col-lg-8 my-auto">
                <h3 style="white-space: break-spaces;">{{promozione?.nome}}</h3>
                <h5 class="col text-muted">Promozione #{{promozione?.id}}</h5>
            </div>     
            <div class="col-lg-4 my-auto">                  
                <nz-button-group class="w-100">
                    <button nz-button nzSize="large" class="w-100 crop" type="button" (click)="onModifica()">Modifica la promozione</button>
                    <button nz-button nzSize="large" class="w-25" nz-dropdown [nzDropdownMenu]="menu1" nzPlacement="bottomRight">
                        <span nz-icon nzType="ellipsis"></span>
                    </button>
                </nz-button-group>
                <nz-dropdown-menu #menu1="nzDropdownMenu">
                    <ul nz-menu>                        
                        <li nz-menu-item class="p-3" (click)="onElimina()">Elimina la promozione <i class="ms-2 bi bi-trash3"></i></li>                        
                    </ul>
                </nz-dropdown-menu>
            </div>          
        </div>
    </ng-template>
    <nz-card [nzTitle]="promozioniTitle" class="mt-5 mb-5">
        <div class="row p-md-3 p-1">
            <div class="col">
                <nz-breadcrumb>
                    <nz-breadcrumb-item>
                        <a routerLink="/home">
                            <span nz-icon nzType="home"></span>
                        </a>                  
                    </nz-breadcrumb-item>
                    <nz-breadcrumb-item>
                      <a routerLink="/promozioni">                    
                        <span>Promozioni</span>
                      </a>
                    </nz-breadcrumb-item>
                    <nz-breadcrumb-item>Dettaglio promozione</nz-breadcrumb-item>
                </nz-breadcrumb>
            </div>
        </div>
        <div class="row p-md-3 p-1" style="padding-top: 0px !important;">
            <div class="col-lg-6">
                <div class="mt-3">
    
                    <ng-template #datiHeader>
                        <div class="header">
                            <h5 class="d-inline text-white">Dati</h5>                        
                        </div>                    
                    </ng-template>
    
                    <nz-list nzBordered [nzHeader]="datiHeader" class="lista">
                        <nz-list-item>
                            <span nz-typography><span class="text-muted">N°</span></span> {{promozione?.id}}
                        </nz-list-item>
                        <nz-list-item>
                            <span nz-typography><span class="text-muted">Nome:</span></span> {{promozione?.nome}}
                        </nz-list-item>
                        <nz-list-item>
                            <span nz-typography><span class="text-muted">Descrizione:</span></span> {{promozione?.descrizione}}
                        </nz-list-item>
                        <nz-list-item>
                            <span nz-typography><span class="text-muted">Sconto:</span></span> - {{promozione?.percentuale ? (promozione?.sconto + ' %' ) : (promozione?.sconto | currency: 'EUR' )}}
                        </nz-list-item>
                        <nz-list-item [routerLink]="['/prodotti/', promozione?.id_prodotto]" class="cursor-pointer">
                            <span nz-typography><span class="text-muted">Prodotto:</span></span> {{promozione?.nome_prodotto}}
                        </nz-list-item>       
                        <nz-list-item>
                            <span nz-typography><span class="text-muted">Età Min:</span></span> {{promozione?.eta_min}}
                        </nz-list-item>       
                        <nz-list-item>
                            <span nz-typography><span class="text-muted">Età Max:</span></span> {{promozione?.eta_max}}
                        </nz-list-item>                      
                        <nz-list-item class="p-0">
                            <nz-collapse nzAccordion [nzExpandIconPosition]="'right'" class="w-100" style="background-color: transparent; border: 0px solid; padding-left: 8px;">
                                <nz-collapse-panel [nzHeader]="'Altro'" style="border-bottom: 0px;" class="cursor-pointer">
                                    <nz-list-item>
                                        <span nz-typography><span class="text-muted">Data creazione:</span></span> {{promozione?.data_creazione | date: 'dd/MM/yyyy HH:mm'}}
                                    </nz-list-item>
                                    <nz-list-item>
                                        <span nz-typography><span class="text-muted">Data aggiornamento:</span></span> {{promozione?.data_aggiornamento | date: 'dd/MM/yyyy HH:mm'}}
                                    </nz-list-item>
                                </nz-collapse-panel>
                              </nz-collapse>
                        </nz-list-item>                    
                    </nz-list>                
                </div>            
            </div>
            <div class="col-lg-6">
                
                <div class="mt-3">
                    <ng-template #infoHeader>      
                        <div class="header">
                            <h5 class="d-inline text-white">Informazioni</h5>                        
                        </div>
                    </ng-template>
        
                    <nz-list nzBordered [nzHeader]="infoHeader" class="lista">                    
                        <nz-list-item *ngIf="!promozione?.giornaliero">
                            <span nz-typography><span class="text-muted">Inizio validita:</span></span> {{promozione?.inizio_validita | date: 'dd/MM/yyyy'}}
                        </nz-list-item>
                        <nz-list-item *ngIf="!promozione?.giornaliero">
                            <span nz-typography><span class="text-muted">Fine validita:</span></span> {{promozione?.fine_validita | date: 'dd/MM/yyyy'}}
                        </nz-list-item>
                        <nz-list-item *ngIf="promozione?.giornaliero">
                            <span nz-typography><span class="text-muted">Giorni:</span></span> {{promozione?.giorni}}
                        </nz-list-item>
                        <nz-list-item>
                            <span nz-typography><span class="text-muted">Inizio orario:</span></span> {{promozione?.inizio_orario}}
                        </nz-list-item>
                        <nz-list-item>
                            <span nz-typography><span class="text-muted">Fine orario:</span></span> {{promozione?.fine_orario}}
                        </nz-list-item>                                    
                    </nz-list>    
                </div>                         
            </div>
        </div>
    </nz-card>
</div>