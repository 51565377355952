import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/service/api.service';

@Component({
  selector: 'app-elimina-configurazione',
  templateUrl: './elimina-configurazione.component.html',
  styleUrls: ['./elimina-configurazione.component.css']
})
export class EliminaConfigurazioneComponent {

  constructor(private dialogRef: MatDialogRef<EliminaConfigurazioneComponent>, @Inject(MAT_DIALOG_DATA) public data : {id: number}, private router: Router, private apiService: ApiService) { }

  onDelete() {
    
    // Elimino la configurazione
    this.apiService.deleteConfigurazione(this.data.id).subscribe({
      next: (data) => this.dialogRef.close(),
      error: (err) => this.apiService.Error(err.error.message),
      complete: () => this.router.navigate(['/configurazioni'])
    })  
    
  }

  onClose() {
    this.dialogRef.close();
  }

}