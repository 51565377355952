<mat-dialog-content>
    <div class="title-section mb-3" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle style="cursor: move;">
        <h5 class="title">Conferma</h5>
    </div>
    <nz-divider></nz-divider>    
    <p><strong> Sicuro di voler eliminare questo utente?</strong></p>
    <p>Eliminerai anche i gruppi da lui creati e i suoi tesseramenti!. Questa azione è permanente.</p>    
    <nz-divider></nz-divider>
</mat-dialog-content>
<mat-dialog-actions>    
    <button type="button" nz-button nzSize="large" (click)="onClose()" class="me-2">Annulla</button>
    <button type="button" nz-button nzDanger nzSize="large" nzType="primary" (click)="eliminaCliente()">Elimina</button>        
</mat-dialog-actions>