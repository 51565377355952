import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/service/api.service';
import { Cliente, Prodotto, Tesseramento, Carrello, CheckOut, PuntoRitiro } from 'src/app/shared/interfacce';
import { ModificaProdottoTesseramentoComponent } from '../modifica-prodotto-tesseramento/modifica-prodotto-tesseramento.component';
import { DatePipe } from '@angular/common';
import { CartService } from 'src/app/service/cart.service';
import { DateService } from 'src/app/service/date.service';

@Component({
  selector: 'app-aggiungi-prodotti-tesseramento',
  templateUrl: './aggiungi-prodotti-tesseramento.component.html',
  styleUrls: ['./aggiungi-prodotti-tesseramento.component.css']
})
export class AggiungiProdottiTesseramentoComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<AggiungiProdottiTesseramentoComponent>, @Inject(MAT_DIALOG_DATA) public data: { clienti: Cliente[] }, private apiService: ApiService, private cartService: CartService, public dateService: DateService, private datePipe: DatePipe, private dialog: MatDialog) { }

  @ViewChild('listaProdotti', { static: false }) listaProdotti!: ElementRef;

  totale: number = 0;
  totale_carrello: number = 0;
  eta: number = 0;
  i: number = 0;

  punti_ritiro: PuntoRitiro[] = [];  
  prodotti: Prodotto[] = [];
  tesseramenti: Tesseramento[] = [];
  cliente!: Cliente;  

  disable_all: boolean = true;
  disable_skipass: boolean = true;
  informazione: boolean = false;
  caricamento: boolean = true;

  informazioni: { ritiro: boolean, giornalieri: Prodotto[], giornalieri_specifici: Prodotto[] } = { ritiro: false, giornalieri: [], giornalieri_specifici: [] }

  carrello: Carrello[] = [];
  checkout: CheckOut = { note: '', carrello: this.carrello, totale: 0 };

  formInfo!: FormGroup;

  ngOnInit(): void {

    // Formo i controlli per il form
    this.formInfo = new FormGroup({
      ritiro: new FormControl(null)
    })

    this.cartService.clearCarrello();

    this.getData();
  }

  getData() {

    this.caricamento = true;

    this.totale = 0;
    
    // Recupero il cliente che sta facendo l'ordine
    this.cliente = this.data.clienti[this.i];

    // Calcolo l'età del cliente
    let differenza = Math.abs(Date.now() - new Date(this.cliente.data_nascita).getTime());
    this.eta = Math.floor((differenza / (1000 * 3600 * 24)) / 365.25);

    // Recupero i punti di ritiro
    this.apiService.getPuntiRitiro().subscribe({
      next: (data) => this.punti_ritiro = [...data],
      error: (err) => this.apiService.Error(err.error.message),
      complete: () => {

        // Recupero i prodotti
        this.apiService.getProdottiEta(this.cliente.id, this.eta).subscribe({
          next: (data) => this.prodotti = [...data],
          error: (err) => this.apiService.Error(err.error.message),
          complete: () => {

            // Recupero i possibili tesseramenti del cliente
            this.apiService.getTesseramentiCliente(this.cliente.id).subscribe({
              next: (data) => this.tesseramenti = [...data],
              error: (err) => this.apiService.Error(err.error.message),
              complete: () => {

                // creo il record nel carrello per il membro selezionato se non era presente
                this.cartService.addClienteCarrello(this.cliente);

                // Recupero i prezzi corretti per i prodotti
                this.getTesseramenti();
                this.getCarrello();

                this.caricamento = false;

                // Ordino i prodotti in base se sono abilitati o no
                this.prodotti.sort(function (a, b) { return (b.disabilitato === a.disabilitato) ? 0 : b.disabilitato ? -1 : 1; });

              }
            })
          }
        })

      }
    })
  }

  getTesseramenti() {

    this.disable_all = true;
    this.disable_skipass = true;

    // ciclo tra i prodotti
    this.prodotti.map(prodotto => {

      // ciclo tra i tesseramenti
      this.tesseramenti
        .filter(tesseramento => this.dateService.checkDate(tesseramento.inizio_validita, tesseramento.fine_validita) !== 0) // Filtra i tesseramenti validi
        .filter(tesseramento => tesseramento.id_prodotto === prodotto.id) // Filtra i tesseramenti che corrispondono al prodotto
        .map(tesseramento => {

          // Se trovo il match, disabilito il prodotto
          prodotto.disabilitato = true;
          prodotto.stato = '- (ACQUISTATO E VALIDO)';
          prodotto.attivo = true;

          // Se il prodotto è obbligatorio, abilito il resto dei prodotti
          if (prodotto.obbligatorio) {
            this.disable_all = false;
          }

          // Se il prodotto è la skicard della Vialattea, abilito i giornalieri
          if (prodotto.id == 1) {
            this.disable_skipass = false;
          }
        })
    })

  }

  getCarrello() {

    // Ottengo il carrello del cliente
    this.cartService.getCarrelloCliente(this.cliente).map(carrello => {

      // ciclo tra i prodotti nel carrello
      carrello.prodotti.map(prodotto_cart => {

        // ciclo tra i prodotti
        this.prodotti.filter(prodotto => prodotto_cart.id === prodotto.id) // Filtra i prodotti che corrispondono ai prodotti nel carrello
          .map(prodotto => {
            this.onAdd(prodotto)
          })
      })
    });

  }

  checkCarrello() {

    // recupero i prodotti nel carrello del cliente            
    return this.cartService.getProdottiCarrelloCliente(this.cliente);

  }

  onAdd(prodotto: Prodotto) {

    prodotto.selezionato = true;

    // recupero il carrello del cliente
    this.cartService.addProdottoCarrello(this.cliente, prodotto);

    // abilito tutti i prodotti se era obbligatorio
    if (prodotto.obbligatorio)
      this.disable_all = false;

    // se c'è la ski card della vialattea nei tesseramenti attivi abilito i giornalieri
    if (prodotto.id == 1)
      this.disable_skipass = false;

    // Recupero i totali del carrello del cliente e di tutto il carrello
    this.totale = this.cartService.getTotaleCarrelloCliente(this.cliente);
    this.totale_carrello = this.cartService.getTotaleCarrello()

  }

  onRemove(prodotto: Prodotto) {

    prodotto.selezionato = false;

    // Elimino il prodotto dal carrello del cliente
    this.cartService.deleteProdottoCarrello(this.cliente.id, prodotto)

    // se il prodotto era obbligatorio tolgo tutto
    if (prodotto.obbligatorio) {

      this.disable_all = true;
      // deseleziono tutti i prodotti
      this.prodotti.map(prodotto => prodotto.selezionato = false)
    }

    // se il prodotto era la skicard vialattea tolgo i giornalieri
    if (prodotto.id == 1) {
      this.disable_skipass = true;

      // deseleziono i prodotti della via lattea
      this.prodotti.filter(prodotto => prodotto.id_categoria == 13).map(prodotto => prodotto.selezionato = false)
    }

    // Recupero i totali del carrello del cliente e di tutto il carrello
    this.totale = this.cartService.getTotaleCarrelloCliente(this.cliente);
    this.totale_carrello = this.cartService.getTotaleCarrello()
  }

  getInfo() {

    this.informazioni.giornalieri = [];
    this.informazioni.giornalieri_specifici = [];

    for (const controlName in this.formInfo.controls) {
      if (controlName != 'ritiro') {
        this.formInfo.removeControl(controlName);
      }
    }

    // Ciclo nel carrello
    this.carrello.map(cart => {

      // Ciclo nei prodotti per il carrello del cliente
      cart.prodotti.map(prodotto => {

        //se un prodotto è delle categoria della Vialattea o SkipassOpen
        if (prodotto.informativo) {

          // setto true il pannello informazioni e rendo obbligatoria la scelta del punto di ritiro
          this.informazione = true;
          this.informazioni.ritiro = true;

          this.formInfo.get('ritiro')?.setValidators(Validators.required);
          this.formInfo.get('ritiro')?.updateValueAndValidity();

        }

        // se è un prodotto giornaliero 
        if (prodotto.giornaliero) {

          // mostro il pannello informazione
          this.informazione = true;

          // parso i giorni se non sono già stati parsati
          try {
            prodotto.giorni = JSON.parse(prodotto.giorni);
          } catch {
            prodotto.giorni = prodotto.giorni;
          }

          // aggiungo ai prodotti giornalieri da scegliere se non è già presente
          const alreadyExists = this.informazioni.giornalieri.find((giornaliero) => giornaliero.id === prodotto.id);
          if (!alreadyExists) { this.informazioni.giornalieri.push(prodotto); }

          // rendo obbligatoria la scelta
          this.formInfo.addControl('giornaliero_' + prodotto.id, new FormControl('', Validators.required));
        }

        // se è un prodotto giornaliero 
        if (prodotto.date_specifiche) {

          // mostro il pannello informazione
          this.informazione = true;

          // aggiungo ai prodotti giornalieri da scegliere se non è già presente
          const alreadyExists = this.informazioni.giornalieri_specifici.find((giornaliero) => giornaliero.id === prodotto.id);
          if (!alreadyExists) { this.informazioni.giornalieri_specifici.push(prodotto); }

          // rendo obbligatoria la scelta
          this.formInfo.addControl('giornaliero_specifico_' + prodotto.id, new FormControl('', Validators.required));
        }

      })
    })

  }

  onCheckout() {

    this.carrello = this.cartService.getCarrello();

    // Se ci sono più clienti e non sono all'ultimo, passo al prossimo
    if (this.data.clienti.length > 1 && this.i !== this.data.clienti.length - 1) {

      this.i++;
      this.getData();
      this.listaProdotti.nativeElement.scrollTop = 0;

    } else {

      // Recupero le info da richiedere
      this.getInfo();

      // Se non ci sono info da richiedere chiudo tutto
      if (!this.informazione) {

        this.checkout.carrello = this.carrello;
        this.checkout.totale = this.totale_carrello;
        this.dialogRef.close(this.checkout);

      }
    }
  }

  onSubmit() {    

    //ciclo nel carrello
    this.carrello.map(carrello => {

      //ciclo nei prodotti
      carrello.prodotti.map(prodotto => {

        // ciclo nei giornalieri
        this.informazioni.giornalieri.filter(giornaliero => giornaliero.id == prodotto.id)
          .map(giornaliero => {
            // recupero il giorno selezionato e lo setto come inizio e fine validità
            let giorno = this.formInfo.get('giornaliero_' + giornaliero.id)?.value;
            giornaliero.data_utilizzo = this.datePipe.transform(giorno, 'yyyy-MM-dd');

            giornaliero = this.dateService.checkSupplemento(giorno, giornaliero);

          })

        // ciclo nei giornalieri specifici
        this.informazioni.giornalieri_specifici.filter(giornaliero => giornaliero.id == prodotto.id)
          .map(giornaliero => {
            // recupero il giorno selezionato e lo setto come inizio e fine validità
            let giorno = this.formInfo.get('giornaliero_specifico_' + giornaliero.id)?.value;
            giornaliero.data_utilizzo = this.datePipe.transform(giorno, 'yyyy-MM-dd');

            giornaliero = this.dateService.checkSupplemento(giorno, giornaliero);
          })
      })

    })

    // ottengo il totale del carrello
    this.totale = this.cartService.getTotaleCarrelloCliente(this.cliente);
    this.totale_carrello = this.cartService.getTotaleCarrello()

    this.checkout.note = (this.formInfo.get('ritiro')?.value || '').length > 0 ? 'Punto di ritiro: ' + this.formInfo.get('ritiro')?.value : ''
    this.checkout.carrello = this.carrello;
    this.checkout.totale = this.totale_carrello;

    this.dialogRef.close(this.checkout);
  }

  onIndietro() {
    this.i--;
    this.getData();
    this.listaProdotti.nativeElement.scrollTop = 0;
  }

  onIndietroInformazione() {
    this.informazione = false;
    this.getData();
  }

  onEdit(event: any, item: any) {
    event.stopPropagation();
    var dialogRef = this.dialog.open(ModificaProdottoTesseramentoComponent, { autoFocus: false, data: { prodotto: item }, width: '750px' });
    dialogRef?.afterClosed().subscribe((data) => {

      if (data) {
        item.prezzo = parseFloat(data.prezzo);
      }

    })
  }

  onClose() {
    this.dialogRef.close(false);
  }

}