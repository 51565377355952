import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/service/api.service';

@Component({
  selector: 'app-elimina-movimento',
  templateUrl: './elimina-movimento.component.html',
  styleUrls: ['./elimina-movimento.component.css']
})
export class EliminaMovimentoComponent {

  constructor(private dialogRef: MatDialogRef<EliminaMovimentoComponent>, @Inject(MAT_DIALOG_DATA) public data: {id: number}, private apiService: ApiService, private router: Router) { }

  onClose() {
    this.dialogRef.close(true)
  }

  onElimina(){    

    // Elimino il movimento
    this.apiService.deleteMovimento(this.data.id).subscribe({
      next: (data) => this.apiService.setMovimenti(),
      error: (err) => this.apiService.Error(err.error.message),
      complete: () => {
        this.router.navigate(['/movimenti']);
        this.dialogRef.close(true)
      }
    })
  }

}
