<mat-dialog-content>
    <div class="title-section mb-3" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle style="cursor: move;">
        <h5 class="title">Conferma</h5>
    </div>
    <nz-divider></nz-divider>    
    <p><strong>Sicuro di voler eliminare questo contatto?</strong></p>
    <p>Questa azione è permanente.</p>    
    <nz-divider></nz-divider>
</mat-dialog-content>
<mat-dialog-actions>    
    <button type="button" nz-button nzSize="large" class="me-2" (click)="onClose()">Annulla</button>
    <button type="button" nz-button nzSize="large" nzType="primary" nzDanger (click)="onElimina()">Elimina</button>        
</mat-dialog-actions>