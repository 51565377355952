<div class="p-3">
    <ng-template #prodottiTitle>    
        <div class="row p-md-3 p-1">
            <div class="col-lg-2 my-auto">            
                <div class="rounded foto" [style.background-image]="'url(' + immagine + '), url(../../../assets/img/default_product.png)'" (click)="onDownload()" nzTooltipTitle="Se presente, clicca per scaricare l'immagine!" nz-tooltip nzTooltipPlacement="top" ></div>
            </div>
            <div class="col-lg-6 my-auto ps-4">
                <h3 style="white-space: break-spaces;">{{prodotto?.nome}}</h3>
                <h5 class="col text-muted">Prodotto #{{prodotto?.id}}</h5>
            </div>     
            <div class="col-lg-4 my-auto">                  
                <nz-button-group class="w-100">
                    <button nz-button nzSize="large" class="w-100 crop" type="button" (click)="onModifica()">Modifica il prodotto</button>
                    <button nz-button nzSize="large" class="w-25" nz-dropdown [nzDropdownMenu]="menu1" nzPlacement="bottomRight">
                        <span nz-icon nzType="ellipsis"></span>
                    </button>
                </nz-button-group>
                <nz-dropdown-menu #menu1="nzDropdownMenu">
                    <ul nz-menu>
                        <li nz-menu-item class="p-3" (click)="onFasce()">Gestisci le fasce di prezzo <i class="ms-2 bi bi-tags-fill"></i></li>
                        <li nz-menu-item class="p-3" (click)="onDuplica()">Duplica prodotto <i class="ms-2 bi bi-clipboard2-check-fill"></i></li>
                        <li nz-menu-item class="p-3" (click)="onEliminaImmagine()" *ngIf="immagine.length > 0">Elimina l'immagine del prodotto <i class="ms-2 bi bi-card-image"></i></li>
                        <li nz-menu-item class="p-3" (click)="onElimina()">Elimina il prodotto <i class="ms-2 bi bi-trash3"></i></li>                        
                    </ul>
                </nz-dropdown-menu>
            </div>          
        </div>
    </ng-template>
    <nz-card [nzTitle]="prodottiTitle" class="mt-5 mb-5">
        <div class="row p-md-3 p-1">
            <div class="col">
                <nz-breadcrumb>
                    <nz-breadcrumb-item>
                        <a routerLink="/home">
                            <span nz-icon nzType="home"></span>
                        </a>                  
                    </nz-breadcrumb-item>
                    <nz-breadcrumb-item>
                      <a routerLink="/prodotti">                    
                        <span>Prodotti</span>
                      </a>
                    </nz-breadcrumb-item>
                    <nz-breadcrumb-item>Dettaglio prodotto</nz-breadcrumb-item>
                </nz-breadcrumb>
            </div>
        </div>
        <div class="row p-md-3 p-1" style="padding-top: 0px !important;">
            <div class="col-lg-6">
                <div class="mt-3">
    
                    <ng-template #datiHeader>
                        <div class="header">
                            <h5 class="d-inline text-white">Dati</h5>                        
                        </div>                    
                    </ng-template>
    
                    <nz-list nzBordered [nzHeader]="datiHeader" class="lista">
                        <nz-list-item>
                            <span nz-typography><span class="text-muted">N°</span></span> {{prodotto?.id}}
                        </nz-list-item>
                        <nz-list-item>
                            <span nz-typography><span class="text-muted">Nome:</span></span> {{prodotto?.nome}}
                        </nz-list-item>
                        <nz-list-item>
                            <span nz-typography><span class="text-muted">Descrizione:</span></span> {{prodotto?.descrizione}}
                        </nz-list-item>
                        <nz-list-item>
                            <span nz-typography><span class="text-muted">Prezzo:</span></span> {{prodotto?.prezzo | currency: 'EUR'}}
                        </nz-list-item>
                        <nz-list-item [routerLink]="['/categorie/', prodotto?.id_categoria]" class="cursor-pointer">
                            <span nz-typography><span class="text-muted">Categoria:</span></span> {{prodotto?.categoria}}
                        </nz-list-item>     
                        <nz-list-item class="p-0">
                            <nz-collapse nzAccordion [nzExpandIconPosition]="'right'" class="w-100" style="background-color: transparent; border: 0px solid; padding-left: 8px;">
                                <nz-collapse-panel [nzHeader]="'Info'" style="border-bottom: 0px;" class="cursor-pointer">
                                    <nz-list-item>
                                        <span nz-typography><span class="text-muted">Posizione:</span></span> {{prodotto?.posizione}}
                                    </nz-list-item>            
                                    <nz-list-item>
                                        <span nz-typography><span class="text-muted">Visibile nello shop:</span></span> {{prodotto?.online ? 'Si' : 'No'}}
                                    </nz-list-item>
                                    <nz-list-item>
                                        <span nz-typography><span class="text-muted">Obbligatorio:</span></span> {{prodotto?.obbligatorio ? 'Si' : 'No'}}
                                    </nz-list-item>                                    
                                    <nz-list-item>
                                        <span nz-typography><span class="text-muted">Codice Tessera:</span></span> {{prodotto?.codice ? 'Si' : 'No'}}
                                    </nz-list-item>                                    
                                    <nz-list-item>
                                        <span nz-typography><span class="text-muted">Punti di ritiro richiesti:</span></span> {{prodotto?.informativo ? 'Si' : 'No'}}
                                    </nz-list-item>
                                    <nz-list-item>
                                        <span nz-typography><span class="text-muted">Modulo assicurazione richiesto:</span></span> <a *ngIf="prodotto?.assicurativo" (click)="onPDF()">Si</a> <p class="mb-0" *ngIf="!prodotto?.assicurativo">No</p>
                                    </nz-list-item>
                                    <nz-list-item>
                                        <span nz-typography><span class="text-muted">Fototessera richiesta:</span></span> {{prodotto?.fototessera ? 'Si' : 'No'}}
                                    </nz-list-item>
                                    <nz-list-item>
                                        <span nz-typography><span class="text-muted">Acquistabile con bonifico:</span></span> {{prodotto?.bonifico ? 'Si' : 'No'}}
                                    </nz-list-item>
                                    <nz-list-item>
                                        <span nz-typography><span class="text-muted">Acquistabile con carta:</span></span> {{prodotto?.carta ? 'Si' : 'No'}}
                                    </nz-list-item>
                                    <nz-list-item>
                                        <span nz-typography><span class="text-muted">Acquistabile con satispay:</span></span> {{prodotto?.satispay ? 'Si' : 'No'}}
                                    </nz-list-item>
                                </nz-collapse-panel>
                              </nz-collapse>
                        </nz-list-item>                  
                        <nz-list-item class="p-0">
                            <nz-collapse nzAccordion [nzExpandIconPosition]="'right'" class="w-100" style="background-color: transparent; border: 0px solid; padding-left: 8px;">
                                <nz-collapse-panel [nzHeader]="'Altro'" style="border-bottom: 0px;" class="cursor-pointer">
                                    <nz-list-item>
                                        <span nz-typography><span class="text-muted">Data creazione:</span></span> {{prodotto?.data_creazione | date: 'dd/MM/yyyy HH:mm'}}
                                    </nz-list-item>
                                    <nz-list-item>
                                        <span nz-typography><span class="text-muted">Data aggiornamento:</span></span> {{prodotto?.data_aggiornamento | date: 'dd/MM/yyyy HH:mm'}}
                                    </nz-list-item>
                                </nz-collapse-panel>
                              </nz-collapse>
                        </nz-list-item>                    
                    </nz-list>                
                </div>            
            </div>
            <div class="col-lg-6">
                
                <div class="mt-3">
                    <ng-template #infoHeader>      
                        <div class="header">
                            <h5 class="d-inline text-white">Informazioni</h5>                        
                        </div>
                    </ng-template>
        
                    <nz-list nzBordered [nzHeader]="infoHeader" class="lista">
                        <nz-list-item>
                            <span nz-typography><span class="text-muted">Vendibile a partire dal:</span></span> {{prodotto?.inizio_validita | date: 'dd/MM/yyyy'}}
                        </nz-list-item>
                        <nz-list-item>
                            <span nz-typography><span class="text-muted">Vendibile fino al:</span></span> {{prodotto?.fine_validita | date: 'dd/MM/yyyy'}}
                        </nz-list-item>
                        <nz-list-item *ngIf="prodotto?.giornaliero">
                            <span nz-typography><span class="text-muted">Giorni:</span></span> {{prodotto?.giorni}}
                        </nz-list-item> 
                        <nz-list-item *ngIf="prodotto?.date_specifiche">
                            <span nz-typography><span class="text-muted">Date specifiche:</span></span> {{prodotto?.date_specifiche_dal | date: 'dd/MM/yyyy'}} - {{prodotto?.date_specifiche_al | date: 'dd/MM/yyyy'}}
                        </nz-list-item>                        
                    </nz-list>    
                </div>            
                
                <div class="mt-3">
                    <ng-template #fasceHeader>      
                        <div class="header">
                            <h5 class="d-inline text-white">Fasce di età</h5>
                            <span class="badge bg-white text-dark rounded-pill float-end mt-1">{{fasce.length}}</span>
                        </div>
                    </ng-template>
        
                    <nz-list nzBordered [nzHeader]="fasceHeader" class="lista">
                        <nz-list-item class="cursor-pointer" *ngFor="let fascia of fasce" (click)="onFascia(fascia.id)">
                            <span nz-typography>{{fascia.eta_min}} - {{fascia.eta_max}} | {{fascia.descrizione}}</span>{{fascia.prezzo | currency: 'EUR'}}
                        </nz-list-item>     
                        <nz-list-item *ngIf="fasce.length <= 0">
                            <span nz-typography>Nessuna fascia presente</span>
                        </nz-list-item>                  
                    </nz-list>    
                </div>            
            </div>
        </div>
    </nz-card>
</div>