import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from 'src/app/service/api.service';

@Component({
  selector: 'app-elimina-fasce-prezzi-prodotto',
  templateUrl: './elimina-fasce-prezzi-prodotto.component.html',
  styleUrls: ['./elimina-fasce-prezzi-prodotto.component.css']
})
export class EliminaFascePrezziProdottoComponent {

  constructor(private apiService: ApiService, private dialogRef: MatDialogRef<EliminaFascePrezziProdottoComponent>, @Inject(MAT_DIALOG_DATA) public data: { id: number }) { }

  onElimina() {

    // Elimino le fasce del prodotto
    this.apiService.deleteFasciaProdotti(this.data.id).subscribe({
      next: (data) => { },
      error: (err) => this.apiService.Error(err.error.message),
      complete: () => this.dialogRef.close(true)
    })

  }

  onClose() {
    this.dialogRef.close(false)
  }

}