import { Injectable, Pipe, PipeTransform } from "@angular/core";
import { Categoria, Cliente, CodiceSconto, Configurazione, Gruppo, Log, Movimenti, Ordine, Prodotto, Promozione, PuntoRitiro, Tesseramento, Utente } from "./interfacce";


@Injectable({
    providedIn: 'root'
})
@Pipe({
    name: 'searchClientiNomeCognome',
    pure: false
})
export class SearchClientiNomeCognomePipe implements PipeTransform {
    transform(clienti: any, query: string): any {
        return clienti.filter((cliente: Cliente) => ((cliente.cognome.trim() + ' ' + cliente.nome.trim()).toString().trim().toLocaleLowerCase().indexOf(query.toLocaleLowerCase()) !== -1 || (cliente.nome.trim() + ' ' + cliente.cognome.trim()).toString().trim().toLocaleLowerCase().indexOf(query.toLocaleLowerCase()) !== -1))
    }
}

@Injectable({
    providedIn: 'root'
})
@Pipe({
    name: 'searchClientiEmail',
    pure: false
})
export class SearchClientiEmailPipe implements PipeTransform {
    transform(clienti: any, query: string): any {
        return clienti.filter((cliente: Cliente) => (cliente?.email || '')?.toLowerCase().indexOf(query.toLowerCase()) >= 0)
    }
}

@Pipe({
    name: 'searchGruppiNome',
    pure: false
})
export class SearchGruppiNomePipe implements PipeTransform {
    transform(gruppi: any, query: string): any {
        return gruppi.filter((gruppo: Gruppo) => gruppo.nome?.toLowerCase().indexOf(query.toLowerCase()) >= 0)
    }
}

@Pipe({
    name: 'searchGruppiCapogruppo',
    pure: false
})
export class SearchGruppiCapogruppoPipe implements PipeTransform {
    transform(gruppi: any, query: string): any {
        return gruppi.filter((gruppo: Gruppo) => gruppo.cliente?.toLowerCase().indexOf(query.toLowerCase()) >= 0)
    }
}

@Pipe({
    name: 'searchTesseramentiSocio',
    pure: false
})
export class SearchTesseramentiSocioPipe implements PipeTransform {
    transform(tesseramenti: any, query: string): any {
        return tesseramenti.filter((tesseramento: Tesseramento) => ((tesseramento.cognome.trim() + ' ' + tesseramento.nome.trim()).toString().trim().toLocaleLowerCase().indexOf(query.toLocaleLowerCase()) !== -1 || (tesseramento.nome.trim() + ' ' + tesseramento.cognome.trim()).toString().trim().toLocaleLowerCase().indexOf(query.toLocaleLowerCase()) !== -1))
    }
}

@Pipe({
    name: 'searchTesseramentiStato',
    pure: false
})
export class SearchTesseramentiStatoPipe implements PipeTransform {
    transform(tesseramenti: any, query: string): any {
        return tesseramenti.filter((tesseramento: Tesseramento) => tesseramento.stato!.toLowerCase().indexOf(query.toLowerCase()) >= 0)
    }
}

@Pipe({
    name: 'searchTesseramentiProdotto',
    pure: false
})
export class SearchTesseramentiProdottoPipe implements PipeTransform {
    transform(tesseramenti: any, query: string): any {
        return tesseramenti.filter((tesseramento: Tesseramento) => tesseramento.prodotto?.toLowerCase().indexOf(query.toLowerCase()) >= 0)
    }
}

@Pipe({
    name: 'searchTesseramentiCategoriaData',
    pure: false
})
export class SearchTesseramentiCategoriaDataPipe implements PipeTransform {
    transform(tesseramenti: any, query: string): any {
        if (query) {
            let data_inizio = new Date(query[0]).getTime();
            let data_fine = new Date(query[1]).getTime();
            return tesseramenti.filter((tesseramento: Tesseramento) => data_inizio <= new Date(tesseramento.data_creazione).getTime() && data_fine >= new Date(tesseramento.data_creazione).getTime());
        } else {
            return tesseramenti;
        }
    }
}

@Pipe({
    name: 'searchTesseramentiCategoriaPagati',
    pure: false
})
export class SearchTesseramentiCategoriaPagatiPipe implements PipeTransform {
    transform(tesseramenti: any, pagato: boolean): any {
        return tesseramenti.filter((tesseramento: Tesseramento) => {
            if (pagato) {
                return tesseramento.residuo != 0;
            } else {
                return tesseramento;
            }
        })
    }
}

@Pipe({
    name: 'searchTesseramentiCategoriaAttivi',
    pure: false
})
export class SearchTesseramentiCategoriaAttiviPipe implements PipeTransform {
    transform(tesseramenti: any, attivo: boolean): any {
        return tesseramenti.filter((tesseramento: Tesseramento) => {
            if (attivo) {
                return tesseramento.attivo == false && tesseramento.stato == 'valido';
            } else {
                return tesseramento;
            }
        })
    }
}

@Pipe({
    name: 'searchOrdiniCliente',
    pure: false
})
export class SearchOrdiniClientePipe implements PipeTransform {
    transform(ordini: any, query: string): any {
        return ordini.filter((ordine: Ordine) => ordine.clienti?.toLowerCase().indexOf(query.toLowerCase()) >= 0)
    }
}

@Pipe({
    name: 'searchOrdiniProdotto',
    pure: false
})
export class SearchOrdiniProdottoPipe implements PipeTransform {
    transform(ordini: any, query: string): any {
        return ordini.filter((ordine: Ordine) => ordine.prodotti?.toLowerCase().indexOf(query.toLowerCase()) >= 0)
    }
}

@Pipe({
    name: 'searchOrdiniResiduo',
    pure: false
})
export class SearchOrdiniResiduoPipe implements PipeTransform {
    transform(ordini: any, query: string): any {
        return ordini.filter((ordine: Ordine) => {            
            if (query == 'pagato') {
                return ordine.residuo == 0 || ordine.residuo == 0.00;
            } else if (query == 'non pagato') {
                return ordine.residuo != 0;
            } else {
                return ordine
            }
        })
    }
}


@Pipe({
    name: 'searchProdottiNome',
    pure: false
})
export class SearchProdottiNomePipe implements PipeTransform {
    transform(prodotti: any, query: string): any {
        return prodotti.filter((prodotto: Prodotto) => prodotto.nome?.toLowerCase().indexOf(query.toLowerCase()) >= 0)
    }
}

@Pipe({
    name: 'searchProdottiDescrizione',
    pure: false
})
export class SearchProdottiDescrizionePipe implements PipeTransform {
    transform(prodotti: any, query: string): any {
        return prodotti.filter((prodotto: Prodotto) => (prodotto.descrizione || '')?.toLowerCase().indexOf(query.toLowerCase()) >= 0)
    }
}

@Pipe({
    name: 'searchCategorieNome',
    pure: false
})
export class SearchCategorieNomePipe implements PipeTransform {
    transform(categorie: any, query: string): any {
        return categorie.filter((categoria: Categoria) => categoria.nome?.toLowerCase().indexOf(query.toLowerCase()) >= 0)
    }
}

@Pipe({
    name: 'searchPromozioniNome',
    pure: false
})
export class SearchPromozioniNomePipe implements PipeTransform {
    transform(promozioni: any, query: string): any {
        return promozioni.filter((promozione: Promozione) => promozione.nome?.toLowerCase().indexOf(query.toLowerCase()) >= 0)
    }
}

@Pipe({
    name: 'searchPromozioniProdotto',
    pure: false
})
export class SearchPromozioniProdottoPipe implements PipeTransform {
    transform(promozioni: any, query: string): any {
        return promozioni.filter((promozione: Promozione) => promozione.nome_prodotto?.toLowerCase().indexOf(query.toLowerCase()) >= 0)
    }
}

@Pipe({
    name: 'searchMovimentiDescrizione',
    pure: false
})
export class SearchMovimentiDescrizionePipe implements PipeTransform {
    transform(movimenti: any, query: string): any {
        return movimenti.filter((movimento: Movimenti) => (movimento.descrizione || '')?.toLowerCase().indexOf(query.toLowerCase()) >= 0)
    }
}

@Pipe({
    name: 'searchMovimentiCliente',
    pure: false
})
export class SearchMovimentiClientePipe implements PipeTransform {
    transform(movimenti: any, query: string): any {
        return movimenti.filter((movimento: Movimenti) => (movimento.cliente || '')?.toLowerCase().indexOf(query.toLowerCase()) >= 0)
    }
}

@Pipe({
    name: 'searchUtentiUsername',
    pure: false
})
export class SearchUtentiUsernamePipe implements PipeTransform {
    transform(utenti: any, query: string): any {
        return utenti.filter((utente: Utente) => utente.username?.toLowerCase().indexOf(query.toLowerCase()) >= 0)
    }
}

@Pipe({
    name: 'searchUtentiTipo',
    pure: false
})
export class SearchUtentiTipoPipe implements PipeTransform {
    transform(utenti: any, query: string): any {
        return utenti.filter((utente: Utente) => utente.tipo?.toLowerCase().indexOf(query.toLowerCase()) >= 0)
    }
}

@Pipe({
    name: 'searchLogUtenti',
    pure: false
})
export class SearchLogUtentiPipe implements PipeTransform {
    transform(logs: any, query: string): any {
        return logs.filter((log: Log) => log.username?.toLowerCase().indexOf(query.toLowerCase()) >= 0)
    }
}

@Pipe({
    name: 'searchLogDescrizione',
    pure: false
})
export class SearchLogDescrizionePipe implements PipeTransform {
    transform(logs: any, query: string): any {
        return logs.filter((log: Log) => log.descrizione?.toLowerCase().indexOf(query.toLowerCase()) >= 0)
    }
}

@Pipe({
    name: 'searchPuntiRitiroDescrizione',
    pure: false
})
export class SearchPuntiRitiroDescrizionePipe implements PipeTransform {
    transform(punti_ritiro: any, query: string): any {
        return punti_ritiro.filter((punto_ritiro: PuntoRitiro) => punto_ritiro.descrizione?.toLowerCase().indexOf(query.toLowerCase()) >= 0)
    }
}

@Pipe({
    name: 'searchCodiciScontoCliente',
    pure: false
})
export class SearchCodiciScontoClientePipe implements PipeTransform {
    transform(codici_sconto: any, query: string): any {
        return codici_sconto.filter((codice_sconto: CodiceSconto) => codice_sconto.cliente?.toLowerCase().indexOf(query.toLowerCase()) >= 0)
    }
}

@Pipe({
    name: 'searchCodiciScontoCodice',
    pure: false
})
export class SearchCodiciScontoCodicePipe implements PipeTransform {
    transform(codici_sconto: any, query: string): any {
        return codici_sconto.filter((codice_sconto: CodiceSconto) => (codice_sconto.codice || '')?.toLowerCase().indexOf(query.toLowerCase()) >= 0)
    }
}

@Pipe({
    name: 'searchConfigurazioneDescrizione',
    pure: false
})
export class SearchConfigurazioneDescrizionePipe implements PipeTransform {
    transform(configurazioni: any, query: string): any {
        return configurazioni.filter((configurazione: Configurazione) => configurazione.descrizione?.toLowerCase().indexOf(query.toLowerCase()) >= 0)
    }
}