import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ApiService } from 'src/app/service/api.service';
import { FasceProdotti, Prodotto } from 'src/app/shared/interfacce';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-aggiungi-promozione',
  templateUrl: './aggiungi-promozione.component.html',
  styleUrls: ['./aggiungi-promozione.component.css']
})
export class AggiungiPromozioneComponent implements OnInit {

  constructor(private apiService: ApiService, private dialogRef: MatDialogRef<AggiungiPromozioneComponent>, private datePipe: DatePipe) {}

  formPromozione!: FormGroup;
    
  prodotti!: Prodotto[];    
  fasce: FasceProdotti[] = [];
  
  giorni: string[] = ['Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato', 'Domenica'];       

  error_messages = {
    'nome': [
      { type: 'required', message: 'Inserire un nome per procedere con la creazione della promozione'},
      { type: 'minlength', message: 'Inserire almeno 5 caratteri'}
    ],
    'descrizione': [
      { type: 'required', message: 'Inserire un descrizione per procedere con la creazione della promozione'}
    ],
    'prodotto': [
      { type: 'required', message: 'Inserire un prodotto per procedere con la creazione della promozione'}
    ],
    'percentuale': [
      { type: 'required', message: 'Inserire un percentuale per procedere con la creazione della promozione'}
    ],
    'giornaliero': [
      { type: 'required', message: 'Inserire un giornaliero per procedere con la creazione della promozione'}
    ],
    'sconto': [
      { type: 'required', message: 'Inserire un sconto per procedere con la creazione della promozione'},
      { type: 'pattern', message: 'Inserire un sconto valido'}
    ],
    'inizio_validita': [
      { type: 'required', message: 'Inserire un inizio di validita per procedere con la creazione della promozione'},
      { type: 'matDatepickerFilter', message: 'Inserire un inizio validita valido' }
    ],
    'fine_validita': [
      { type: 'required', message: 'Inserire una fine di validita per procedere con la creazione della promozione'},
      { type: 'matDatepickerFilter', message: 'Inserire un inizio validita valido' }
    ],
    'giorni': [
      { type: 'required', message: 'Inserire dei giorni per procedere con la creazione della promozione'}
    ],
    'inizio_orario': [
      { type: 'required', message: 'Inserire un inizio_orario per procedere con la creazione della promozione'}
    ],
    'fine_orario': [
      { type: 'required', message: 'Inserire un fine_orario per procedere con la creazione della promozione'}
    ],   
    'eta_min': [
      { type: 'min', message: 'Inserire come minimo 0 anni di età' },
      { type: 'max', message: 'Inserire come massimo 119 anni di età' },
      { type: 'required', message: "Inserire un'età minima per procedere con la creazione della promozione" },
    ],
    'eta_max': [
      { type: 'min', message: 'Inserire come minimo 1 anni di età' },
      { type: 'max', message: 'Inserire come massimo 120 anni di età' },
      { type: 'required', message: "Inserire un'età massima per procedere con la creazione della promozione" },
    ],
  }
  
  ngOnInit(): void {  
    
    // Creo il form con i controlli
    this.formPromozione = new FormGroup({
      nome: new FormControl(null, Validators.required),      
      descrizione: new FormControl(null),      
      prodotto: new FormControl(null, Validators.required),
      eta_max: new FormControl(null),
      eta_min: new FormControl(null),
      percentuale: new FormControl(null),      
      giornaliero: new FormControl(null),
      sconto: new FormControl(null, [Validators.required,Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$') ]),
      inizio_validita: new FormControl(null, Validators.required),
      fine_validita: new FormControl(null, Validators.required),  
      giorni: new FormControl(null),
      inizio_orario: new FormControl(null),
      fine_orario: new FormControl(null)
    })

    // Recupero i prodotti
    this.apiService.getProdotti().subscribe({
      next: (data) => this.prodotti = [...data],
      error: (err) => this.apiService.Error(err.error.message)
    })

  }

  onGiornalieri(giornalieri: boolean) {

    // Rimuovo i validator per azzerare la situazione
    this.formPromozione.get('inizio_validita')?.clearValidators();
    this.formPromozione.get('fine_validita')?.clearValidators();
    this.formPromozione.get('giorni')?.clearValidators();

    // Se è stato selezionato come giornaliero procedo
    if (giornalieri) {     
           
      // Setto come obbligatorio selezionare almeno un giorno nel form
      this.formPromozione.get('giorni')?.setValidators(Validators.required);

    } else {

      // Setto come obbligatorio inserire una data di inizio e fine validità
      this.formPromozione.get('inizio_validita')?.setValidators(Validators.required);
      this.formPromozione.get('fine_validita')?.setValidators(Validators.required);

    }

    // Aggiorno la validità dei campi nel form
    this.formPromozione.get('inizio_validita')?.updateValueAndValidity();
    this.formPromozione.get('fine_validita')?.updateValueAndValidity();
    this.formPromozione.get('giorni')?.updateValueAndValidity();
    
  }

  onSubmit() {

    // Formo l'oggetto per l'api
    let promozione = {
      nome: this.formPromozione.get('nome')?.value,      
      descrizione: this.formPromozione.get('descrizione')?.value,
      id_prodotto: this.formPromozione.get('prodotto')?.value,  
      eta_min: this.formPromozione.get('eta_min')?.value || 1,
      eta_max: this.formPromozione.get('eta_max')?.value || 120,
      percentuale: this.formPromozione.get('percentuale')?.value ? true : false,      
      giornaliero: this.formPromozione.get('giornaliero')?.value ? true : false,
      sconto: this.formPromozione.get('sconto')?.value,
      inizio_validita: this.formPromozione.get('giornaliero')?.value ? null : this.datePipe.transform(this.formPromozione.get('inizio_validita')?.value, 'yyyy-MM-dd'),
      fine_validita: this.formPromozione.get('giornaliero')?.value ? null : this.datePipe.transform(this.formPromozione.get('fine_validita')?.value, 'yyyy-MM-dd'),
      giorni: this.formPromozione.get('giornaliero')?.value ? JSON.stringify(this.formPromozione.get('giorni')?.value) : null,
      inizio_orario: this.datePipe.transform(this.formPromozione.get('inizio_orario')?.value, 'HH:mm'),
      fine_orario: this.datePipe.transform(this.formPromozione.get('fine_orario')?.value, 'HH:mm')      
    }      

    // Aggiungo la promozione al db
    this.apiService.addPromozione(promozione).subscribe({
      next: (data) => this.apiService.setPromozioni(),
      error: (err) => this.apiService.Error(err.error.message),
      complete: () => this.dialogRef.close(true)
    })

  }

  onClose() {
    this.dialogRef.close(false);
  }
}