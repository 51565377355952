<div class="p-3">
    <ng-template #clientiTitle>
        <div class="row p-md-3 p-1">
            <div class="col-lg-2 my-auto">            
                <div class="border rounded foto" [style.background-image]="'url(' + immagine + '), url(../../../assets/img/default-user.jpg)'" (click)="onDownload()" nzTooltipTitle="Se presente, clicca per scaricare l'immagine!" nz-tooltip nzTooltipPlacement="top"></div>                 
            </div>     
            <div class="col-lg-6 my-auto ps-4">            
                <h3 style="white-space: break-spaces;">{{cliente?.nome}} {{cliente?.cognome}}</h3>
                <h5 class="col text-muted">Cliente #{{cliente?.id}}</h5>                
            </div>   
            <div class="col-lg-4 my-auto">
                <nz-button-group class="w-100">
                    <button nz-button nzSize="large" class="w-100 crop" type="button" (click)="onModifica()">Modifica il cliente</button>
                    <button nz-button nzSize="large" class="w-25" nz-dropdown [nzDropdownMenu]="menu1" nzPlacement="bottomRight">
                        <span nz-icon nzType="ellipsis"></span>
                    </button>
                </nz-button-group>
                <nz-dropdown-menu #menu1="nzDropdownMenu">
                    <ul nz-menu>                        
                        <li nz-menu-item class="p-3" (click)="onContatti()">Gestisci i contatti <i class="ms-2 bi bi-person-lines-fill"></i></li>
                        <li nz-menu-item class="p-3" (click)="onGruppi()">Gestisci i gruppi <i class="ms-2 bi bi-people-fill"></i></li>
                        <li nz-menu-item class="p-3" (click)="onImpersonate()">Impersona l'utente <i class="ms-2 bi bi-person-workspace"></i></li>
                        <li nz-menu-item class="p-3" (click)="onOrder()">Crea un ordine/tesseramento <i class="ms-2 bi bi-cart4"></i></li>
                        <li nz-menu-item class="p-3" (click)="onEliminaImmagine()" *ngIf="immagine.length > 0">Elimina l'immagine <i class="ms-2 bi bi-card-image"></i></li>
                        <li nz-menu-item class="p-3" (click)="onElimina()">Elimina il cliente <i class="ms-2 bi bi-person-dash-fill"></i></li>
                    </ul>
                </nz-dropdown-menu>
            </div>          
        </div>
    </ng-template>
    <nz-card [nzTitle]="clientiTitle" class="mt-5 mb-5">
        <div class="row p-md-3 p-1">
            <div class="col">
                <nz-breadcrumb>
                    <nz-breadcrumb-item>
                        <a routerLink="/home">
                            <span nz-icon nzType="home"></span>
                        </a>                  
                    </nz-breadcrumb-item>
                    <nz-breadcrumb-item>
                      <a routerLink="/clienti">                    
                        <span>Clienti</span>
                      </a>
                    </nz-breadcrumb-item>
                    <nz-breadcrumb-item>Dettaglio cliente</nz-breadcrumb-item>
                </nz-breadcrumb>
            </div>
        </div>
        <div class="row p-md-3 p-1" style="padding-top: 0px !important;">
            <div class="col-lg-6">
                <div class="mt-3">
    
                    <ng-template #datiHeader>
                        <div class="header">
                            <h5 class="d-inline text-white">Dati</h5>
                            <a (click)="exportData()" class="float-end text-white">Esporta in excel</a>
                        </div>                    
                    </ng-template>
    
                    <nz-list nzBordered [nzHeader]="datiHeader" class="lista">
                        <nz-list-item>
                            <span nz-typography><span class="text-muted">N°</span></span> {{cliente?.id}}
                        </nz-list-item>
                        <nz-list-item>
                            <span nz-typography><span class="text-muted">Nome:</span></span> {{cliente?.nome}}
                        </nz-list-item>
                        <nz-list-item>
                            <span nz-typography><span class="text-muted">Cognome:</span></span> {{cliente?.cognome}}
                        </nz-list-item>
                        <nz-list-item>
                            <span nz-typography><span class="text-muted">Data di nascita:</span></span> {{cliente?.data_nascita | date: 'dd MMM yyyy'}}
                        </nz-list-item>
                        <nz-list-item>
                            <span nz-typography><span class="text-muted">Codice fiscale:</span></span> {{cliente?.codice_fiscale}}
                        </nz-list-item>
                        <nz-list-item>
                            <span nz-typography><span class="text-muted">Telefono:</span></span>{{cliente?.telefono}}                        
                        </nz-list-item>
                        <nz-list-item>
                            <span nz-typography><span class="text-muted">Email:</span></span> {{cliente?.email | lowercase}}
                        </nz-list-item>
                        <nz-list-item class="p-0">
                            <nz-collapse nzAccordion [nzExpandIconPosition]="'right'" class="w-100" style="background-color: transparent; border: 0px solid; padding-left: 8px;">
                                <nz-collapse-panel [nzHeader]="'Codici tesseramenti'" style="border-bottom: 0px;" class="cursor-pointer">
                                    <nz-list-item>
                                        <span nz-typography><span class="text-muted">SkiPass Open:</span></span> {{skipass_open}}
                                    </nz-list-item>
                                    <nz-list-item>
                                        <span nz-typography><span class="text-muted">SkiCard ViaLattea:</span></span> {{skicard_vialattea}}
                                    </nz-list-item>
                                </nz-collapse-panel>
                              </nz-collapse>
                        </nz-list-item>
                        <nz-list-item class="p-0">
                            <nz-collapse nzAccordion [nzExpandIconPosition]="'right'" class="w-100" style="background-color: transparent; border: 0px solid;padding-left: 8px;">
                                <nz-collapse-panel [nzHeader]="'Info'" style="border-bottom: 0px;" class="cursor-pointer">
                                    <nz-list-item>
                                        <span class="text-muted">Comune di nascita:</span> {{cliente?.comune_nascita | titlecase}}
                                    </nz-list-item>
                                    <nz-list-item>
                                        <span class="text-muted">Provincia di nascita:</span> {{cliente?.provincia_nascita | titlecase}}
                                    </nz-list-item>
                                    <nz-list-item>
                                        <span class="text-muted">Stato di nascita:</span> {{cliente?.nazione_nascita | titlecase}}
                                    </nz-list-item>
                                    <nz-list-item>
                                        <span class="text-muted">Indirizzo di residenza:</span> {{cliente?.indirizzo | titlecase}}
                                    </nz-list-item>
                                    <nz-list-item>
                                        <span class="text-muted">Comune di residenza:</span> {{cliente?.comune | titlecase}}
                                    </nz-list-item>
                                    <nz-list-item>
                                        <span class="text-muted">Provincia di residenza:</span> {{cliente?.provincia | titlecase}}
                                    </nz-list-item>
                                    <nz-list-item>
                                        <span class="text-muted">Stato di residenza:</span> {{cliente?.nazione | titlecase}}
                                    </nz-list-item>
                                    <nz-list-item>
                                        <span class="text-muted">Newsletter</span> {{cliente?.newsletter ? 'Si' : 'No'}}
                                    </nz-list-item>
                                </nz-collapse-panel>
                              </nz-collapse>
                        </nz-list-item>
                        <nz-list-item class="p-0">
                            <nz-collapse nzAccordion [nzExpandIconPosition]="'right'" class="w-100" style="background-color: transparent; border: 0px solid; padding-left: 8px;">
                                <nz-collapse-panel [nzHeader]="'Altro'" style="border-bottom: 0px;" class="cursor-pointer">
                                    <nz-list-item>
                                        <span nz-typography><span class="text-muted">Data creazione:</span></span> {{cliente?.data_creazione | date: 'dd/MM/yyyy HH:mm'}}
                                    </nz-list-item>
                                    <nz-list-item>
                                        <span nz-typography><span class="text-muted">Data aggiornamento:</span></span> {{cliente?.data_aggiornamento | date: 'dd/MM/yyyy HH:mm'}}
                                    </nz-list-item>
                                </nz-collapse-panel>
                              </nz-collapse>
                        </nz-list-item>    
                    </nz-list>                
                </div>
                <div class="mt-3">
    
                    <ng-template #gruppiHeader>
                        <div class="header">
                            <h5 class="d-inline text-white">Gruppi di appartenenza</h5>
                            <span class="badge bg-white text-dark rounded-pill float-end mt-1">{{gruppi.length}}</span>
                        </div>
                    </ng-template>
    
                    <nz-list nzBordered [nzHeader]="gruppiHeader" class="lista">
                            <nz-list-item [routerLink]="['/gruppi/', gruppo.id]" *ngFor="let gruppo of (show_all_gruppi ? gruppi : gruppi.slice(0, 10))" class="cursor-pointer">
                            <span nz-typography>{{gruppo?.nome}}</span>
                        </nz-list-item>     
                        <nz-list-item *ngIf="gruppi.length > 10 && !show_all_gruppi" (click)="show_all_gruppi = !show_all_gruppi" class="cursor-pointer">
                            <span nz-typography>Mostra tutti ...</span>
                        </nz-list-item>
                        <nz-list-item *ngIf="show_all_gruppi" (click)="show_all_gruppi = !show_all_gruppi" class="cursor-pointer">
                            <span nz-typography>Mostra di meno ...</span>
                        </nz-list-item>
                        <nz-list-item *ngIf="gruppi.length <= 0">
                            <span nz-typography>Nessun gruppo collegato</span>
                        </nz-list-item>                  
                    </nz-list>  
                </div>
            </div>
            <div class="col-lg-6">
                
                <div class="mt-3">
                    <ng-template #contattiHeader>      
                        <div class="header">
                            <h5 class="d-inline text-white">Contatti</h5>
                            <span class="badge bg-white text-dark rounded-pill float-end mt-1">{{contatti.length}}</span>
                        </div>
                    </ng-template>
        
                    <nz-list nzBordered [nzHeader]="contattiHeader" class="lista">
                        <nz-list-item class="cursor-pointer" *ngFor="let contatto of (show_all_contatti ? contatti : contatti.slice(0, 10))" (click)="onModificaContatto(contatto.id)">
                            <span nz-typography>{{contatto.cognome}} {{contatto.nome}}</span>{{contatto.parentela}}
                        </nz-list-item>   
                        <nz-list-item *ngIf="contatti.length > 10 && !show_all_contatti" (click)="show_all_contatti = !show_all_contatti" class="cursor-pointer">
                            <span nz-typography>Mostra tutti ...</span>
                        </nz-list-item>
                        <nz-list-item *ngIf="show_all_contatti" (click)="show_all_contatti = !show_all_contatti" class="cursor-pointer">
                            <span nz-typography>Mostra di meno ...</span>
                        </nz-list-item>  
                        <nz-list-item *ngIf="contatti.length <= 0">
                            <span nz-typography>Nessun contatto associato</span>
                        </nz-list-item>                  
                    </nz-list>    
                </div>                                            
    
                <div class="mt-3">   
                    <ng-template #tesseramentiHeader>   
                        <div class="header">
                            <h5 class="d-inline text-white">Ordini/Tesseramenti</h5>
                            <span class="badge bg-white text-dark rounded-pill float-end mt-1">{{tesseramenti.length}}</span>
                        </div>                                 
                    </ng-template>
        
                    <nz-list nzBordered [nzHeader]="tesseramentiHeader" class="lista">
                        <nz-list-item class="cursor-pointer" [routerLink]="['/tesseramenti/', tesseramento.id]" *ngFor="let tesseramento of (show_all_tesseramenti ? tesseramenti : tesseramenti.slice(0, 10))">
                            <span nz-typography>{{tesseramento.prodotto}}</span>Scad. {{tesseramento.fine_validita | date:'dd/MM/yyyy'}}
                        </nz-list-item>     
                        <nz-list-item *ngIf="tesseramenti.length > 10 && !show_all_tesseramenti" (click)="show_all_tesseramenti = !show_all_tesseramenti" class="cursor-pointer">
                            <span nz-typography>Mostra tutti ...</span>
                        </nz-list-item>
                        <nz-list-item *ngIf="show_all_tesseramenti" (click)="show_all_tesseramenti = !show_all_tesseramenti" class="cursor-pointer">
                            <span nz-typography>Mostra di meno ...</span>
                        </nz-list-item>  
                        <nz-list-item *ngIf="tesseramenti.length <= 0">
                            <span nz-typography>Nessun ordine/tesseramento associato</span>
                        </nz-list-item>                  
                    </nz-list>                 
                </div>

                <div class="mt-3">   
                    <ng-template #movimentiHeader>   
                        <div class="header">
                            <h5 class="d-inline text-white">Movimenti</h5>
                            <span class="badge bg-white text-dark rounded-pill float-end mt-1">{{movimenti.length}}</span>
                        </div>                                 
                    </ng-template>
        
                    <nz-list nzBordered [nzHeader]="movimentiHeader" class="lista">
                        <nz-list-item class="cursor-pointer" [routerLink]="'/movimenti/' + movimento.id + (movimento.metodo.toLowerCase() == 'contanti' ? '/cassa/' : '/altri-metodi/')" *ngFor="let movimento of (show_all_movimenti ? movimenti : movimenti.slice(0, 10))">
                            <span nz-typography>{{movimento.descrizione}}</span>{{movimento.pagato | currency:'EUR'}} | Tot: {{movimento.totale | currency:'EUR'}}
                        </nz-list-item>   
                        <nz-list-item *ngIf="movimenti.length > 10 && !show_all_movimenti" (click)="show_all_movimenti = !show_all_movimenti" class="cursor-pointer">
                            <span nz-typography>Mostra tutti ...</span>
                        </nz-list-item>
                        <nz-list-item *ngIf="show_all_movimenti" (click)="show_all_movimenti = !show_all_movimenti" class="cursor-pointer">
                            <span nz-typography>Mostra di meno ...</span>
                        </nz-list-item>    
                        <nz-list-item *ngIf="movimenti.length <= 0">
                            <span nz-typography>Nessun movimento associato</span>
                        </nz-list-item>                  
                    </nz-list>                 
                </div>
            </div>
        </div>
    </nz-card>
</div>