import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/service/api.service';

@Component({
  selector: 'app-elimina-tesseramento',
  templateUrl: './elimina-tesseramento.component.html',
  styleUrls: ['./elimina-tesseramento.component.css']
})
export class EliminaTesseramentoComponent {

  constructor(private dialogRef: MatDialogRef<EliminaTesseramentoComponent>, @Inject(MAT_DIALOG_DATA) public data : {id: number}, private router: Router, private apiService: ApiService) { }

  onElimina() {   
    
    // Elimino il tesseramento
    this.apiService.deleteTesseramento(this.data.id).subscribe({
      next: (data) => this.dialogRef.close(true),
      error: (err) => this.apiService.Error(err.error.message),
      complete: () => this.router.navigate(['/tesseramenti'])
    })

  }

  onClose() {
    this.dialogRef.close();
  }

}
